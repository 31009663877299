import { AMERICAN_DATE_4Y, birthDateTime, differenceInYears } from "../../util/dates";
import type { BarePrismaClient } from "@procision-software/database";

export const PatientSmartFields = [
  { field: "patientFirstName", label: "Patient first name" },
  { field: "patientPreferredName", label: "Patient preferred name" },
  { field: "patientLastName", label: "Patient last name" },
  { field: "patientDateOfBirth", label: "Patient date of birth" },
  { field: "patientMrn", label: "Patient MRN#" },
  { field: "patientAge", label: "Patient age" },
] as const;

export const getPatientSmartFieldDataPrisma = async (
  prisma: BarePrismaClient,
  input: { patientId: string } | { encounterId: string }
) => {
  const patient = await prisma.patient.findFirstOrThrow({
    where: {
      ...("patientId" in input
        ? { id: input.patientId }
        : { encounters: { some: { id: input.encounterId } } }),
    },
  });
  return patientSmartFieldData(patient);
};

export function patientSmartFieldData(patient: {
  firstName: string;
  preferredName: string | null | undefined;
  lastName: string;
  dateOfBirth: Date;
  mrn: string;
}) {
  return {
    patientFirstName: patient.firstName,
    patientPreferredName: patient.preferredName ?? undefined,
    patientLastName: patient.lastName,
    patientDateOfBirth: birthDateTime(patient.dateOfBirth).toFormat(AMERICAN_DATE_4Y),
    patientMrn: patient.mrn,
    patientAge: differenceInYears(new Date(), patient.dateOfBirth).toString(),
  } satisfies Partial<Record<(typeof PatientSmartFields)[number]["field"], string | undefined>>;
}
