import { logger } from "@procision-software/asimov";
import type { BarePrismaClient } from "@procision-software/database";
import createTwilioClient from "twilio";
import { patientCommunicationWhitelistValidate } from "../../patient-communication-whitelist";
import type { PatientCommunicationRecord } from "../types";
import { patientCommunicationFindById, patientCommunicationUpdate } from "./database";

export async function patientCommunicationSend(
  prisma: BarePrismaClient,
  id: string,
  force?: boolean
) {
  const patientCommunication = await patientCommunicationFindById(prisma, id);

  if (patientCommunication.sentAt && !force) {
    throw new Error("Patient Communication has already been sent");
  }

  if (
    !(await patientCommunicationWhitelistValidate(prisma, {
      channel: patientCommunication.channel,
      to: patientCommunication.to,
    }))
  ) {
    return await patientCommunicationUpdate(prisma, {
      id,
      sentAt: new Date(),
      error: "NOT_WHITELISTED",
    });
  }

  switch (patientCommunication.channel) {
    case "SMS":
      return await patientCommunicationSendSMS(prisma, patientCommunication);
    default:
      throw new Error("Unsupported channel");
  }
}

/**
 * Transforms a PatientCommunication into a Twilio message and sends it. This should not be used
 * directly and it should be preferred to use the `patientCommunicationSend` function to properly
 * communicate with the patient.
 *
 * @private
 * @param prisma Prisma client
 * @param id Patient Communication ID
 */
export async function patientCommunicationSendSMS(
  prisma: BarePrismaClient,
  { id, preview: body, context, to }: PatientCommunicationRecord
) {
  const twilioClient = createTwilioClient(
    process.env.TWILIO_ACCOUNT_SID,
    process.env.TWILIO_AUTH_TOKEN
  );

  try {
    const twilioMessage = await twilioClient.messages.create({
      body,
      from: process.env.TWILIO_PHONE_NUMBER,
      to,
    });

    const error =
      twilioMessage.status === "failed" || twilioMessage.status === "undelivered"
        ? "INVALID_PHONE_NUMBER"
        : "NO_ERROR";

    return await patientCommunicationUpdate(prisma, {
      id,
      sentAt: new Date(),
      context: {
        ...(context as object),
        ...twilioMessage.toJSON(),
      },
      error,
    });
  } catch (someError) {
    logger.error("Error sending SMS", someError);

    const errorContext = JSON.parse(JSON.stringify(someError)) as object;

    const error = (() => {
      /**
       * Twilio error codes:
       * - 21211 (Invalid 'To' Phone Number)
       * - 21610 (Attempt to send to unsubscribed recipient)
       */
      switch (Reflect.get(someError as Error, "code") as number) {
        case 21211:
          return "INVALID_PHONE_NUMBER";
        case 21610:
          return "UNSUBSCRIBED";
        default:
          return "UNKNOWN_ERROR";
      }
    })();

    return await patientCommunicationUpdate(prisma, {
      id,
      sentAt: new Date(),
      context: {
        ...(context as object),
        ...{ error: errorContext },
      },
      error,
    });
  }
}
