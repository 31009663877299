import type { ForwardedRef } from "react";
import { forwardRef } from "react";
import { type FieldValues, type Path } from "react-hook-form";
import { mergeRefs } from "react-merge-refs";
import { cn } from "../../utils";
import { BoundField, type BoundFieldWithLegacyProps, useFilteredFieldProps } from "./BoundField";
import type { RadioProps, RadioValueType } from "./Radio";
import { Radio } from "./Radio";

type Ref = HTMLInputElement;

type RadioGroupProps<TValue extends RadioValueType> = Omit<RadioProps<TValue>, "children"> & {
  options: { label: string; value: TValue }[];
  orientation?: "horizontal" | "vertical";
  "data-testid"?: string;
};

const RadioInnerGroup = <TValue extends RadioValueType>(
  { value, options, orientation, ...props }: RadioGroupProps<TValue>,
  ref?: ForwardedRef<Ref>
) => {
  return (
    <div
      className={cn("flex", orientation === "vertical" ? "flex-col" : "flex-row flex-wrap gap-1")}
    >
      {options.map((opt, i) => {
        return (
          <Radio
            {...props}
            data-testid={`${props["data-testid"] ?? ""}_${opt.label ?? opt.value}`}
            id={undefined} // Allow each radio option to generate its own id
            key={i}
            ref={i === 0 ? ref : undefined}
            value={opt.value}
            checked={opt.value === value}
          >
            {opt.label}
          </Radio>
        );
      })}
    </div>
  );
};

export const RadioGroup = forwardRef(RadioInnerGroup) as <TValue extends RadioValueType>(
  props: RadioGroupProps<TValue> & {
    ref?: React.ForwardedRef<Ref>;
  }
) => ReturnType<typeof RadioInnerGroup>;

type RadioGroupFieldProps<
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
  TValue extends RadioValueType,
> = Omit<RadioGroupProps<TValue>, "name"> & BoundFieldWithLegacyProps<TFieldValues, TFieldPath>;

const RadioGroupFieldInner = <
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
  TValue extends RadioValueType,
>(
  props: RadioGroupFieldProps<TFieldValues, TFieldPath, TValue>,
  ref?: ForwardedRef<Ref>
) => {
  const { fieldProps, inputProps } = useFilteredFieldProps<
    TFieldValues,
    TFieldPath,
    RadioGroupFieldProps<TFieldValues, TFieldPath, TValue>
  >(props);

  return (
    <BoundField
      {...fieldProps}
      labelProps={{ ...fieldProps.labelProps, size: inputProps.size }}
      className={cn("!flex-none", fieldProps.className)}
      render={({ field }) => (
        <RadioGroup {...inputProps} {...field} ref={mergeRefs([ref, field.ref])} />
      )}
    />
  );
};

export const RadioGroupField = forwardRef(RadioGroupFieldInner) as <
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
  TValue extends RadioValueType,
>(
  props: RadioGroupFieldProps<TFieldValues, TFieldPath, TValue> & {
    ref?: React.ForwardedRef<Ref>;
  }
) => ReturnType<typeof RadioGroupFieldInner>;
