import { CurrencyType } from "@procision-software/database-zod";
import { DateTime } from "luxon";
import { z } from "zod";

// Stedi returns string numbers like "750" (dollars implied) for 75000 cents

type EligibilityCheckParams$V3 = {
  encounter: {
    serviceTypeCodes: string[];
    dateOfService: Date;
  };
  provider: {
    npi: string;
    organizationName: string;
  };
  tradingPartnerServiceId: string;
  controlNumber: string;
  informationReceiverName: {
    federalTaxpayerIdentificationNumber: string;
  };
  subscriber: {
    memberId: string;
    dateOfBirth: Date;
    firstName: string;
    lastName: string;
  };
};

// A = Coinsurance
// B = Copayment
// C = Deductible
// F = Limits
// G = Out of Pocket Max
const ServiceTypeCodeSchema$V3 = z.string().describe("ServiceTypeCode");
const SERVICE_TYPE_CODES = {
  COINSURANCE: "A",
  COPAYMENT: "B",
  DEDUCTIBLE: "C",
  OUT_OF_POCKET_MAX: "G",
};
const TIME_QUALIFIER_CODES = {
  CALENDAR_YEAR: "23",
  REMAINING: "29",
  UNDEFINED: undefined,
};
const SERVICE_CODE_HOSPITAL_OUTPATIENT = "50";
const ServiceTypeSchema$V3 = z.string().describe("ServiceType"); // Always 30?

const InsuranceEligibilitySchema$V3 = z.object({
  serviceTypeCodes: z.array(ServiceTypeCodeSchema$V3).optional(),
  coverageLevelCode: z.string().optional(),
  serviceTypes: z.array(ServiceTypeSchema$V3).optional(),
  inPlanNetworkIndicatorCode: z.string().optional(),
  inPlanNetworkIndicator: z.string().optional(),
  code: z.string().optional(),
  name: z.string().optional(),
  timeQualifierCode: z.string().optional(),
  benefitPercent: z.string().optional(), // stedi returns a string number like "80" for 80%
  benefitAmount: z.string().optional(), // stedi returns a string number like "750" for $750
});
type InsuranceEligibility$V3 = z.infer<typeof InsuranceEligibilitySchema$V3>;
const EligibilityCheckResultsSchema = z.object({
  benefitsInformation: z.array(InsuranceEligibilitySchema$V3),
});
type EligibilityCheckResults$V3 = z.infer<typeof EligibilityCheckResultsSchema>;
export class VerificationFailedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "VerificationFailed";
  }
}
/**
 *
 * @param parameters stedi's expected parameters for doing a check
 * @param options explicit localization options and keys to not be dependent on env
 * @returns typed results of the eligibility check
 * @throws Error if the check fails or returns malformed data
 */
async function eligibilityCheck$V3(
  parameters: EligibilityCheckParams$V3,
  options: { dateOfServiceTZ: string; dateOfBirthTZ: string; apiKey: string }
): Promise<EligibilityCheckResults$V3> {
  const YYYYMMDD = (d: Date, zone: string) => DateTime.fromJSDate(d, { zone }).toFormat("yyyyLLdd");
  const dateOfService = YYYYMMDD(parameters.encounter.dateOfService, options.dateOfServiceTZ);
  const dateOfBirth = YYYYMMDD(parameters.subscriber.dateOfBirth, options.dateOfBirthTZ);
  const response = await fetch(
    "https://healthcare.us.stedi.com/2024-04-01/change/medicalnetwork/eligibility/v3",
    {
      method: "POST",
      headers: {
        Authorization: options.apiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...parameters,
        encounter: {
          ...parameters.encounter,
          dateOfService,
        },
        subscriber: {
          ...parameters.subscriber,
          dateOfBirth,
        },
      }),
    }
  );
  const body: unknown = await response.json();
  if (body && typeof body === "object" && "message" in body) {
    throw new Error(body.message as string);
  }
  try {
    return EligibilityCheckResultsSchema.parse(body); // throws error if response is not valid
  } catch (_e) {
    // e is a zod error. Produce a better error.
    throw new VerificationFailedError("Verification failed");
  }
}

type Money = {
  amount: number;
  currency: CurrencyType;
};
type EligibilityCheckResults = {
  eligibilityCheckDate: Date;
  deductible: Money | null;
  deductibleRemaining: Money | null;
  outOfPocketMax: Money | null;
  outOfPocketMaxRemaining: Money | null;
  coinsurance: number | null; // This is a float between 0-1 where 0=0% and 1=100%
  copayment: Money | null;
};

type ContractedNetworkStatus = "IN_NETWORK" | "OUT_OF_NETWORK";

/**
 *
 * @param parameters stedi's expected parameters for doing a check
 * @param contracted are we in or out of network for this check
 * @param checkBenefitsFor are we checking benefits for an individual (insurancePayment.relationshipToPatient === Self) or a family (insurancePayment.relationshipToPatient !== Self)
 * @param options localization options
 * @returns object with the results of the eligibility check if successful, null otherwise
 * @throws Error if stedi delivered a malformed response.
 */
export async function eligibilityCheck(
  parameters: EligibilityCheckParams$V3,
  contracted: ContractedNetworkStatus,
  options: { dateOfServiceTZ: string; dateOfBirthTZ: string; apiKey: string }
): Promise<EligibilityCheckResults | null> {
  const eligibilityCheckDate = new Date();
  const eligibility = await eligibilityCheck$V3(parameters, options); // throws error if response is not valid

  const processedResults = processEligibilityResults(eligibility, contracted);
  if (!processedResults) {
    return null;
  }

  return {
    eligibilityCheckDate,
    ...processedResults,
  };
}
class TooManyResultsError extends Error {
  constructor(message: string, data: unknown) {
    super(message);
    this.name = "TooManyResults";
    this.cause = data;
  }
}
export function processEligibilityResults(
  eligibility: EligibilityCheckResults$V3,
  contracted: ContractedNetworkStatus
): Omit<EligibilityCheckResults, "eligibilityCheckDate"> | null {
  const findBenefits = (filter: {
    code?: string;
    timeQualifierCode?: string;
    serviceTypeCode?: string;
    benefitsFor?: "IND" | "FAM" | undefined;
  }) =>
    eligibility.benefitsInformation.filter(
      (b) =>
        // most of the arrays are optional so can be undefined. If we're seeking a match out of a non existent array, we should return false
        (filter.code ? (b.code?.includes(filter.code) ?? false) : true) &&
        // especially for out of pocket max, the out of pocket max has no time qualifier code on it
        ("timeQualifierCode" in filter
          ? filter.timeQualifierCode
            ? b.timeQualifierCode === filter.timeQualifierCode
            : !("timeQualifierCode" in b)
          : true) &&
        (contracted === "IN_NETWORK"
          ? b.inPlanNetworkIndicatorCode === "Y"
          : b.inPlanNetworkIndicatorCode === "N") &&
        (filter.serviceTypeCode
          ? (b.serviceTypeCodes?.includes(filter.serviceTypeCode) ?? false)
          : true) &&
        (filter.benefitsFor ? b.coverageLevelCode === filter.benefitsFor : true)
    );
  const get = (field: "benefitPercent" | "benefitAmount", data: InsuranceEligibility$V3[]) => {
    if (data.length === 1 && data[0]?.[field]) {
      return parseFloat(data[0][field]);
    } else if (data.length > 1) {
      throw new TooManyResultsError(`Expected one result, got ${data.length}`, data);
    } else {
      return null;
    }
  };
  const currencyFromStedi = (amount: number | null) =>
    amount !== null ? { currency: CurrencyType.USD, amount: amount } : null;

  // We need to determine if we're checking benefits for an individual or a family
  const indDeductibleRemaining = get(
      "benefitAmount",
      findBenefits({
        code: SERVICE_TYPE_CODES.DEDUCTIBLE,
        timeQualifierCode: TIME_QUALIFIER_CODES.REMAINING,
        benefitsFor: "IND",
      })
    ),
    famDeductibleRemaining = get(
      "benefitAmount",
      findBenefits({
        code: SERVICE_TYPE_CODES.DEDUCTIBLE,
        timeQualifierCode: TIME_QUALIFIER_CODES.REMAINING,
        benefitsFor: "FAM",
      })
    );
  const indOopMaxRemaining = get(
      "benefitAmount",
      findBenefits({
        code: SERVICE_TYPE_CODES.OUT_OF_POCKET_MAX,
        timeQualifierCode: TIME_QUALIFIER_CODES.REMAINING,
        benefitsFor: "IND",
      })
    ),
    famOopMaxRemaining = get(
      "benefitAmount",
      findBenefits({
        code: SERVICE_TYPE_CODES.OUT_OF_POCKET_MAX,
        timeQualifierCode: TIME_QUALIFIER_CODES.REMAINING,
        benefitsFor: "FAM",
      })
    );

  const benefitsForDeductible =
    famDeductibleRemaining !== null &&
    indDeductibleRemaining !== null &&
    famDeductibleRemaining < indDeductibleRemaining
      ? "FAM"
      : "IND";
  const benefitsForOopMax =
    famOopMaxRemaining !== null &&
    indOopMaxRemaining !== null &&
    famOopMaxRemaining < indOopMaxRemaining
      ? "FAM"
      : "IND";
  return {
    deductible: currencyFromStedi(
      get(
        "benefitAmount",
        findBenefits({
          code: SERVICE_TYPE_CODES.DEDUCTIBLE,
          timeQualifierCode: TIME_QUALIFIER_CODES.CALENDAR_YEAR,
          benefitsFor: benefitsForDeductible,
        })
      )
    ),
    deductibleRemaining: currencyFromStedi(
      get(
        "benefitAmount",
        findBenefits({
          code: SERVICE_TYPE_CODES.DEDUCTIBLE,
          timeQualifierCode: TIME_QUALIFIER_CODES.REMAINING,
          benefitsFor: benefitsForDeductible,
        })
      )
    ),
    outOfPocketMax: currencyFromStedi(
      get(
        "benefitAmount",
        findBenefits({
          code: SERVICE_TYPE_CODES.OUT_OF_POCKET_MAX,
          timeQualifierCode: TIME_QUALIFIER_CODES.UNDEFINED,
          benefitsFor: benefitsForOopMax,
        })
      )
    ),
    outOfPocketMaxRemaining: currencyFromStedi(
      get(
        "benefitAmount",
        findBenefits({
          code: SERVICE_TYPE_CODES.OUT_OF_POCKET_MAX,
          timeQualifierCode: TIME_QUALIFIER_CODES.REMAINING,
          benefitsFor: benefitsForOopMax,
        })
      )
    ),
    coinsurance: get(
      "benefitPercent",
      findBenefits({
        code: SERVICE_TYPE_CODES.COINSURANCE,
        serviceTypeCode: SERVICE_CODE_HOSPITAL_OUTPATIENT,
      })
    ),
    copayment: currencyFromStedi(
      get(
        "benefitAmount",
        findBenefits({
          code: SERVICE_TYPE_CODES.COPAYMENT,
          serviceTypeCode: SERVICE_CODE_HOSPITAL_OUTPATIENT,
        })
      )
    ),
  };
}
