import { accessibleBy } from "@casl/prisma";
import { Prisma } from "@procision-software/database";
import { type AppAbility } from "../ability/permissions";

const ALLOW_LISTED_MODELS = [
  {
    model: "User",
    reason: "Everyone only has access to their own user and users are never enumerated",
  },
  {
    model: "FacilityHours",
    reason: "Facility hours are treated as part of the Facility ",
  },
  {
    model: "RoomFeature",
    reason: "This has not been built out yet",
  },
  {
    model: "FeatureOfRoom",
    reason: "This has not been built out yet",
  },
  {
    model: "TimePeriodSchedule",
    reason: "Schedule is treated as part of the TimePeriod",
  },
  {
    model: "Medication",
    reason: "This has not been built out yet",
  },
  {
    model: "PreferenceCardEquipment",
    reason: "PreferenceCardEquipment is treated as part of the PreferenceCard",
  },
].map((v) => v.model);

// https://www.prisma.io/docs/concepts/components/prisma-client/client-extensions/query#modify-all-operations-in-all-models-of-your-schema
const LOGGED_STATEMENTS = [
  "create",
  "createMany",
  "delete",
  "update",
  "deleteMany",
  "updateMany",
  "upsert",
];

// https://www.prisma.io/blog/client-extensions-preview-8t3w27xkrxxn#example-audit-log-context
export function forUser(userId: string, ability: AppAbility, log = true) {
  return Prisma.defineExtension((prisma) =>
    prisma.$extends({
      query: {
        $allModels: {
          async $allOperations({ model, args, operation, query }) {
            if (!model.endsWith("Version")) {
              const permissionType = {
                create: "create",
                createManyAndReturn: "create",
                delete: "delete",
                update: "update",
                findFirst: "read",
                findFirstOrThrow: "read",
                findMany: "read",
                findUnique: "read",
                findUniqueOrThrow: "read",
                aggregate: "read",
                count: "read",
                createMany: "create",
                deleteMany: "delete",
                groupBy: "read",
                updateMany: "update",
                upsert: "manage",
                updateManyAndReturn: "update",
              }[operation];

              const uniqueOperations = ["update", "delete", "findUnique", "findUniqueOrThrow"];
              const constraint = accessibleBy(ability, permissionType)[model];
              if (!["findUnique", "findUniqueOrThrow", "upsert"].includes(operation)) {
                if (
                  constraint &&
                  "where" in args &&
                  args.where &&
                  !uniqueOperations.includes(operation)
                ) {
                  args.where = {
                    AND: [args.where, constraint].filter((v) => !!v),
                  };
                } else if (
                  constraint &&
                  "where" in args &&
                  args.where &&
                  uniqueOperations.includes(operation)
                ) {
                  // update is required to provide an `id` in it's where clause. It can't be nested under an "and"
                  // args.where is one of 200 some types
                  // constraint is one of 200 some types
                  // they're the same, but typescript doesn't know.
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  args.where = {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ...(args.where as any),
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ...(constraint as any),
                  };
                } else if (
                  constraint &&
                  operation !== "create" &&
                  operation !== "createMany" &&
                  operation !== "createManyAndReturn"
                ) {
                  args.where = constraint;
                } else if (
                  constraint &&
                  ("create" === operation ||
                    "createMany" === operation ||
                    "createManyAndReturn" === operation)
                ) {
                  // TS isn't aware that the models that aren't subjects are exempted via the includes? check
                  if (
                    !ALLOW_LISTED_MODELS.includes(model) &&
                    // @ts-expect-error 2345
                    !ability.can("create", model)
                  ) {
                    throw new Error("Not authorized to create " + model);
                  }
                }
              } else {
                // $model may not be a valid SubjectType but again TS
                // @ts-expect-error 2345
                if (!ability.can("read", model)) {
                  throw new Error("Not authorized to read " + model);
                }
              }
            }
            if (process.env.NODE_ENV === "development" && process.env.SHOW_SQL)
              console.info({ model, operation, params: JSON.stringify(args) });
            if (log && LOGGED_STATEMENTS.includes(operation)) {
              const [, result] = await prisma.$transaction([
                // the true at the end forces the same connection (same transaction (same user_id))) throughout
                prisma.$executeRaw`SELECT set_config('app.current_user_id', ${userId.toString()}, TRUE)`,
                query(args),
              ]);
              return result;
            } else {
              return query(args);
            }
          },
        },
      },
    })
  );
}
