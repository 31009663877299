import { BillingClaimStatus, BillingPayerType, type PrismaClient } from "@prisma/client";
import {
  PermissionDeniedError,
  billingOrganizationFor,
  organizationIdToString,
  toOrganizationId,
  type AppAbility,
  type OrganizationId,
} from "@procision-software/auth";
import { CurrencyType, type BillingTransaction, type Case } from "@procision-software/database-zod";
import { caseIdToString, toCaseId, type CaseId } from "@procision-software/mason";
import { patientBillableCharges, setPayment, toBillingChargeId } from "../charges";
import {
  augmentClaimWithBillingAmounts,
  billingClaimIdToString,
  type BillingClaimId,
} from "../claim";
import { createPayment, toBillingTransactionId } from "../payment";

/**
 * Calculates the payment amount based on available funds and the charge billed amount.
 *
 * @param availableFunds - The total available funds.
 * @param chargeBilledAmount - The amount billed for the charge.
 * @returns The lesser of available funds or the charge billed amount.
 */
function calculatePaymentOnCharge(availableFunds: number, chargeBilledAmount: number) {
  return Math.min(availableFunds, chargeBilledAmount);
}

/**
 * possible return codes from applyPrePayment
 */
export const APPLY_PRE_PAYMENT_RESULT_NOOP = 0,
  APPLY_PRE_PAYMENT_RESULT_SUCCESS = 1,
  APPLY_PRE_PAYMENT_RESULT_INSUFFICIENT_FUNDS = 2,
  APPLY_PRE_PAYMENT_ALREADY_APPLIED = 4;

/**
 * Applies a prepayment from a case to a claim. The case is derived from the claim.
 *
 * @param prisma
 * @param ability
 * @param patientResponsibilityClaimId - the claim to apply the prepayment to
 *
 * @returns a tuple of the result code and the leftover balance if any
 */
export async function applyPrePayment(
  prisma: PrismaClient,
  ability: AppAbility,
  patientResponsibilityClaimId: BillingClaimId
): Promise<[number, number?]> {
  const claim = await prisma.billingClaim.findFirstOrThrow({
    where: {
      id: billingClaimIdToString(patientResponsibilityClaimId),
    },
    include: {
      billingCase: {
        include: {
          case: {
            include: {
              facility: true,
            },
          },
        },
      },
      billingPayer: true,
    },
  });
  if (claim.billingPayer?.paymentType !== BillingPayerType.Self_Pay) {
    throw new Error(`Cannot apply prepayment to a ${claim.billingPayer?.paymentType} claim`);
  }

  const augmentedClaim = await augmentClaimWithBillingAmounts(prisma, claim);
  const billableCharges = patientBillableCharges(augmentedClaim.charges);
  if (billableCharges.length === 0) {
    return [APPLY_PRE_PAYMENT_RESULT_NOOP];
  }

  const kase = claim.billingCase.case;
  const organizationId = kase.facility.organizationId;
  const prePaymentAmount = kase.prePaymentAmount ?? 0;
  if (kase.prePaymentTransferredAt) {
    return [APPLY_PRE_PAYMENT_ALREADY_APPLIED];
  }

  const payment =
    prePaymentAmount > 0 &&
    (await makeTransactionForPrepayment(
      prisma,
      ability,
      toOrganizationId(organizationId),
      patientResponsibilityClaimId,
      claim.billingCase.case
    ));

  if (!payment) return [APPLY_PRE_PAYMENT_RESULT_NOOP];

  let availableFunds = prePaymentAmount;
  let prBalance = augmentedClaim.outstandingAmount;

  type Charge = (typeof augmentedClaim)["charges"][number];
  const isSelfPayOnly = (charge: Charge) =>
    charge.payerTypes.includes(BillingPayerType.Self_Pay) && charge.payerTypes.length === 1;
  const selfPayFirst = (a: Charge, b: Charge) => {
    const aIsSelfPayOnly = isSelfPayOnly(a),
      bIsSelfPayOnly = isSelfPayOnly(b);
    // either one being self pay comes first
    if (aIsSelfPayOnly && !bIsSelfPayOnly) return -1;
    if (!aIsSelfPayOnly && bIsSelfPayOnly) return 1;
    // whether both self pay or neither, sort by sequence number
    return a.sequenceNumber - b.sequenceNumber;
  };
  const relevantCharges = billableCharges.sort(selfPayFirst);

  await Promise.all(
    relevantCharges.map(async (charge) => {
      let shouldPayAmountOnCharge = 0;
      if (availableFunds > 0 && charge.outstandingAmount) {
        shouldPayAmountOnCharge = calculatePaymentOnCharge(
          availableFunds,
          charge.outstandingAmount
        );
        prBalance -= shouldPayAmountOnCharge;
        availableFunds -= shouldPayAmountOnCharge;
        if (shouldPayAmountOnCharge > 0) {
          await setPayment(prisma, ability, {
            billingTransactionId: toBillingTransactionId(payment.id),
            billingChargeId: toBillingChargeId(charge.id),
            amount: shouldPayAmountOnCharge,
            billingAdjustmentId: null,
            billingClaimId: patientResponsibilityClaimId,
            currency: CurrencyType.USD,
            group: null, // if group is "PR" than the helper balanceOnCharge screws up later
          });
        }
      }
    })
  );
  await markCaseBalanceTransferred(prisma, ability, toCaseId(kase.id));
  await postPayment(prisma, ability, payment);
  if (prBalance === 0) {
    await markClaimDone(prisma, ability, patientResponsibilityClaimId);
  }
  const claimAfterPrepayment = await augmentClaimWithBillingAmounts(prisma, claim);
  if (claimAfterPrepayment.outstandingAmount === 0) {
    await markClaimDone(prisma, ability, patientResponsibilityClaimId);
  }
  if (availableFunds > 0) {
    const firstCharge = billableCharges[0]!; // overpayments should NOT be applied to self-pay first. https://linear.app/procision/issue/ENG-465/apply-patient-prepayment-to-self-pay-only-charge-first-due-april-26#comment-7ae6c46b
    // the billingTransaction's amount is already the prePay amount not the amount allocated so the BTA is the only thing that needs updated.
    await prisma.billingTransactionAllocation.updateMany({
      where: {
        billingChargeId: firstCharge.id,
        billingClaimId: patientResponsibilityClaimId,
      },
      data: {
        amount: {
          increment: availableFunds,
        },
      },
    });
    // fall through to the success case
  } else if (prBalance > 0) {
    return [APPLY_PRE_PAYMENT_RESULT_INSUFFICIENT_FUNDS, prBalance];
  }
  return [APPLY_PRE_PAYMENT_RESULT_SUCCESS];
}
function markClaimDone(
  prisma: PrismaClient,
  ability: AppAbility,
  patientResponsibilityClaimId: BillingClaimId
) {
  return prisma.billingClaim.updateMany({
    where: {
      id: billingClaimIdToString(patientResponsibilityClaimId),
    },
    data: {
      status: BillingClaimStatus.Done,
    },
  });
}

/**
 * creates a BillingTransaction matching data from the prepayment
 * @param prisma
 * @param ability
 * @param prePaymentAmount
 * @param organizationId
 * @param patientResponsibilityClaimId
 * @returns the new transaction
 */
async function makeTransactionForPrepayment(
  prisma: PrismaClient,
  ability: AppAbility,
  organizationId: OrganizationId,
  patientResponsibilityClaimId: BillingClaimId,
  kase: Case
) {
  if (!kase.prePaymentType) throw new Error("Case does not have a prepayment type");
  const billingOrg = await billingOrganizationFor(prisma, {
    id: organizationIdToString(organizationId),
  });
  return await createPayment(prisma, ability, {
    amount: kase.prePaymentAmount ?? 0,
    organization: {
      connect: {
        id: billingOrg.id,
      },
    },
    status: "In_Progress",
    referenceNumber: "PREPAYMENT",
    payerName: "PREPAYMENT",
    paymentMethod: kase.prePaymentType,
    transactionDate: kase.surgeryDate,
    checkDate: kase.surgeryDate,
    currency: CurrencyType.USD,
    claims: {
      connect: [
        {
          id: billingClaimIdToString(patientResponsibilityClaimId),
        },
      ],
    },
  });
}

/**
 * Posts the given payment
 */
async function postPayment(prisma: PrismaClient, ability: AppAbility, payment: BillingTransaction) {
  // need to mark the transaction as completed and the transaction allocations as posted
  // can't use actuallyPostPayment becuase it falls into a non-infinite recursive loop
  // and we wind up with too many claims
  ability.can("update", "BillingTransaction") &&
    (await prisma.billingTransaction.update({
      where: {
        id: payment.id,
      },
      data: {
        status: "Complete",
      },
    }));
  ability.can("update", "BillingTransactionAllocation") &&
    (await prisma.billingTransactionAllocation.updateMany({
      where: {
        billingTransactionId: payment.id,
      },
      data: {
        status: "Posted",
      },
    }));
}

/**
 *
 * The case has data fields for the prepayment, and a field to indicate they've been transferred to billing
 */
async function markCaseBalanceTransferred(
  prisma: PrismaClient,
  ability: AppAbility,
  caseId: CaseId
) {
  if (!ability.can("update", "Case")) {
    throw new PermissionDeniedError();
  }

  const kase = await prisma.case.updateMany({
    where: {
      id: caseIdToString(caseId),
    },
    data: {
      prePaymentTransferredAt: new Date(),
    },
  });

  return kase;
}
