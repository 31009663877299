"use client";

import { Dialog as HuiDialog, Transition } from "@headlessui/react";
import { XIcon } from "lucide-react";
import type { HTMLAttributes, MutableRefObject, ReactNode } from "react";
import { Fragment, forwardRef, useEffect, useState } from "react";
import { cn } from "../../utils";
import { Button } from "../Buttons/Button";

type DialogPanelProps = {
  onClose: () => void;
  title?: ReactNode;
  className?: string;
  children: ReactNode | (({ close }: { close: () => void }) => ReactNode);
};

const DialogPanel = forwardRef<HTMLDivElement, DialogPanelProps>(
  ({ onClose, title, className, children }, ref) => {
    return (
      <HuiDialog.Panel
        ref={ref}
        className={cn(
          `flex max-h-[100vh] max-w-[100vw] flex-1 flex-col gap-4 border border-primary bg-primary
          p-2 shadow-lg sm:flex-initial sm:rounded-lg sm:p-6`,
          className
        )}
      >
        <HuiDialog.Title
          as="div"
          className="flex flex-none items-center gap-2 border-b border-primary pb-2"
        >
          <h2 className="flex-1 truncate">{title}</h2>
          <Button
            type="button"
            variant="secondary"
            outline={false}
            asLink
            onClick={onClose}
            tabIndex={-1} // prevent focus when modal opens
            leftIcon={XIcon}
            title="Close"
          />
        </HuiDialog.Title>
        {typeof children === "function" ? children({ close: onClose }) : children}
      </HuiDialog.Panel>
    );
  }
);
DialogPanel.displayName = "Dialog.Panel";

type DialogProps = DialogPanelProps & {
  show: boolean;
  multiPanel?: boolean;
  panelProps?: { className?: string };
  initialFocus?: MutableRefObject<HTMLElement | null>;
};

const DialogBase = ({
  show,
  multiPanel,
  onClose,
  className,
  panelProps,
  initialFocus,
  ...props
}: DialogProps) => {
  const [showModal, setShowModal] = useState(show);
  useEffect(() => setShowModal(show), [show]);
  const handleClose = () => setShowModal(false); // Allows the Transition to finish before closing
  return (
    <Transition appear show={showModal} as={Fragment} afterLeave={onClose}>
      <HuiDialog className="relative z-50" onClose={handleClose} initialFocus={initialFocus}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-950/60" aria-hidden="true" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 flex items-start justify-center sm:items-center sm:p-6">
            {multiPanel ? (
              <>
                {typeof props.children === "function"
                  ? props.children({ close: handleClose })
                  : props.children}
              </>
            ) : (
              <DialogPanel
                {...props}
                {...panelProps}
                className={panelProps?.className ?? className}
                onClose={handleClose}
              />
            )}
          </div>
        </Transition.Child>
      </HuiDialog>
    </Transition>
  );
};

type ActionsProps = HTMLAttributes<HTMLDivElement>;
const DialogActions = forwardRef<HTMLDivElement, ActionsProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={cn("relative grid auto-cols-fr grid-flow-col gap-4", className)}
      >
        {children}
      </div>
    );
  }
);
DialogActions.displayName = "Dialog.Actions";

export const Dialog = Object.assign(DialogBase, { Panel: DialogPanel, Actions: DialogActions });
