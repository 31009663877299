import { CommandMenu, Icon, Popover } from "@procision-software/ui";
import { MoonIcon, SunIcon } from "lucide-react";
import {
  ThemeProvider as NextThemesProvider,
  useTheme as useNextTheme,
  type ThemeProviderProps as BaseThemeProviderProps,
} from "next-themes";

const THEMES = [
  { key: "light", label: "Light" },
  { key: "dark", label: "Dark" },
  { key: "system", label: "System" },
] as const;
type Themes = (typeof THEMES)[number]["key"];

type ThemeProviderProps = {
  defaultTheme?: Themes;
  forcedTheme?: Themes;
} & BaseThemeProviderProps;

export function ThemeProvider({
  attribute,
  defaultTheme,
  enableSystem,
  children,
  ...props
}: ThemeProviderProps) {
  return (
    <NextThemesProvider
      {...props}
      attribute={attribute ?? "class"}
      defaultTheme={defaultTheme ?? "system"}
      enableSystem={enableSystem ?? true}
    >
      {children}
    </NextThemesProvider>
  );
}

export const useTheme = useNextTheme;

export function ThemeToggle() {
  const { setTheme, forcedTheme } = useTheme();

  if (forcedTheme) return null; // Do not allow theme switching when a theme is forced

  return (
    <Popover>
      {({ close }) => (
        <>
          <Popover.Button
            variant="secondary"
            outline={false}
            asLink
            title="Toggle theme"
            className="p-0"
          >
            <Icon icon={SunIcon} className="scale-100 transition-all dark:scale-0" />
            <Icon icon={MoonIcon} className="absolute scale-0 transition-all dark:scale-100" />
            <span className="sr-only">Toggle theme</span>
          </Popover.Button>
          <Popover.Content align="end">
            <CommandMenu shouldFilter={false}>
              <CommandMenu.List>
                {THEMES.map((t) => (
                  <CommandMenu.Item
                    key={t.key}
                    value={t.key}
                    onSelect={() => {
                      close();
                      setTheme(t.key);
                    }}
                  >
                    {t.label}
                  </CommandMenu.Item>
                ))}
              </CommandMenu.List>
            </CommandMenu>
          </Popover.Content>
        </>
      )}
    </Popover>
  );
}
