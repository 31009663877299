import { useOrganization, useUser } from "@clerk/nextjs";
import { getUserRoles, type KnownUserRole } from "@procision-software/auth";
import { caseIdToString, type CaseId } from "@procision-software/mason";
import { useMemo } from "react";
import { type BillingCaseId } from "~/billing/models/case";
import { type BillingClaimId } from "~/billing/models/claim";
import { type KnownLDFlags } from "~/hooks/feature-flags";
import { isAllowed } from "~/models/access";
import { hasPermission } from "~/utils/require-permissions";
import { trpc } from "~/utils/trpc";
import { useCaseRelatedData } from "../appointment/useCaseRelatedData";
import { workflowSorter } from "../workflow/repository";

type Destination = {
  key: string;
  label: string;
  featureFlag?: keyof KnownLDFlags | null;
  href: string | false;
};

export function useIntents(
  {
    billingCaseId,
    caseId,
    claimId,
    staffId,
  }: {
    billingCaseId?: BillingCaseId;
    caseId?: CaseId;
    claimId?: BillingClaimId;
    staffId?: string;
  },
  exclusions: ("appointment" | "payer" | "staff")[] = []
): [Destination[]] {
  // const featureFlags = useFeatureFlags(); // As of this commit no intents depend on feature flags but the Destination type still supports conditional flags
  const { user } = useUser();
  const { organization } = useOrganization();
  const userRoles = getUserRoles(user, organization);
  const hasBilling = useMemo(() => hasPermission(userRoles, ["asc:billing"]), [userRoles]);
  const caseContext = useCaseRelatedData(
    useMemo(
      () => ({ caseId, billingCaseId, claimId, hasBilling }),
      [caseId, billingCaseId, claimId, hasBilling]
    )
  );

  // Precompute booleans for clarity
  const hasCaseId = Boolean(caseContext.caseId);
  const hasBillingCaseId = Boolean(caseContext.billingCaseId);
  const hasEncounterId = Boolean(caseContext.encounterId);
  const hasPatientId = Boolean(caseContext.patientId);
  const hasStaffId = Boolean(staffId);
  const hasAppointment =
    (hasCaseId || hasBillingCaseId || hasEncounterId) && !exclusions.includes("appointment");
  const hasStaff = hasStaffId && !exclusions.includes("staff");
  const userHasAscRole = userRoles.includes("asc");
  const userHasAscAdminRole = userRoles.includes("asc:admin");
  const userHasPracticeRole = userRoles.includes("practice");
  const userHasClinicalRole = isAllowed(userRoles, ["asc:clinical", "practice:clinical"]);
  const userHasBillingRole = userRoles.includes("asc:billing");

  const schedulingPrefix = getSchedulingPrefix(userRoles);

  // Helper function for href generation
  const generateHref = (path: string, condition: boolean): string | false =>
    condition ? path : false;

  // Fetch EMR templates for the encounter as diff templates will contain diff workflow pages
  const templateTrpc = trpc.core.emr.template.getForEncounter.useQuery(caseContext.encounterId!, {
    enabled: hasEncounterId && userHasClinicalRole,
  });

  const destinations: Destination[] = useMemo(
    () => [
      ...(hasPatientId
        ? [
            {
              key: "patient-overview",
              label: "Patient Overview",
              featureFlag: null,
              href: generateHref(
                `/patients/${caseContext.patientId}/`,
                hasPatientId && userHasAscRole
              ),
            },
          ]
        : []),
      ...(hasAppointment
        ? [
            {
              key: "scheduling",
              label: "Case Details",
              featureFlag: null,
              href: generateHref(
                `/${schedulingPrefix}/cases/${caseIdToString(caseContext.caseId!)}/casedetails`,
                hasCaseId && (userHasAscRole || userHasPracticeRole)
              ),
            },
            {
              key: "patient",
              label: "Demographics",
              featureFlag: null,
              href: generateHref(
                `/${schedulingPrefix}/cases/${caseIdToString(caseContext.caseId!)}/demographics`,
                hasCaseId && (userHasAscRole || userHasPracticeRole)
              ),
            },
            {
              key: "payment",
              label: "Payment",
              featureFlag: null,
              href: generateHref(
                `/${schedulingPrefix}/cases/${caseIdToString(caseContext.caseId!)}/payment`,
                hasCaseId && (userHasAscRole || userHasPracticeRole)
              ),
            },
            // Dynamically generated EMR pages
            ...(templateTrpc.data?.pages.sort(workflowSorter).map(
              (page): Destination => ({
                key: page.workflowType,
                label: page.workflowType,
                featureFlag: "emrFeatures",
                href: generateHref(
                  `/emr/workflow/${caseContext.encounterId}/${page.workflowType}`,
                  hasEncounterId && userHasClinicalRole
                ),
              })
            ) ?? []),
            {
              key: "documents",
              label: "Documents",
              featureFlag: null,
              href: generateHref(
                `/emr/workflow/${caseContext.encounterId}/documents`,
                hasEncounterId && userHasClinicalRole
              ),
            },
            {
              key: "endofday",
              label: "End of Day",
              featureFlag: null,
              href: generateHref(
                `/facility/cases/end-of-day?filter=${encodeURIComponent(
                  JSON.stringify({ json: { patientId: caseContext.patientId } })
                )}`,
                hasPatientId && userHasAscRole && schedulingPrefix === "facility"
              ),
            },
            {
              key: "billingCase",
              label: "Charge Entry",
              featureFlag: null,
              href: generateHref(
                `/billing/cases/${caseContext.billingCaseId}`,
                hasBillingCaseId && userHasBillingRole
              ),
            },
            {
              key: "ledger",
              label: "Ledger",
              featureFlag: null,
              href: generateHref(
                `/billing/ledger/${caseContext.billingCaseId}`,
                hasBillingCaseId && userHasBillingRole
              ),
            },
          ]
        : []),
      ...(hasStaff
        ? [
            {
              key: "schedulecase",
              label: "Schedule Case",
              featureFlag: null,
              href: generateHref(
                `/facility/cases/new?surgeonId=${encodeURIComponent(staffId ?? "")}`,
                userHasAscRole && hasStaff
              ),
            },
            {
              key: "view upcoming cases",
              label: "View Upcoming Cases",
              featureFlag: null,
              href: generateHref(
                `/facility/cases?filter=${encodeURIComponent(JSON.stringify({ surgeon: [staffId] }))}`,
                userHasAscRole
              ),
            },
            {
              key: "standing orders",
              label: "Standing Orders",
              featureFlag: null,
              href: generateHref(`/facility/admin/standing-orders/${staffId}`, userHasAscAdminRole),
            },
            {
              key: "block schedules",
              label: "Block Schedules",
              featureFlag: null,
              href: generateHref(
                `/facility/admin/block-schedules?filter=${encodeURIComponent(JSON.stringify({ surgeon: [staffId] }))}`,
                userHasAscAdminRole
              ),
            },
          ]
        : []),
    ],
    [
      caseContext.caseId,
      caseContext.billingCaseId,
      caseContext.patientId,
      caseContext.encounterId,
      hasAppointment,
      hasStaff,
      hasCaseId,
      hasPatientId,
      hasBillingCaseId,
      hasEncounterId,
      userHasAscRole,
      userHasPracticeRole,
      userHasClinicalRole,
      userHasBillingRole,
      schedulingPrefix,
      templateTrpc.data,
      staffId,
      userHasAscAdminRole,
    ]
  );

  return useMemo(() => [destinations] as const, [destinations]);
}

/**
 * Function to determine the scheduling prefix based on user roles.
 * @param roles - Array of user roles.
 * @returns The scheduling prefix string.
 */
const getSchedulingPrefix = (roles: KnownUserRole[]) => {
  if (roles.includes("practice")) return "practice";
  if (roles.includes("asc")) return "facility";
  return "";
};
