import { PermissionDeniedError, type AppAbility } from "@procision-software/auth";
import { type Prisma, type PrismaClient } from "@procision-software/database";
import adjustmentsJson from "~/../prisma/seed/blob/adjustment_codes.json";

// Define a unique symbol
// declare const BillingAdjustmentIdTag: unique symbol;

// Create a tagged type
// export type BillingAdjustmentId = string & { readonly tag: typeof BillingAdjustmentIdTag };
export type BillingAdjustmentId = string;

// Function to tag a string
export function toBillingAdjustmentId(id: string): BillingAdjustmentId {
  // return id as BillingAdjustmentId;
  return id;
}

export function billingAdjustmentIdToString(id: BillingAdjustmentId): string {
  return id.toString();
}

export async function seedAdjustments(prisma: PrismaClient, ability: AppAbility) {
  const result =
    ability.can("manage", "BillingAdjustment") &&
    (await prisma.billingAdjustment.createMany({
      data: adjustmentsJson.map(
        (adj) =>
          ({
            code: adj.code.toString(),
            name: adj.description,
          }) as Prisma.BillingAdjustmentCreateInput
      ),
    }));
  if (!result) {
    throw new PermissionDeniedError();
  }
  return result.count;
}
