import {
  AnesthesiaType,
  Sex,
  type BarePrismaClient,
  type Case,
  type CptCode,
  type Encounter,
  type Patient,
  type Procedure,
  type Room,
} from "@procision-software/database";
import * as humanparser from "humanparser";
import { DateTime } from "luxon";
import { z } from "zod";
import { toCaseId } from "../appointment";
import { toEmrTemplateId } from "../emr-template";
import { ensureEncounterExistsForAppointment } from "./repository";

type _AdvantixCsvHeaderRows = [
  // 1 schema is doing this. This is for Brian's benefit.
  // see also https://procision.oneschema.co/templates/3405356/transforms/3967833
  /* Col  A */ "Facility Name",
  /* Col  B */ "Page", // heading
  /* Col  C */ "Daily Schedule", // heading
  /* Col  D */ "", // Date export was generated
  /* Col  E */ "", // Time export was generated
  /* Col  F */ "Sort By:", // heading
  /* Col  G */ "Schedule", // sort by value
  /* Col  H */ "Remarks:", // heading
  /* Col  I */ "", // "Yes" | "No" -- are remarks included in export
  /* Col  J */ "Bold Font:", // heading
  /*        */ // there isn't a value column for bold font in my example data
  /* Col  K */ "Patient:", // heading
  /* Col  L */ "", // "Yes" | "No" -- are patient details included in export
  /* Col  M */ "Schedule Date", // heading
  /* Col  N */ "", // "From" and "To" dates in format MM/DD/YYYY{space}{hyphen}{space}MM/DD/YYYY (single column)
  /* Col  O */ "Equipment:", // heading
  /* Col  P */ "", // "Yes" | "No" -- are equipment details included in export
  /* Col  Q */ "Surgeon:", // heading
  /* Col  R */ "", // "Yes" | "No" -- are surgeon details included in export
  /* Col  S */ "Schedule Time:", // heading
  /* Col  T */ "", // always "All"?
  /* Col  U */ "Procedures:",
  /* Col  V */ "", // "First 2 Proc Only" -- not sure what other values are possible
  /* Col  W */ "Schedule:",
  /* Col  X */ "", // are schedule dates included? Always "Yes" in my example data
  /* Col  Y */ "Expand procedure description:", // heading
  /* Col  Z */ "", // are procedure descriptions included in export? Always no in my example data
  /* Col AA */ "Begin/End Time:", // heading
  /* Col AB */ "", // are schedule appointment's times included? Always "Yes" in my example data
  /* Col AC */ "Schedule:", // heading
  /* Col AD */ "", // the locations (rooms) that are included. Comma delimited.
  /* Col AE */ "Sugeon:", // heading
  /* Col AF */ "", // which surgeons are included. AD is a list, AF seems to be the literal "All"
  /* Col AG */ "Facility Name", // heading, same as A
  /* Col AH */ "Page", // page: page #, same as B
  /* Col AI */ "Daily Schedule", // same as C
  /* Col AJ */ "", // same as D
  /* Col AK */ "Schedule Date:", // same as M
  /* Col AL */ "", // same as N
  /* Col AM */ "", // time export was generated -- same as E?
  /* Col AN */ "Begin", // literal Begin
  /* Col AO */ "Schedule", // literal Schedule
  /* Col AP */ "End", // literal "End time"
  /* Col AQ */ "Annot", // literal "Annot" short for annotation?
  /* Col AR */ "Patient / account", // literal
  /* Col AS */ "# of appointments",
  /* Col AT */ "Cell Phone", // literal
  /* Col AU */ "Home Phone", // literal
  /* Col AV */ "Age", // literal
  /* Col AW */ "Sex", // literal
  /* Col AX */ "DOB", // literal
  /* Col AY */ "Anes", // literal
  /* Col AZ */ "", // literal. No idea what it's meant to be
  /* Col BA */ "Sugeon", // literal
  /* Col BB */ "", // literal. No idea what it's meant to be
  /* Col BC */ "Procedure", // literal
  /* Col BD */ "Proc QC", // Not sure if this is a literal or the given procedure name. Not important to us.
  /* Col BE */ "Irb", // literal
  /* Col BF */ "", // literal
  /* Col BG */ " -- DOW - DOS -- ", // heading  -- Mon - 11/11/24 --
  /* Col BH */ "Number of Appointments:", // heading
  /* Col BI */ "", // the actual number of appointments present in this export
  /* Col BJ */ "", // location (room)
  /* Col BK */ "", // count of appointments by location
  /* Col BL */ "", // Scheduled Start Time.
  /* Col BM */ "", // Scheduled End Time
  /* Col BN */ "", // Always blank?
  /* Col BO */ "", // patient - {name as LAST SUFFIX?, FIRST}{ / }{MRN}. Don't think middle appears.
  /* Col BP */ "", // phone value for AT?
  /* Col BQ */ "", // phone Value for AU?
  /* Col BR */ "", // age Value for AV?
  /* Col BS */ "", // gender value for AW?
  /* Col BT */ "", // dob value for AX?
  /* col BU */ "", // anes value for AY. TIVA = IV Sedation.
  /* col BV */ "", // surgeon. "JOSHI MD, PARTH"
  /* col BW */ "", // procedure name. Ends with 5-digit code which is NOT a CPT  code.
  /* col BX */ "", // 5 digit number which IS the CPT code relevant to BW
  /* col BY */ "", // IRB value for BE? Always blank
  /* col BZ */ "", // always blank
  /* col CA */ "Total Cases:", // heading
  /* col CB */ "", // the actual number of cases present in this export relevant to CA. How is it different from AS? Not important right now.
];

type AdvantixContext = {
  exportDate: Date; // D+E
  startRange: Date; // N first half
  endRange: Date; // N second half
  // totalAppointments: number; // AS // Unimportant
  // totalCases: number; // CB // Unimportant
};

type AdvantixEncounter = {
  appointmentDate: Date; // DOS
  location: string; // ENDO #1, ENDO #2, etc
  surgeon: string; // "JOSHI MD, PARTH"
  patientName: string;
  mrn: string;
  cellPhone: string;
  homePhone: string;
  dob: Date;
  sex: Sex | null;
  anesth: AnesthesiaType | null; // TIVA
  procedures: string[];
  caseDescription: string; // first of 2 procedures in BW
  expectedLength: number; // difference between BL and BM in minutes

  context: AdvantixContext;
};

type ProcisionAppointment = {
  encounter: Encounter;
  case: Case | null;
  patient: Patient;
  procedures: (Procedure & { cptCode: CptCode })[];
  location: Room | null;
};

export const NameSchema = z.string().transform((s: string) => {
  // s might be "LAST SUFFIX, FIRST", "FIRST LAST", "LAST, FIRST", "LAST, FIRST MIDDLE". We want "FIRST MIDDLE LAST SUFFIX"
  const parts = s.trim().split(",");
  if (parts.length === 1) return s;

  // figure out if the last part is a suffix
  const lastPart = parts[parts.length - 1];
  const isSuffix = (segment: string) =>
    ["JR", "SR", "II", "III", "IV"].includes(segment.toUpperCase());
  const suffix = lastPart?.split(" ").find((p) => isSuffix(p));
  if (suffix) return s.trim();

  const rearranged = parts.slice(1).join(",") + " " + parts[0];
  if (rearranged.startsWith(" ")) {
    // the suffix was in the middle
    // trying to handle this test case
    // Expected: "John Doe, Jr"
    // Received: " John Doe Jr"
    const rearrangedParts = rearranged.trim().split(" ");
    const possibleSuffix = rearrangedParts[rearrangedParts.length - 1];
    if (possibleSuffix && isSuffix(possibleSuffix)) {
      return (
        rearrangedParts.slice(0, rearrangedParts.length - 1).join(" ") +
        ", " +
        possibleSuffix
      ).trim();
    } else {
      return rearranged.trim();
    }
  } else {
    // this means the comma is not followed by a known suffix so it's probably preceeded by a last name
    return rearranged.trim();
  }
});

// When the one schema improter concludes, this is the data we should be left with
export const AdvantixParser = z.object({
  cell_phone: z.string(),
  appointment_end: z.string(),
  home_phone: z.string().nullish(),
  surgeonName: z.string(), // Yes, this one is camelcased not underscored for compat with premier and it's preloaded functionality
  sex: z.string().nullish(),
  procedure: z.string().transform((s: string) => String(s).substring(0, String(s).length - 5)), // there's an advantix number of 5 digits at the end of the procedure name
  scheduled_date: z.string(), // ISO 8601
  cpt_code: z.string(),
  patient_mrn: z.string(),
  export_time: z.string(),
  patient_name: NameSchema,
  anesthesiaType: z.string(), // Yes, this one is camelcased not underscored for compat with premier and it's preloaded functionality
  dob: z.string(),
  export_date: z.string(),
  appointment_time: z.string(),
  scheduled_length: z.string(),
  roomName: z.string(), // Yes, this one is camelcased not underscored for compat with premier and it's preloaded functionality
  exported_date_range: z.string(), // MM/DD/YYYY - MM/DDYYYY
});
type AdvantixExportRow = z.output<typeof AdvantixParser>;

const datemasks = { dMy: "d/M/yyyy", Mdy: "M/d/yyyy", YMD: "yyyy-M-d" } as const;
function advantixDateParser(date: string, zone: string, format: "dMy" | "Mdy" | "YMD"): Date {
  return DateTime.fromFormat(date, datemasks[format], { zone }).toJSDate();
}
function twoDigitYearToFourDigit(date: string): string {
  const [m, d, y] = date.split("/");
  if (!y) throw new Error("Invalid incoming date");
  return `${m}/${d}/${parseInt(y, 10) + 2000}`;
}

export function flattenAdvantixData(
  data: AdvantixExportRow[],
  localTimeZone: string
): AdvantixEncounter[] {
  // Advantix gives dates in dd/mm/yyyy format, mm/dd/yyyy format, and y-m-d format. Be careful.
  // Advantix cartesians on procedure codes so any given appointment may have 1 OR 2 rows.
  // combine rows where room/start/end are the same
  const exportedRange = data.map((row) =>
    row.exported_date_range // MDY
      .split("  -  ")
      .map((d) => advantixDateParser(twoDigitYearToFourDigit(d), localTimeZone, "Mdy"))
  );
  const startRange = exportedRange
    .map((r) => r[0])
    .filter((d) => !!d)
    .reduce((a, b) => (a < b ? a : b));
  const endRange = exportedRange
    .map((r) => r[1])
    .filter((d) => !!d)
    .reduce((a, b) => (a > b ? a : b));
  const exportDate = advantixDateParser(data[0]?.export_date ?? "", localTimeZone, "Mdy");
  if (!startRange || !endRange || !exportDate)
    throw new Error("No start, end, and/or export date range found in Advantix data.");
  const context = {
    startRange: DateTime.fromJSDate(startRange, { zone: localTimeZone }).startOf("day").toJSDate(),
    endRange: DateTime.fromJSDate(endRange, { zone: localTimeZone }).endOf("day").toJSDate(),
    exportDate,
  };

  return data.reduce<AdvantixEncounter[]>((acc, row) => {
    const start = DateTime.fromISO(row.scheduled_date, { zone: localTimeZone }).toJSDate();
    const length = parseInt(row.scheduled_length, 10);

    const existing = acc.find(
      (existingRow) =>
        +existingRow.appointmentDate === +start &&
        existingRow.expectedLength === length &&
        existingRow.location === row.roomName
    );
    if (existing) {
      existing.procedures.push(row.cpt_code); // tack on the CPT Code.
      return acc; // existing mutated
    } else {
      // dob mask = dd/mm/yyyy
      const dob = advantixDateParser(row.dob, "UTC", "dMy"); // we store birthdates in GMT
      const KNOWN_ANESTHESIA_TYPES: string[] = Object.values(AnesthesiaType);
      return [
        ...acc,
        {
          appointmentDate: start,
          anesth: KNOWN_ANESTHESIA_TYPES.includes(row.anesthesiaType)
            ? (row.anesthesiaType as AnesthesiaType)
            : null,
          location: row.roomName,
          caseDescription: row.procedure,
          mrn: row.patient_mrn,
          cellPhone: row.cell_phone,
          homePhone: row.home_phone ?? "",
          dob,
          expectedLength: length,
          patientName: row.patient_name,
          surgeon: row.surgeonName,
          sex: row.sex ? (row.sex === "F" ? Sex.Female : Sex.Male) : null,
          procedures: [row.cpt_code],
          context,
        } satisfies AdvantixEncounter,
      ];
    }
  }, []);
}

function isReschedule(
  advantixEncounter: AdvantixEncounter,
  procisionAppointment: null | ProcisionAppointment
): boolean {
  if (!procisionAppointment?.case) return false;
  return +procisionAppointment.case.surgeryDate !== +advantixEncounter.appointmentDate;
}

async function getExistingEncounter(
  prisma: BarePrismaClient,
  incomingData: AdvantixEncounter,
  now: Date
): Promise<ProcisionAppointment | null> {
  const DEBUG_OUTPUT = false;
  // get the existing encounter
  const patientUpcomingEncounters = await prisma.patient.findFirst({
    where: {
      mrn: incomingData.mrn,
    },
    include: {
      encounters: {
        include: {
          appointment: {
            include: {
              procedures: {
                include: {
                  cptCode: true,
                },
              },
              room: true,
            },
          },
        },
        where: {
          appointment: {
            status: {
              not: "Canceled",
            },
          },
          OR: [
            {
              // it may be a reschedule
              periodStart: {
                gte: now,
              },
            },
            {
              // or it may be old data
              periodStart: incomingData.appointmentDate,
            },
          ],
        },
      },
    },
  });

  DEBUG_OUTPUT && console.log("loaded patient upcoming encounters");

  if (!patientUpcomingEncounters) {
    DEBUG_OUTPUT && console.log("No existing patient");
    return null;
  }

  DEBUG_OUTPUT && console.log("Found patient");

  // is one of the upcoming encounters for the same procedures as the incoming data?
  const candidatesToBeTheSame: ProcisionAppointment[] = [];
  for (const encounter of patientUpcomingEncounters.encounters) {
    DEBUG_OUTPUT && console.log("considering encounter %s", encounter.id);
    if (await appointmentIsThisEncounter(prisma, encounter, incomingData)) {
      DEBUG_OUTPUT && console.log("encounter is this one");
      if (encounter.appointment) {
        candidatesToBeTheSame.push({
          encounter,
          case: encounter.appointment,
          location: encounter.appointment.room,
          patient: patientUpcomingEncounters,
          procedures: encounter.appointment.procedures,
        });
        break; // abandon for loop
      } else {
        DEBUG_OUTPUT && console.log("no case found");
        DEBUG_OUTPUT &&
          console.log(`encounter has ${patientUpcomingEncounters.encounters.length} encounters`);
      }
    }
  }

  DEBUG_OUTPUT && console.log(`found ${candidatesToBeTheSame.length} candidates`);

  if (candidatesToBeTheSame.length === 1) {
    DEBUG_OUTPUT && console.log("returning candidate");
    DEBUG_OUTPUT && console.log(JSON.stringify(candidatesToBeTheSame[0]!));
    return candidatesToBeTheSame[0]!;
  }

  if (candidatesToBeTheSame.length > 1) {
    DEBUG_OUTPUT && console.log("too many candidates to be sure");
    // if the dates are the same, we can still return it. Otherwise we need to return null and treat it as a new one.
    // not implemented for now
  } else {
    DEBUG_OUTPUT && console.log("no candidates");
  }

  return null;
}

async function appointmentIsThisEncounter(
  prisma: BarePrismaClient,
  procisionEncounter: Encounter,
  advantixEncounter: AdvantixEncounter
): Promise<boolean> {
  // are the dates the same?. -1 as a default because it won't match a date
  if (+(procisionEncounter.periodStart ?? -1) === +advantixEncounter.appointmentDate) return true;
  if (!procisionEncounter.appointmentId) return false;
  // is every procedure on the procision encounter in the advantix encounter?
  const procisionProcedures = await prisma.procedure.findMany({
    where: {
      caseId: procisionEncounter.appointmentId,
    },
    include: {
      cptCode: true,
    },
  });
  return procisionProcedures.every((p) => advantixEncounter.procedures.includes(p.cptCode.code));
}

async function appointmentsToCancel(
  prisma: BarePrismaClient,
  startRange: Date,
  endRange: Date,
  matchedEncounterIds: string[]
): Promise<(Case & { encounters: Encounter[]; patient: Patient | null })[]> {
  const unmatchedCases = await prisma.case.findMany({
    where: {
      OR: [
        {
          // cases during the approrpriate time range with an encounter ID which is not in the matchedEncounterIds
          encounters: {
            some: {
              id: {
                notIn: matchedEncounterIds,
              },
            },
          },
        },
        {
          // cases during the appropriate time range with no encounters (never got past scheduling)
          encounters: {
            none: {},
          },
        },
      ],
      surgeryDate: {
        gte: startRange,
        lte: endRange,
      },
      status: {
        not: "Canceled",
      },
    },
    include: {
      patient: true,
      encounters: true,
    },
  });
  return unmatchedCases;
}

export type AdvantixSyncResult = {
  dos: Date;
  mrn: string | null;
  encounterId: string | null;
  appointmentId: string | null;
  operation: "schedule" | "reschedule" | "modify" | "cancel";
};
export async function advantixSync(
  prisma: BarePrismaClient,
  facilityId: string,
  incomingData: AdvantixExportRow[],
  timezone: string,
  executionTime: Date
): Promise<AdvantixSyncResult[]> {
  // get the existing encounter
  const results: AdvantixSyncResult[] = [];
  const facility = await prisma.facility.findFirstOrThrow({ where: { id: facilityId } });
  const advantixEncoutners = flattenAdvantixData(incomingData, timezone);

  const defaultTemplate = await prisma.emrTemplate.findFirstOrThrow({
    where: {
      archived: false,
    },
  });

  for (const appointment of advantixEncoutners) {
    const existing = await getExistingEncounter(prisma, appointment, executionTime);
    const doReschedule = isReschedule(appointment, existing);
    const {
      firstName: patientFirstName,
      lastName: patientLastName,
      middleName: patientMiddleName,
    } = humanparser.parseName(appointment.patientName);

    if (existing) {
      if (doReschedule) {
        // update encounter.periodStart, encounter.periodEnd, case.surgeryDate
        await prisma.encounter.update({
          where: {
            id: existing.encounter.id,
          },
          data: {
            periodStart: appointment.appointmentDate,
            periodEnd: new Date(
              appointment.appointmentDate.getTime() + appointment.expectedLength * 60000
            ),
          },
        });
        await prisma.case.update({
          where: {
            id: existing.case!.id,
          },
          data: {
            surgeryDate: appointment.appointmentDate,
            expectedCaseLength: appointment.expectedLength,
          },
        });
      }
      await prisma.case.update({
        where: {
          id: existing.case!.id,
        },
        data: {
          surgeryDate: appointment.appointmentDate,
          createdBy: "FACILITY",
          status: existing.case?.status === "Draft" ? "Accepted" : existing.case?.status,
        },
      });

      const existingPatient = await prisma.patient.findFirst({
        where: {
          mrn: appointment.mrn,
          organizationId: facility.organizationId,
        },
      });
      let patientId: string | null = existingPatient?.id ?? null;
      if (existingPatient) {
        await prisma.patient.update({
          where: {
            id: existingPatient.id,
          },
          data: {
            dateOfBirth: appointment.dob,
            firstName: patientFirstName ?? "",
            lastName: patientLastName ?? "",
            middleName: patientMiddleName ?? "",
            mrn: appointment.mrn,
            organizationId: facility.organizationId,
            primaryPhone: appointment.cellPhone,
            secondaryPhone: appointment.homePhone,
            sex: appointment.sex,
          },
        });
      } else {
        patientId = (
          await prisma.patient.create({
            data: {
              dateOfBirth: appointment.dob,
              firstName: patientFirstName ?? "",
              lastName: patientLastName ?? "",
              middleName: patientMiddleName ?? "",
              mrn: appointment.mrn,
              organizationId: facility.organizationId,
              primaryPhone: appointment.cellPhone,
              secondaryPhone: appointment.homePhone,
              sex: appointment.sex,
            },
          })
        ).id;
      }

      if (!patientId) {
        throw new Error("Patient ID not found after create or update.");
      }

      await ensureEncounterExistsForAppointment(
        prisma,
        toCaseId(existing.case!.id),
        toEmrTemplateId(existing.encounter.emrTemplateId ?? defaultTemplate.id)
      );

      results.push({
        dos: appointment.appointmentDate,
        mrn: appointment.mrn,
        encounterId: existing.encounter.id,
        appointmentId: existing.case?.id ?? null,
        operation: doReschedule ? "reschedule" : "modify",
      });
      // end update block
    } else {
      // create
      const existingPatient = await prisma.patient.findFirst({
        where: {
          mrn: appointment.mrn,
          organizationId: facility.organizationId,
        },
      });
      let patientId: string | null = existingPatient?.id ?? null;
      if (existingPatient) {
        await prisma.patient.update({
          where: {
            id: existingPatient.id,
          },
          data: {
            dateOfBirth: appointment.dob,
            firstName: patientFirstName ?? "",
            lastName: patientLastName ?? "",
            middleName: patientMiddleName ?? "",
            mrn: appointment.mrn,
            organizationId: facility.organizationId,
            primaryPhone: appointment.cellPhone,
            secondaryPhone: appointment.homePhone,
            sex: appointment.sex,
          },
        });
      } else {
        patientId = (
          await prisma.patient.create({
            data: {
              dateOfBirth: appointment.dob,
              firstName: patientFirstName ?? "",
              lastName: patientLastName ?? "",
              middleName: patientMiddleName ?? "",
              mrn: appointment.mrn,
              organizationId: facility.organizationId,
              primaryPhone: appointment.cellPhone,
              secondaryPhone: appointment.homePhone,
              sex: appointment.sex,
            },
          })
        ).id;
      }

      if (!patientId) {
        throw new Error("Patient ID not found after create or update.");
      }
      const {
        organization: {
          practiceMembers: [practice],
        },
        ...surgeon
      } = await prisma.staff.findFirstOrThrow({
        where: {
          id: appointment.surgeon,
        },
        include: {
          organization: {
            include: {
              practiceMembers: true,
            },
          },
        },
      });
      if (!practice) {
        throw new Error("Practice not found for surgeon.");
      }

      // TODO: refactor to use createCase (from scheduling)
      const kase = await prisma.case.create({
        data: {
          surgeryDate: appointment.appointmentDate,
          patient: {
            connect: {
              id: patientId,
            },
          },
          facility: {
            connect: {
              id: facilityId,
            },
          },
          surgeon: {
            connect: {
              id: surgeon.id,
            },
          },
          practice: {
            connect: {
              id: practice.id,
            },
          },
          room: {
            connect: {
              id: appointment.location,
            },
          },
          name: appointment.caseDescription,
          createdBy: "FACILITY",
          status: "Accepted",
          expectedCaseLength: appointment.expectedLength,
          roomTurnOverTime: facility.defaultRoomTurnOverTimeInMinutes,
          arriveMinutesBeforeAppointment: facility.defaultArriveMinutesBeforeAppointment,
          anesthesiaType: appointment.anesth ? appointment.anesth : null,
          procedures: {
            createMany: {
              data: (
                await Promise.all(
                  appointment.procedures.map((cptCode) =>
                    prisma.cptCode.findFirst({
                      where: {
                        code: cptCode,
                      },
                    })
                  )
                )
              )
                .filter(<T>(v: T | null): v is T => !!v)
                .map((cptCode) => ({
                  cptCodeId: cptCode.code,
                  description: cptCode.name,
                })),
            },
          },
        },
      });

      await ensureEncounterExistsForAppointment(
        prisma,
        toCaseId(kase.id),
        toEmrTemplateId(defaultTemplate.id)
      );

      results.push({
        dos: appointment.appointmentDate,
        mrn: appointment.mrn,
        encounterId: (
          await prisma.encounter.findFirstOrThrow({
            where: {
              appointmentId: kase.id,
            },
          })
        ).id,
        appointmentId: kase.id,
        operation: "schedule",
      });
      // end create block
    }
  }
  const context = advantixEncoutners[0]?.context;
  const matchedEncounterIds = results
    .map((r) => r.encounterId)
    .filter((r) => !!r)
    .map((r) => r!);
  if (context?.startRange && context?.endRange) {
    const toCancel = await appointmentsToCancel(
      prisma,
      context?.startRange,
      context?.endRange,
      matchedEncounterIds
    );
    // delete
    results.push(
      ...toCancel.map((c) => {
        return {
          dos: c.surgeryDate,
          mrn: c.patient?.mrn ?? null,
          encounterId: c.encounters[0]?.id ?? null,
          appointmentId: c.id,
          operation: "cancel" as const,
        };
      })
    );
  }

  return results;
}
