"use client";

import { useAuth, useOrganization, useUser } from "@clerk/nextjs";
import { bundleInheritedRoles, getUserRoles } from "@procision-software/auth";
import { type KnownUserRole } from "@procision-software/auth/src/ability/permissions";
import { ErrorScreen } from "@procision-software/ui";
import { type NextPage } from "next";
import { type ReactElement } from "react";
import { useRouter } from "~/hooks/useRouter";
import type { NextPageWithLayout } from "~/types/next";

export function hasPermission(userRoles: KnownUserRole[], allowedRoles: KnownUserRole[]) {
  const allUserRoles = bundleInheritedRoles(userRoles);
  return allowedRoles.some((role) => allUserRoles.includes(role));
}

/**
 * This function is used to block a page from rendering unless the auth'ed user intersects with
 * allowed roles.
 * @param {NextPage | NextPageWithLayout | ReactElement} Page  The restricted page
 * @param {string[]} Allowed roles for access to this page
 *
 */
export function requirePermissions<P, IP>(
  Page: NextPage<P, IP> | NextPageWithLayout<P, IP> | ReactElement<P>,
  roles: KnownUserRole[]
) {
  const component = function RestrictedComponent(props: object) {
    const { isSignedIn, isLoaded: isAuthLoaded } = useAuth();
    const { user, isLoaded: isUserLoaded } = useUser();
    const { organization, isLoaded: isOrganizationLoaded } = useOrganization();
    const LocalPage = Page as unknown as NextPage;
    const router = useRouter();

    if (isAuthLoaded === false || isOrganizationLoaded === false || isUserLoaded === false) {
      return <div>Loading...</div>;
    }

    if (isSignedIn && hasPermission(getUserRoles(user, organization), roles)) {
      return <LocalPage {...props} />;
    }

    return (
      <ErrorScreen
        title="Access Denied"
        error="You have not been granted access.  Please contact your administrator to obtain access.  Thank you."
        onBackClick={router.back}
      />
    );
  };
  if (Page && "getLayout" in Page) {
    component.getLayout = (Page as NextPageWithLayout<P, IP>).getLayout;
  }
  return component;
}
