import { createId } from "@paralleldrive/cuid2";
import type { AppAbility } from "@procision-software/auth";
import type { Prisma } from "@procision-software/database";
import type { PaginationInput } from "@procision-software/database-zod";
import { MrnStrategySchema, type Patient } from "@procision-software/database-zod";
import { birthDateTime } from "@procision-software/mason";
import { DateTime } from "luxon";
import { billingCaseIdToString, type BillingCaseId } from "~/billing/models/case";
import { billingClaimIdToString, type BillingClaimId } from "~/billing/models/claim";
import type { PrismaClient } from "~/utils/flexible-prisma-client";
import { caseIdToString, type CaseId } from "@procision-software/mason";
import { getCase } from "./case";

const MATCH_THRESHOLD = 5; // 5 of 20 points 1 exact match + 1 partial, 3 partial, or 1 exact and 2 initials
const PAST_CASES_INCLUDE: Prisma.PatientInclude = {
  cases: {
    select: {
      id: true,
      surgeonId: true,
      surgeryDate: true,
      financialReference: true,
    },
    orderBy: {
      surgeryDate: "desc",
    },
    // Note to future: Maybe this should filter on status? `where: { status: { in: ["Discharged", "Completed" ]}}` ?
  },
};

type PatientExtended = Patient & { cases?: { id: string; surgeryDate: Date }[] | undefined };
type SearchField = "firstName" | "lastName" | "dateOfBirth" | "middleName" | "ssn" | "mrn";
type Match = {
  record: PatientExtended;
  score: number;
};
// dep of score
function scoreString(candidate: Patient, fieldName: SearchField, fieldValue: string) {
  const fieldValueString = fieldValue.toLocaleLowerCase();
  const candidateFieldValue = (candidate[fieldName] as string).toLocaleLowerCase();
  if (fieldValueString === candidateFieldValue) {
    return 4;
  } else if (candidateFieldValue.includes(fieldValueString)) {
    return 1 + fieldValueString.length / candidateFieldValue.length;
  } else if (fieldValueString.includes(candidateFieldValue)) {
    return 1 + candidateFieldValue.length / fieldValueString.length;
  } else {
    return 0;
  }
}
// dep of score
function scoreDate(candidate: Patient, fieldName: SearchField, fieldValue: Date) {
  const candidateFieldValueDate = candidate[fieldName] as Date;
  if (candidateFieldValueDate && fieldValue) {
    return 4;
  } else {
    return 0;
  }
}

// dep of scoreQuery
function score(candidate: Patient, fieldName: SearchField, fieldValue?: string | Date) {
  let result = 0;
  if (
    fieldValue != candidate[fieldName] &&
    ((fieldValue && !candidate[fieldName]) ?? (!fieldValue && candidate[fieldName]))
  ) {
    return result;
  }
  if (typeof fieldValue === "string" && typeof candidate[fieldName] === "string") {
    result = scoreString(candidate, fieldName, fieldValue);
  } else if (fieldValue instanceof Date && candidate[fieldName] instanceof Date) {
    result = scoreDate(candidate, fieldName, fieldValue);
  } else {
    result = 0;
  }
  return result;
}

function buildFilterConditions(searchParams: {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date;
  middleName?: string;
  ssn?: string;
  mrn?: string;
}): Prisma.PatientWhereInput[] {
  const conditions: Prisma.PatientWhereInput[] = [];
  const { firstName, lastName, dateOfBirth, middleName, ssn, mrn } = searchParams;
  if (firstName) {
    conditions.push({
      firstName: {
        contains: firstName.trim(),
        mode: "insensitive",
      },
    });
  }
  if (lastName) {
    conditions.push({
      lastName: {
        contains: lastName.trim(),
        mode: "insensitive",
      },
    });
  }
  if (dateOfBirth) {
    conditions.push({
      dateOfBirth: {
        gte: birthDateTime(dateOfBirth).startOf("day").toJSDate(),
        lt: birthDateTime(dateOfBirth).startOf("day").plus({ day: 1 }).toJSDate(),
      },
    });
  }
  if (middleName) {
    conditions.push({
      middleName: {
        contains: middleName.trim(),
        mode: "insensitive",
      },
    });
  }
  if (ssn) {
    conditions.push({
      ssn: {
        contains: ssn.replace(/\D/g, ""),
      },
    });
  }
  if (mrn) {
    const mrnNumber = parseInt(mrn, 10);
    if (mrnNumber.toString() === mrn.trim()) {
      conditions.push({
        OR: [
          {
            cases: {
              some: {
                financialReference: parseInt(mrn, 10),
              },
            },
          },
          {
            mrn: {
              contains: mrn.trim(),
              mode: "insensitive",
            },
          },
        ],
      });
    } else {
      conditions.push({
        mrn: {
          contains: mrn.trim(),
          mode: "insensitive",
        },
      });
    }
  }
  return conditions;
}

function scoreFilteredResults(
  candidates: PatientExtended[],
  searchParams: {
    firstName?: string;
    lastName?: string;
    dateOfBirth?: Date;
    middleName?: string;
    ssn?: string;
    mrn?: string;
  },
  threshold: number
): Match[] {
  const fields: SearchField[] = [
    "firstName",
    "lastName",
    "dateOfBirth",
    "middleName",
    "ssn",
    "mrn",
  ];

  const rankedMatches = candidates
    .map<{
      record: PatientExtended;
      score: number;
    }>((candidate) => ({
      record: candidate,
      score: fields.reduce((acc, fieldName) => {
        return acc + score(candidate, fieldName, searchParams[fieldName]);
      }, 0),
    }))
    .sort((a, b) => b.score - a.score);

  return rankedMatches.filter((match) => match.score >= threshold);
}

// Filter, used by matient match
export async function possibleMatches(
  prismaPatientAdapter: PrismaClient["patient"],
  filterParams: {
    firstName?: string;
    lastName?: string;
    dateOfBirth?: Date;
    middleName?: string;
    ssn?: string;
    mrn?: string;
  },
  filter: Prisma.PatientWhereInput,
  threshold = MATCH_THRESHOLD
): Promise<Match[]> {
  const conditions = buildFilterConditions(filterParams);
  const candidates = await prismaPatientAdapter.findMany({
    where: {
      AND: [
        filter,
        {
          OR: conditions,
        },
      ],
    },
    include: {
      ...PAST_CASES_INCLUDE,
    },
  });
  return scoreFilteredResults(candidates, filterParams, threshold);
}

// different from filterConditions in that the same term is being applied to each filter
// rather than a structured term being matched to a particular field
function searchTermClauses(term: string, meta: string[]): Prisma.PatientWhereInput[] {
  const datelike = /(\d{1,2})[/-](\d{1,2})[/-](\d{2,4})/;
  const ssnlike = /(\d{3})[- ]?(\d{2})[- ]?(\d{4})|\d{9}/g;
  const dateparts = term.match(datelike);
  if (!ssnlike.test(term) && dateparts) {
    meta.push(`dob`);
    const d = dateparts.slice(1).map((d) => parseInt(d));
    if (!d || d.length < 3 || !d[0] || !d[1] || !d[2]) return [];
    const date = DateTime.utc(d[2] < 100 ? 2000 + d[2] : d[2], d[0], d[1]);
    const datePlus1 = date.plus({ days: 1 });
    return [
      {
        dateOfBirth: {
          gte: date.toJSDate(),
          lt: datePlus1.toJSDate(),
        },
      },
    ];
  } else {
    if (ssnlike.test(term)) meta.push(`ssn`);
    else meta.push(`name/mrn/case`);

    return buildFilterConditions({
      firstName: term,
      lastName: term,
      middleName: term,
      ssn: term?.replace(/\D/g, "") ?? "",
      mrn: term,
    });
  }
}

export async function searchPatients(
  prisma: PrismaClient,
  query: string,
  pagination: PaginationInput,
  cartesianCases = false
) {
  const terms = query
    .split(" ")
    .filter(Boolean)
    .map((term) => term.toLowerCase());
  const candidates = await prisma.patient.findMany({
    where: {
      phantom: false,
      AND: terms.map((term) => ({ OR: searchTermClauses(term, []) })),
    },
    include: {
      ...PAST_CASES_INCLUDE,
    },
  });
  const scorePatient = (patient: Patient, term: string) => {
    return (
      [
        "firstName",
        "middleName",
        "lastName",
        "mrn",
        "ssn",
        "financialReference",
      ] as (keyof Patient)[]
    ).reduce((acc: number, fieldName: keyof Patient) => {
      if (patient[fieldName]) {
        const f = patient[fieldName];
        if (typeof f === "number") {
          if (f === parseInt(term, 10)) {
            return acc + 10;
          }
        }
        if (typeof f !== "string") return acc;
        if (f === term) {
          return acc + 4;
        } else if (f.startsWith(term)) {
          return acc + 2;
        } else if (f.includes(term)) {
          return acc + 1;
        }
      }
      return acc;
    }, 0);
  };
  // the sorting & filtering is happening app-side so pagination needs to happen app-side too
  const paginate = <T>(patients: T[], pagination: PaginationInput | undefined) => {
    if (!pagination) return patients;
    const { page, perPage } = pagination;
    const start = (page - 1) * perPage;
    const end = start + perPage;
    return patients.slice(start, end);
  };

  const scoreCandidate = (candidate: Patient) => {
    const score = terms.reduce((acc, term) => {
      return acc + scorePatient(candidate, term);
    }, 0);
    return score;
  };
  // score each matching patient and then sort by score
  const scored = await Promise.all(
    candidates
      .reduce(
        (candidates, candidate) => {
          // candidate has cases nested under it, so we need to flatten it out
          if (cartesianCases) {
            return candidates.concat(
              candidate.cases?.map((c) => ({
                ...candidate,
                // new fields here need added to PAST_CASES_INCLUDE above
                caseId: c.id,
                surgeryDate: c.surgeryDate,
                financialReference: c.financialReference,
                caseCount: candidate.cases.length,
              })) ?? []
            );
          } else {
            return candidates.concat([
              {
                ...candidate,
                caseId: candidate.cases?.[0]?.id ?? null,
                surgeryDate: candidate.cases?.[0]?.surgeryDate ?? null,
                financialReference: candidate.cases?.[0]?.financialReference ?? null,
                caseCount: candidate.cases.length,
              },
            ]);
          }
        },
        [] as (Patient & {
          caseId: string | null;
          surgeryDate: Date | null;
          financialReference: number | null;
          caseCount: number;
        })[]
      )
      .map((candidate) => ({
        record: candidate,
        score: scoreCandidate(candidate),
      }))
      .sort((a, b) => {
        // if they're the same score, sort by current proximity to surgery date
        const diff = b.score - a.score;
        if (diff === 0) {
          const surgA = +(a.record.surgeryDate ?? 0),
            surgB = +(b.record.surgeryDate ?? 0);
          const dA = Math.abs(Date.now() - surgA),
            dB = Math.abs(Date.now() - surgB);
          return dA - dB;
        } else {
          return diff;
        }
      })
      .map((match) => match.record)
  );
  return {
    // paginate the patients down to what was requested
    rows: paginate(scored, pagination),
    pagination: {
      ...pagination,
      all: scored.length,
    },
  };
}

export async function newSequentialMrn(
  prisma: PrismaClient,
  ability: AppAbility,
  { id: facilityId }: { id: string }
) {
  return await prisma.$transaction(
    async (tx) => {
      let newId = "";
      const facility = await tx.facility.findFirstOrThrow({
        where: { id: facilityId },
      });
      let sequence = facility.sequentialMrnSequence;

      /**
       * FIXME: This seems problematic as a whole. Can we simply just grab the MAX(mrn) from
       * patient where organizationId === facility.organizationId and use whenever we need it than this loop + manual counter?
       */
      do {
        newId = previewSequentialMrn(facility.mrnPrefix, sequence);
        sequence++;
      } while (
        await tx.patient.findFirst({
          select: {
            id: true,
          },
          where: {
            mrn: newId,
            organizationId: facility.organizationId,
          },
        })
      );

      await tx.facility.update({
        where: { id: facility.id },
        data: { sequentialMrnSequence: sequence },
      });
      return newId;
    },
    { timeout: 10000 }
  );
}

async function newCuidMrn({ mrnPrefix }: { mrnPrefix: string }) {
  return Promise.resolve(`${mrnPrefix}${createId()}`);
}

export async function newMRN(
  prisma: PrismaClient,
  ability: AppAbility,
  { id: facilityId }: { id: string }
): Promise<string> {
  const facility = await prisma.facility.findFirstOrThrow({
    where: { id: facilityId },
    select: {
      mrnStrategy: true,
      mrnPrefix: true,
      id: true,
    },
  });
  switch (facility.mrnStrategy) {
    case MrnStrategySchema.Enum.SEQUENTIAL:
      return await newSequentialMrn(prisma, ability, facility);
    case MrnStrategySchema.Enum.CUID:
      return await newCuidMrn(facility);
    default:
      throw new Error(`Unknown MRN strategy`);
  }
}

export function getPatient(
  prisma: PrismaClient,
  ability: AppAbility,
  identifier: { id: string } | { mrn: string; organizationId: string }
) {
  return prisma.patient.findFirstOrThrow({
    where: {
      ...("id" in identifier && {
        // ID is globally unique
        id: identifier.id,
      }),
      ...("mrn" in identifier && {
        // MRN is not globally unique. A given user might have access to two patients with the same MRN, but different organizations
        mrn: identifier.mrn,
        organizationId: identifier.organizationId,
      }),
    },
  });
}

/**
 * Retrieves a Patient record by a given Case ID.
 *
 * @param {PrismaClient} prisma - Prisma client instance.
 * @param {AppAbility} ability - The ability instance for access control, defined via CASL.
 * @param {CaseId} caseId - The ID of the case to retrieve the patient for.
 *
 * @returns {Promise<Patient>} The retrieved Patient record
 *
 * @throws Will throw an error if the Case or Patient record is not found.
 *
 * @example
 * ```typescript
 * const patient = await getPatientByCaseId(prisma, ability, toCaseId("12345"));
 * ```
 **/
export async function getPatientByCaseId(
  prisma: PrismaClient,
  ability: AppAbility,
  caseId: CaseId
): Promise<Patient> {
  const kase = await getCase({ prisma, caseId: caseIdToString(caseId) });
  if (!kase || !kase.patientId) {
    throw new Error("Not found");
  }
  return await getPatient(prisma, ability, { id: kase.patientId });
}

/**
 * Retrieves a Patient record by a given Claim ID.
 *
 * @param {PrismaClient} prisma - Prisma client instance.
 * @param {AppAbility} ability - The ability instance for access control, defined via CASL.
 * @param {BillingClaimId} claimId - The ID of the claim to retrieve the patient for.
 *
 * @returns {Promise<Patient>} The retrieved Patient record
 *
 * @throws Will throw an error if the Claim, its Billing Case, its Case, or its Patient record is not found.
 *
 * @example
 * ```typescript
 * const patient = await getPatientByClaimId(prisma, ability, toBillingClaimId("12345"));
 * ```
 **/
export async function getPatientByClaimId(
  prisma: PrismaClient,
  ability: AppAbility,
  claimId: BillingClaimId
): Promise<Patient> {
  const claim = await prisma.billingClaim.findFirst({
    where: {
      id: billingClaimIdToString(claimId),
    },
    include: {
      billingCase: {
        include: {
          case: {
            include: {
              patient: true,
            },
          },
        },
      },
    },
  });
  if (!claim || !claim.billingCase || !claim.billingCase.case || !claim.billingCase.case.patient) {
    throw new Error("Not found");
  }
  return claim.billingCase.case.patient;
}

/**
 * Retrieves a Patient record by a given Billing Case ID.
 *
 * @param {PrismaClient} prisma - Prisma client instance.
 * @param {AppAbility} ability - The ability instance for access control, defined via CASL.
 * @param {BillingCaseId} id - The ID of the Billing Case to retrieve the patient for.
 *
 * @returns {Promise<Patient>} The retrieved Patient record
 *
 * @throws Will throw an error if the billing case, case or patient record is not found.
 *
 * @example
 * ```typescript
 * const patient = await getPatientByBillingCaseId(prisma, ability, toBillingCaseId("12345"));
 * ```
 **/
export async function getPatientByBillingCaseId(
  prisma: PrismaClient,
  ability: AppAbility,
  id: BillingCaseId
) {
  const billingCase = await prisma.billingCase.findFirst({
    where: {
      id: billingCaseIdToString(id),
    },
    include: {
      case: {
        include: {
          patient: true,
        },
      },
    },
  });
  if (!billingCase || !billingCase.case || !billingCase.case.patient) {
    throw new Error("Not found");
  }
  return billingCase.case.patient;
}

// helper
export function patientName(patient: Pick<Patient, "firstName" | "lastName" | "middleName">) {
  const { firstName, lastName, middleName } = patient;
  return [firstName, middleName, lastName].filter(Boolean).join(" ");
}

export function formatSSN(patient: Patient | string | undefined | null) {
  if (!patient) return "";
  const ssn = typeof patient === "string" ? patient : patient.ssn;
  if (!ssn) return "";
  return ssn.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
}

export function maskSSN(patient: Patient | string | undefined | null) {
  if (!patient) return "";
  const ssn = typeof patient === "string" ? patient : patient.ssn;
  if (!ssn) return "";
  return ssn.replace(/\D/g, "").replace(/(\d{3})(\d{2})(\d{4})/, "***-**-$3");
}

export function previewSequentialMrn(prefix: string, sequence: number) {
  return `${prefix}${sequence + 1}`;
}
