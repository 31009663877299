import type { PrismaClient } from "@procision-software/database";
import { DateTime, type Zone } from "luxon";
import { DaysOfWeek } from "@procision-software/database-zod";
import { z } from "zod";

export type Timezone = string | Zone;

export function unixEpoch(): Date {
  return new Date(0);
}

export function endOfTimes(): Date {
  // let's hope this isn't used past 470 years from now
  return new Date(2500, 1, 1, 0, 0, 0, 0);
}

export function isEpoch(date: Date) {
  const year = DateTime.fromJSDate(date).year; // This is tolerant of both local & UTC
  return year === 1969 || year === 1970;
}

export const DOS_FORMAT_STRING = "LLL dd yy h:mm";
export async function formatDOS(
  prisma: PrismaClient,
  facilityId: string | undefined
): Promise<(date: Date) => string> {
  if (!facilityId) {
    return (date: Date) => DateTime.fromJSDate(date).toFormat(DOS_FORMAT_STRING);
  }
  const facility = await prisma.facility.findUnique({
    where: {
      id: facilityId,
    },
    select: {
      timezone: true,
    },
  });
  return (date: Date) =>
    DateTime.fromJSDate(date, { zone: facility?.timezone }).toFormat(DOS_FORMAT_STRING);
}

export const AMERICAN_DATE_2Y = "LL/dd/yy";
export const AMERICAN_DATE_4Y = "LL/dd/yyyy";
export const HTML5_DATE_FORMAT = "yyyy-LL-dd"; // format <input type="date" /> requires
export const AMERICAN_12H_TIME = "h:mm a";
export const HTML5_TIME_FORMAT = "HH:mm"; // format <input type="time" /> requires
export const LUXON_24H_TIME = "HH:mm"; // same as HTML5_TIME_FORMAT but for luxon
export const COMPACT_DATETIME = `${AMERICAN_DATE_2Y} ${AMERICAN_12H_TIME}`;
export const EXTENDED_DATETIME = `${AMERICAN_DATE_4Y} ${AMERICAN_12H_TIME}`;

export const DayOfWeekToLuxonDayNumber = {
  [DaysOfWeek.MONDAY]: 1,
  [DaysOfWeek.TUESDAY]: 2,
  [DaysOfWeek.WEDNESDAY]: 3,
  [DaysOfWeek.THURSDAY]: 4,
  [DaysOfWeek.FRIDAY]: 5,
  [DaysOfWeek.SATURDAY]: 6,
  [DaysOfWeek.SUNDAY]: 7,
};

export function differenceInYears(date1: Date, date2: Date) {
  return Math.floor(DateTime.fromJSDate(date1).diff(DateTime.fromJSDate(date2), "years").years); // local is fine here
}

export type TimeString = `${number}:${number}`;
export function timeOfDayToSeconds(time: TimeString | Date) {
  if (time instanceof Date) {
    return time.getHours() * 3600 + time.getMinutes() * 60;
  }

  const [hours, minutes, ..._rest] = time.split(":");
  return Number(hours) * 3600 + Number(minutes) * 60;
}

/**
 * Check if two dates have the same year, month, and day.
 */
export const isSameDay = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export function findNthDayOfMonth(
  dayOfWeek: number,
  weekOfMonth: number,
  startingDate: DateTime
): DateTime {
  if (weekOfMonth < 1) throw new Error("Week of month must be a positive integer");
  let date = startingDate;

  // Find the next occurrence of the specified day of the week
  while (date.weekday !== dayOfWeek) {
    date = date.plus({ days: 1 });
  }

  // Advance to the specified week of the month
  date = date.plus({ weeks: weekOfMonth - 1 });

  if (date.month !== startingDate.month)
    throw new Error("Indicated month doesn't have that many daysOfWeek[index]");

  // Return the resulting date
  return date;
}

export type Minutes = number;

export const DateFilterSchema = z.object({
  start: z.date(),
  end: z.date(),
});

export type DateDuration = z.infer<typeof DateFilterSchema>;

export const DateQueryStringSchema = z.string().regex(/^\d{4}-\d{2}-\d{2}$/);

export const DateRangeQueryStringSchema = z
  .object({
    start: DateQueryStringSchema,
    end: DateQueryStringSchema,
  })
  .optional();

export function dateFromQueryString(date: string, timezone: Timezone): Date;
export function dateFromQueryString(date: undefined, timezone: Timezone): undefined;
export function dateFromQueryString(date: string | undefined, timezone: Timezone): Date | undefined;
export function dateFromQueryString(
  date: string | undefined,
  timezone: Timezone
): Date | undefined {
  if (!date) return undefined;
  return DateTime.fromISO(date, { zone: timezone }).startOf("day").setZone("utc").toJSDate();
}

export function dateRangeFromQueryString(
  date: { start: string; end: string },
  timezone: Timezone
): { start: Date; end: Date };
export function dateRangeFromQueryString(
  date: { start: string; end: string } | undefined,
  timezone: Timezone
): { start: Date; end: Date } | undefined;
export function dateRangeFromQueryString(
  date: { start: string; end: string } | undefined,
  timezone: Timezone
): { start: Date; end: Date } | undefined {
  if (!date) return undefined;
  return {
    start: DateTime.fromISO(date.start, { zone: timezone })
      .startOf("day")
      .setZone("utc")
      .toJSDate(),
    end: DateTime.fromISO(date.end, { zone: timezone }).endOf("day").setZone("utc").toJSDate(),
  };
}

export const HTML_DATE_FORMAT = "yyyy-LL-dd";
export const HTML_TIME_FORMAT = "HH:mm";

export function formatDate(date: Date, timezone: string, format?: string) {
  return DateTime.fromJSDate(date, { zone: timezone }).toFormat(format ?? "LL/dd/yyyy");
}

export function birthDateTime(date: Date) {
  return DateTime.fromJSDate(date, { zone: "utc" });
}
