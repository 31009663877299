import { generateDocumentPreview, type TipTapDocument } from "@procision-software/mason";
import { type MessageType, Prisma } from "@prisma/client";
import type { InputJsonValue } from "@prisma/client/runtime/library";
import type { BarePrismaClient } from "@procision-software/database";

// Business Logic functions
export async function communicationTemplateList(
  prisma: BarePrismaClient,
  { filters }: { filters: { organizationId?: string; messageTypes?: MessageType[] } },
  pagination: { perPage: number; page: number }
) {
  if (filters.organizationId)
    await prisma.organization.findFirstOrThrow({ where: { id: filters.organizationId } });

  const where = {
    ...(filters.organizationId ? { organizationId: filters.organizationId } : {}),
    ...(filters.messageTypes ? { messageType: { in: filters.messageTypes } } : {}),
  };

  const all = await prisma.communicationTemplate.count({ where });

  const rows = await prisma.communicationTemplate.findMany({
    where,
    take: pagination.perPage,
    skip: pagination.perPage * (pagination.page - 1),
  });

  return {
    rows,
    pagination: {
      ...pagination,
      all,
    },
  };
}

export async function communicationTemplateCreate(
  prisma: BarePrismaClient,
  sessionOrgId: string,
  data: {
    organizationId?: string;
    name: string;
    smsContentJson: TipTapDocument | Prisma.NullTypes.DbNull;
    messageType: MessageType;
  }
) {
  const organizationId = data.organizationId ? data.organizationId : sessionOrgId;
  if (!organizationId) throw new Error("Organization ID is required");
  if (!data.smsContentJson) throw new Error("SMS Content is required");

  await prisma.organization.findFirstOrThrow({ where: { id: organizationId } });

  return await prisma.communicationTemplate.create({
    data: {
      name: data.name,
      messageType: data.messageType,
      smsContentJson: Prisma.JsonNull, // Will be overwritten if present
      ...(data.smsContentJson !== Prisma.DbNull && {
        smsContentJson: data.smsContentJson as InputJsonValue,
        smsContentText: generateDocumentPreview(data.smsContentJson as TipTapDocument),
      }),
      organization: {
        connect: {
          id: organizationId,
        },
      },
    },
  });
}

export async function communicationTemplateGet(prisma: BarePrismaClient, id: string) {
  return await prisma.communicationTemplate.findFirstOrThrow({ where: { id } });
}

export async function communicationTemplateUpdate(
  prisma: BarePrismaClient,
  input: {
    id: string;
    name?: string;
    smsContentJson?: TipTapDocument;
    messageType?: MessageType;
    organizationId?: string;
  }
) {
  const { id, smsContentJson, ...data } = input;
  const update: Prisma.CommunicationTemplateUpdateInput = data;
  if (smsContentJson) {
    update.smsContentJson = smsContentJson as InputJsonValue;
    update.smsContentText = generateDocumentPreview(smsContentJson);
  }

  return await prisma.communicationTemplate.update({
    where: { id },
    data: {
      ...update,
      ...(data.organizationId && {
        organization: {
          connect: {
            id: data.organizationId,
          },
        },
      }),
    },
  });
}

export async function communicationTemplateDelete(prisma: BarePrismaClient, id: string) {
  return await prisma.communicationTemplate.delete({ where: { id } });
}
