import { z } from 'zod';
import { BulkResolverAlgorithmSchema } from '../inputTypeSchemas/BulkResolverAlgorithmSchema'

/////////////////////////////////////////
// INTEGRATION SCHEMA
/////////////////////////////////////////

export const IntegrationSchema = z.object({
  resolver: BulkResolverAlgorithmSchema,
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  organizationId: z.string(),
  useExternalMrn: z.boolean(),
  oneSchemaTemplate: z.string(),
})

export type Integration = z.infer<typeof IntegrationSchema>

export default IntegrationSchema;
