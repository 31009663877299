"use client";

import {
  Suspense,
  useEffect,
  useMemo,
  type FC,
  type PropsWithChildren,
  type ReactElement,
} from "react";
import { ErrorBoundary } from "react-error-boundary";
import { LoadScreen } from "../../screens";
import { createFastContext } from "../../utils";
import { Panel } from "../Panel/Panel";
import { type TitlebarProps } from "../Titlebar/Titlebar";
import { Drawer, type DrawerDirection } from "./Drawer";

type DrawerContext = {
  content: ReactElement | null;
  titlebar: TitlebarProps;
  open: boolean;
  options: {
    direction: DrawerDirection;
    dismissible: boolean;
    modal: boolean;
    className?: string;
    contentClassName?: string;
  };
};

const defaultOptions: DrawerContext["options"] = {
  direction: "right",
  dismissible: true,
  modal: true,
  className: undefined,
  contentClassName: undefined,
};

const { Provider, useStore } = createFastContext<DrawerContext>("Drawer", {
  content: null,
  titlebar: { title: "" },
  options: defaultOptions,
  open: false,
});

export const DrawerProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Provider>
      {children}
      <ManagedDrawer />
    </Provider>
  );
};

export const ManagedDrawer: FC = () => {
  const [{ content, titlebar, options, open }, setStore] = useStore((store) => store);
  return (
    <Drawer
      direction={options.direction}
      dismissible={options.dismissible}
      modal={options.modal}
      open={open}
      onOpenChange={(val) => !val && setStore({ content: null, open: false })}
    >
      <Drawer.Content
        direction={options.direction}
        className={options.className}
        aria-description={titlebar.title ?? "Drawer"}
        aria-describedby={undefined}
      >
        <Drawer.Titlebar {...titlebar} direction={options.direction} />
        <ErrorBoundary
          fallback={
            <Panel variant="error" className="p-4">
              There was an error processing your request.
            </Panel>
          }
        >
          <div className={options.contentClassName ?? "p-2 sm:p-4"}>
            <Suspense fallback={<LoadScreen />}>{content}</Suspense>
          </div>
        </ErrorBoundary>
      </Drawer.Content>
    </Drawer>
  );
};

export const useDrawer = () => {
  const [_, setStore] = useStore();

  return useMemo(
    () => ({
      /**
       * Opens a managed drawer with the given content and options.
       * @param content - The content to display in the drawer (required)
       * @param options.title - The title of the drawer (required)
       * @param options.titlebarProps - Additional properties to apply to the drawer's titlebar
       * @param options.direction - The direction the drawer should open from
       * @param options.dismissible - Whether the drawer can be dismissed by clicking outside of it
       * @param options.modal - Whether the drawer should trap focus and prevent interaction with the rest of the page
       * @param options.className - Additional classes to apply to the drawer container
       * @param options.contentClassName - Additional classes to apply to the drawer content container (excluding titlebar)
       */
      open: (
        content: ReactElement,
        options: {
          title: string;
          titlebarProps?: Partial<TitlebarProps>;
          direction?: DrawerDirection;
          dismissible?: boolean;
          modal?: boolean;
          className?: string;
          contentClassName?: string;
        }
      ) => {
        const { title, titlebarProps, ...rest } = options;
        setStore({
          content,
          titlebar: { title, ...titlebarProps },
          options: { ...defaultOptions, ...rest },
          open: true,
        });
      },

      /**
       * Updates the current drawer's titlebar. Useful for updating the title or
       * actions based on user's interaction with the drawer content.
       */
      setTitlebar: (titlebar: Partial<TitlebarProps>) => {
        setStore((prev) => ({ ...prev, titlebar: { ...prev.titlebar, ...titlebar } }));
      },

      /**
       * drawer.open takes a ReactElement. It cannot change externally (that is, external state changes will not cause it to re-render).
       * This hook allows an external component to update the content of the drawer like "normal" React.
       * @example const [count,setCount] = useState(0); const content = useMemo(() => <p><button onClick={() => setCount((c) => c + 1)}>{count}</button></p>, [count, setCount]); drawer.useReactiveContent(content); <button onClick={() => drawer.open(content, { title: "counter" })}>Open Drawer</button>
       */
      useReactiveContent: (content: ReactElement) => {
        return useEffect(() => setStore((prev) => ({ ...prev, content })), [content]);
      },

      /**
       * Closees the managed drawer and removes its content.
       */
      close: () => setStore({ content: null, open: false }),
    }),
    [setStore]
  );
};
