import type { JsonValue } from "@prisma/client/runtime/library";
import type { MessageType } from "@procision-software/database";

export type CommunicationTemplateRecord = {
  messageType: MessageType;
  id: string;
  createdAt: Date;
  updatedAt: Date;
  organizationId: string;
  name: string;
  smsContentJson: JsonValue;
  smsContentText?: string | null;
};

export const MessageTypesFormatted: Record<MessageType, string> = {
  Appointment_Reminder: "Appointment Reminder",
  PAT_Reminder: "PAT Reminder",
  Post_Appointment_Survey: "Post Appointment Survey",
  Prompt_to_complete_PAT: "Prompt to complete PAT",
};
