import { z } from 'zod';
import { JsonValueSchema } from '../inputTypeSchemas/JsonValueSchema'

/////////////////////////////////////////
// ENCOUNTER WORKFLOW SIGNATURE SCHEMA
/////////////////////////////////////////

export const EncounterWorkflowSignatureSchema = z.object({
  id: z.string(),
  encounterWorkflowId: z.string(),
  state: JsonValueSchema,
  signedAt: z.coerce.date(),
  signedById: z.string().nullish(),
  signedName: z.string(),
})

export type EncounterWorkflowSignature = z.infer<typeof EncounterWorkflowSignatureSchema>

export default EncounterWorkflowSignatureSchema;
