"use client";

import { staffFullName, birthDateTime } from "@procision-software/mason";
import {
  CaseStatus,
  JobRoleType,
  OrganizationType,
  type Sex,
} from "@procision-software/database-zod";
import {
  Button,
  CurrencyInputField,
  DateInputField,
  Dialog,
  Form,
  InputField,
  Popover,
  useConfirm,
  type ButtonProps,
} from "@procision-software/ui";
import { useAppContext } from "~/components/AppContextProvider";
import { PlusIcon } from "lucide-react";
import { useRouter } from "~/hooks/useRouter";
import { useMemo, useState, type FC } from "react";
import { useForm } from "react-hook-form";
import { Input } from "~/components/base/fields";
import { useFeatureFlags } from "~/hooks/feature-flags";
import { client, trpc } from "~/utils/trpc";

type NewCaseType = {
  surgeonId: string;
  patientFirstName: string;
  patientLastName: string;
  patientDateOfBirth: Date;
  patientGender: Sex;
  description: string;
  prepaymentAmount: number;
  paymentMethod: string;
};
const QuickCaseForm: FC<{
  onSave: (kase: NewCaseType, target: "save" | "link" | "accept" | "eod") => void;
  surgeonId: string;
  hasEmrTemplates: boolean;
}> = ({ onSave, surgeonId, hasEmrTemplates }) => {
  const { emrFeatures } = useFeatureFlags();
  const enableAccept = !emrFeatures || hasEmrTemplates;
  const form = useForm<NewCaseType>({
    defaultValues: {
      surgeonId,
      patientFirstName: "John",
      patientLastName: "Doe",
      patientGender: "Male",
      description: "Eye Surgery",
      paymentMethod: "Insurance",
      prepaymentAmount: 10000,
      patientDateOfBirth: birthDateTime(new Date()).minus({ years: 30 }).toJSDate(),
    },
  });
  return (
    <Form>
      <div className="flex">
        <InputField {...form} label="Surgeon" name="surgeonId" />
        <InputField {...form} label="Patient First Name" name="patientFirstName" />
        <InputField {...form} label="Patient Last Name" name="patientLastName" />
      </div>
      <div className="flex">
        <DateInputField {...form} timezone="utc" label="Patient DOB" name="patientDateOfBirth" />
        <InputField {...form} label="Gender" name="patientGender" />
        <InputField {...form} label="Case Description" name="description" />
      </div>
      <div className="flex">
        <CurrencyInputField
          currencyType="USD"
          {...form}
          label="Prepayment"
          name="prepaymentAmount"
        />
        <InputField {...form} label="Payment Method" name="paymentMethod" />
        <p>
          Insurance, <br />
          Self_Pay, or
          <br />
          nothing
        </p>
      </div>
      <div className="flex">
        <Button
          variant="primary"
          outline={true}
          onClick={() => void onSave(form.getValues(), "save")}
        >
          Save
        </Button>
        <Button
          variant="primary"
          outline={true}
          onClick={() => void onSave(form.getValues(), "link")}
        >
          ... and link Pt
        </Button>
        <Button
          variant="primary"
          outline={true}
          disabled={!enableAccept}
          onClick={() => void onSave(form.getValues(), "accept")}
        >
          ... and accept
        </Button>
        <Button
          variant="primary"
          outline={true}
          disabled={!enableAccept}
          onClick={() => void onSave(form.getValues(), "eod")}
        >
          ... and go to EOD
        </Button>
      </div>
    </Form>
  );
};

export default function CreateCaseButton({
  onCreated,
  ...props
}: Partial<ButtonProps> & {
  onCreated: (caseId: string) => void;
}) {
  const { facility, perspective, isProcisionUser } = useAppContext();
  const { emrFeatures } = useFeatureFlags();
  const trpcContext = trpc.useUtils();
  const router = useRouter();
  const confirm = useConfirm();
  const emrTemplatesTrpc = trpc.core.emr.template.list.useQuery(
    {
      pagination: { page: 1, perPage: 10 },
    },
    { enabled: emrFeatures && perspective === "asc" }
  );

  const [searchText, setSearchText] = useState("");

  const rapidMode = isProcisionUser();
  const staffQuery = trpc.staff.list.useQuery({
    roleType: JobRoleType.SURGEON,
    sort: "lastName",
  });

  const surgeons = useMemo(
    () =>
      staffQuery.data?.filter(
        (s) => !searchText || staffFullName(s).toLowerCase().includes(searchText.toLowerCase())
      ) ?? [],
    [staffQuery, searchText]
  );

  const createCase = trpc.case.create.useMutation();
  const [createCaseQuicklyModalOpen, setCreateCaseQuicklyModalOpen] = useState(false);

  const create = async (surgeonId: string, practiceId: string) => {
    const kase = await createCase.mutateAsync({
      facilityId: facility.id,
      practiceId,
      surgeonId,
      status: CaseStatus.Draft,
      createdBy: perspective === "asc" ? OrganizationType.FACILITY : OrganizationType.PRACTICE,
    });
    await trpcContext.case.count.invalidate();
    if (kase) onCreated(kase.id);
  };

  const isLoading = staffQuery.isLoading || createCase.isLoading;

  return (
    <>
      {rapidMode && (
        <Dialog
          title="Create a new case"
          show={createCaseQuicklyModalOpen}
          onClose={() => setCreateCaseQuicklyModalOpen(false)}
        >
          {surgeons[0] && (
            <QuickCaseForm
              surgeonId={surgeons[0].id}
              hasEmrTemplates={
                emrTemplatesTrpc.data ? emrTemplatesTrpc.data.rows.length > 0 : false
              }
              onSave={async (form, target) => {
                // Set SSN, phone, weight, height, surgeryDate, and OR automatically
                const surgeon = surgeons.find((s) => s.id === form.surgeonId);
                if (!surgeon) return;
                const practiceId = surgeon.organization?.practiceMembers?.[0]?.id ?? "";
                const kase = await createCase.mutateAsync({
                  facilityId: facility.id,
                  practiceId,
                  surgeonId: form.surgeonId,
                  status: CaseStatus.Draft,
                  createdBy:
                    perspective === "asc" ? OrganizationType.FACILITY : OrganizationType.PRACTICE,
                });
                await trpcContext.case.count.invalidate();
                if (!kase) {
                  return alert("Failed to create");
                }

                const [room] = await client.room.list.query({});
                if (!room) {
                  return alert("No rooms in facility");
                }
                await client.case.updatePartial.mutate({
                  id: kase.id,
                  patientFirstName: form.patientFirstName,
                  patientLastName: form.patientLastName,
                  patientDateOfBirth: form.patientDateOfBirth,
                  patientSex: form.patientGender,
                  patientHomePhone: "555-555-1212",
                  patientMobilePhone: "555-555-1212",
                  patientSsn: "987-65-4320", // Fake SSN
                  patientAddress1: "123 Main St",
                  patientCity: "Beverly Hills",
                  patientState: "CA",
                  patientZip: "90210",
                  name: form.description,
                  prePaymentAmount: form.prepaymentAmount,
                  prePaymentType: "Cash",
                  heightInInches: 71,
                  weightInPounds: 150,
                  surgeryDate: new Date(),
                  expectedCaseLength: 20,
                  roomId: room.id,
                });
                if (form.paymentMethod === "Insurance") {
                  const {
                    rows: [provider],
                  } = await client.insuranceProvider.list.query({
                    pagination: {
                      page: 1,
                      perPage: 1,
                    },
                  });
                  if (provider) {
                    await client.insurance.create.mutate({
                      caseId: kase.id,
                      providerId: provider?.id,
                      firstName: form.patientFirstName,
                      lastName: form.patientLastName,
                      groupNumber: "",
                      policyNumber: "4499",
                      primary: true,
                      preAuthStatus: "PENDING",
                      relationship: "SELF",
                    });
                  }
                } else if (form.paymentMethod === "Self_Pay") {
                  await client.selfpay.create.mutate({
                    caseId: kase.id,
                    paymentMethodTypeId: "CREDIT_CARD",
                    notes: "",
                  });
                }

                if (["link", "accept", "eod"].includes(target)) {
                  const p = await client.patient.create.mutate({
                    firstName: form.patientFirstName,
                    middleName: "",
                    lastName: form.patientLastName,
                    dateOfBirth: form.patientDateOfBirth,
                    ssn: "987-65-4320", // Fake SSN
                    sex: form.patientGender,
                    primaryPhone: "555-555-1212",
                  });
                  await client.case.updatePartial.mutate({
                    id: kase.id,
                    patientId: p.id,
                  });
                  kase.patientId = p.id;
                }
                if (["accept", "eod"].includes(target)) {
                  const emrTemplateId = await confirm(
                    "Which EMR Template",
                    "EMR Template",
                    emrTemplatesTrpc.data?.rows.map((t) => ({ key: t.id, label: t.name }))
                  );
                  if (!emrTemplateId) {
                    void router.push(`/facility/cases/${kase.id}/demographics`);
                    setCreateCaseQuicklyModalOpen(false);
                    return;
                  }
                  await client.case.updatePartial.mutate({
                    id: kase.id,
                    status: CaseStatus.Accepted,
                    emrTemplateId: emrTemplateId,
                  });
                }

                if (target === "eod") {
                  void router.push(
                    `/facility/cases/end-of-day?filter=${encodeURIComponent(JSON.stringify({ json: { patientId: kase.patientId } }))}`
                  );
                } else {
                  void router.push(`/facility/cases/${kase.id}`);
                }
                setCreateCaseQuicklyModalOpen(false);
              }}
            />
          )}
        </Dialog>
      )}
      <Popover>
        {({ close }) => (
          <>
            <Popover.Button
              variant="primary"
              outline={false}
              {...props}
              disabled={props.disabled || isLoading}
              leftIcon={props.leftIcon ?? PlusIcon}
              title="Create new case"
              // className={cn(
              //   variant !== "nav" && "button",
              //   variant === "nav" &&
              //     `-m-1 rounded-lg p-1 text-gray-700 hover:bg-gray-300
              //   hover:text-primary focus:bg-gray-300 focus:text-primary focus:ring-0 dark:bg-transparent
              //   dark:text-gray-300 dark:hover:bg-gray-500 dark:focus:bg-gray-500`,
              //   className
              // )}
            />
            <Popover.Content align="center">
              <div className="flex max-h-64 flex-col gap-1 overflow-y-auto p-2">
                <Input
                  className="py-1"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                {rapidMode && (
                  <Button
                    variant="secondary"
                    outline={true}
                    onClick={() => setCreateCaseQuicklyModalOpen(true)}
                    leftIcon={PlusIcon}
                    className="justify-start"
                  >
                    Rapid Case
                  </Button>
                )}
                {surgeons.map((surgeon) => {
                  const practice = surgeon.organization?.practiceMembers?.[0] ?? null;
                  if (!practice) return null;
                  return (
                    <Button
                      key={surgeon.id}
                      variant="secondary"
                      outline={false}
                      type="button"
                      onClick={async () => {
                        await create(surgeon.id, practice.id);
                        close();
                      }}
                      className="justify-start"
                    >
                      {staffFullName(surgeon)}
                      {perspective === "asc" && (
                        <span
                          className="pl-1 text-xs text-gray-400 group-hover:text-gray-100
                            group-focus:text-gray-100"
                        >
                          {practice?.name}
                        </span>
                      )}
                    </Button>
                  );
                })}
              </div>
            </Popover.Content>
          </>
        )}
      </Popover>
    </>
  );
}
