import { z } from 'zod';
import { JsonValueSchema } from '../inputTypeSchemas/JsonValueSchema'
import { QuestionFieldTypeSchema } from '../inputTypeSchemas/QuestionFieldTypeSchema'
import { QuestionValueTypeSchema } from '../inputTypeSchemas/QuestionValueTypeSchema'

/////////////////////////////////////////
// QUESTIONNAIRE QUESTION SCHEMA
/////////////////////////////////////////

export const QuestionnaireQuestionSchema = z.object({
  fieldType: QuestionFieldTypeSchema,
  valueType: QuestionValueTypeSchema,
  id: z.string(),
  questionnaireId: z.string(),
  version: z.number().int(),
  label: z.string().nullish(),
  subLabel: z.string().nullish(),
  ordinal: z.number().int(),
  parentQuestionId: z.string().nullish(),
  repeatable: z.boolean().nullish(),
  readOnly: z.boolean().nullish(),
  required: z.boolean().nullish(),
  choices: JsonValueSchema.nullable(),
  published: z.boolean().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type QuestionnaireQuestion = z.infer<typeof QuestionnaireQuestionSchema>

export default QuestionnaireQuestionSchema;
