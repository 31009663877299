import type {
  PrismaClient,
  KnownCodeableConcept,
  BarePrismaClient,
} from "@procision-software/database";
import {
  CodeableConceptSchema,
  type CodeableConcept,
  type CodeableConceptId,
  type CodeableConceptPath,
  type RegistryValues,
} from "./types";
import { z } from "zod";

export class PathNotFoundError extends Error {
  constructor(path: string[]) {
    super(`Path not found: ${path.join(".")}`);
  }
}

export function codeableConceptById(
  prisma: PrismaClient,
  id: CodeableConceptId
): Promise<CodeableConcept> {
  return prisma.codeableConcept.findFirstOrThrow({
    where: {
      id,
    },
  });
}

export const ccAtoS = (path: string[]) => ({ path });

// Function overloads
export async function codeableConceptIdForPath(
  prisma: BarePrismaClient,
  targetPath: KnownCodeableConcept
): Promise<CodeableConceptId>; // Non-null promise for KnownCodeableConcepts
export async function codeableConceptIdForPath(
  prisma: BarePrismaClient,
  targetPath: { $path: KnownCodeableConcept }
): Promise<CodeableConceptId>; // Non-null promise for KnownCodeableConcept
export async function codeableConceptIdForPath(
  prisma: BarePrismaClient,
  targetPath: CodeableConceptPath
): Promise<CodeableConceptId>; // Non-null promise for CodeableConceptPath
export async function codeableConceptIdForPath(
  prisma: BarePrismaClient,
  targetPath: KnownCodeableConcept | CodeableConceptPath | { $path: KnownCodeableConcept }
): Promise<CodeableConceptId | null> {
  // FIXME: This is ripe for caching in redis
  let walkerId: null | string = null;
  const path =
    typeof targetPath === "string"
      ? targetPath.split(".")
      : typeof targetPath === "object" && "$path" in targetPath
        ? targetPath.$path.split(".")
        : targetPath;
  const descendents = [...path];
  while (descendents.length) {
    const key = descendents.shift()!;
    const codeableConcept: CodeableConcept | null = await prisma.codeableConcept.findFirst({
      where: {
        code: key,
        codeableConceptId: walkerId,
      },
    });
    if (!codeableConcept) {
      throw new PathNotFoundError(path);
    }
    walkerId = codeableConcept.id;
  }
  return walkerId;
}

export async function pathForNode(prisma: PrismaClient, node: CodeableConcept): Promise<string[]> {
  const path = [];
  let walkerId = node.codeableConceptId;
  while (walkerId) {
    const codeableConcept = await prisma.codeableConcept.findFirst({
      where: {
        id: walkerId,
      },
    });
    if (!codeableConcept) {
      throw new Error(`Invalid codeable concept id: ${walkerId}`);
    }
    path.unshift(codeableConcept.code);
    walkerId = codeableConcept.codeableConceptId;
  }
  return path;
}

export const codeableConceptPathForId = async (
  prisma: PrismaClient,
  id: CodeableConceptId
): Promise<CodeableConceptPath> => {
  const node = await prisma.codeableConcept.findFirstOrThrow({
    where: {
      id,
    },
  });
  return (await pathForNode(prisma, node)).concat(node.code);
};

export function childPathSchema(parentNode: KnownCodeableConcept) {
  return Object.assign(
    z.string().startsWith(parentNode + ".", `Expected node under path ${parentNode}`),
    { _metaType: "CodeableConcept" }
  );
}

export function codeableConceptSchemaAtPath<T extends KnownCodeableConcept>(path: T) {
  return CodeableConceptSchema.omit({ code: true }).extend({
    code: z.string().refine(
      (val): val is RegistryValues<T> => {
        return true;
      },
      {
        message: `Invalid code. Must be one of the allowed values for ${path}`,
      }
    ),
  });
}
