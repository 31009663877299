import { cva } from "class-variance-authority";
import { type LucideIcon } from "lucide-react";
import { forwardRef, type ButtonHTMLAttributes, type ReactNode } from "react";
import { cn, useAsyncEvent } from "../../utils";
import type { InputSize } from "../Forms/Input";
import { Icon, type IconProps } from "../Icons";

type Ref = HTMLButtonElement;

type ElementProps = Omit<ButtonHTMLAttributes<Ref>, "className" | "onClick" | "children">;

export type ButtonVariant = "primary" | "secondary" | "destructive" | "success" | "warning";
export type ButtonSize = InputSize;

export const buttonVariants = cva(
  `relative box-border inline-flex items-center justify-center gap-1 whitespace-nowrap border
  focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-primary
  disabled:pointer-events-none disabled:opacity-70 data-[disabled="true"]:pointer-events-none
  data-[disabled="true"]:opacity-70 print:hidden`,
  {
    variants: {
      size: {
        table: "h-6 gap-1 rounded-md px-1.5 py-0.5 text-sm",
        sm: "h-[1.875rem] rounded-md px-4 py-1.5 text-sm",
        md: "h-9.5 rounded-lg px-6 py-2 text-sm",
      } satisfies Record<ButtonSize, string>,
      variant: {
        primary: `text-brand hover:bg-brand-hover hover:text-white focus-visible:bg-brand-hover
        focus-visible:text-white focus-visible:ring-brand`,
        secondary: `text-primary hover:bg-tertiary hover:text-primary focus-visible:bg-tertiary
        focus-visible:text-primary focus-visible:ring-secondary`,
        destructive: `text-error hover:bg-error-hover hover:text-white focus-visible:bg-error-hover
        focus-visible:text-white focus-visible:ring-error`,
        success: `text-success hover:bg-success-hover hover:text-white
        focus-visible:bg-success-hover focus-visible:text-white focus-visible:ring-success`,
        warning: `text-warning hover:bg-warning-hover hover:text-white
        focus-visible:bg-warning-hover focus-visible:text-white focus-visible:ring-warning`,
      } satisfies Record<ButtonVariant, string>,
      outline: {
        true: "",
        false: "border-transparent",
      },
      asLink: {
        true: `justify-start border-transparent bg-transparent hover:bg-transparent hover:underline
        hover:underline-offset-2 focus-visible:bg-transparent focus-visible:underline
        focus-visible:ring-0`,
        false: "",
      },
      ghosted: {
        true: "opacity-50 hover:opacity-100 focus-visible:opacity-100",
        false: "",
      },
      noText: {
        true: "",
        false: "",
      },
      leftIcon: {
        true: "",
        false: "",
      },
      rightIcon: {
        true: "",
        false: "",
      },
    },
    compoundVariants: [
      { variant: "primary", outline: false, asLink: false, className: "bg-brand-alt text-white" },
      { variant: "primary", outline: true, asLink: false, className: "border-brand bg-primary" },
      {
        variant: "primary",
        asLink: true,
        className: `hover:underline-brand font-semibold text-brand hover:text-brand-hover
        focus-visible:text-brand-hover`,
      },

      { variant: "secondary", outline: false, asLink: false, className: "bg-primary" },
      {
        variant: "secondary",
        outline: true,
        asLink: false,
        className: "border-primary bg-primary",
      },
      {
        variant: "secondary",
        asLink: true,
        className:
          "hover:underline-primary text-primary hover:text-primary focus-visible:text-primary",
      },

      {
        variant: "destructive",
        outline: false,
        asLink: false,
        className: "bg-error-alt text-white",
      },
      {
        variant: "destructive",
        outline: true,
        asLink: false,
        className: "border-error bg-primary",
      },
      {
        variant: "destructive",
        asLink: true,
        className: "hover:underline-error text-primary hover:text-error focus-visible:text-error",
      },

      { variant: "success", outline: false, asLink: false, className: "bg-success-alt text-white" },
      { variant: "success", outline: true, asLink: false, className: "border-success bg-primary" },
      {
        variant: "success",
        asLink: true,
        className:
          "hover:underline-success text-primary hover:text-success focus-visible:text-success",
      },

      { variant: "warning", outline: false, asLink: false, className: "bg-warning-alt text-white" },
      { variant: "warning", outline: true, asLink: false, className: "border-warning bg-primary" },
      {
        variant: "warning",
        asLink: true,
        className:
          "hover:underline-warning text-primary hover:text-warning focus-visible:text-warning",
      },

      { size: "sm", noText: true, className: "px-2" },
      { size: "sm", noText: false, leftIcon: false, rightIcon: false, className: "" },
      { size: "sm", noText: false, leftIcon: true, rightIcon: false, className: "gap-1.5 px-3" },
      { size: "sm", noText: false, leftIcon: false, rightIcon: true, className: "gap-1.5 px-3" },
      { size: "sm", noText: false, leftIcon: true, rightIcon: true, className: "gap-1.5 px-3" },
      { size: "sm", asLink: true, outline: false, className: "h-auto w-fit px-1 py-0" },
      { size: "sm", asLink: true, outline: true, className: "justify-start" },

      { size: "md", noText: true, className: "px-3" },
      { size: "md", noText: false, leftIcon: false, rightIcon: false, className: "" },
      { size: "md", noText: false, leftIcon: true, rightIcon: false, className: "gap-1.5 px-3.5" },
      { size: "md", noText: false, leftIcon: false, rightIcon: true, className: "gap-1.5 px-3.5" },
      { size: "md", noText: false, leftIcon: true, rightIcon: true, className: "gap-1.5 px-3.5" },
      { size: "md", asLink: true, outline: false, className: "h-auto w-fit px-1.5 py-0" },
      { size: "md", asLink: true, outline: true, className: "justify-start" },
    ],
    defaultVariants: {
      size: "md",
      variant: "secondary",
      outline: false,
      asLink: false,
      noText: true,
      leftIcon: false,
      rightIcon: false,
    },
  }
);

export type ButtonVariantProps = {
  size?: ButtonSize;
  variant?: ButtonVariant;
  outline?: boolean;
  leftIcon?: LucideIcon | null;
  leftIconProps?: IconProps;
  rightIcon?: LucideIcon | null;
  rightIconProps?: IconProps;
  asLink?: boolean;
  ghosted?: boolean;
};

export type ButtonProps = Omit<ElementProps, "onClick"> &
  ButtonVariantProps & {
    className?: string;
    loading?: boolean;
    title?: string;
    children?: ReactNode;
    onClick?:
      | ((event: React.MouseEvent<Ref, MouseEvent>) => Promise<unknown>)
      | ((event: React.MouseEvent<Ref, MouseEvent>) => unknown);
  };

/**
 * Button element that can be styled with different variants and sizes.
 * @param [size] - Size of the button (default: "md")
 * @param [variant] - Variant of the button (default: "secondary")
 * @param [outline] - Whether the button should be outlined (default: false)
 * @param [leftIcon] - Icon to display on the left side of the button
 * @param [leftIconProps] - Icon props to override the button defaults
 * @param [rightIcon] - Icon to display on the right side of the button
 * @param [rightIconProps] - Icon props to override the button defaults
 * @param [asLink] - Style the button as a link (when true and outline false the button will not have minimal padding - useful to align with text)
 * @param [ghosted] - Reduce the opacity of the button until the user hovers over it for a less prominent button
 * @param [className] - Allows overriding the default button styles
 * @param [loading] - Sets the button into a loading state (when true, the button is disabled and shows a loading spinner)
 * @param [disabled] - Disables the button (when true, the button is disabled and cannot be clicked)
 * @param [onClick] - Function to run when the button is clicked
 * @param [children] - The content of the button
 */
export const Button = forwardRef<Ref, ButtonProps>(
  (
    {
      size,
      variant,
      outline,
      leftIcon,
      leftIconProps,
      rightIcon,
      rightIconProps,
      asLink,
      ghosted,
      className,
      loading: _loading,
      disabled: _disabled,
      onClick,
      children,
      ...props
    },
    ref
  ) => {
    const [clicking, handleClick] = useAsyncEvent(onClick);

    const loading = _loading || clicking;
    const disabled = _disabled || loading;

    return (
      <button
        type="button"
        {...props}
        ref={ref}
        className={cn(
          buttonVariants({
            size,
            variant,
            outline,
            asLink: !!asLink,
            ghosted: !!ghosted,
            noText: !children,
            leftIcon: !!leftIcon || !!loading,
            rightIcon: !!rightIcon,
          }),
          className
        )}
        onClick={handleClick}
        {...(disabled ? { disabled: true, "data-disabled": true, "aria-disabled": true } : {})}
      >
        <Icon icon={leftIcon} size={size ?? undefined} {...leftIconProps} loading={loading} />
        {children ?? <span className="sr-only">{props.title}</span>}
        <Icon
          icon={rightIcon}
          size={size ?? undefined}
          className={cn(rightIconProps?.className, "ml-auto")}
          {...rightIconProps}
        />
      </button>
    );
  }
);
Button.displayName = "Button";
