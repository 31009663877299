"use client";

import { useId, useMemo, type ReactNode } from "react";
import {
  Controller,
  type ControllerFieldState,
  type FieldPathValue,
  type FieldValues,
  type Path,
  type RefCallBack,
  type UseControllerProps,
  type UseFormReturn,
} from "react-hook-form";
import { Field } from "./Field";
import { type LabelProps } from "./Label";

export type BoundFieldProps<
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
> = Omit<UseControllerProps<TFieldValues, TFieldPath>, "defaultValue"> & {
  readOnly?: boolean;
  disabled?: boolean;
  label?: string;
  labelProps?: LabelProps;
  labelPreserveCase?: boolean;
  helperText?: string;
  className?: string;
};

export const BoundField = <
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
>({
  name,
  control,
  rules,
  shouldUnregister,
  readOnly,
  disabled,
  label,
  labelProps,
  labelPreserveCase,
  helperText,
  className,
  render,
}: BoundFieldProps<TFieldValues, TFieldPath> & {
  render: (values: {
    field: {
      // This type is defined inline for better visibility in editor hover tooltips
      id: string;
      readOnly?: boolean;
      "aria-disabled"?: boolean;
      error?: boolean;
      "aria-errormessage"?: string;
      // from controller field
      onChange: (...event: unknown[]) => unknown;
      onBlur: () => unknown;
      value: FieldPathValue<TFieldValues, TFieldPath>;
      disabled?: boolean;
      name: TFieldPath;
      ref: RefCallBack;
    };
    fieldState: ControllerFieldState;
  }) => ReactNode;
}) => {
  const id = useId();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => {
        const hasError =
          !disabled && !readOnly && (fieldState.invalid || !!fieldState.error?.message);
        return (
          <Field
            required={!!rules?.required}
            disabled={disabled}
            error={fieldState.error?.message ?? hasError}
            label={label}
            labelProps={labelProps}
            labelPreserveCase={labelPreserveCase}
            helperText={helperText}
            className={className}
          >
            {render({
              field: {
                id,
                ...field,
                readOnly,
                ...(disabled && { disabled: true, "aria-disabled": true }),
                ...(hasError && {
                  error: true,
                  "aria-invalid": true,
                  "aria-errormessage": fieldState.error?.message,
                }),
              },
              fieldState,
            })}
          </Field>
        );
      }}
    />
  );
};

/**
 * These are props passed into the legacy FormField components. They are here until
 * we can remove the legacy props. Otherwise they will be passed to the input component
 * and generate React runtime errors.
 * @deprecated
 */
export type LegacyFormFieldProps<TFieldValues extends FieldValues> = Partial<
  Omit<
    UseFormReturn<TFieldValues, unknown, TFieldValues>,
    "name" | "control" | "rules" | "shouldUnregister" | "defaultValue" | "disabled"
  > & {
    // error?: boolean;
    // errorMessage?: string;
    fieldClassName?: string;
  }
>;

/**
 * Helper type while transitioning away from legacy FormField props
 * @deprecated
 */
export type BoundFieldWithLegacyProps<
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
> = BoundFieldProps<TFieldValues, TFieldPath> & LegacyFormFieldProps<TFieldValues>;

/**
 * A utility function to strip off react-hook-from useForm props from a field component's
 * props so that the form props can be passed to the BoundField component and the rest of
 * the props can be passed to the input component.
 * @deprecated
 */
export function useFilteredFieldProps<
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
  TProps,
>(props: TProps & BoundFieldWithLegacyProps<TFieldValues, TFieldPath>) {
  // destructure out the FormFieldProps from the rest of the props so that
  // we can pass the rest of the props to the child input component without
  // the React runtime errors trying to bind FormFieldProps to the input
  return useMemo(() => {
    const {
      // useFormReturn props not used, but need to strip off
      watch: _watch,
      getValues: _getValues,
      getFieldState: _getFieldState,
      setError: _setError,
      clearErrors: _clearErrors,
      setValue: _setValue,
      trigger: _trigger,
      formState: _formState,
      resetField: _resetField,
      reset: _reset,
      handleSubmit: _handleSubmit,
      unregister: _unregister,
      control: _control,
      register: _register,
      setFocus: _setFocus,

      // legacy FormField props no longer used
      // stacked: _stacked,
      // error: _error,
      // errorMessage: _errorMessage,

      // BoundField props we care about
      name,
      control,
      rules,
      shouldUnregister,
      readOnly,
      disabled,
      label,
      labelProps,
      labelPreserveCase,
      helperText,
      fieldClassName,

      // The rest of the props to be passed to the input component
      ...rest
    } = props;

    return {
      fieldProps: {
        name,
        control,
        rules,
        shouldUnregister,
        readOnly,
        disabled,
        label,
        labelProps,
        labelPreserveCase,
        helperText,
        className: fieldClassName,
      } satisfies BoundFieldProps<TFieldValues, TFieldPath>,
      inputProps: rest as TProps,
    };
  }, [props]);
}
