import { AbilityBuilder, type PureAbility } from "@casl/ability";
import { createPrismaAbility, type PrismaQuery, type Subjects } from "@casl/prisma";
import type { Organization as ClerkOrganization, User as ClerkUser } from "@clerk/backend";
import type {
  Address,
  Allergy,
  Asset,
  AssetType,
  Attachment,
  AttachmentType,
  BillingAdjustment,
  BillingCase,
  BillingCaseAttachment,
  BillingCaseAttachmentType,
  BillingCharge,
  BillingChargeMaster,
  BillingChargeModifier,
  BillingClaim,
  BillingDiagnosis,
  BillingModifier,
  BillingOrganization,
  BillingPayer,
  BillingTransaction,
  BillingTransactionAllocation,
  Bucket,
  BulkCreate,
  Case,
  CaseMedicalCondition,
  CasePreferenceCard,
  CasePreferenceCardEquipment,
  CaseRole,
  CaseStatusTracker,
  ChargeAllocationsView,
  ChargePayerAmountView,
  ClinicalEvent,
  ClinicalEventDimension,
  CodeableConcept,
  CommunicationTemplate,
  Condition,
  ConsentForm,
  CptCode,
  Diagnosis,
  DocumentTemplate,
  EmrTemplate,
  EmrTemplatePage,
  EmrTemplateSection,
  Encounter,
  EncounterClosure,
  EncounterDocument,
  EncounterParticipant,
  EncounterWorkflow,
  EncounterWorkflowSignature,
  Equipment,
  EquipmentRequirement,
  Facility,
  FacilityHours,
  HcpcsCode,
  HealthReviewCondition,
  HealthReviewRequirement,
  HealthReviewRequirementAttachment,
  HealthReviewType,
  InsuranceProvider,
  Integration,
  JobRole,
  Line,
  MedicalCondition,
  MedicationAdministrationRecord,
  Message,
  MessageThread,
  Order,
  OrderGroup,
  OrderResponse,
  Organization,
  OrganizationRelationship,
  Patient,
  PatientCommunication,
  PatientCommunicationWhitelist,
  PatientCondition,
  PatientConsent,
  PatientMedication,
  PatientMedicationChange,
  PatientNote,
  PatientSignature,
  PaymentInsurance,
  PaymentInsuranceAttachment,
  PaymentInsuranceAttachmentType,
  PaymentLetterProtection,
  PaymentMethodType,
  PaymentSelfPay,
  PaymentWorkersComp,
  PaymentWorkersCompAttachment,
  PaymentWorkersCompAttachmentType,
  Practice,
  PreferenceCard,
  Prisma,
  PrismaClient,
  Procedure,
  ProcedureHistory,
  ProviderEncounterDocument,
  PublicSubmission,
  Questionnaire,
  QuestionnaireQuestion,
  QuestionnaireQuestionCondition,
  QuestionnaireResponse,
  QuestionnaireResponseAnswer,
  Role,
  Room,
  Signature,
  Specialty,
  Staff,
  StaffSignature,
  StandingMedicationOrder,
  StandingOrder,
  StorageBlob,
  Supplier,
  SupplierRep,
  SurgeryProfile,
  SurgeryProfileCharge,
  SurgeryProfileDiagnosis,
  SurgicalTimeout,
  Tag,
  TextTemplate,
  TimePeriod,
  TimePeriodSchedule,
  User,
  WaystarInsuranceProvider,
  WaystarPayerGroup,
  WorkersCompProvider,
} from "@procision-software/database";
import { CaseStatus, OrganizationType } from "@procision-software/database-zod";
import { getUserRoles } from "../components/AuthHelper";

export class PermissionDeniedError extends Error {
  constructor(msg?: string) {
    super(msg ?? "Permission denied");
    this.name = "PermissionDeniedError";
  }
}

export type KnownUserRole =
  | "asc"
  | "asc:admin"
  | "asc:billing"
  | "asc:clinical"
  | "asc:patient-deletion"
  | "practice"
  | "practice:admin"
  | "practice:clinical"
  | "platform"
  | "platform:admin"
  | "patient";
export const KnownUserRoles: KnownUserRole[] = [
  "asc",
  "asc:admin",
  "asc:billing",
  "asc:clinical",
  "asc:patient-deletion",
  "practice",
  "practice:admin",
  "practice:clinical",
  "platform",
  "platform:admin",
  "patient",
];
export function isKnownUserRole(v: string): v is KnownUserRole {
  return KnownUserRoles.includes(v as KnownUserRole);
}

export type AbilitySubjects =
  | "all"
  | Subjects<{
      Address: Address;
      Allergy: Allergy;
      Asset: Asset;
      AssetType: AssetType;
      Attachment: Attachment;
      AttachmentType: AttachmentType;
      BillingAdjustment: BillingAdjustment;
      BillingCase: BillingCase;
      BillingCaseAttachment: BillingCaseAttachment;
      BillingCaseAttachmentType: BillingCaseAttachmentType;
      BillingCharge: BillingCharge;
      BillingChargeMaster: BillingChargeMaster;
      BillingChargeModifier: BillingChargeModifier;
      BillingClaim: BillingClaim;
      BillingDiagnosis: BillingDiagnosis;
      BillingModifier: BillingModifier;
      BillingOrganization: BillingOrganization;
      BillingPayer: BillingPayer;
      BillingTransaction: BillingTransaction;
      BillingTransactionAllocation: BillingTransactionAllocation;
      Bucket: Bucket;
      BulkCreate: BulkCreate;
      Case: Case;
      CaseMedicalCondition: CaseMedicalCondition;
      CasePreferenceCard: CasePreferenceCard;
      CasePreferenceCardEquipment: CasePreferenceCardEquipment;
      CaseRole: CaseRole;
      CaseStatusTracker: CaseStatusTracker;
      ChargeAllocationsView: ChargeAllocationsView;
      ChargePayerAmountView: ChargePayerAmountView;
      ClinicalEvent: ClinicalEvent;
      ClinicalEventDimension: ClinicalEventDimension;
      CodeableConcept: CodeableConcept;
      CommunicationTemplate: CommunicationTemplate;
      Condition: Condition;
      ConsentForm: ConsentForm;
      CptCode: CptCode;
      Diagnosis: Diagnosis;
      DocumentTemplate: DocumentTemplate;
      EmrTemplate: EmrTemplate;
      EmrTemplatePage: EmrTemplatePage;
      EmrTemplateSection: EmrTemplateSection;
      Encounter: Encounter;
      EncounterClosure: EncounterClosure;
      EncounterDocument: EncounterDocument;
      EncounterParticipant: EncounterParticipant;
      EncounterWorkflow: EncounterWorkflow;
      EncounterWorkflowSignature: EncounterWorkflowSignature;
      Equipment: Equipment;
      EquipmentRequirement: EquipmentRequirement;
      Facility: Facility;
      FacilityHours: FacilityHours;
      HcpcsCode: HcpcsCode;
      HealthReviewCondition: HealthReviewCondition;
      HealthReviewRequirement: HealthReviewRequirement;
      HealthReviewRequirementAttachment: HealthReviewRequirementAttachment;
      HealthReviewType: HealthReviewType;
      InsuranceProvider: InsuranceProvider;
      Integration: Integration;
      JobRole: JobRole;
      Line: Line;
      MedicalCondition: MedicalCondition;
      MedicationAdministrationRecord: MedicationAdministrationRecord;
      Message: Message;
      MessageThread: MessageThread;
      Order: Order;
      OrderGroup: OrderGroup;
      OrderResponse: OrderResponse;
      Organization: Organization;
      OrganizationRelationship: OrganizationRelationship;
      Patient: Patient;
      PatientCommunication: PatientCommunication;
      PatientCommunicationWhitelist: PatientCommunicationWhitelist;
      PatientCondition: PatientCondition;
      PatientConsent: PatientConsent;
      PatientMedication: PatientMedication;
      PatientMedicationChange: PatientMedicationChange;
      PatientNote: PatientNote;
      PatientSignature: PatientSignature;
      PaymentInsurance: PaymentInsurance;
      PaymentInsuranceAttachment: PaymentInsuranceAttachment;
      PaymentInsuranceAttachmentType: PaymentInsuranceAttachmentType;
      PaymentLetterProtection: PaymentLetterProtection;
      PaymentMethodType: PaymentMethodType;
      PaymentSelfPay: PaymentSelfPay;
      PaymentWorkersComp: PaymentWorkersComp;
      PaymentWorkersCompAttachment: PaymentWorkersCompAttachment;
      PaymentWorkersCompAttachmentType: PaymentWorkersCompAttachmentType;
      Platform: Record<string, unknown>; // this isn't a prisma object, but must conform to the conventional prisma shape
      Practice: Practice;
      PreferenceCard: PreferenceCard;
      Procedure: Procedure;
      ProcedureHistory: ProcedureHistory;
      ProviderEncounterDocument: ProviderEncounterDocument;
      PublicSubmission: PublicSubmission;
      Questionnaire: Questionnaire;
      QuestionnaireQuestion: QuestionnaireQuestion;
      QuestionnaireQuestionCondition: QuestionnaireQuestionCondition;
      QuestionnaireResponse: QuestionnaireResponse;
      QuestionnaireResponseAnswer: QuestionnaireResponseAnswer;
      Role: Role;
      Room: Room;
      Signature: Signature;
      Specialty: Specialty;
      Staff: Staff;
      StaffSignature: StaffSignature;
      StandingMedicationOrder: StandingMedicationOrder;
      StandingOrder: StandingOrder;
      StorageBlob: StorageBlob;
      Supplier: Supplier;
      SupplierRep: SupplierRep;
      SurgeryProfile: SurgeryProfile;
      SurgeryProfileCharge: SurgeryProfileCharge;
      SurgeryProfileDiagnosis: SurgeryProfileDiagnosis;
      SurgicalTimeout: SurgicalTimeout;
      Tag: Tag;
      TextTemplate: TextTemplate;
      TimePeriod: TimePeriod;
      TimePeriodSchedule: TimePeriodSchedule;
      User: User;
      WaystarInsuranceProvider: WaystarInsuranceProvider;
      WaystarPayerGroup: WaystarPayerGroup;
      WorkersCompProvider: WorkersCompProvider;
    }>;

export type AppAbility = PureAbility<[string, AbilitySubjects], PrismaQuery>;

/** role intents
 *
 * asc:admin - bundles everything
 * asc - Case & Calendar but not Admin
 * practice:admin - bundles everything
 * practice - Case & Calendar
 */

const subRoles: Record<string, KnownUserRole[]> = {
  "asc:admin": ["asc", "asc:admin", "asc:billing", "asc:clinical"],
  "asc:billing": ["asc:billing", "asc"],
  "asc:clinical": ["asc:clinical", "asc"],
  "practice:admin": ["practice", "practice:admin"],
} as const;

export function bundleInheritedRoles(roles: Role[] | KnownUserRole[]) {
  type Subrole = keyof typeof subRoles;
  return roles
    .map<Subrole>((r) => (typeof r === "string" ? r : r.name))
    .reduce((acc, role) => {
      if (subRoles[role]) {
        return acc.concat(subRoles[role]);
      } else {
        return acc.concat(role as KnownUserRole);
      }
    }, [] as KnownUserRole[]);
}

/**
 * do not use this function directly, use `defineAbilitiesForOrganization` instead. This function is exported for testing purposes.
 * @param prisma
 * @param organization
 * @param roles
 * @param param3
 * @param userId
 */
export async function defineAbilitiesForOrganization(
  prisma: PrismaClient | null,
  organization: Organization | string,
  roles: Role[] | KnownUserRole[],
  { can, cannot }: Pick<AbilityBuilder<AppAbility>, "can" | "cannot">,
  userId: string | null
) {
  const organizationId = typeof organization === "string" ? organization : organization.id;
  const accessibleOrganizations = [organizationId];
  const inheritedRoles = bundleInheritedRoles(roles);

  if (inheritedRoles.includes("asc")) {
    (
      await prisma?.organizationRelationship?.findMany({
        where: {
          facilityOrgId: organizationId,
        },
        select: {
          practiceOrgId: true,
        },
      })
    )?.forEach(({ practiceOrgId }: { practiceOrgId: string }) => {
      accessibleOrganizations.includes(practiceOrgId) ||
        accessibleOrganizations.push(practiceOrgId);
    });
  }

  const staffRecord = await prisma?.staff?.findFirst({
    where: { organizationId, userId },
  });

  const SIGNATURE_CONDITIONS = {
    OR: [
      { appliedPatientSignature: { patient: { organizationId: { in: accessibleOrganizations } } } },
      { appliedStaffSignature: { staff: { organizationId: { in: accessibleOrganizations } } } },
      {
        clinicalEvents: {
          some: { encounter: { serviceProviderId: { in: accessibleOrganizations } } },
        },
      },
      {
        encounterDocuments: {
          some: { encounter: { serviceProviderId: { in: accessibleOrganizations } } },
        },
      },
      {
        encounterWorkflows: {
          some: { encounter: { serviceProviderId: { in: accessibleOrganizations } } },
        },
      },
      { orders: { some: { encounter: { serviceProviderId: { in: accessibleOrganizations } } } } },
    ],
  };

  // Core models that everyone can read
  can("read", "AssetType");
  can("read", "AttachmentType");
  can("read", "BillingAdjustment");
  can("read", "BillingCaseAttachmentType");
  can("read", "Bucket", { organizationId });
  can("read", "CodeableConcept");
  can("read", "Condition");
  can("read", "ConsentForm", { organizationId });
  can("read", "CptCode");
  can("read", "DocumentTemplate", { organizationId });
  can("read", "HcpcsCode");
  can("read", "HealthReviewType");
  can("read", "Integration", { organizationId });
  can("read", "JobRole");
  can("read", "MedicalCondition");
  can("read", "Organization", { id: organizationId });
  can("read", "PatientCommunicationWhitelist");
  can("read", "PatientSignature", {
    patient: { organizationId: { in: accessibleOrganizations } },
    signatures: { some: SIGNATURE_CONDITIONS },
  });
  can("read", "PaymentInsuranceAttachmentType");
  can("read", "PaymentMethodType");
  can("read", "PaymentWorkersCompAttachmentType");
  can("read", "Signature", SIGNATURE_CONDITIONS);
  can("read", "Specialty");
  can("read", "StaffSignature", {
    staff: { organizationId: { in: accessibleOrganizations } },
    // signatures: { some: SIGNATURE_CONDITIONS }, // TODO: new staff signatures aren't applied to anything yet and become unaccessable
  });
  can("read", "Tag", { OR: [{ organizationId }, { organizationId: null }] });
  can("read", "TextTemplate", { OR: [{ organizationId }, { organizationId: null }] });
  can("read", "WaystarInsuranceProvider");
  can("read", "WaystarPayerGroup");
  can("read", "Questionnaire");
  can("read", "QuestionnaireQuestion");
  can("read", "QuestionnaireQuestionCondition");

  for (const role of inheritedRoles) {
    switch (role) {
      case "platform:admin": {
        can("manage", "Asset");
        can("manage", "AssetType");
        can("manage", "BillingAdjustment");
        can("manage", "BillingChargeMaster");
        can("manage", "BillingModifier");
        can("manage", "BillingOrganization");
        can("manage", "Bucket");
        can("manage", "CodeableConcept");
        can("manage", "CommunicationTemplate");
        can("manage", "Condition");
        can("manage", "CptCode");
        can("manage", "DocumentTemplate");
        can("manage", "EmrTemplate");
        can("manage", "EmrTemplatePage");
        can("manage", "EmrTemplateSection");
        can("manage", "Facility");
        can("manage", "FacilityHours");
        can("manage", "HcpcsCode");
        can("manage", "HealthReviewType");
        can("manage", "InsuranceProvider");
        can("manage", "Integration");
        can("manage", "JobRole");
        can("manage", "Message");
        can("manage", "MessageThread");
        can("manage", "Organization");
        can("manage", "OrganizationRelationship");
        can("manage", "PatientCommunicationWhitelist");
        can("manage", "PaymentMethodType");
        can("manage", "Platform"); // This is kinda hacky but an easy way to detect you're a platform admin via `ability.can("manage", "Platform")`
        can("manage", "Practice");
        can("manage", "Questionnaire");
        can("manage", "QuestionnaireQuestion");
        can("manage", "QuestionnaireQuestionCondition");
        can("manage", "QuestionnaireResponse");
        can("manage", "QuestionnaireResponseAnswer");
        can("manage", "Room");
        can("manage", "Specialty");
        can("manage", "Staff");
        can("manage", "StaffSignature");
        can("manage", "TextTemplate");
        can("manage", "User");
        can("manage", "WaystarInsuranceProvider");
        can("manage", "WaystarPayerGroup");
        can("manage", "WorkersCompProvider");
        can("read", "EncounterWorkflow"); // this is neccessary to report how many impacted cases a particular page version uses.

        break;
      }
      case "asc:patient-deletion": {
        can("delete", "Patient", { organizationId });
        can("delete", "CaseStatusTracker", { case: { facility: { organizationId } } });
        break;
      }
      case "asc": {
        can("acknowledge", "Case", { facility: { organizationId } });
        can("manage", "Address", { organizationId });
        can("manage", "Attachment", { case: { facility: { organizationId } } });
        can("manage", "BulkCreate", { organizationId });
        can("manage", "Case", { facility: { organizationId } });
        can("manage", "CaseMedicalCondition", { case: { facility: { organizationId } } });
        can("manage", "CasePreferenceCard", { case: { facility: { organizationId } } });
        can("manage", "CasePreferenceCardEquipment", {
          casePreferenceCard: { case: { facility: { organizationId } } },
        });
        can("manage", "CaseRole", { case: { facility: { organizationId } } });
        can("manage", "Diagnosis", { case: { facility: { organizationId } } });
        can("manage", "EquipmentRequirement", { case: { facility: { organizationId } } });
        can("manage", "HealthReviewCondition", { organizationId });
        can("manage", "HealthReviewCondition", { organizationId });
        can("manage", "HealthReviewRequirement", { case: { facility: { organizationId } } });
        can("manage", "HealthReviewRequirementAttachment", {
          healthReviewRequirement: { case: { facility: { organizationId } } },
        });
        can("manage", "InsuranceProvider", { organizationId }); // 2023-10-30 Obinna said all schedulers can modify payers providers
        can("manage", "Message", { messageThread: { facility: { organizationId } } });
        can("manage", "MessageThread", { facility: { organizationId } });
        can("create", "Patient", { organizationId });
        can("update", "Patient", { organizationId });
        can("read", "Patient", { organizationId });
        can("manage", "PatientCommunication", { organizationId });
        can("manage", "PatientConsent", { case: { facility: { organizationId } } });
        can("manage", "PaymentInsurance", { case: { facility: { organizationId } } });
        can("manage", "PaymentInsuranceAttachment", {
          paymentInsurance: { case: { facility: { organizationId } } },
        });
        can("manage", "PaymentLetterProtection", { case: { facility: { organizationId } } });
        can("manage", "PaymentSelfPay", { case: { facility: { organizationId } } });
        can("manage", "PaymentWorkersComp", { case: { facility: { organizationId } } });
        can("manage", "PaymentWorkersCompAttachment", {
          paymentWorkersComp: { case: { facility: { organizationId } } },
        });
        can("manage", "Procedure", { case: { facility: { organizationId } } });
        can("manage", "StorageBlob", { organizationId });
        can("manage", "WorkersCompProvider", { organizationId });
        can("read", "CaseStatusTracker", { case: { facility: { organizationId } } });
        can("read", "EmrTemplate", { facility: { organizationId } }); // none of these emr template concepts are clinical data, though, they're close.
        can("read", "EmrTemplatePage", { emrTemplate: { facility: { organizationId } } });
        can("read", "EmrTemplateSection", {
          page: { emrTemplate: { facility: { organizationId } } },
        });
        can("manage", "Encounter", { serviceProviderId: organizationId, open: true });
        can("read", "Encounter", { serviceProviderId: organizationId });
        can("read", "EncounterClosure", { encounter: { serviceProviderId: organizationId } });
        can("read", "Equipment", { organizationId });
        can("read", "Facility", { organizationId });
        can("create", "Facility", "sequentialMrnSequence", { organizationId });
        can("update", "Facility", "sequentialMrnSequence", { organizationId });
        can("read", "FacilityHours", { facility: { organizationId } });
        can("read", "Practice", {
          organization: { practices: { some: { facilityOrgId: organizationId } } },
        });
        can("read", "PreferenceCard", { organizationId });
        can("read", "Room", { facility: { organizationId } });
        can("read", "Specialty");
        can("read", "Staff", { organizationId: { in: accessibleOrganizations } });
        can("read", "Supplier", { organizationId });
        can("read", "SupplierRep", { supplier: { organizationId } });
        can("read", "SurgeryProfile", { facility: { organizationId } });
        can("read", "SurgeryProfileCharge", { surgeryProfile: { facility: { organizationId } } });
        can("read", "SurgeryProfileDiagnosis", {
          surgeryProfile: { facility: { organizationId } },
        });
        can("read", "TimePeriod", { room: { facility: { organizationId } } });
        can("read", "TimePeriodSchedule", {
          timePeriods: { some: { room: { facility: { organizationId } } } },
        });
        can("read", "User", { staff: { some: { organizationId } } });
        can("update", "TimePeriod", ["roomId"], { room: { facility: { organizationId } } }); // cannot manage TimePeriod because only admins can manage TimePeriods
        cannot("manage", "Case", {
          createdBy: OrganizationType.PRACTICE,
          status: { in: [CaseStatus.Draft, CaseStatus.Abandoned] },
        });
        for (const op of ["create", "update", "read"] as const) {
          can(op, "BillingCase", { case: { facility: { organizationId } } });
        }
        can("read", "CommunicationTemplate", { organizationId });

        break;
      }
      case "asc:billing": {
        const MANAGEABLE_CASES = { facility: { organizationId } };
        const MANAGEABLE_BILLING_CASES = { case: MANAGEABLE_CASES };
        can("manage", "BillingCase", MANAGEABLE_BILLING_CASES);
        can("manage", "BillingCaseAttachment", {
          billingCase: MANAGEABLE_BILLING_CASES,
        });
        can("manage", "BillingCharge", { billingCase: MANAGEABLE_BILLING_CASES });
        can("manage", "BillingChargeMaster", { billingOrganization: { organizationId } });
        can("manage", "BillingChargeModifier", {
          billingCharge: { billingCase: MANAGEABLE_BILLING_CASES },
        });
        can("manage", "BillingClaim", { billingCase: MANAGEABLE_BILLING_CASES });
        can("manage", "BillingDiagnosis", {
          billingCase: MANAGEABLE_BILLING_CASES,
        });
        can("manage", "BillingOrganization", { organizationId });
        can("manage", "BillingPayer", { billingCase: MANAGEABLE_BILLING_CASES });
        can("manage", "BillingTransaction", { organization: { organizationId } });
        can("manage", "BillingTransactionAllocation", {
          billingTransaction: { organization: { organizationId } },
        });
        can("read", "BillingModifier");

        break;
      }
      case "practice": {
        const MANAGEABLE_CASES = { practice: { organizationId } };
        const RELATED_FACILITY_ORG = { facilities: { some: { practiceOrgId: organizationId } } };
        can("manage", "Attachment", { case: MANAGEABLE_CASES });
        can("manage", "BulkCreate", { organizationId, destination: "Case" });
        can("manage", "Case", MANAGEABLE_CASES);
        can("manage", "CaseMedicalCondition", { case: MANAGEABLE_CASES });
        can("manage", "Diagnosis", { case: MANAGEABLE_CASES });
        can("read", "DocumentTemplate", {
          organization: RELATED_FACILITY_ORG,
        });
        can("manage", "EquipmentRequirement", { case: MANAGEABLE_CASES });
        can("manage", "HealthReviewRequirement", { case: MANAGEABLE_CASES });
        can("manage", "HealthReviewRequirementAttachment", {
          healthReviewRequirement: { case: MANAGEABLE_CASES },
        });
        can("manage", "Message", { messageThread: MANAGEABLE_CASES });
        can("manage", "MessageThread", MANAGEABLE_CASES);
        can("manage", "PaymentInsurance", { case: MANAGEABLE_CASES });
        can("manage", "PaymentInsuranceAttachment", {
          paymentInsurance: { case: MANAGEABLE_CASES },
        });
        can("manage", "PaymentLetterProtection", { case: MANAGEABLE_CASES });
        can("manage", "PaymentSelfPay", { case: MANAGEABLE_CASES });
        can("manage", "PaymentWorkersComp", { case: MANAGEABLE_CASES });
        can("manage", "PaymentWorkersCompAttachment", {
          paymentWorkersComp: { case: MANAGEABLE_CASES },
        });
        can("manage", "Procedure", { case: MANAGEABLE_CASES });
        can("manage", "Procedure", { case: MANAGEABLE_CASES });
        can("manage", "StorageBlob", { organizationId });
        can("read", "CaseStatusTracker", { case: MANAGEABLE_CASES });
        can("read", "Encounter", { appointment: MANAGEABLE_CASES });
        can("read", "Equipment", {
          organization: RELATED_FACILITY_ORG,
        });
        can("read", "Facility", {
          organization: RELATED_FACILITY_ORG,
        });
        can("read", "FacilityHours", {
          facility: { organization: RELATED_FACILITY_ORG },
        });
        can("read", "HealthReviewCondition", { organizationId });
        can("read", "HealthReviewCondition", { organizationId });
        can("read", "InsuranceProvider", {
          organization: RELATED_FACILITY_ORG,
        });
        can("read", "Patient", { cases: { some: { practice: { organizationId } } } });
        can("read", "Practice", { organizationId });
        can("read", "Room", {
          facility: { organization: RELATED_FACILITY_ORG },
        });
        can("read", "Staff", { organizationId });
        can("read", "SurgeryProfile", {
          facility: { organization: RELATED_FACILITY_ORG },
        });
        can("read", "SurgeryProfileCharge", {
          surgeryProfile: {
            facility: { organization: RELATED_FACILITY_ORG },
          },
        });
        can("read", "SurgeryProfileDiagnosis", {
          surgeryProfile: {
            facility: { organization: RELATED_FACILITY_ORG },
          },
        });
        can("read", "TextTemplate", {
          // organizationId is null for global templates but was explicitly allowed above
          organization: RELATED_FACILITY_ORG,
        });
        can("read", "TimePeriod", {
          AND: [
            {
              room: {
                facility: {
                  organization: RELATED_FACILITY_ORG,
                },
              },
            },
            { OR: [{ practice: { organizationId } }, { practiceId: null }] },
          ],
        });
        can("read", "TimePeriodSchedule", {
          timePeriods: {
            some: {
              AND: [
                {
                  room: {
                    facility: {
                      organization: RELATED_FACILITY_ORG,
                    },
                  },
                },
                { OR: [{ practice: { organizationId } }, { practiceId: null }] },
              ],
            },
          },
        });
        can("read", "WorkersCompProvider", {
          organization: RELATED_FACILITY_ORG,
        });
        can("update", "Encounter", { appointment: MANAGEABLE_CASES, open: true }); // this is for the encounter status update
        // TODO: Encounter needs a open=true check to be updatable. Needs to be readable as is though.
        cannot("manage", "Case", {
          createdBy: OrganizationType.FACILITY,
          status: { in: [CaseStatus.Draft, CaseStatus.Abandoned] },
        });

        break;
      }
      case "practice:clinical": {
        const MY_CASES: Prisma.CaseWhereInput = { practice: { organizationId } };
        const MY_ENCOUNTERS: Prisma.EncounterWhereInput = { appointment: MY_CASES };
        const MY_OPEN_ENCOUNTERS: Prisma.EncounterWhereInput = { ...MY_ENCOUNTERS, open: true };
        const ENCOUNTER_TEMPLATES: Prisma.EmrTemplateWhereInput = {
          encounters: { some: MY_ENCOUNTERS },
        };
        const ENCOUNTER_PATIENTS: Prisma.PatientWhereInput = {
          encounters: { some: MY_ENCOUNTERS },
        };
        const ENCOUNTER_QUESTIONNAIRES: Prisma.QuestionnaireWhereInput = {}; // TODO: Figure this one out
        const RELEVANT_ORDER: Prisma.OrderWhereInput = { encounter: MY_ENCOUNTERS };
        const RELEVANT_OPEN_ORDER: Prisma.OrderWhereInput = { encounter: MY_OPEN_ENCOUNTERS };
        const RELEVANT_CE: Prisma.ClinicalEventWhereInput = { encounter: MY_ENCOUNTERS };
        const RELEVANT_OPEN_CE: Prisma.ClinicalEventWhereInput = { encounter: MY_OPEN_ENCOUNTERS };

        can("manage", "Allergy", { patient: ENCOUNTER_PATIENTS });
        can("manage", "Asset", { encounter: MY_ENCOUNTERS, patient: ENCOUNTER_PATIENTS });
        can("read", "ClinicalEvent", { encounter: MY_ENCOUNTERS });
        can("manage", "ClinicalEvent", { encounter: MY_OPEN_ENCOUNTERS });
        can("read", "Encounter", MY_ENCOUNTERS);
        can("manage", "Encounter", MY_OPEN_ENCOUNTERS);
        can("read", "Line", { clinicalEvent: RELEVANT_CE });
        can("manage", "Line", { clinicalEvent: RELEVANT_OPEN_CE });
        can("read", "MedicationAdministrationRecord", { order: RELEVANT_ORDER });
        can("manage", "MedicationAdministrationRecord", { order: RELEVANT_OPEN_ORDER });
        can("read", "Order", RELEVANT_ORDER);
        can("manage", "Order", RELEVANT_OPEN_ORDER);
        can("manage", "PatientCondition", { patient: ENCOUNTER_PATIENTS });
        can("manage", "PatientMedicationChange", { medication: { patient: ENCOUNTER_PATIENTS } }); // This is medication reconciliation does the physician need to manage this?
        can("read", "PatientNote", { encounter: MY_ENCOUNTERS });
        can("manage", "PatientNote", { encounter: MY_OPEN_ENCOUNTERS });
        can("manage", "ProcedureHistory", { patient: ENCOUNTER_PATIENTS });
        can("manage", "PublicSubmission", { facility: { organizationId } });
        can("read", "QuestionnaireResponse", { encounter: MY_ENCOUNTERS });
        can("manage", "QuestionnaireResponse", { encounter: MY_OPEN_ENCOUNTERS });
        can("read", "QuestionnaireResponseAnswer", {
          questionnaireResponse: { encounter: MY_ENCOUNTERS },
        });
        can("manage", "QuestionnaireResponseAnswer", {
          questionnaireResponse: { encounter: MY_OPEN_ENCOUNTERS },
        });
        can("read", "Signature", {
          OR: [
            { appliedPatientSignature: { patient: { organizationId: organizationId } } },
            { appliedStaffSignature: { staff: { organizationId: organizationId } } },
            { clinicalEvents: { some: { encounter: MY_ENCOUNTERS } } },
            { encounterDocuments: { some: { encounter: MY_ENCOUNTERS } } },
            { encounterWorkflows: { some: { encounter: MY_ENCOUNTERS } } },
            { orders: { some: { encounter: MY_ENCOUNTERS } } },
          ],
        });
        can("manage", "Signature", {
          OR: [
            { appliedPatientSignature: { patient: { organizationId: organizationId } } },
            { appliedStaffSignature: { staff: { organizationId: organizationId } } },
            { clinicalEvents: { some: { encounter: MY_OPEN_ENCOUNTERS } } },
            { encounterDocuments: { some: { encounter: MY_OPEN_ENCOUNTERS } } },
            { encounterWorkflows: { some: { encounter: MY_OPEN_ENCOUNTERS } } },
            { orders: { some: { encounter: MY_OPEN_ENCOUNTERS } } },
          ],
        });
        can("read", "StorageBlob", {
          assets: { some: { encounter: MY_ENCOUNTERS } },
        });
        can("manage", "StorageBlob", {
          assets: { some: { encounter: MY_OPEN_ENCOUNTERS } },
        });
        can("read", "SurgicalTimeout", { clinicalEvent: RELEVANT_CE });
        can("manage", "SurgicalTimeout", { clinicalEvent: RELEVANT_OPEN_CE });
        can("read", "Address", { patients: { some: ENCOUNTER_PATIENTS } });
        can("read", "Allergy", { patient: ENCOUNTER_PATIENTS });
        can("read", "EmrTemplate", ENCOUNTER_TEMPLATES);
        can("read", "EmrTemplatePage", { emrTemplate: ENCOUNTER_TEMPLATES });
        can("read", "EncounterDocument", { encounter: MY_ENCOUNTERS });
        can("read", "EncounterParticipant", { encounter: MY_ENCOUNTERS });
        can("read", "EncounterWorkflow", { encounter: MY_ENCOUNTERS }); // TODO: If physician needs to sign the encounterWorkflow, they would need an update here.
        can("read", "EncounterWorkflowSignature", {
          encounterWorkflow: { encounter: MY_ENCOUNTERS },
        });
        can("read", "OrderGroup", {
          organization: { facilities: { some: { practiceOrgId: organizationId } } },
        });
        can("read", "PatientCondition", { patient: ENCOUNTER_PATIENTS });
        can("read", "PatientMedication", { patient: ENCOUNTER_PATIENTS });
        can("read", "PatientMedication", { patient: ENCOUNTER_PATIENTS });
        can("read", "ProcedureHistory", { patient: ENCOUNTER_PATIENTS });
        can("read", "PublicSubmission", { encounter: MY_ENCOUNTERS, reviewStatus: "Draft" }); // is this really neccessary
        can("read", "Questionnaire", ENCOUNTER_QUESTIONNAIRES);
        can("read", "QuestionnaireQuestion", { questionnaire: ENCOUNTER_QUESTIONNAIRES });
        can("read", "QuestionnaireQuestionCondition", {
          sourceQuestion: { questionnaire: ENCOUNTER_QUESTIONNAIRES },
        });
        can("read", "StandingOrder", {
          provider: { organizationId: { in: accessibleOrganizations } },
        });

        if (staffRecord) {
          // Physician can manage documents for encounters in their practice
          can("read", "EncounterDocument", { encounter: MY_ENCOUNTERS });
          can("manage", "EncounterDocument", { encounter: MY_OPEN_ENCOUNTERS });
          can("read", "ProviderEncounterDocument", {
            encounterDocument: { encounter: MY_ENCOUNTERS },
          });
          can("manage", "ProviderEncounterDocument", {
            encounterDocument: { encounter: MY_OPEN_ENCOUNTERS },
          });
          can("manage", "StaffSignature", { staff: { id: staffRecord.id } });
        }

        break;
      }
      case "asc:admin": {
        can("manage", "ConsentForm", { organizationId });
        can("manage", "DocumentTemplate", { organizationId });
        can("manage", "Equipment", { organizationId });
        can("manage", "Facility", { organizationId });
        can("manage", "FacilityHours", { facility: { organizationId } });
        can("manage", "OrderGroup", { organizationId });
        can("manage", "Organization", { id: organizationId });
        can("manage", "PatientSignature", {
          patient: { organizationId: { in: accessibleOrganizations } },
          signatures: { some: SIGNATURE_CONDITIONS },
        });
        can("manage", "PreferenceCard", { organizationId });
        can("manage", "Signature", SIGNATURE_CONDITIONS);
        can("manage", "Staff", { organizationId: { in: accessibleOrganizations } });
        can("manage", "StaffSignature", {
          staff: { organizationId: { in: accessibleOrganizations } },
          signatures: { some: SIGNATURE_CONDITIONS },
        });
        can("manage", "StandingMedicationOrder", {
          standingOrder: { provider: { organizationId: { in: accessibleOrganizations } } },
        });
        can("manage", "StandingOrder", {
          provider: { organizationId: { in: accessibleOrganizations } },
        });
        can("manage", "Supplier", { organizationId });
        can("manage", "SupplierRep", { supplier: { organizationId } });
        can("manage", "SurgeryProfile", { facility: { organizationId } });
        can("manage", "SurgeryProfileCharge", { surgeryProfile: { facility: { organizationId } } });
        can("manage", "SurgeryProfileDiagnosis", {
          surgeryProfile: { facility: { organizationId } },
        });
        can("manage", "TextTemplate", { organizationId });
        can("manage", "TimePeriod", { room: { facility: { organizationId } } });
        can("manage", "TimePeriodSchedule", {
          timePeriods: { some: { room: { facility: { organizationId } } } },
        });
        can("read", "ChargeAllocationsView", { organizationId });
        can("read", "ChargePayerAmountView", { organizationId });
        can("read", "Organization", { id: { in: accessibleOrganizations } }); // I can manage my own organization but I can read any affiliated organization
        can("read", "WaystarInsuranceProvider");
        can("update", "Room", { facility: { organizationId } }); // could already read by asc, cannot delete | create
        cannot("delete", "Organization", { id: organizationId });
        can("update", "Encounter", ["open"], { open: false, serviceProviderId: organizationId }); // allow asc:admin to reopen a closed encounter
        can("manage", "CommunicationTemplate", { organizationId });

        // allow asc:admin to manage their own templates
        const MANAGEABLE_TEMPLATES = { facility: { organizationId } } as const;
        can("manage", "EmrTemplate", MANAGEABLE_TEMPLATES);
        can("manage", "EmrTemplatePage", { emrTemplate: MANAGEABLE_TEMPLATES });
        can("manage", "EmrTemplateSection", { page: { emrTemplate: MANAGEABLE_TEMPLATES } });

        break;
      }
      case "asc:clinical": {
        const READABLE_ENCOUNTER: Prisma.EncounterWhereInput = {
          serviceProviderId: organizationId,
        };
        const MANAGEABLE_ENCOUNTER: Prisma.EncounterWhereInput = {
          serviceProviderId: organizationId,
          open: true,
        };

        const MANAGEABLE_PATIENT: Prisma.PatientWhereInput = { organizationId };
        const MANAGEABLE_QUESTIONNAIRE: Prisma.QuestionnaireWhereInput = { organizationId };
        const READABLE_ORDER: Prisma.OrderWhereInput = { encounter: READABLE_ENCOUNTER };
        const MANAGEABLE_ORDER: Prisma.OrderWhereInput = { encounter: MANAGEABLE_ENCOUNTER };
        const READABLE_CE: Prisma.ClinicalEventWhereInput = { encounter: READABLE_ENCOUNTER };
        const MANAGEABLE_CE: Prisma.ClinicalEventWhereInput = { encounter: MANAGEABLE_ENCOUNTER };

        can("manage", "Allergy", { patient: MANAGEABLE_PATIENT });
        can("manage", "Asset", {
          encounter: { serviceProvider: { id: organizationId } },
          patient: { organizationId },
        });
        can("read", "ClinicalEvent", READABLE_CE);
        can("manage", "ClinicalEvent", MANAGEABLE_CE);
        can("read", "ClinicalEventDimension", { clinicalEvent: READABLE_CE });
        can("manage", "ClinicalEventDimension", { clinicalEvent: MANAGEABLE_CE });
        can("read", "Encounter", READABLE_ENCOUNTER);
        can("manage", "Encounter", MANAGEABLE_ENCOUNTER);
        can("read", "EncounterClosure", { encounter: READABLE_ENCOUNTER });
        can("manage", "EncounterClosure", { encounter: MANAGEABLE_ENCOUNTER });
        can("read", "EncounterDocument", { encounter: READABLE_ENCOUNTER });
        can("manage", "EncounterDocument", { encounter: MANAGEABLE_ENCOUNTER });
        can("read", "EncounterParticipant", { encounter: READABLE_ENCOUNTER });
        can("manage", "EncounterParticipant", { encounter: MANAGEABLE_ENCOUNTER });
        can("read", "EncounterWorkflow", { encounter: READABLE_ENCOUNTER });
        can("manage", "EncounterWorkflow", { encounter: MANAGEABLE_ENCOUNTER });
        can("read", "EncounterWorkflowSignature", {
          encounterWorkflow: { encounter: READABLE_ENCOUNTER },
        });
        can("manage", "EncounterWorkflowSignature", {
          encounterWorkflow: { encounter: MANAGEABLE_ENCOUNTER },
        });
        can("read", "Line", { clinicalEvent: READABLE_CE });
        can("manage", "Line", { clinicalEvent: MANAGEABLE_CE });
        can("manage", "MedicationAdministrationRecord", { order: MANAGEABLE_ORDER });
        can("read", "MedicationAdministrationRecord", { order: READABLE_ORDER });
        can("read", "Order", READABLE_ORDER);
        can("manage", "Order", MANAGEABLE_ORDER);
        can("manage", "PatientCondition", { patient: MANAGEABLE_PATIENT });
        can("manage", "PatientCondition", { patient: MANAGEABLE_PATIENT });
        can("manage", "PatientMedication", { patient: MANAGEABLE_PATIENT });
        can("manage", "PatientMedicationChange", { medication: { patient: MANAGEABLE_PATIENT } });
        can("read", "PatientNote", { encounter: READABLE_ENCOUNTER });
        can("manage", "PatientNote", { encounter: MANAGEABLE_ENCOUNTER });
        can("manage", "PatientSignature", {
          patient: { organizationId: { in: accessibleOrganizations } },
          signatures: { some: SIGNATURE_CONDITIONS },
        });
        can("manage", "ProcedureHistory", { patient: MANAGEABLE_PATIENT });
        can("read", "ProviderEncounterDocument", {
          encounterDocument: { encounter: READABLE_ENCOUNTER },
        });
        can("manage", "ProviderEncounterDocument", {
          encounterDocument: { encounter: MANAGEABLE_ENCOUNTER },
        });
        can("manage", "PublicSubmission", { facility: { organizationId } });
        can("read", "QuestionnaireResponse", { encounter: READABLE_ENCOUNTER });
        can("manage", "QuestionnaireResponse", { encounter: MANAGEABLE_ENCOUNTER });
        can("read", "QuestionnaireResponseAnswer", {
          questionnaireResponse: { encounter: READABLE_ENCOUNTER },
        });
        can("manage", "QuestionnaireResponseAnswer", {
          questionnaireResponse: { encounter: MANAGEABLE_ENCOUNTER },
        });
        can("manage", "Signature", SIGNATURE_CONDITIONS);
        can("manage", "StaffSignature", {
          staff: { organizationId: { in: accessibleOrganizations } },
          signatures: { some: SIGNATURE_CONDITIONS },
        });
        can("manage", "StaffSignature", { staff: { organizationId } });
        can("read", "StorageBlob", {
          assets: { some: { encounter: READABLE_ENCOUNTER } },
        });
        can("manage", "StorageBlob", {
          assets: { some: { encounter: MANAGEABLE_ENCOUNTER } },
        });
        can("read", "SurgicalTimeout", { clinicalEvent: READABLE_CE });
        can("manage", "SurgicalTimeout", { clinicalEvent: MANAGEABLE_CE });
        can("read", "EmrTemplate", { facility: { organizationId } });
        can("read", "EmrTemplatePage", { emrTemplate: { facility: { organizationId } } });
        can("read", "OrderGroup", { organizationId });
        can("read", "Questionnaire", MANAGEABLE_QUESTIONNAIRE);
        can("read", "QuestionnaireQuestion", { questionnaire: MANAGEABLE_QUESTIONNAIRE });
        can("read", "QuestionnaireQuestionCondition", {
          sourceQuestion: { questionnaire: MANAGEABLE_QUESTIONNAIRE },
        });
        can("read", "StandingOrder", {
          provider: { organizationId: { in: accessibleOrganizations } },
        });

        break;
      }
      case "practice:admin": {
        can("manage", "Practice", { organizationId });
        can("manage", "Staff", { organizationId });

        break;
      }
      case "patient": {
        // see also publicPatPatientPermissions below
        can("read", "EmrTemplatePage", { status: "Published", workflowType: "PAT" });
        // can read facilities with published PATs
        can("read", "Facility", {
          emrTemplates: {
            some: {
              pages: {
                some: {
                  status: "Published",
                  workflowType: "PAT",
                },
              },
            },
          },
        });
        can("create", "PublicSubmission"); // TODO: How to better jail this?

        break;
      }
    }
  }
}

export function publicPatPatientPermissions(
  encounterId: string,
  patientId: string,
  { can }: Pick<AbilityBuilder<AppAbility>, "can">
) {
  const permissionEncounterWhere: Prisma.EncounterWhereInput = {
    id: encounterId,
    phantom: true,
    publicSubmissions: {
      every: {
        reviewStatus: "Draft",
      },
    },
  };
  const permissionPatientWhere: Prisma.PatientWhereInput = {
    id: patientId,
    phantom: true,
  };

  // allow update on the encounter and patient
  for (const operation of ["read", "update"]) {
    can(operation, "Encounter", permissionEncounterWhere);
    can(operation, "Patient", permissionPatientWhere);
  }

  // TODO: This can probably be removed, invalidated by new signature work. Leaving for now...
  can("create", "EncounterWorkflowSignature", {
    encounterWorkflow: { encounter: permissionEncounterWhere },
  });

  can("manage", "Address", { patients: { some: permissionPatientWhere } });
  can("manage", "Allergy", { patient: permissionPatientWhere });
  can("manage", "ClinicalEvent", { encounter: permissionEncounterWhere });
  can("manage", "PatientCondition", { patient: permissionPatientWhere });
  can("manage", "PatientMedication", { patient: permissionPatientWhere });
  can("manage", "PatientSignature", { patient: permissionPatientWhere });
  can("manage", "ProcedureHistory", { patient: permissionPatientWhere });
  can("manage", "PublicSubmission", { encounter: permissionEncounterWhere, reviewStatus: "Draft" });
  can("manage", "Signature", { appliedPatientSignature: { patient: permissionPatientWhere } });

  can("manage", "QuestionnaireResponse", { encounterId: encounterId });
  can("manage", "QuestionnaireResponseAnswer", {
    questionnaireResponse: { encounterId: encounterId },
  });
}

export async function defineAbility(
  prisma: PrismaClient,
  userId: string,
  organizationId: string,
  clerkUser?: Pick<ClerkUser, "publicMetadata">,
  clerkOrganization?: Pick<ClerkOrganization, "slug">
) {
  if (!userId?.length || !organizationId?.length) {
    throw new Error("userId and organizationId are required");
  }

  const ability = new AbilityBuilder<AppAbility>(createPrismaAbility);
  const roles = getUserRoles(clerkUser, clerkOrganization);
  // roles are roles from clerk. string[] which SHOULD be KnownUserRole[] but typos can happen
  await defineAbilitiesForOrganization(
    prisma,
    organizationId,
    roles.filter(isKnownUserRole),
    ability,
    userId
  );
  ability.can("manage", "User", { id: userId });

  return ability.build();
}

/**
 * does the same work as define ability, but takes raw values instead of
 * assuming an auth method
 *
 * @param prisma
 * @param organizationId
 * @param roles
 * @returns AppAbility for the designated organization/roles intersection
 */

export async function generateAbility(
  prisma: PrismaClient,
  organizationId: string,
  roles: string[],
  customAbilities: (a: AbilityBuilder<AppAbility>) => Promise<unknown> = () => Promise.resolve(),
  userId: string | null = null
) {
  const a = new AbilityBuilder<AppAbility>(createPrismaAbility);
  await customAbilities(a);
  await defineAbilitiesForOrganization(
    prisma,
    organizationId,
    roles.filter(isKnownUserRole),
    a,
    userId
  );
  return a.build();
}
