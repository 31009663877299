import type { Prisma } from "@prisma/client";
import type { InputJsonValue, JsonValue } from "@prisma/client/runtime/library";
import type { PatientCommunicationError } from "@procision-software/database";
import type { CommunicationTemplateRecord } from "../communication-template";

export type PatientCommunicationChannel = "SMS";

export type PatientCommunicationCreateInput = {
  channel: PatientCommunicationChannel;
  message: InputJsonValue;
  encounterId?: string | null;
  patientId?: string | null;
  templateId?: string | null;
  userId?: string | null;
  organizationId: string;
  to: string;
};

export type PatientCommunicationUpdateInput = {
  id: string;
} & Partial<{
  channel: PatientCommunicationChannel;
  context: Prisma.PatientCommunicationUpdateInput["context"];
  error: Prisma.PatientCommunicationUpdateInput["error"];
  message: InputJsonValue;
  sentAt: Date | null;
  to?: string;
}>;

export type PatientCommunicationRecord = {
  id: string;
  channel: PatientCommunicationChannel;
  message: JsonValue; // the data type of the Document Template editor
  preview: string;
  encounterId: string | null;
  patientId: string | null;
  userId: string | null;
  context: JsonValue;
  organizationId: string;
  createdAt: Date;
  updatedAt: Date;
  sentAt: Date | null;
  error: PatientCommunicationError;
  to: string;
  templateId: string | null;
  template: CommunicationTemplateRecord | null;
};

export const PatientCommunicationErrorFormatted: Record<PatientCommunicationError, string> = {
  NO_ERROR: "Reminder",
  UNSUBSCRIBED: "Unsubscribed",
  INVALID_PHONE_NUMBER: "Invalid Phone Number",
  UNKNOWN_ERROR: "Unknown Error",
  NOT_WHITELISTED: "Not Whitelisted",
};
