import * as z from "zod";

// Code identifying the type of communication number

export const BillingProviderContactInformationCommunicationNumberQualifier03Schema = z.enum([
  "EM",
  "FX",
  "TE",
]);
export type BillingProviderContactInformationCommunicationNumberQualifier03 = z.infer<
  typeof BillingProviderContactInformationCommunicationNumberQualifier03Schema
>;

// Code identifying the type of communication number

export const CommunicationNumberQualifier07_EnumSchema = z.enum(["EM", "EX", "FX", "TE"]);
export type CommunicationNumberQualifier07_Enum = z.infer<
  typeof CommunicationNumberQualifier07_EnumSchema
>;

// Code identifying the major duty or responsibility of the person or group named

export const ContactFunctionCodeSchema = z.enum(["IC"]);
export type ContactFunctionCode = z.infer<typeof ContactFunctionCodeSchema>;

// Code identifying an organizational entity, a physical location, property or an individual

export const BillingProviderNameEntityIdentifierCode01Schema = z.enum(["85"]);
export type BillingProviderNameEntityIdentifierCode01 = z.infer<
  typeof BillingProviderNameEntityIdentifierCode01Schema
>;

// Code qualifying the type of entity
//
// - NM102 qualifies NM103.

export const BillingProviderNameEntityTypeQualifier02Schema = z.enum(["1", "2"]);
export type BillingProviderNameEntityTypeQualifier02 = z.infer<
  typeof BillingProviderNameEntityTypeQualifier02Schema
>;

// Code designating the system/method of code structure used for Identification Code (67)

export const BillingProviderNameIdentificationCodeQualifier08Schema = z.enum(["XX"]);
export type BillingProviderNameIdentificationCodeQualifier08 = z.infer<
  typeof BillingProviderNameIdentificationCodeQualifier08Schema
>;

// Code qualifying the Reference Identification

export const BillingProviderTaxIdentificationRefReferenceIdentificationQualifier01Schema = z.enum([
  "EI",
  "SY",
]);
export type BillingProviderTaxIdentificationRefReferenceIdentificationQualifier01 = z.infer<
  typeof BillingProviderTaxIdentificationRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const BillingProviderUpinLicenseInformationRefReferenceIdentificationQualifier01Schema =
  z.enum(["0B", "1G"]);
export type BillingProviderUpinLicenseInformationRefReferenceIdentificationQualifier01 = z.infer<
  typeof BillingProviderUpinLicenseInformationRefReferenceIdentificationQualifier01Schema
>;

// Code identifying the type of provider

export const ProviderCodeSchema = z.enum(["BI"]);
export type ProviderCode = z.infer<typeof ProviderCodeSchema>;

// Code qualifying the Reference Identification

export const ReferenceIdentificationQualifier02_EnumSchema = z.enum(["PXC"]);
export type ReferenceIdentificationQualifier02_Enum = z.infer<
  typeof ReferenceIdentificationQualifier02_EnumSchema
>;

// Code identifying an organizational entity, a physical location, property or an individual

export const PayToAddressNameNm1EntityIdentifierCode01Schema = z.enum(["87"]);
export type PayToAddressNameNm1EntityIdentifierCode01 = z.infer<
  typeof PayToAddressNameNm1EntityIdentifierCode01Schema
>;

// Code identifying an organizational entity, a physical location, property or an
// individual
//
// Code identifying the type of provider

export const ErCodeSchema = z.enum(["PE"]);
export type ErCode = z.infer<typeof ErCodeSchema>;

// Code qualifying the type of entity
//
// - NM102 qualifies NM103.

export const PayToPlanNameNm1EntityTypeQualifier02Schema = z.enum(["2"]);
export type PayToPlanNameNm1EntityTypeQualifier02 = z.infer<
  typeof PayToPlanNameNm1EntityTypeQualifier02Schema
>;

// Code designating the system/method of code structure used for Identification Code (67)

export const PayToPlanNameNm1IdentificationCodeQualifier08Schema = z.enum(["PI", "XV"]);
export type PayToPlanNameNm1IdentificationCodeQualifier08 = z.infer<
  typeof PayToPlanNameNm1IdentificationCodeQualifier08Schema
>;

// Code qualifying the Reference Identification

export const PayToPlanSecondaryIdentificationRefReferenceIdentificationQualifier01Schema = z.enum([
  "FY",
  "NF",
  "2U",
]);
export type PayToPlanSecondaryIdentificationRefReferenceIdentificationQualifier01 = z.infer<
  typeof PayToPlanSecondaryIdentificationRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const PayToPlanTaxIdentificationNumberRefReferenceIdentificationQualifier01Schema = z.enum([
  "EI",
]);
export type PayToPlanTaxIdentificationNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof PayToPlanTaxIdentificationNumberRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const AdjustedRepricedClaimNumberRefReferenceIdentificationQualifier01Schema = z.enum([
  "9C",
]);
export type AdjustedRepricedClaimNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof AdjustedRepricedClaimNumberRefReferenceIdentificationQualifier01Schema
>;

// Code indicating a Yes or No condition or response
//
// - CRC02 is a Certification Condition Code applies indicator. A "Y" value indicates the
// condition codes in CRC03 through CRC07 apply; an "N" value indicates the condition codes
// in CRC03 through CRC07 do not apply.
//
// Code indicating a Yes or No condition or response
//
// - CLM06 is provider signature on file indicator. A "Y" value indicates the provider
// signature is on file; an "N" value indicates the provider signature is not on file.

export const ProviderOrSupplierSignatureIndicator06_EnumSchema = z.enum(["N", "Y"]);
export type ProviderOrSupplierSignatureIndicator06_Enum = z.infer<
  typeof ProviderOrSupplierSignatureIndicator06_EnumSchema
>;

// Specifies the situation or category to which the code applies
//
// - CRC01 qualifies CRC03 through CRC07.

export const AmbulanceCertificationCrcCodeCategory01Schema = z.enum(["07"]);
export type AmbulanceCertificationCrcCodeCategory01 = z.infer<
  typeof AmbulanceCertificationCrcCodeCategory01Schema
>;

// Code indicating a condition

export const AmbulanceCertificationCrcConditionCode03Schema = z.enum([
  "01",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "12",
]);
export type AmbulanceCertificationCrcConditionCode03 = z.infer<
  typeof AmbulanceCertificationCrcConditionCode03Schema
>;

// Code identifying an organizational entity, a physical location, property or an individual

export const AmbulanceDropOffLocationNm1EntityIdentifierCode01Schema = z.enum(["45"]);
export type AmbulanceDropOffLocationNm1EntityIdentifierCode01 = z.infer<
  typeof AmbulanceDropOffLocationNm1EntityIdentifierCode01Schema
>;

// Code identifying an organizational entity, a physical location, property or an individual

export const AmbulancePickUpLocationNm1EntityIdentifierCode01Schema = z.enum(["PW"]);
export type AmbulancePickUpLocationNm1EntityIdentifierCode01 = z.infer<
  typeof AmbulancePickUpLocationNm1EntityIdentifierCode01Schema
>;

// Code indicating the reason for ambulance transport

export const AmbulanceTransportReasonCodeSchema = z.enum(["A", "B", "C", "D", "E"]);
export type AmbulanceTransportReasonCode = z.infer<typeof AmbulanceTransportReasonCodeSchema>;

// Code specifying the units in which a value is being expressed, or manner in which a
// measurement has been taken

export const UnitOrBasisForMeasurementCode01_EnumSchema = z.enum(["LB"]);
export type UnitOrBasisForMeasurementCode01_Enum = z.infer<
  typeof UnitOrBasisForMeasurementCode01_EnumSchema
>;

// Code specifying the units in which a value is being expressed, or manner in which a
// measurement has been taken

export const UnitOrBasisForMeasurementCode05_EnumSchema = z.enum(["DH"]);
export type UnitOrBasisForMeasurementCode05_Enum = z.infer<
  typeof UnitOrBasisForMeasurementCode05_EnumSchema
>;

// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const PurpleCodeListQualifierCodeSchema = z.enum(["BP"]);
export type PurpleCodeListQualifierCode = z.infer<typeof PurpleCodeListQualifierCodeSchema>;

// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const FluffyCodeListQualifierCodeSchema = z.enum(["BO"]);
export type FluffyCodeListQualifierCode = z.infer<typeof FluffyCodeListQualifierCodeSchema>;

// Code qualifying the Reference Identification

export const CarePlanOversightRefReferenceIdentificationQualifier01Schema = z.enum(["1J"]);
export type CarePlanOversightRefReferenceIdentificationQualifier01 = z.infer<
  typeof CarePlanOversightRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const ClaimIdentifierForTransmissionIntermediariesRefReferenceIdentificationQualifier01Schema =
  z.enum(["D9"]);
export type ClaimIdentifierForTransmissionIntermediariesRefReferenceIdentificationQualifier01 =
  z.infer<
    typeof ClaimIdentifierForTransmissionIntermediariesRefReferenceIdentificationQualifier01Schema
  >;

// Code indicating whether the provider accepts assignment

export const AssignmentOrPlanParticipationCodeSchema = z.enum(["A", "B", "C"]);
export type AssignmentOrPlanParticipationCode = z.infer<
  typeof AssignmentOrPlanParticipationCodeSchema
>;

// Code indicating a Yes or No condition or response
//
// - CLM08 is assignment of benefits indicator. A "Y" value indicates insured or authorized
// person authorizes benefits to be assigned to the provider; an "N" value indicates
// benefits have not been assigned to the provider.
//
// Code indicating a Yes or No condition or response
//
// - OI03 is the assignment of benefits indicator. A "Y" value indicates insured or
// authorized person authorizes benefits to be assigned to the provider; an "N" value
// indicates benefits have not been assigned to the provider.
//
// Code indicating a Yes or No condition or response
//
// - FRM02, FRM03, FRM04 and FRM05 are responses which only have meaning in reference to the
// question identified in FRM01.

export const BenefitsAssignmentCertificationIndicatorSchema = z.enum(["N", "W", "Y"]);
export type BenefitsAssignmentCertificationIndicator = z.infer<
  typeof BenefitsAssignmentCertificationIndicatorSchema
>;

// Code indicating the reason why a request was delayed

export const DelayReasonCodeSchema = z.enum([
  "1",
  "10",
  "11",
  "15",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
]);
export type DelayReasonCode = z.infer<typeof DelayReasonCodeSchema>;

// Code identifying the type of facility referenced
//
// - C023-02 qualifies C023-01 and C023-03.

export const FacilityCodeQualifierSchema = z.enum(["B"]);
export type FacilityCodeQualifier = z.infer<typeof FacilityCodeQualifierSchema>;

// Code indicating how the patient or subscriber authorization signatures were obtained and
// how they are being retained by the provider

export const PatientSignatureSourceCodeSchema = z.enum(["P"]);
export type PatientSignatureSourceCode = z.infer<typeof PatientSignatureSourceCodeSchema>;

// Code identifying an accompanying cause of an illness, injury or an accident

export const RelatedCausesCodeSchema = z.enum(["AA", "EM", "OA"]);
export type RelatedCausesCode = z.infer<typeof RelatedCausesCodeSchema>;

// Code indicating whether the provider has on file a signed statement by the patient
// authorizing the release of medical data to other organizations

export const ReleaseOfInformationCodeSchema = z.enum(["I", "Y"]);
export type ReleaseOfInformationCode = z.infer<typeof ReleaseOfInformationCodeSchema>;

// Code indicating the Special Program under which the services rendered to the patient were
// performed

export const SpecialProgramIndicatorSchema = z.enum(["02", "03", "05", "09"]);
export type SpecialProgramIndicator = z.infer<typeof SpecialProgramIndicatorSchema>;

// Code identifying the functional area or purpose for which the note applies

export const ClaimNoteNteNoteReferenceCode01Schema = z.enum(["ADD", "CER", "DCP", "DGN", "TPO"]);
export type ClaimNoteNteNoteReferenceCode01 = z.infer<typeof ClaimNoteNteNoteReferenceCode01Schema>;

// Code specifying the exception reason for consideration of out-of-network health care
// services
//
// - HCP15 is the exception reason generated by a third party organization.

export const ExceptionCodeSchema = z.enum(["1", "2", "3", "4", "5", "6"]);
export type ExceptionCode = z.infer<typeof ExceptionCodeSchema>;

// Code specifying policy compliance

export const PolicyComplianceCodeSchema = z.enum(["1", "2", "3", "4", "5"]);
export type PolicyComplianceCode = z.infer<typeof PolicyComplianceCodeSchema>;

// Code specifying pricing methodology at which the claim or line item has been priced or
// repriced

export const ClaimPricingRepricingInformationPricingMethodology01Schema = z.enum([
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
]);
export type ClaimPricingRepricingInformationPricingMethodology01 = z.infer<
  typeof ClaimPricingRepricingInformationPricingMethodology01Schema
>;

// Code assigned by issuer to identify reason for rejection
//
// - HCP13 is the rejection message returned from the third party organization.

export const RejectReasonCodeSchema = z.enum(["T1", "T2", "T3", "T4", "T5", "T6"]);
export type RejectReasonCode = z.infer<typeof RejectReasonCodeSchema>;

// Code indicating the title or contents of a document, report or supporting item

export const ClaimSupplementalInformationAttachmentReportTypeCode01Schema = z.enum([
  "A3",
  "A4",
  "AM",
  "AS",
  "B2",
  "B3",
  "B4",
  "BR",
  "BS",
  "BT",
  "CB",
  "CK",
  "CT",
  "D2",
  "DA",
  "DB",
  "DG",
  "DJ",
  "DS",
  "EB",
  "HC",
  "HR",
  "I5",
  "IR",
  "LA",
  "M1",
  "MT",
  "NN",
  "OB",
  "OC",
  "OD",
  "OE",
  "OX",
  "OZ",
  "P4",
  "P5",
  "PE",
  "PN",
  "PO",
  "PQ",
  "PY",
  "PZ",
  "RB",
  "RR",
  "RT",
  "RX",
  "SG",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "13",
  "15",
  "21",
  "V5",
  "XP",
]);
export type ClaimSupplementalInformationAttachmentReportTypeCode01 = z.infer<
  typeof ClaimSupplementalInformationAttachmentReportTypeCode01Schema
>;

// Code defining timing, transmission method or format by which reports are to be sent

export const ClaimSupplementalInformationAttachmentTransmissionCode02Schema = z.enum([
  "AA",
  "BM",
  "EL",
  "EM",
  "FT",
  "FX",
]);
export type ClaimSupplementalInformationAttachmentTransmissionCode02 = z.infer<
  typeof ClaimSupplementalInformationAttachmentTransmissionCode02Schema
>;

// Code designating the system/method of code structure used for Identification Code (67)
//
// - PWK05 and PWK06 may be used to identify the addressee by a code number.

export const IdentificationCodeQualifier05_EnumSchema = z.enum(["AC"]);
export type IdentificationCodeQualifier05_Enum = z.infer<
  typeof IdentificationCodeQualifier05_EnumSchema
>;

// Code qualifying the Reference Identification

export const ClinicalLaboratoryImprovementAmendmentCliaNumberRefReferenceIdentificationQualifier01Schema =
  z.enum(["X4"]);
export type ClinicalLaboratoryImprovementAmendmentCliaNumberRefReferenceIdentificationQualifier01 =
  z.infer<
    typeof ClinicalLaboratoryImprovementAmendmentCliaNumberRefReferenceIdentificationQualifier01Schema
  >;

// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const HealthCareCodeInformation03_CodeListQualifierCode01Schema = z.enum(["BG"]);
export type HealthCareCodeInformation03_CodeListQualifierCode01 = z.infer<
  typeof HealthCareCodeInformation03_CodeListQualifierCode01Schema
>;

// Code identifying a contract type

export const ContractTypeCodeSchema = z.enum(["01", "02", "03", "04", "05", "06", "09"]);
export type ContractTypeCode = z.infer<typeof ContractTypeCodeSchema>;

// Code indicating the date format, time format, or date and time format
//
// - DTP02 is the date or time or period format that will appear in DTP03.
//
// Code indicating the date format, time format, or date and time format

export const DateTimePeriodFormatQualifier05_EnumSchema = z.enum(["D8"]);
export type DateTimePeriodFormatQualifier05_Enum = z.infer<
  typeof DateTimePeriodFormatQualifier05_EnumSchema
>;

// Code specifying type of date or time, or both date and time

export const DateAccidentDtpDateTimeQualifier01Schema = z.enum(["439"]);
export type DateAccidentDtpDateTimeQualifier01 = z.infer<
  typeof DateAccidentDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateAcuteManifestationDtpDateTimeQualifier01Schema = z.enum(["453"]);
export type DateAcuteManifestationDtpDateTimeQualifier01 = z.infer<
  typeof DateAcuteManifestationDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateAdmissionDtpDateTimeQualifier01Schema = z.enum(["435"]);
export type DateAdmissionDtpDateTimeQualifier01 = z.infer<
  typeof DateAdmissionDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateAssumedAndRelinquishedCareDatesDtpDateTimeQualifier01Schema = z.enum([
  "090",
  "091",
]);
export type DateAssumedAndRelinquishedCareDatesDtpDateTimeQualifier01 = z.infer<
  typeof DateAssumedAndRelinquishedCareDatesDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateAuthorizedReturnToWorkDtpDateTimeQualifier01Schema = z.enum(["296"]);
export type DateAuthorizedReturnToWorkDtpDateTimeQualifier01 = z.infer<
  typeof DateAuthorizedReturnToWorkDtpDateTimeQualifier01Schema
>;

// Code indicating the date format, time format, or date and time format
//
// - DTP02 is the date or time or period format that will appear in DTP03.

export const DateDisabilityDatesDtpDateTimePeriodFormatQualifier02Schema = z.enum(["D8", "RD8"]);
export type DateDisabilityDatesDtpDateTimePeriodFormatQualifier02 = z.infer<
  typeof DateDisabilityDatesDtpDateTimePeriodFormatQualifier02Schema
>;

// Code specifying type of date or time, or both date and time

export const DateDisabilityDatesDtpDateTimeQualifier01Schema = z.enum(["314", "360", "361"]);
export type DateDisabilityDatesDtpDateTimeQualifier01 = z.infer<
  typeof DateDisabilityDatesDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateDischargeDtpDateTimeQualifier01Schema = z.enum(["096"]);
export type DateDischargeDtpDateTimeQualifier01 = z.infer<
  typeof DateDischargeDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateHearingAndVisionPrescriptionDateDtpDateTimeQualifier01Schema = z.enum(["471"]);
export type DateHearingAndVisionPrescriptionDateDtpDateTimeQualifier01 = z.infer<
  typeof DateHearingAndVisionPrescriptionDateDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateInitialTreatmentDateDtpDateTimeQualifier01Schema = z.enum(["454"]);
export type DateInitialTreatmentDateDtpDateTimeQualifier01 = z.infer<
  typeof DateInitialTreatmentDateDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateLastMenstrualPeriodDtpDateTimeQualifier01Schema = z.enum(["484"]);
export type DateLastMenstrualPeriodDtpDateTimeQualifier01 = z.infer<
  typeof DateLastMenstrualPeriodDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateLastSeenDateDtpDateTimeQualifier01Schema = z.enum(["304"]);
export type DateLastSeenDateDtpDateTimeQualifier01 = z.infer<
  typeof DateLastSeenDateDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateLastWorkedDtpDateTimeQualifier01Schema = z.enum(["297"]);
export type DateLastWorkedDtpDateTimeQualifier01 = z.infer<
  typeof DateLastWorkedDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateLastXRayDateDtpDateTimeQualifier01Schema = z.enum(["455"]);
export type DateLastXRayDateDtpDateTimeQualifier01 = z.infer<
  typeof DateLastXRayDateDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateOnsetOfCurrentIllnessOrSymptomDtpDateTimeQualifier01Schema = z.enum(["431"]);
export type DateOnsetOfCurrentIllnessOrSymptomDtpDateTimeQualifier01 = z.infer<
  typeof DateOnsetOfCurrentIllnessOrSymptomDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DatePropertyAndCasualtyDateOfFirstContactDtpDateTimeQualifier01Schema = z.enum([
  "444",
]);
export type DatePropertyAndCasualtyDateOfFirstContactDtpDateTimeQualifier01 = z.infer<
  typeof DatePropertyAndCasualtyDateOfFirstContactDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateRepricerReceivedDateDtpDateTimeQualifier01Schema = z.enum(["050"]);
export type DateRepricerReceivedDateDtpDateTimeQualifier01 = z.infer<
  typeof DateRepricerReceivedDateDtpDateTimeQualifier01Schema
>;

// Code qualifying the Reference Identification

export const DemonstrationProjectIdentifierRefReferenceIdentificationQualifier01Schema = z.enum([
  "P4",
]);
export type DemonstrationProjectIdentifierRefReferenceIdentificationQualifier01 = z.infer<
  typeof DemonstrationProjectIdentifierRefReferenceIdentificationQualifier01Schema
>;

// Specifies the situation or category to which the code applies
//
// - CRC01 qualifies CRC03 through CRC07.

export const EpsdtReferralCrcCodeQualifier01Schema = z.enum(["ZZ"]);
export type EpsdtReferralCrcCodeQualifier01 = z.infer<typeof EpsdtReferralCrcCodeQualifier01Schema>;

// Code indicating a condition

export const EpsdtReferralCrcConditionIndicator03Schema = z.enum(["AV", "NU", "S2", "ST"]);
export type EpsdtReferralCrcConditionIndicator03 = z.infer<
  typeof EpsdtReferralCrcConditionIndicator03Schema
>;

// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const HealthCareCodeInformation01_DiagnosisTypeCode01Schema = z.enum(["ABK", "BK"]);
export type HealthCareCodeInformation01_DiagnosisTypeCode01 = z.infer<
  typeof HealthCareCodeInformation01_DiagnosisTypeCode01Schema
>;

// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const HealthCareCodeInformation02_DiagnosisTypeCode01Schema = z.enum(["ABF", "BF"]);
export type HealthCareCodeInformation02_DiagnosisTypeCode01 = z.infer<
  typeof HealthCareCodeInformation02_DiagnosisTypeCode01Schema
>;

// Code indicating a Yes or No condition or response
//
// - CRC02 is a Certification Condition Code applies indicator. A "Y" value indicates the
// condition codes in CRC03 through CRC07 apply; an "N" value indicates the condition codes
// in CRC03 through CRC07 do not apply.
//
// Code indicating a Yes or No condition or response
//
// - SV109 is the emergency-related indicator; a "Y" value indicates service provided was
// emergency related; an "N" value indicates service provided was not emergency related.
//
// Code indicating a Yes or No condition or response
//
// - SV111 is early and periodic screen for diagnosis and treatment of children (EPSDT)
// involvement; a "Y" value indicates EPSDT involvement; an "N" value indicates no EPSDT
// involvement.
//
// Code indicating a Yes or No condition or response
//
// - SV112 is the family planning involvement indicator. A "Y" value indicates family
// planning services involvement; an "N" value indicates no family planning services
// involvement.
//
// Code indicating a Yes or No condition or response
//
// - PAT09 indicates whether the patient is pregnant or not pregnant. Code "Y" indicates the
// patient is pregnant; code "N" indicates the patient is not pregnant.

export const PregnancyIndicator09_EnumSchema = z.enum(["Y"]);
export type PregnancyIndicator09_Enum = z.infer<typeof PregnancyIndicator09_EnumSchema>;

// Specifies the situation or category to which the code applies
//
// - CRC01 qualifies CRC03 through CRC07.

export const HomeboundIndicatorCrcCodeCategory01Schema = z.enum(["75"]);
export type HomeboundIndicatorCrcCodeCategory01 = z.infer<
  typeof HomeboundIndicatorCrcCodeCategory01Schema
>;

// Code indicating a condition

export const HomeboundIndicator03_EnumSchema = z.enum(["IH"]);
export type HomeboundIndicator03_Enum = z.infer<typeof HomeboundIndicator03_EnumSchema>;

// Code qualifying the Reference Identification

export const InvestigationalDeviceExemptionNumberRefReferenceIdentificationQualifier01Schema =
  z.enum(["LX"]);
export type InvestigationalDeviceExemptionNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof InvestigationalDeviceExemptionNumberRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const MammographyCertificationNumberRefReferenceIdentificationQualifier01Schema = z.enum([
  "EW",
]);
export type MammographyCertificationNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof MammographyCertificationNumberRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification
//
// Code to qualify amount

export const ReferenceIdentificationQualifierSchema = z.enum(["F5"]);
export type ReferenceIdentificationQualifier = z.infer<
  typeof ReferenceIdentificationQualifierSchema
>;

// Code qualifying the Reference Identification

export const MedicalRecordNumberRefReferenceIdentificationQualifier01Schema = z.enum(["EA"]);
export type MedicalRecordNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof MedicalRecordNumberRefReferenceIdentificationQualifier01Schema
>;

// Code identifying the general category of payment adjustment

export const ClaimAdjustmentGroupCodeSchema = z.enum(["CO", "CR", "OA", "PI", "PR"]);
export type ClaimAdjustmentGroupCode = z.infer<typeof ClaimAdjustmentGroupCodeSchema>;

// Code to qualify amount

export const CoordinationOfBenefitsCobPayerPaidAmountAmtAmountQualifierCode01Schema = z.enum(["D"]);
export type CoordinationOfBenefitsCobPayerPaidAmountAmtAmountQualifierCode01 = z.infer<
  typeof CoordinationOfBenefitsCobPayerPaidAmountAmtAmountQualifierCode01Schema
>;

// Code to qualify amount

export const CoordinationOfBenefitsCobTotalNonCoveredAmountAmtAmountQualifierCode01Schema = z.enum([
  "A8",
]);
export type CoordinationOfBenefitsCobTotalNonCoveredAmountAmtAmountQualifierCode01 = z.infer<
  typeof CoordinationOfBenefitsCobTotalNonCoveredAmountAmtAmountQualifierCode01Schema
>;

// Code qualifying the Reference Identification

export const OtherPayerBillingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema =
  z.enum(["G2", "LU"]);
export type OtherPayerBillingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01 =
  z.infer<
    typeof OtherPayerBillingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema
  >;

// Code specifying type of date or time, or both date and time

export const ClaimCheckOrRemittanceDateDtpDateTimeQualifier01Schema = z.enum(["573"]);
export type ClaimCheckOrRemittanceDateDtpDateTimeQualifier01 = z.infer<
  typeof ClaimCheckOrRemittanceDateDtpDateTimeQualifier01Schema
>;

// Code qualifying the Reference Identification

export const OtherPayerClaimAdjustmentIndicatorRefReferenceIdentificationQualifier01Schema = z.enum(
  ["T4"]
);
export type OtherPayerClaimAdjustmentIndicatorRefReferenceIdentificationQualifier01 = z.infer<
  typeof OtherPayerClaimAdjustmentIndicatorRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const OtherPayerClaimControlNumberRefReferenceIdentificationQualifier01Schema = z.enum([
  "F8",
]);
export type OtherPayerClaimControlNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof OtherPayerClaimControlNumberRefReferenceIdentificationQualifier01Schema
>;

// Code identifying an organizational entity, a physical location, property or an individual

export const OtherPayerNameNm1EntityIdentifierCode01Schema = z.enum(["PR"]);
export type OtherPayerNameNm1EntityIdentifierCode01 = z.infer<
  typeof OtherPayerNameNm1EntityIdentifierCode01Schema
>;

// Code qualifying the Reference Identification

export const OtherPayerPriorAuthorizationNumberRefReferenceIdentificationQualifier01Schema = z.enum(
  ["G1"]
);
export type OtherPayerPriorAuthorizationNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof OtherPayerPriorAuthorizationNumberRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const OtherPayerReferralNumberRefReferenceIdentificationQualifier01Schema = z.enum(["9F"]);
export type OtherPayerReferralNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof OtherPayerReferralNumberRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const OtherPayerSecondaryIdentifierRefReferenceIdentificationQualifier01Schema = z.enum([
  "EI",
  "FY",
  "NF",
  "2U",
]);
export type OtherPayerSecondaryIdentifierRefReferenceIdentificationQualifier01 = z.infer<
  typeof OtherPayerSecondaryIdentifierRefReferenceIdentificationQualifier01Schema
>;

// Code identifying an organizational entity, a physical location, property or an individual

export const OtherPayerReferringProviderNm1EntityIdentifierCode01Schema = z.enum(["DN", "P3"]);
export type OtherPayerReferringProviderNm1EntityIdentifierCode01 = z.infer<
  typeof OtherPayerReferringProviderNm1EntityIdentifierCode01Schema
>;

// Code qualifying the type of entity
//
// - NM102 qualifies NM103.

export const OtherPayerReferringProviderNm1EntityTypeQualifier02Schema = z.enum(["1"]);
export type OtherPayerReferringProviderNm1EntityTypeQualifier02 = z.infer<
  typeof OtherPayerReferringProviderNm1EntityTypeQualifier02Schema
>;

// Code qualifying the Reference Identification

export const OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema =
  z.enum(["G2", "0B", "1G"]);
export type OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01 =
  z.infer<
    typeof OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema
  >;

// Code identifying an organizational entity, a physical location, property or an individual

export const OtherPayerRenderingProviderNm1EntityIdentifierCode01Schema = z.enum(["82"]);
export type OtherPayerRenderingProviderNm1EntityIdentifierCode01 = z.infer<
  typeof OtherPayerRenderingProviderNm1EntityIdentifierCode01Schema
>;

// Code qualifying the Reference Identification

export const OtherPayerRenderingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema =
  z.enum(["G2", "LU", "0B", "1G"]);
export type OtherPayerRenderingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01 =
  z.infer<
    typeof OtherPayerRenderingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema
  >;

// Code identifying an organizational entity, a physical location, property or an individual

export const OtherPayerServiceFacilityLocationNm1EntityIdentifierCode01Schema = z.enum(["77"]);
export type OtherPayerServiceFacilityLocationNm1EntityIdentifierCode01 = z.infer<
  typeof OtherPayerServiceFacilityLocationNm1EntityIdentifierCode01Schema
>;

// Code qualifying the Reference Identification

export const OtherPayerServiceFacilityLocationSecondaryIdentificationRefReferenceIdentificationQualifier01Schema =
  z.enum(["G2", "LU", "0B"]);
export type OtherPayerServiceFacilityLocationSecondaryIdentificationRefReferenceIdentificationQualifier01 =
  z.infer<
    typeof OtherPayerServiceFacilityLocationSecondaryIdentificationRefReferenceIdentificationQualifier01Schema
  >;

// Code identifying an organizational entity, a physical location, property or an individual

export const OtherPayerSupervisingProviderNm1EntityIdentifierCode01Schema = z.enum(["DQ"]);
export type OtherPayerSupervisingProviderNm1EntityIdentifierCode01 = z.infer<
  typeof OtherPayerSupervisingProviderNm1EntityIdentifierCode01Schema
>;

// Code identifying type of claim

export const ClaimFilingIndicatorCodeSchema = z.enum([
  "AM",
  "BL",
  "CH",
  "CI",
  "DS",
  "FI",
  "HM",
  "LM",
  "MA",
  "MB",
  "MC",
  "OF",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "TV",
  "VA",
  "WC",
  "ZZ",
]);
export type ClaimFilingIndicatorCode = z.infer<typeof ClaimFilingIndicatorCodeSchema>;

// Code indicating the relationship between two individuals or entities
//
// - SBR02 specifies the relationship to the person insured.

export const OtherSubscriberInformationSbrIndividualRelationshipCode02Schema = z.enum([
  "G8",
  "01",
  "18",
  "19",
  "20",
  "21",
  "39",
  "40",
  "53",
]);
export type OtherSubscriberInformationSbrIndividualRelationshipCode02 = z.infer<
  typeof OtherSubscriberInformationSbrIndividualRelationshipCode02Schema
>;

// Code identifying the type of insurance policy within a specific insurance program

export const InsuranceTypeCodeSchema = z.enum([
  "12",
  "13",
  "14",
  "15",
  "16",
  "41",
  "42",
  "43",
  "47",
]);
export type InsuranceTypeCode = z.infer<typeof InsuranceTypeCodeSchema>;

// Code identifying the insurance carrier's level of responsibility for a payment of a claim

export const PayerResponsibilitySequenceNumberCodeSchema = z.enum([
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "P",
  "S",
  "T",
  "U",
]);
export type PayerResponsibilitySequenceNumberCode = z.infer<
  typeof PayerResponsibilitySequenceNumberCodeSchema
>;

// Code identifying an organizational entity, a physical location, property or an individual

export const OtherSubscriberNameNm1EntityIdentifierCode01Schema = z.enum(["IL"]);
export type OtherSubscriberNameNm1EntityIdentifierCode01 = z.infer<
  typeof OtherSubscriberNameNm1EntityIdentifierCode01Schema
>;

// Code designating the system/method of code structure used for Identification Code (67)

export const OtherSubscriberNameNm1IdentificationCodeQualifier08Schema = z.enum(["II", "MI"]);
export type OtherSubscriberNameNm1IdentificationCodeQualifier08 = z.infer<
  typeof OtherSubscriberNameNm1IdentificationCodeQualifier08Schema
>;

// Code qualifying the Reference Identification

export const OtherSubscriberSecondaryIdentificationRefReferenceIdentificationQualifier01Schema =
  z.enum(["SY"]);
export type OtherSubscriberSecondaryIdentificationRefReferenceIdentificationQualifier01 = z.infer<
  typeof OtherSubscriberSecondaryIdentificationRefReferenceIdentificationQualifier01Schema
>;

// Code to qualify amount

export const RemainingPatientLiabilityAmtAmountQualifierCode01Schema = z.enum(["EAF"]);
export type RemainingPatientLiabilityAmtAmountQualifierCode01 = z.infer<
  typeof RemainingPatientLiabilityAmtAmountQualifierCode01Schema
>;

// Specifies the situation or category to which the code applies
//
// - CRC01 qualifies CRC03 through CRC07.

export const PatientConditionInformationVisionCrcCodeCategory01Schema = z.enum(["E1", "E2", "E3"]);
export type PatientConditionInformationVisionCrcCodeCategory01 = z.infer<
  typeof PatientConditionInformationVisionCrcCodeCategory01Schema
>;

// Code indicating a condition

export const PatientConditionInformationVisionCrcConditionCode03Schema = z.enum([
  "L1",
  "L2",
  "L3",
  "L4",
  "L5",
]);
export type PatientConditionInformationVisionCrcConditionCode03 = z.infer<
  typeof PatientConditionInformationVisionCrcConditionCode03Schema
>;

// Code qualifying the Reference Identification

export const RepricedClaimNumberRefReferenceIdentificationQualifier01Schema = z.enum(["9A"]);
export type RepricedClaimNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof RepricedClaimNumberRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const ServiceAuthorizationExceptionCodeRefReferenceIdentificationQualifier01Schema = z.enum([
  "4N",
]);
export type ServiceAuthorizationExceptionCodeRefReferenceIdentificationQualifier01 = z.infer<
  typeof ServiceAuthorizationExceptionCodeRefReferenceIdentificationQualifier01Schema
>;

// Code identifying the type of communication number

export const ServiceFacilityContactInformationCommunicationNumberQualifier03Schema = z.enum(["TE"]);
export type ServiceFacilityContactInformationCommunicationNumberQualifier03 = z.infer<
  typeof ServiceFacilityContactInformationCommunicationNumberQualifier03Schema
>;

// Code identifying the type of communication number

export const ServiceFacilityContactInformationCommunicationNumberQualifier05Schema = z.enum(["EX"]);
export type ServiceFacilityContactInformationCommunicationNumberQualifier05 = z.infer<
  typeof ServiceFacilityContactInformationCommunicationNumberQualifier05Schema
>;

// Code qualifying the Reference Identification

export const AdjustedRepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01Schema =
  z.enum(["9D"]);
export type AdjustedRepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof AdjustedRepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01Schema
>;

// Code specifying the type of quantity

export const AmbulancePatientCountQtyQuantityQualifier01Schema = z.enum(["PT"]);
export type AmbulancePatientCountQtyQuantityQualifier01 = z.infer<
  typeof AmbulancePatientCountQtyQuantityQualifier01Schema
>;

// Specifies the situation or category to which the code applies
//
// - CRC01 qualifies CRC03 through CRC07.

export const ConditionIndicatorDurableMedicalEquipmentCrcCodeCategory01Schema = z.enum(["09"]);
export type ConditionIndicatorDurableMedicalEquipmentCrcCodeCategory01 = z.infer<
  typeof ConditionIndicatorDurableMedicalEquipmentCrcCodeCategory01Schema
>;

// Code indicating a condition

export const ConditionIndicatorDurableMedicalEquipmentCrcConditionIndicator03Schema = z.enum([
  "38",
  "ZV",
]);
export type ConditionIndicatorDurableMedicalEquipmentCrcConditionIndicator03 = z.infer<
  typeof ConditionIndicatorDurableMedicalEquipmentCrcConditionIndicator03Schema
>;

// Code specifying type of date or time, or both date and time

export const DateBeginTherapyDateDtpDateTimeQualifier01Schema = z.enum(["463"]);
export type DateBeginTherapyDateDtpDateTimeQualifier01 = z.infer<
  typeof DateBeginTherapyDateDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateCertificationRevisionRecertificationDateDtpDateTimeQualifier01Schema = z.enum([
  "607",
]);
export type DateCertificationRevisionRecertificationDateDtpDateTimeQualifier01 = z.infer<
  typeof DateCertificationRevisionRecertificationDateDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateLastCertificationDateDtpDateTimeQualifier01Schema = z.enum(["461"]);
export type DateLastCertificationDateDtpDateTimeQualifier01 = z.infer<
  typeof DateLastCertificationDateDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateServiceDateDtpDateTimeQualifier01Schema = z.enum(["472"]);
export type DateServiceDateDtpDateTimeQualifier01 = z.infer<
  typeof DateServiceDateDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateShippedDateDtpDateTimeQualifier01Schema = z.enum(["011"]);
export type DateShippedDateDtpDateTimeQualifier01 = z.infer<
  typeof DateShippedDateDtpDateTimeQualifier01Schema
>;

// Code specifying type of date or time, or both date and time

export const DateTestDateDtpDateTimeQualifier01Schema = z.enum(["738", "739"]);
export type DateTestDateDtpDateTimeQualifier01 = z.infer<
  typeof DateTestDateDtpDateTimeQualifier01Schema
>;

// Code identifying the type/source of the descriptive number used in Product/Service ID
// (234)
//
// - LIN02 through LIN31 provide for fifteen different product/service IDs for each item.
// For example: Case, Color, Drawing No., U.P.C. No., ISBN No., Model No., or SKU.

export const ProductOrServiceIdQualifier02_EnumSchema = z.enum([
  "EN",
  "EO",
  "HI",
  "N4",
  "ON",
  "UK",
  "UP",
]);
export type ProductOrServiceIdQualifier02_Enum = z.infer<
  typeof ProductOrServiceIdQualifier02_EnumSchema
>;

// Code specifying the units in which a value is being expressed, or manner in which a
// measurement has been taken

export const CompositeUnitOfMeasure05_CodeQualifier01Schema = z.enum([
  "F2",
  "GR",
  "ME",
  "ML",
  "UN",
]);
export type CompositeUnitOfMeasure05_CodeQualifier01 = z.infer<
  typeof CompositeUnitOfMeasure05_CodeQualifier01Schema
>;

// Code qualifying the Reference Identification

export const PrescriptionOrCompoundDrugAssociationNumberRefReferenceIdentificationQualifier01Schema =
  z.enum(["VY", "XZ"]);
export type PrescriptionOrCompoundDrugAssociationNumberRefReferenceIdentificationQualifier01 =
  z.infer<
    typeof PrescriptionOrCompoundDrugAssociationNumberRefReferenceIdentificationQualifier01Schema
  >;

// Code indicating the title or contents of a document, report or supporting item

export const DurableMedicalEquipmentCertificateOfMedicalNecessityIndicatorPwkAttachmentReportTypeCode01Schema =
  z.enum(["CT"]);
export type DurableMedicalEquipmentCertificateOfMedicalNecessityIndicatorPwkAttachmentReportTypeCode01 =
  z.infer<
    typeof DurableMedicalEquipmentCertificateOfMedicalNecessityIndicatorPwkAttachmentReportTypeCode01Schema
  >;

// Code defining timing, transmission method or format by which reports are to be sent

export const DurableMedicalEquipmentCertificateOfMedicalNecessityIndicatorPwkAttachmentTransmissionCode02Schema =
  z.enum(["AB", "AD", "AF", "AG", "NS"]);
export type DurableMedicalEquipmentCertificateOfMedicalNecessityIndicatorPwkAttachmentTransmissionCode02 =
  z.infer<
    typeof DurableMedicalEquipmentCertificateOfMedicalNecessityIndicatorPwkAttachmentTransmissionCode02Schema
  >;

// Code indicating the type of certification

export const CertificationTypeCodeSchema = z.enum(["I", "R", "S"]);
export type CertificationTypeCode = z.infer<typeof CertificationTypeCodeSchema>;

// Code specifying the units in which a value is being expressed, or manner in which a
// measurement has been taken
//
// - CR302 and CR303 specify the time period covered by this certification.

export const DurableMedicalEquipmentCertificationCr3UnitOrBasisForMeasurementCode02Schema = z.enum([
  "MO",
]);
export type DurableMedicalEquipmentCertificationCr3UnitOrBasisForMeasurementCode02 = z.infer<
  typeof DurableMedicalEquipmentCertificationCr3UnitOrBasisForMeasurementCode02Schema
>;

// Code identifying the type/source of the descriptive number used in Product/Service ID
// (234)
//
// - C003-01 qualifies C003-02 and C003-08.

export const ProcedureIdentifierSchema = z.enum(["HC"]);
export type ProcedureIdentifier = z.infer<typeof ProcedureIdentifierSchema>;

// Code indicating frequency or type of activities or actions being reported
//
// - SV506 is the frequency at which the rental equipment is billed.

export const RentalUnitPriceIndicatorSchema = z.enum(["1", "4", "6"]);
export type RentalUnitPriceIndicator = z.infer<typeof RentalUnitPriceIndicatorSchema>;

// Code specifying the units in which a value is being expressed, or manner in which a
// measurement has been taken

export const DurableMedicalEquipmentServiceSv5UnitOrBasisForMeasurementCode02Schema = z.enum([
  "DA",
]);
export type DurableMedicalEquipmentServiceSv5UnitOrBasisForMeasurementCode02 = z.infer<
  typeof DurableMedicalEquipmentServiceSv5UnitOrBasisForMeasurementCode02Schema
>;

// Code identifying a specific industry code list

export const FormIdentificationCodeLqCodeListQualifierCode01Schema = z.enum(["AS", "UT"]);
export type FormIdentificationCodeLqCodeListQualifierCode01 = z.infer<
  typeof FormIdentificationCodeLqCodeListQualifierCode01Schema
>;

// Specifies the situation or category to which the code applies
//
// - CRC01 qualifies CRC03 through CRC07.

export const HospiceEmployeeIndicatorCrcCodeCategory01Schema = z.enum(["70"]);
export type HospiceEmployeeIndicatorCrcCodeCategory01 = z.infer<
  typeof HospiceEmployeeIndicatorCrcCodeCategory01Schema
>;

// Code indicating a condition

export const HospiceEmployeeIndicatorCrcConditionIndicator03Schema = z.enum(["65"]);
export type HospiceEmployeeIndicatorCrcConditionIndicator03 = z.infer<
  typeof HospiceEmployeeIndicatorCrcConditionIndicator03Schema
>;

// Code qualifying the Reference Identification

export const ImmunizationBatchNumberRefReferenceIdentificationQualifier01Schema = z.enum(["BT"]);
export type ImmunizationBatchNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof ImmunizationBatchNumberRefReferenceIdentificationQualifier01Schema
>;

// Code identifying the type/source of the descriptive number used in Product/Service ID
// (234)
//
// - C003-01 qualifies C003-02 and C003-08.
//
// Code identifying the type/source of the descriptive number used in Product/Service ID
// (234)

export const ProductOrServiceIdQualifier01_EnumSchema = z.enum(["ER", "HC", "IV", "WK"]);
export type ProductOrServiceIdQualifier01_Enum = z.infer<
  typeof ProductOrServiceIdQualifier01_EnumSchema
>;

// Code qualifying the Reference Identification

export const LineItemControlNumberRefReferenceIdentificationQualifier01Schema = z.enum(["6R"]);
export type LineItemControlNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof LineItemControlNumberRefReferenceIdentificationQualifier01Schema
>;

// Code identifying the functional area or purpose for which the note applies

export const LineNoteNteNoteReferenceCode01Schema = z.enum(["ADD", "DCP"]);
export type LineNoteNteNoteReferenceCode01 = z.infer<typeof LineNoteNteNoteReferenceCode01Schema>;

// Code specifying pricing methodology at which the claim or line item has been priced or
// repriced

export const LinePricingRepricingInformationPricingMethodology01Schema = z.enum([
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
]);
export type LinePricingRepricingInformationPricingMethodology01 = z.infer<
  typeof LinePricingRepricingInformationPricingMethodology01Schema
>;

// Code specifying the units in which a value is being expressed, or manner in which a
// measurement has been taken

export const UnitOrBasisForMeasurementCode03_EnumSchema = z.enum(["MJ", "UN"]);
export type UnitOrBasisForMeasurementCode03_Enum = z.infer<
  typeof UnitOrBasisForMeasurementCode03_EnumSchema
>;

// Code specifying the type of quantity

export const ObstetricAnesthesiaAdditionalUnitsQtyQuantityQualifier01Schema = z.enum(["FL"]);
export type ObstetricAnesthesiaAdditionalUnitsQtyQuantityQualifier01 = z.infer<
  typeof ObstetricAnesthesiaAdditionalUnitsQtyQuantityQualifier01Schema
>;

// Code identifying an organizational entity, a physical location, property or an individual

export const OrderingProviderNameEntityIdentifierCode01Schema = z.enum(["DK"]);
export type OrderingProviderNameEntityIdentifierCode01 = z.infer<
  typeof OrderingProviderNameEntityIdentifierCode01Schema
>;

// Code qualifying the Reference Identification

export const ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema = z.enum(["2U"]);
export type ReferenceIdentifier04_ReferenceIdentificationQualifier01 = z.infer<
  typeof ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema
>;

// Code to qualify amount
//
// Code qualifying the Reference Identification

export const AmountQualifierCodeSchema = z.enum(["F4"]);
export type AmountQualifierCode = z.infer<typeof AmountQualifierCodeSchema>;

// Code indicating whether or not co-payment requirements were met on a line by line basis

export const CoPayStatusCodeSchema = z.enum(["0"]);
export type CoPayStatusCode = z.infer<typeof CoPayStatusCodeSchema>;

// Code identifying an organizational entity, a physical location, property or an individual

export const PurchasedServiceProviderNameEntityIdentifierCode01Schema = z.enum(["QB"]);
export type PurchasedServiceProviderNameEntityIdentifierCode01 = z.infer<
  typeof PurchasedServiceProviderNameEntityIdentifierCode01Schema
>;

// Code qualifying the Reference Identification

export const RepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01Schema = z.enum([
  "9B",
]);
export type RepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof RepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01Schema
>;

// Code to qualify amount

export const SalesTaxAmountAmtAmountQualifierCode01Schema = z.enum(["T"]);
export type SalesTaxAmountAmtAmountQualifierCode01 = z.infer<
  typeof SalesTaxAmountAmtAmountQualifierCode01Schema
>;

// Code identifying a specific product or process characteristic to which a measurement
// applies

export const MeasurementQualifierSchema = z.enum(["HT", "R1", "R2", "R3", "R4"]);
export type MeasurementQualifier = z.infer<typeof MeasurementQualifierSchema>;

// Code identifying the broad category to which a measurement applies

export const MeasurementReferenceIdentificationCodeSchema = z.enum(["OG", "TR"]);
export type MeasurementReferenceIdentificationCode = z.infer<
  typeof MeasurementReferenceIdentificationCodeSchema
>;

// Code identifying the functional area or purpose for which the note applies

export const ThirdPartyOrganizationNotesNteNoteReferenceCode01Schema = z.enum(["TPO"]);
export type ThirdPartyOrganizationNotesNteNoteReferenceCode01 = z.infer<
  typeof ThirdPartyOrganizationNotesNteNoteReferenceCode01Schema
>;

// Code indicating the nature of a patient's condition

export const PatientConditionCodeSchema = z.enum(["A", "C", "D", "E", "F", "G", "M"]);
export type PatientConditionCode = z.infer<typeof PatientConditionCodeSchema>;

// Code indicating the relationship between two individuals or entities

export const IndividualRelationshipCode01_EnumSchema = z.enum([
  "G8",
  "01",
  "19",
  "20",
  "21",
  "39",
  "40",
  "53",
]);
export type IndividualRelationshipCode01_Enum = z.infer<
  typeof IndividualRelationshipCode01_EnumSchema
>;

// Code specifying the units in which a value is being expressed, or manner in which a
// measurement has been taken

export const UnitOrBasisForMeasurementCode07_EnumSchema = z.enum(["01"]);
export type UnitOrBasisForMeasurementCode07_Enum = z.infer<
  typeof UnitOrBasisForMeasurementCode07_EnumSchema
>;

// Code indicating the sex of the individual

export const GenderCodeSchema = z.enum(["F", "M", "U"]);
export type GenderCode = z.infer<typeof GenderCodeSchema>;

// Code identifying an organizational entity, a physical location, property or an individual

export const PatientNameNm1EntityIdentifierCode01Schema = z.enum(["QC"]);
export type PatientNameNm1EntityIdentifierCode01 = z.infer<
  typeof PatientNameNm1EntityIdentifierCode01Schema
>;

// Code qualifying the Reference Identification

export const PropertyAndCasualtyClaimNumberRefReferenceIdentificationQualifier01Schema = z.enum([
  "Y4",
]);
export type PropertyAndCasualtyClaimNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof PropertyAndCasualtyClaimNumberRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const PropertyAndCasualtyPatientIdentifierRefReferenceIdentificationQualifier01Schema =
  z.enum(["SY", "1W"]);
export type PropertyAndCasualtyPatientIdentifierRefReferenceIdentificationQualifier01 = z.infer<
  typeof PropertyAndCasualtyPatientIdentifierRefReferenceIdentificationQualifier01Schema
>;

// Code indicating the relationship between two individuals or entities
//
// - SBR02 specifies the relationship to the person insured.

export const SubscriberInformationSbrIndividualRelationshipCode02Schema = z.enum(["18"]);
export type SubscriberInformationSbrIndividualRelationshipCode02 = z.infer<
  typeof SubscriberInformationSbrIndividualRelationshipCode02Schema
>;

// Code specifying the type of transaction

export const ClaimOrEncounterIdentifierSchema = z.enum(["CH", "RP", "31"]);
export type ClaimOrEncounterIdentifier = z.infer<typeof ClaimOrEncounterIdentifierSchema>;

// Code indicating the hierarchical application structure of a transaction set that utilizes
// the HL segment to define the structure of the transaction set

export const HierarchicalStructureCodeSchema = z.enum(["0019"]);
export type HierarchicalStructureCode = z.infer<typeof HierarchicalStructureCodeSchema>;

// Code identifying purpose of transaction set

export const TransactionSetPurposeCodeSchema = z.enum(["00", "18"]);
export type TransactionSetPurposeCode = z.infer<typeof TransactionSetPurposeCodeSchema>;

// Code identifying an organizational entity, a physical location, property or an individual

export const ReceiverNameNm1EntityIdentifierCode01Schema = z.enum(["40"]);
export type ReceiverNameNm1EntityIdentifierCode01 = z.infer<
  typeof ReceiverNameNm1EntityIdentifierCode01Schema
>;

// Code designating the system/method of code structure used for Identification Code (67)

export const ReceiverNameNm1IdentificationCodeQualifier08Schema = z.enum(["46"]);
export type ReceiverNameNm1IdentificationCodeQualifier08 = z.infer<
  typeof ReceiverNameNm1IdentificationCodeQualifier08Schema
>;

// Code identifying an organizational entity, a physical location, property or an individual

export const SubmitterNameNm1EntityIdentifierCode01Schema = z.enum(["41"]);
export type SubmitterNameNm1EntityIdentifierCode01 = z.infer<
  typeof SubmitterNameNm1EntityIdentifierCode01Schema
>;

// Reference assigned to identify Implementation Convention
//
// - The implementation convention reference (ST03) is used by the translation routines of
// the interchange partners to select the appropriate implementation convention to match the
// transaction set definition. When used, this implementation convention reference takes
// precedence over the implementation reference specified in the GS08.

export const ImplementationGuideVersionNameSchema = z.enum(["005010X222A1"]);
export type ImplementationGuideVersionName = z.infer<typeof ImplementationGuideVersionNameSchema>;

// Code uniquely identifying a Transaction Set
//
// - The transaction set identifier (ST01) is used by the translation routines of the
// interchange partners to select the appropriate transaction set definition (e.g., 810
// selects the Invoice Transaction Set).

export const TransactionSetIdentifierCodeSchema = z.enum(["837"]);
export type TransactionSetIdentifierCode = z.infer<typeof TransactionSetIdentifierCodeSchema>;

export const TransactionSetHeaderSchema = z.object({
  implementation_guide_version_name_03: ImplementationGuideVersionNameSchema,
  transaction_set_control_number_02: z.number(),
  transaction_set_identifier_code_01: TransactionSetIdentifierCodeSchema,
});
export type TransactionSetHeader = z.infer<typeof TransactionSetHeaderSchema>;

export const SubmitterNameSchema = z.object({
  entity_identifier_code_01: SubmitterNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: ReceiverNameNm1IdentificationCodeQualifier08Schema,
  submitter_first_name_04: z.union([z.null(), z.string()]).optional(),
  submitter_identifier_09: z.string(),
  submitter_last_or_organization_name_03: z.string(),
  submitter_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
});
export type SubmitterName = z.infer<typeof SubmitterNameSchema>;

export const SubmitterEdiContactInformationSchema = z.object({
  communication_number_04: z.string(),
  communication_number_06: z.union([z.null(), z.string()]).optional(),
  communication_number_08: z.union([z.null(), z.string()]).optional(),
  communication_number_qualifier_03:
    BillingProviderContactInformationCommunicationNumberQualifier03Schema,
  communication_number_qualifier_05: z
    .union([CommunicationNumberQualifier07_EnumSchema, z.null()])
    .optional(),
  communication_number_qualifier_07: z
    .union([CommunicationNumberQualifier07_EnumSchema, z.null()])
    .optional(),
  contact_function_code_01: ContactFunctionCodeSchema,
  submitter_contact_name_02: z.union([z.null(), z.string()]).optional(),
});
export type SubmitterEdiContactInformation = z.infer<typeof SubmitterEdiContactInformationSchema>;

export const SubmitterNameLoopSchema = z.object({
  submitter_edi_contact_information_PER: z.array(SubmitterEdiContactInformationSchema),
  submitter_name_NM1: SubmitterNameSchema,
});
export type SubmitterNameLoop = z.infer<typeof SubmitterNameLoopSchema>;

export const ReceiverNameSchema = z.object({
  entity_identifier_code_01: ReceiverNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: ReceiverNameNm1IdentificationCodeQualifier08Schema,
  receiver_name_03: z.string(),
  receiver_primary_identifier_09: z.string(),
});
export type ReceiverName = z.infer<typeof ReceiverNameSchema>;

export const ReceiverNameLoopSchema = z.object({
  receiver_name_NM1: ReceiverNameSchema,
});
export type ReceiverNameLoop = z.infer<typeof ReceiverNameLoopSchema>;

export const BeginningOfHierarchicalTransactionSchema = z.object({
  claim_or_encounter_identifier_06: ClaimOrEncounterIdentifierSchema,
  hierarchical_structure_code_01: HierarchicalStructureCodeSchema,
  originator_application_transaction_identifier_03: z.string(),
  transaction_set_creation_date_04: z.string(),
  transaction_set_creation_time_05: z.string(),
  transaction_set_purpose_code_02: TransactionSetPurposeCodeSchema,
});
export type BeginningOfHierarchicalTransaction = z.infer<
  typeof BeginningOfHierarchicalTransactionSchema
>;

export const HeadingSchema = z.object({
  beginning_of_hierarchical_transaction_BHT: BeginningOfHierarchicalTransactionSchema,
  receiver_name_NM1_loop: ReceiverNameLoopSchema,
  submitter_name_NM1_loop: SubmitterNameLoopSchema,
  transaction_set_header_ST: z.union([TransactionSetHeaderSchema, z.null()]).optional(),
});
export type Heading = z.infer<typeof HeadingSchema>;

export const TransactionSetTrailerSchema = z.object({
  transaction_segment_count_01: z.number(),
  transaction_set_control_number_02: z.number(),
});
export type TransactionSetTrailer = z.infer<typeof TransactionSetTrailerSchema>;

export const SubscriberSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherSubscriberSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  subscriber_supplemental_identifier_02: z.string(),
});
export type SubscriberSecondaryIdentification = z.infer<
  typeof SubscriberSecondaryIdentificationSchema
>;

export const SubscriberNameSchema = z.object({
  entity_identifier_code_01: OtherSubscriberNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([OtherSubscriberNameNm1IdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  subscriber_first_name_04: z.union([z.null(), z.string()]).optional(),
  subscriber_last_name_03: z.string(),
  subscriber_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  subscriber_name_suffix_07: z.union([z.null(), z.string()]).optional(),
  subscriber_primary_identifier_09: z.union([z.null(), z.string()]).optional(),
});
export type SubscriberName = z.infer<typeof SubscriberNameSchema>;

export const SubscriberDemographicInformationSchema = z.object({
  date_time_period_format_qualifier_01: DateTimePeriodFormatQualifier05_EnumSchema,
  subscriber_birth_date_02: z.string(),
  subscriber_gender_code_03: GenderCodeSchema,
});
export type SubscriberDemographicInformation = z.infer<
  typeof SubscriberDemographicInformationSchema
>;

export const SubscriberCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  subscriber_city_name_01: z.string(),
  subscriber_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  subscriber_state_code_02: z.union([z.null(), z.string()]).optional(),
});
export type SubscriberCityStateZipCode = z.infer<typeof SubscriberCityStateZipCodeSchema>;

export const SubscriberAddressSchema = z.object({
  subscriber_address_line_01: z.string(),
  subscriber_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type SubscriberAddress = z.infer<typeof SubscriberAddressSchema>;

export const PropertyAndCasualtySubscriberContactInformationSchema = z.object({
  communication_number_04: z.string(),
  communication_number_06: z.union([z.null(), z.string()]).optional(),
  communication_number_qualifier_03:
    ServiceFacilityContactInformationCommunicationNumberQualifier03Schema,
  communication_number_qualifier_05: z
    .union([ServiceFacilityContactInformationCommunicationNumberQualifier05Schema, z.null()])
    .optional(),
  contact_function_code_01: ContactFunctionCodeSchema,
  name_02: z.union([z.null(), z.string()]).optional(),
});
export type PropertyAndCasualtySubscriberContactInformation = z.infer<
  typeof PropertyAndCasualtySubscriberContactInformationSchema
>;

export const SubscriberNameNm1LoopPropertyAndCasualtyClaimNumberRefSchema = z.object({
  property_casualty_claim_number_02: z.string(),
  reference_identification_qualifier_01:
    PropertyAndCasualtyClaimNumberRefReferenceIdentificationQualifier01Schema,
});
export type SubscriberNameNm1LoopPropertyAndCasualtyClaimNumberRef = z.infer<
  typeof SubscriberNameNm1LoopPropertyAndCasualtyClaimNumberRefSchema
>;

export const SubscriberNameLoopSchema = z.object({
  property_and_casualty_claim_number_REF: z
    .union([SubscriberNameNm1LoopPropertyAndCasualtyClaimNumberRefSchema, z.null()])
    .optional(),
  property_and_casualty_subscriber_contact_information_PER: z
    .union([PropertyAndCasualtySubscriberContactInformationSchema, z.null()])
    .optional(),
  subscriber_address_N3: z.union([SubscriberAddressSchema, z.null()]).optional(),
  subscriber_city_state_zip_code_N4: z
    .union([SubscriberCityStateZipCodeSchema, z.null()])
    .optional(),
  subscriber_demographic_information_DMG: z
    .union([SubscriberDemographicInformationSchema, z.null()])
    .optional(),
  subscriber_name_NM1: SubscriberNameSchema,
  subscriber_secondary_identification_REF: z
    .union([SubscriberSecondaryIdentificationSchema, z.null()])
    .optional(),
});
export type SubscriberNameLoop = z.infer<typeof SubscriberNameLoopSchema>;

export const SubscriberInformationSchema = z.object({
  claim_filing_indicator_code_09: ClaimFilingIndicatorCodeSchema,
  individual_relationship_code_02: z
    .union([SubscriberInformationSbrIndividualRelationshipCode02Schema, z.null()])
    .optional(),
  insurance_type_code_05: z.union([InsuranceTypeCodeSchema, z.null()]).optional(),
  payer_responsibility_sequence_number_code_01: PayerResponsibilitySequenceNumberCodeSchema,
  subscriber_group_name_04: z.union([z.null(), z.string()]).optional(),
  subscriber_group_or_policy_number_03: z.union([z.null(), z.string()]).optional(),
});
export type SubscriberInformation = z.infer<typeof SubscriberInformationSchema>;

export const PayerSecondaryIdentificationSchema = z.object({
  payer_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerSecondaryIdentifierRefReferenceIdentificationQualifier01Schema,
});
export type PayerSecondaryIdentification = z.infer<typeof PayerSecondaryIdentificationSchema>;

export const PayerNameSchema = z.object({
  entity_identifier_code_01: OtherPayerNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: PayToPlanNameNm1IdentificationCodeQualifier08Schema,
  payer_identifier_09: z.string(),
  payer_name_03: z.string(),
});
export type PayerName = z.infer<typeof PayerNameSchema>;

export const PayerCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  payer_city_name_01: z.string(),
  payer_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  payer_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
});
export type PayerCityStateZipCode = z.infer<typeof PayerCityStateZipCodeSchema>;

export const PayerAddressSchema = z.object({
  payer_address_line_01: z.string(),
  payer_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type PayerAddress = z.infer<typeof PayerAddressSchema>;

export const BillingProviderSecondaryIdentificationSchema = z.object({
  billing_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerBillingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type BillingProviderSecondaryIdentification = z.infer<
  typeof BillingProviderSecondaryIdentificationSchema
>;

export const PayerNameLoopSchema = z.object({
  billing_provider_secondary_identification_REF: z
    .union([z.array(BillingProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
  payer_address_N3: z.union([PayerAddressSchema, z.null()]).optional(),
  payer_city_state_zip_code_N4: z.union([PayerCityStateZipCodeSchema, z.null()]).optional(),
  payer_name_NM1: PayerNameSchema,
  payer_secondary_identification_REF: z
    .union([z.array(PayerSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type PayerNameLoop = z.infer<typeof PayerNameLoopSchema>;

export const PatientInformationPatientInformationSchema = z.object({
  date_time_period_format_qualifier_05: z
    .union([DateTimePeriodFormatQualifier05_EnumSchema, z.null()])
    .optional(),
  patient_death_date_06: z.union([z.null(), z.string()]).optional(),
  patient_weight_08: z.union([z.number(), z.null()]).optional(),
  pregnancy_indicator_09: z.union([PregnancyIndicator09_EnumSchema, z.null()]).optional(),
  unit_or_basis_for_measurement_code_07: z
    .union([UnitOrBasisForMeasurementCode07_EnumSchema, z.null()])
    .optional(),
});
export type PatientInformationPatientInformation = z.infer<
  typeof PatientInformationPatientInformationSchema
>;

export const PropertyAndCasualtyPatientIdentifierSchema = z.object({
  property_and_casualty_patient_identifier_02: z.string(),
  reference_identification_qualifier_01:
    PropertyAndCasualtyPatientIdentifierRefReferenceIdentificationQualifier01Schema,
});
export type PropertyAndCasualtyPatientIdentifier = z.infer<
  typeof PropertyAndCasualtyPatientIdentifierSchema
>;

export const PropertyAndCasualtyPatientContactInformationSchema = z.object({
  communication_number_04: z.string(),
  communication_number_06: z.union([z.null(), z.string()]).optional(),
  communication_number_qualifier_03:
    ServiceFacilityContactInformationCommunicationNumberQualifier03Schema,
  communication_number_qualifier_05: z
    .union([ServiceFacilityContactInformationCommunicationNumberQualifier05Schema, z.null()])
    .optional(),
  contact_function_code_01: ContactFunctionCodeSchema,
  name_02: z.union([z.null(), z.string()]).optional(),
});
export type PropertyAndCasualtyPatientContactInformation = z.infer<
  typeof PropertyAndCasualtyPatientContactInformationSchema
>;

export const PatientNameNm1LoopPropertyAndCasualtyClaimNumberRefSchema = z.object({
  property_casualty_claim_number_02: z.string(),
  reference_identification_qualifier_01:
    PropertyAndCasualtyClaimNumberRefReferenceIdentificationQualifier01Schema,
});
export type PatientNameNm1LoopPropertyAndCasualtyClaimNumberRef = z.infer<
  typeof PatientNameNm1LoopPropertyAndCasualtyClaimNumberRefSchema
>;

export const PatientNameSchema = z.object({
  entity_identifier_code_01: PatientNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherPayerReferringProviderNm1EntityTypeQualifier02Schema,
  patient_first_name_04: z.union([z.null(), z.string()]).optional(),
  patient_last_name_03: z.string(),
  patient_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  patient_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type PatientName = z.infer<typeof PatientNameSchema>;

export const PatientDemographicInformationSchema = z.object({
  date_time_period_format_qualifier_01: DateTimePeriodFormatQualifier05_EnumSchema,
  patient_birth_date_02: z.string(),
  patient_gender_code_03: GenderCodeSchema,
});
export type PatientDemographicInformation = z.infer<typeof PatientDemographicInformationSchema>;

export const PatientCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  patient_city_name_01: z.string(),
  patient_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  patient_state_code_02: z.union([z.null(), z.string()]).optional(),
});
export type PatientCityStateZipCode = z.infer<typeof PatientCityStateZipCodeSchema>;

export const PatientAddressSchema = z.object({
  patient_address_line_01: z.string(),
  patient_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type PatientAddress = z.infer<typeof PatientAddressSchema>;

export const PatientNameLoopSchema = z.object({
  patient_address_N3: PatientAddressSchema,
  patient_city_state_zip_code_N4: PatientCityStateZipCodeSchema,
  patient_demographic_information_DMG: PatientDemographicInformationSchema,
  patient_name_NM1: PatientNameSchema,
  property_and_casualty_claim_number_REF: z
    .union([PatientNameNm1LoopPropertyAndCasualtyClaimNumberRefSchema, z.null()])
    .optional(),
  property_and_casualty_patient_contact_information_PER: z
    .union([PropertyAndCasualtyPatientContactInformationSchema, z.null()])
    .optional(),
  property_and_casualty_patient_identifier_REF: z
    .union([PropertyAndCasualtyPatientIdentifierSchema, z.null()])
    .optional(),
});
export type PatientNameLoop = z.infer<typeof PatientNameLoopSchema>;

export const PatientHierarchicalLevelHlLoopPatientInformationSchema = z.object({
  date_time_period_format_qualifier_05: z
    .union([DateTimePeriodFormatQualifier05_EnumSchema, z.null()])
    .optional(),
  individual_relationship_code_01: IndividualRelationshipCode01_EnumSchema,
  patient_death_date_06: z.union([z.null(), z.string()]).optional(),
  patient_weight_08: z.union([z.number(), z.null()]).optional(),
  pregnancy_indicator_09: z.union([PregnancyIndicator09_EnumSchema, z.null()]).optional(),
  unit_or_basis_for_measurement_code_07: z
    .union([UnitOrBasisForMeasurementCode07_EnumSchema, z.null()])
    .optional(),
});
export type PatientHierarchicalLevelHlLoopPatientInformation = z.infer<
  typeof PatientHierarchicalLevelHlLoopPatientInformationSchema
>;

export const StickySupervisingProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerRenderingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  supervising_provider_secondary_identifier_02: z.string(),
});
export type StickySupervisingProviderSecondaryIdentification = z.infer<
  typeof StickySupervisingProviderSecondaryIdentificationSchema
>;

export const StickySupervisingProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerSupervisingProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherPayerReferringProviderNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  supervising_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  supervising_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  supervising_provider_last_name_03: z.string(),
  supervising_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  supervising_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type StickySupervisingProviderName = z.infer<typeof StickySupervisingProviderNameSchema>;

export const StickySupervisingProviderNameLoopSchema = z.object({
  supervising_provider_name_NM1: StickySupervisingProviderNameSchema,
  supervising_provider_secondary_identification_REF: z
    .union([z.array(StickySupervisingProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type StickySupervisingProviderNameLoop = z.infer<
  typeof StickySupervisingProviderNameLoopSchema
>;

export const FluffySpinalManipulationServiceInformationSchema = z.object({
  patient_condition_code_08: PatientConditionCodeSchema,
  patient_condition_description_10: z.union([z.null(), z.string()]).optional(),
  patient_condition_description_11: z.union([z.null(), z.string()]).optional(),
});
export type FluffySpinalManipulationServiceInformation = z.infer<
  typeof FluffySpinalManipulationServiceInformationSchema
>;

export const FluffyThirdPartyOrganizationNotesSchema = z.object({
  line_note_text_02: z.string(),
  note_reference_code_01: ThirdPartyOrganizationNotesNteNoteReferenceCode01Schema,
});
export type FluffyThirdPartyOrganizationNotes = z.infer<
  typeof FluffyThirdPartyOrganizationNotesSchema
>;

export const FluffyTestResultSchema = z.object({
  measurement_qualifier_02: MeasurementQualifierSchema,
  measurement_reference_identification_code_01: MeasurementReferenceIdentificationCodeSchema,
  test_results_03: z.number(),
});
export type FluffyTestResult = z.infer<typeof FluffyTestResultSchema>;

export const ReferenceIdentifier3Schema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type ReferenceIdentifier3 = z.infer<typeof ReferenceIdentifier3Schema>;

export const TentacledSupervisingProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerRenderingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([ReferenceIdentifier3Schema, z.null()]).optional(),
  supervising_provider_secondary_identifier_02: z.string(),
});
export type TentacledSupervisingProviderSecondaryIdentification = z.infer<
  typeof TentacledSupervisingProviderSecondaryIdentificationSchema
>;

export const TentacledSupervisingProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerSupervisingProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherPayerReferringProviderNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  supervising_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  supervising_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  supervising_provider_last_name_03: z.string(),
  supervising_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  supervising_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type TentacledSupervisingProviderName = z.infer<
  typeof TentacledSupervisingProviderNameSchema
>;

export const TentacledSupervisingProviderNameLoopSchema = z.object({
  supervising_provider_name_NM1: TentacledSupervisingProviderNameSchema,
  supervising_provider_secondary_identification_REF: z
    .union([z.array(TentacledSupervisingProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type TentacledSupervisingProviderNameLoop = z.infer<
  typeof TentacledSupervisingProviderNameLoopSchema
>;

export const FluffyServiceLineNumberSchema = z.object({
  assigned_number_01: z.number(),
});
export type FluffyServiceLineNumber = z.infer<typeof FluffyServiceLineNumberSchema>;

export const ReferenceIdentifier2Schema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type ReferenceIdentifier2 = z.infer<typeof ReferenceIdentifier2Schema>;

export const StickyServiceFacilityLocationSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerBillingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([ReferenceIdentifier2Schema, z.null()]).optional(),
  service_facility_location_secondary_identifier_02: z.string(),
});
export type StickyServiceFacilityLocationSecondaryIdentification = z.infer<
  typeof StickyServiceFacilityLocationSecondaryIdentificationSchema
>;

export const StickyServiceFacilityLocationNameSchema = z.object({
  entity_identifier_code_01: OtherPayerServiceFacilityLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  laboratory_or_facility_name_03: z.string(),
  laboratory_or_facility_primary_identifier_09: z.union([z.null(), z.string()]).optional(),
});
export type StickyServiceFacilityLocationName = z.infer<
  typeof StickyServiceFacilityLocationNameSchema
>;

export const StickyServiceFacilityLocationCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  laboratory_or_facility_city_name_01: z.string(),
  laboratory_or_facility_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  laboratory_or_facility_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
});
export type StickyServiceFacilityLocationCityStateZipCode = z.infer<
  typeof StickyServiceFacilityLocationCityStateZipCodeSchema
>;

export const StickyServiceFacilityLocationAddressSchema = z.object({
  laboratory_or_facility_address_line_01: z.string(),
  laboratory_or_facility_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type StickyServiceFacilityLocationAddress = z.infer<
  typeof StickyServiceFacilityLocationAddressSchema
>;

export const StickyServiceFacilityLocationNameLoopSchema = z.object({
  service_facility_location_address_N3: StickyServiceFacilityLocationAddressSchema,
  service_facility_location_city_state_zip_code_N4:
    StickyServiceFacilityLocationCityStateZipCodeSchema,
  service_facility_location_name_NM1: StickyServiceFacilityLocationNameSchema,
  service_facility_location_secondary_identification_REF: z
    .union([z.array(StickyServiceFacilityLocationSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type StickyServiceFacilityLocationNameLoop = z.infer<
  typeof StickyServiceFacilityLocationNameLoopSchema
>;

export const FluffySalesTaxAmountSchema = z.object({
  amount_qualifier_code_01: SalesTaxAmountAmtAmountQualifierCode01Schema,
  sales_tax_amount_02: z.number(),
});
export type FluffySalesTaxAmount = z.infer<typeof FluffySalesTaxAmountSchema>;

export const FluffyRepricedLineItemReferenceNumberSchema = z.object({
  reference_identification_qualifier_01:
    RepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01Schema,
  repriced_line_item_reference_number_02: z.string(),
});
export type FluffyRepricedLineItemReferenceNumber = z.infer<
  typeof FluffyRepricedLineItemReferenceNumberSchema
>;

export const StickyRenderingProviderSpecialtyInformationSchema = z.object({
  provider_code_01: ErCodeSchema,
  provider_taxonomy_code_03: z.string(),
  reference_identification_qualifier_02: ReferenceIdentificationQualifier02_EnumSchema,
});
export type StickyRenderingProviderSpecialtyInformation = z.infer<
  typeof StickyRenderingProviderSpecialtyInformationSchema
>;

export const ReferenceIdentifier1Schema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type ReferenceIdentifier1 = z.infer<typeof ReferenceIdentifier1Schema>;

export const StickyRenderingProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerRenderingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([ReferenceIdentifier1Schema, z.null()]).optional(),
  rendering_provider_secondary_identifier_02: z.string(),
});
export type StickyRenderingProviderSecondaryIdentification = z.infer<
  typeof StickyRenderingProviderSecondaryIdentificationSchema
>;

export const StickyRenderingProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerRenderingProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  rendering_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  rendering_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  rendering_provider_last_or_organization_name_03: z.string(),
  rendering_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  rendering_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type StickyRenderingProviderName = z.infer<typeof StickyRenderingProviderNameSchema>;

export const StickyRenderingProviderNameLoopSchema = z.object({
  rendering_provider_name_NM1: StickyRenderingProviderNameSchema,
  rendering_provider_secondary_identification_REF: z
    .union([z.array(StickyRenderingProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
  rendering_provider_specialty_information_PRV: z
    .union([StickyRenderingProviderSpecialtyInformationSchema, z.null()])
    .optional(),
});
export type StickyRenderingProviderNameLoop = z.infer<typeof StickyRenderingProviderNameLoopSchema>;

export const BraggadociousReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type BraggadociousReferenceIdentifier = z.infer<
  typeof BraggadociousReferenceIdentifierSchema
>;

export const StickyReferringProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([BraggadociousReferenceIdentifierSchema, z.null()]).optional(),
  referring_provider_secondary_identifier_02: z.string(),
});
export type StickyReferringProviderSecondaryIdentification = z.infer<
  typeof StickyReferringProviderSecondaryIdentificationSchema
>;

export const StickyReferringProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerReferringProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherPayerReferringProviderNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  referring_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  referring_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  referring_provider_last_name_03: z.string(),
  referring_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  referring_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type StickyReferringProviderName = z.infer<typeof StickyReferringProviderNameSchema>;

export const StickyReferringProviderNameLoopSchema = z.object({
  referring_provider_name_NM1: StickyReferringProviderNameSchema,
  referring_provider_secondary_identification_REF: z
    .union([z.array(StickyReferringProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type StickyReferringProviderNameLoop = z.infer<typeof StickyReferringProviderNameLoopSchema>;

export const FluffyReferringClinicalLaboratoryImprovementAmendmentCliaFacilityIdentificationSchema =
  z.object({
    reference_identification_qualifier_01: AmountQualifierCodeSchema,
    referring_clia_number_02: z.string(),
  });
export type FluffyReferringClinicalLaboratoryImprovementAmendmentCliaFacilityIdentification =
  z.infer<
    typeof FluffyReferringClinicalLaboratoryImprovementAmendmentCliaFacilityIdentificationSchema
  >;

export const MischievousReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type MischievousReferenceIdentifier = z.infer<typeof MischievousReferenceIdentifierSchema>;

export const StickyReferralNumberSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerReferralNumberRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([MischievousReferenceIdentifierSchema, z.null()]).optional(),
  referral_number_02: z.string(),
});
export type StickyReferralNumber = z.infer<typeof StickyReferralNumberSchema>;

export const FriskyReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type FriskyReferenceIdentifier = z.infer<typeof FriskyReferenceIdentifierSchema>;

export const FluffyPurchasedServiceProviderSecondaryIdentificationSchema = z.object({
  purchased_service_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([FriskyReferenceIdentifierSchema, z.null()]).optional(),
});
export type FluffyPurchasedServiceProviderSecondaryIdentification = z.infer<
  typeof FluffyPurchasedServiceProviderSecondaryIdentificationSchema
>;

export const FluffyPurchasedServiceProviderNameSchema = z.object({
  entity_identifier_code_01: PurchasedServiceProviderNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  purchased_service_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
});
export type FluffyPurchasedServiceProviderName = z.infer<
  typeof FluffyPurchasedServiceProviderNameSchema
>;

export const FluffyPurchasedServiceProviderNameLoopSchema = z.object({
  purchased_service_provider_name_NM1: FluffyPurchasedServiceProviderNameSchema,
  purchased_service_provider_secondary_identification_REF: z
    .union([z.array(FluffyPurchasedServiceProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type FluffyPurchasedServiceProviderNameLoop = z.infer<
  typeof FluffyPurchasedServiceProviderNameLoopSchema
>;

export const FluffyPurchasedServiceInformationSchema = z.object({
  purchased_service_charge_amount_02: z.number(),
  purchased_service_provider_identifier_01: z.string(),
});
export type FluffyPurchasedServiceInformation = z.infer<
  typeof FluffyPurchasedServiceInformationSchema
>;

export const IndecentCompositeMedicalProcedureIdentifierSchema = z.object({
  description_07: z.union([z.null(), z.string()]).optional(),
  procedure_code_02: z.string(),
  procedure_modifier_03: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_04: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_05: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_06: z.union([z.null(), z.string()]).optional(),
  product_or_service_id_qualifier_01: ProductOrServiceIdQualifier01_EnumSchema,
});
export type IndecentCompositeMedicalProcedureIdentifier = z.infer<
  typeof IndecentCompositeMedicalProcedureIdentifierSchema
>;

export const FluffyCompositeDiagnosisCodePointerSchema = z.object({
  diagnosis_code_pointer_01: z.number(),
  diagnosis_code_pointer_02: z.union([z.number(), z.null()]).optional(),
  diagnosis_code_pointer_03: z.union([z.number(), z.null()]).optional(),
  diagnosis_code_pointer_04: z.union([z.number(), z.null()]).optional(),
});
export type FluffyCompositeDiagnosisCodePointer = z.infer<
  typeof FluffyCompositeDiagnosisCodePointerSchema
>;

export const FluffyProfessionalServiceSchema = z.object({
  co_pay_status_code_15: z.union([CoPayStatusCodeSchema, z.null()]).optional(),
  composite_diagnosis_code_pointer_07: FluffyCompositeDiagnosisCodePointerSchema,
  composite_medical_procedure_identifier_01: IndecentCompositeMedicalProcedureIdentifierSchema,
  emergency_indicator_09: z.union([PregnancyIndicator09_EnumSchema, z.null()]).optional(),
  epsdt_indicator_11: z.union([PregnancyIndicator09_EnumSchema, z.null()]).optional(),
  family_planning_indicator_12: z.union([PregnancyIndicator09_EnumSchema, z.null()]).optional(),
  line_item_charge_amount_02: z.number(),
  place_of_service_code_05: z.union([z.null(), z.string()]).optional(),
  service_unit_count_04: z.number(),
  unit_or_basis_for_measurement_code_03: UnitOrBasisForMeasurementCode03_EnumSchema,
});
export type FluffyProfessionalService = z.infer<typeof FluffyProfessionalServiceSchema>;

export const MagentaReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type MagentaReferenceIdentifier = z.infer<typeof MagentaReferenceIdentifierSchema>;

export const StickyPriorAuthorizationSchema = z.object({
  prior_authorization_or_referral_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerPriorAuthorizationNumberRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([MagentaReferenceIdentifierSchema, z.null()]).optional(),
});
export type StickyPriorAuthorization = z.infer<typeof StickyPriorAuthorizationSchema>;

export const FluffyPostageClaimedAmountSchema = z.object({
  amount_qualifier_code_01: AmountQualifierCodeSchema,
  postage_claimed_amount_02: z.number(),
});
export type FluffyPostageClaimedAmount = z.infer<typeof FluffyPostageClaimedAmountSchema>;

export const CunningReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type CunningReferenceIdentifier = z.infer<typeof CunningReferenceIdentifierSchema>;

export const FluffyOrderingProviderSecondaryIdentificationSchema = z.object({
  ordering_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([CunningReferenceIdentifierSchema, z.null()]).optional(),
});
export type FluffyOrderingProviderSecondaryIdentification = z.infer<
  typeof FluffyOrderingProviderSecondaryIdentificationSchema
>;

export const FluffyOrderingProviderNameSchema = z.object({
  entity_identifier_code_01: OrderingProviderNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherPayerReferringProviderNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  ordering_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  ordering_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  ordering_provider_last_name_03: z.string(),
  ordering_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  ordering_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type FluffyOrderingProviderName = z.infer<typeof FluffyOrderingProviderNameSchema>;

export const FluffyOrderingProviderContactInformationSchema = z.object({
  communication_number_04: z.string(),
  communication_number_06: z.union([z.null(), z.string()]).optional(),
  communication_number_08: z.union([z.null(), z.string()]).optional(),
  communication_number_qualifier_03:
    BillingProviderContactInformationCommunicationNumberQualifier03Schema,
  communication_number_qualifier_05: z
    .union([CommunicationNumberQualifier07_EnumSchema, z.null()])
    .optional(),
  communication_number_qualifier_07: z
    .union([CommunicationNumberQualifier07_EnumSchema, z.null()])
    .optional(),
  contact_function_code_01: ContactFunctionCodeSchema,
  ordering_provider_contact_name_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyOrderingProviderContactInformation = z.infer<
  typeof FluffyOrderingProviderContactInformationSchema
>;

export const FluffyOrderingProviderCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  ordering_provider_city_name_01: z.string(),
  ordering_provider_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  ordering_provider_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyOrderingProviderCityStateZipCode = z.infer<
  typeof FluffyOrderingProviderCityStateZipCodeSchema
>;

export const FluffyOrderingProviderAddressSchema = z.object({
  ordering_provider_address_line_01: z.string(),
  ordering_provider_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyOrderingProviderAddress = z.infer<typeof FluffyOrderingProviderAddressSchema>;

export const FluffyOrderingProviderNameLoopSchema = z.object({
  ordering_provider_address_N3: z.union([FluffyOrderingProviderAddressSchema, z.null()]).optional(),
  ordering_provider_city_state_zip_code_N4: z
    .union([FluffyOrderingProviderCityStateZipCodeSchema, z.null()])
    .optional(),
  ordering_provider_contact_information_PER: z
    .union([FluffyOrderingProviderContactInformationSchema, z.null()])
    .optional(),
  ordering_provider_name_NM1: FluffyOrderingProviderNameSchema,
  ordering_provider_secondary_identification_REF: z
    .union([z.array(FluffyOrderingProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type FluffyOrderingProviderNameLoop = z.infer<typeof FluffyOrderingProviderNameLoopSchema>;

export const FluffyObstetricAnesthesiaAdditionalUnitsSchema = z.object({
  obstetric_additional_units_02: z.number(),
  quantity_qualifier_01: ObstetricAnesthesiaAdditionalUnitsQtyQuantityQualifier01Schema,
});
export type FluffyObstetricAnesthesiaAdditionalUnits = z.infer<
  typeof FluffyObstetricAnesthesiaAdditionalUnitsSchema
>;

export const StickyMammographyCertificationNumberSchema = z.object({
  mammography_certification_number_02: z.string(),
  reference_identification_qualifier_01:
    MammographyCertificationNumberRefReferenceIdentificationQualifier01Schema,
});
export type StickyMammographyCertificationNumber = z.infer<
  typeof StickyMammographyCertificationNumberSchema
>;

export const FluffyLineSupplementalInformationSchema = z.object({
  attachment_control_number_06: z.union([z.null(), z.string()]).optional(),
  attachment_report_type_code_01: ClaimSupplementalInformationAttachmentReportTypeCode01Schema,
  attachment_transmission_code_02: ClaimSupplementalInformationAttachmentTransmissionCode02Schema,
  identification_code_qualifier_05: z
    .union([IdentificationCodeQualifier05_EnumSchema, z.null()])
    .optional(),
});
export type FluffyLineSupplementalInformation = z.infer<
  typeof FluffyLineSupplementalInformationSchema
>;

export const FluffyLinePricingRepricingInformationSchema = z.object({
  exception_code_15: z.union([ExceptionCodeSchema, z.null()]).optional(),
  policy_compliance_code_14: z.union([PolicyComplianceCodeSchema, z.null()]).optional(),
  pricing_methodology_01: LinePricingRepricingInformationPricingMethodology01Schema,
  product_or_service_id_qualifier_09: z
    .union([ProductOrServiceIdQualifier01_EnumSchema, z.null()])
    .optional(),
  reject_reason_code_13: z.union([RejectReasonCodeSchema, z.null()]).optional(),
  repriced_allowed_amount_02: z.number(),
  repriced_approved_ambulatory_patient_group_amount_07: z.union([z.number(), z.null()]).optional(),
  repriced_approved_ambulatory_patient_group_code_06: z.union([z.null(), z.string()]).optional(),
  repriced_approved_hcpcs_code_10: z.union([z.null(), z.string()]).optional(),
  repriced_approved_service_unit_count_12: z.union([z.number(), z.null()]).optional(),
  repriced_saving_amount_03: z.union([z.number(), z.null()]).optional(),
  repricing_organization_identifier_04: z.union([z.null(), z.string()]).optional(),
  repricing_per_diem_or_flat_rate_amount_05: z.union([z.number(), z.null()]).optional(),
  unit_or_basis_for_measurement_code_11: z
    .union([UnitOrBasisForMeasurementCode03_EnumSchema, z.null()])
    .optional(),
});
export type FluffyLinePricingRepricingInformation = z.infer<
  typeof FluffyLinePricingRepricingInformationSchema
>;

export const FluffyLineNoteSchema = z.object({
  line_note_text_02: z.string(),
  note_reference_code_01: LineNoteNteNoteReferenceCode01Schema,
});
export type FluffyLineNote = z.infer<typeof FluffyLineNoteSchema>;

export const FluffyLineItemControlNumberSchema = z.object({
  line_item_control_number_02: z.string(),
  reference_identification_qualifier_01:
    LineItemControlNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyLineItemControlNumber = z.infer<typeof FluffyLineItemControlNumberSchema>;

export const StickyRemainingPatientLiabilitySchema = z.object({
  amount_qualifier_code_01: RemainingPatientLiabilityAmtAmountQualifierCode01Schema,
  remaining_patient_liability_02: z.number(),
});
export type StickyRemainingPatientLiability = z.infer<typeof StickyRemainingPatientLiabilitySchema>;

export const FluffyLineCheckOrRemittanceDateSchema = z.object({
  adjudication_or_payment_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: ClaimCheckOrRemittanceDateDtpDateTimeQualifier01Schema,
});
export type FluffyLineCheckOrRemittanceDate = z.infer<typeof FluffyLineCheckOrRemittanceDateSchema>;

export const FluffyLineAdjustmentSchema = z.object({
  adjustment_amount_03: z.number(),
  adjustment_amount_06: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_09: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_12: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_15: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_18: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_04: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_07: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_10: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_13: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_16: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_19: z.union([z.number(), z.null()]).optional(),
  adjustment_reason_code_02: z.string(),
  adjustment_reason_code_05: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_08: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_11: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_14: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_17: z.union([z.null(), z.string()]).optional(),
  claim_adjustment_group_code_01: ClaimAdjustmentGroupCodeSchema,
});
export type FluffyLineAdjustment = z.infer<typeof FluffyLineAdjustmentSchema>;

export const IndigoCompositeMedicalProcedureIdentifierSchema = z.object({
  procedure_code_02: z.string(),
  procedure_code_description_07: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_03: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_04: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_05: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_06: z.union([z.null(), z.string()]).optional(),
  product_or_service_id_qualifier_01: ProductOrServiceIdQualifier01_EnumSchema,
});
export type IndigoCompositeMedicalProcedureIdentifier = z.infer<
  typeof IndigoCompositeMedicalProcedureIdentifierSchema
>;

export const FluffyLineAdjudicationInformationSchema = z.object({
  bundled_or_unbundled_line_number_06: z.union([z.number(), z.null()]).optional(),
  composite_medical_procedure_identifier_03: IndigoCompositeMedicalProcedureIdentifierSchema,
  other_payer_primary_identifier_01: z.string(),
  paid_service_unit_count_05: z.number(),
  service_line_paid_amount_02: z.number(),
});
export type FluffyLineAdjudicationInformation = z.infer<
  typeof FluffyLineAdjudicationInformationSchema
>;

export const FluffyLineAdjudicationInformationLoopSchema = z.object({
  line_adjudication_information_SVD: FluffyLineAdjudicationInformationSchema,
  line_adjustment_CAS: z.union([z.array(FluffyLineAdjustmentSchema), z.null()]).optional(),
  line_check_or_remittance_date_DTP: FluffyLineCheckOrRemittanceDateSchema,
  remaining_patient_liability_AMT: z
    .union([StickyRemainingPatientLiabilitySchema, z.null()])
    .optional(),
});
export type FluffyLineAdjudicationInformationLoop = z.infer<
  typeof FluffyLineAdjudicationInformationLoopSchema
>;

export const FluffyImmunizationBatchNumberSchema = z.object({
  immunization_batch_number_02: z.string(),
  reference_identification_qualifier_01:
    ImmunizationBatchNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyImmunizationBatchNumber = z.infer<typeof FluffyImmunizationBatchNumberSchema>;

export const FluffyHospiceEmployeeIndicatorSchema = z.object({
  code_category_01: HospiceEmployeeIndicatorCrcCodeCategory01Schema,
  condition_indicator_03: HospiceEmployeeIndicatorCrcConditionIndicator03Schema,
  hospice_employed_provider_indicator_02: ProviderOrSupplierSignatureIndicator06_EnumSchema,
});
export type FluffyHospiceEmployeeIndicator = z.infer<typeof FluffyHospiceEmployeeIndicatorSchema>;

export const FluffySupportingDocumentationSchema = z.object({
  question_number_letter_01: z.string(),
  question_response_02: z
    .union([BenefitsAssignmentCertificationIndicatorSchema, z.null()])
    .optional(),
  question_response_03: z.union([z.null(), z.string()]).optional(),
  question_response_04: z.union([z.null(), z.string()]).optional(),
  question_response_05: z.union([z.number(), z.null()]).optional(),
});
export type FluffySupportingDocumentation = z.infer<typeof FluffySupportingDocumentationSchema>;

export const FluffyFormIdentificationCodeSchema = z.object({
  code_list_qualifier_code_01: FormIdentificationCodeLqCodeListQualifierCode01Schema,
  form_identifier_02: z.string(),
});
export type FluffyFormIdentificationCode = z.infer<typeof FluffyFormIdentificationCodeSchema>;

export const FluffyFormIdentificationCodeLoopSchema = z.object({
  form_identification_code_LQ: FluffyFormIdentificationCodeSchema,
  supporting_documentation_FRM: z.array(FluffySupportingDocumentationSchema),
});
export type FluffyFormIdentificationCodeLoop = z.infer<
  typeof FluffyFormIdentificationCodeLoopSchema
>;

export const StickyFileInformationSchema = z.object({
  fixed_format_information_01: z.string(),
});
export type StickyFileInformation = z.infer<typeof StickyFileInformationSchema>;

export const StickyCompositeMedicalProcedureIdentifierSchema = z.object({
  procedure_code_02: z.string(),
  procedure_identifier_01: ProcedureIdentifierSchema,
});
export type StickyCompositeMedicalProcedureIdentifier = z.infer<
  typeof StickyCompositeMedicalProcedureIdentifierSchema
>;

export const FluffyDurableMedicalEquipmentServiceSchema = z.object({
  composite_medical_procedure_identifier_01: StickyCompositeMedicalProcedureIdentifierSchema,
  dme_purchase_price_05: z.number(),
  dme_rental_price_04: z.number(),
  length_of_medical_necessity_03: z.number(),
  rental_unit_price_indicator_06: RentalUnitPriceIndicatorSchema,
  unit_or_basis_for_measurement_code_02:
    DurableMedicalEquipmentServiceSv5UnitOrBasisForMeasurementCode02Schema,
});
export type FluffyDurableMedicalEquipmentService = z.infer<
  typeof FluffyDurableMedicalEquipmentServiceSchema
>;

export const FluffyDurableMedicalEquipmentCertificationSchema = z.object({
  certification_type_code_01: CertificationTypeCodeSchema,
  durable_medical_equipment_duration_03: z.number(),
  unit_or_basis_for_measurement_code_02:
    DurableMedicalEquipmentCertificationCr3UnitOrBasisForMeasurementCode02Schema,
});
export type FluffyDurableMedicalEquipmentCertification = z.infer<
  typeof FluffyDurableMedicalEquipmentCertificationSchema
>;

export const FluffyDurableMedicalEquipmentCertificateOfMedicalNecessityIndicatorSchema = z.object({
  attachment_report_type_code_01:
    DurableMedicalEquipmentCertificateOfMedicalNecessityIndicatorPwkAttachmentReportTypeCode01Schema,
  attachment_transmission_code_02:
    DurableMedicalEquipmentCertificateOfMedicalNecessityIndicatorPwkAttachmentTransmissionCode02Schema,
});
export type FluffyDurableMedicalEquipmentCertificateOfMedicalNecessityIndicator = z.infer<
  typeof FluffyDurableMedicalEquipmentCertificateOfMedicalNecessityIndicatorSchema
>;

export const FluffyPrescriptionOrCompoundDrugAssociationNumberSchema = z.object({
  prescription_number_02: z.string(),
  reference_identification_qualifier_01:
    PrescriptionOrCompoundDrugAssociationNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyPrescriptionOrCompoundDrugAssociationNumber = z.infer<
  typeof FluffyPrescriptionOrCompoundDrugAssociationNumberSchema
>;

export const FluffyCompositeUnitOfMeasureSchema = z.object({
  code_qualifier_01: CompositeUnitOfMeasure05_CodeQualifier01Schema,
});
export type FluffyCompositeUnitOfMeasure = z.infer<typeof FluffyCompositeUnitOfMeasureSchema>;

export const FluffyDrugQuantitySchema = z.object({
  composite_unit_of_measure_05: FluffyCompositeUnitOfMeasureSchema,
  national_drug_unit_count_04: z.number(),
});
export type FluffyDrugQuantity = z.infer<typeof FluffyDrugQuantitySchema>;

export const FluffyDrugIdentificationSchema = z.object({
  national_drug_code_or_universal_product_number_03: z.string(),
  product_or_service_id_qualifier_02: ProductOrServiceIdQualifier02_EnumSchema,
});
export type FluffyDrugIdentification = z.infer<typeof FluffyDrugIdentificationSchema>;

export const FluffyDrugIdentificationLoopSchema = z.object({
  drug_identification_LIN: FluffyDrugIdentificationSchema,
  drug_quantity_CTP: FluffyDrugQuantitySchema,
  prescription_or_compound_drug_association_number_REF: z
    .union([FluffyPrescriptionOrCompoundDrugAssociationNumberSchema, z.null()])
    .optional(),
});
export type FluffyDrugIdentificationLoop = z.infer<typeof FluffyDrugIdentificationLoopSchema>;

export const FluffyDateTestDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateTestDateDtpDateTimeQualifier01Schema,
  test_performed_date_03: z.string(),
});
export type FluffyDateTestDate = z.infer<typeof FluffyDateTestDateSchema>;

export const FluffyDateShippedDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateShippedDateDtpDateTimeQualifier01Schema,
  shipped_date_03: z.string(),
});
export type FluffyDateShippedDate = z.infer<typeof FluffyDateShippedDateSchema>;

export const FluffyDateServiceDateSchema = z.object({
  date_time_period_format_qualifier_02: DateDisabilityDatesDtpDateTimePeriodFormatQualifier02Schema,
  date_time_qualifier_01: DateServiceDateDtpDateTimeQualifier01Schema,
  service_date_03: z.string(),
});
export type FluffyDateServiceDate = z.infer<typeof FluffyDateServiceDateSchema>;

export const FluffyDatePrescriptionDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateHearingAndVisionPrescriptionDateDtpDateTimeQualifier01Schema,
  prescription_date_03: z.string(),
});
export type FluffyDatePrescriptionDate = z.infer<typeof FluffyDatePrescriptionDateSchema>;

export const StickyDateLastXRayDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateLastXRayDateDtpDateTimeQualifier01Schema,
  last_x_ray_date_03: z.string(),
});
export type StickyDateLastXRayDate = z.infer<typeof StickyDateLastXRayDateSchema>;

export const StickyDateLastSeenDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateLastSeenDateDtpDateTimeQualifier01Schema,
  treatment_or_therapy_date_03: z.string(),
});
export type StickyDateLastSeenDate = z.infer<typeof StickyDateLastSeenDateSchema>;

export const FluffyDateLastCertificationDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateLastCertificationDateDtpDateTimeQualifier01Schema,
  last_certification_date_03: z.string(),
});
export type FluffyDateLastCertificationDate = z.infer<typeof FluffyDateLastCertificationDateSchema>;

export const StickyDateInitialTreatmentDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateInitialTreatmentDateDtpDateTimeQualifier01Schema,
  initial_treatment_date_03: z.string(),
});
export type StickyDateInitialTreatmentDate = z.infer<typeof StickyDateInitialTreatmentDateSchema>;

export const FluffyDateCertificationRevisionRecertificationDateSchema = z.object({
  certification_revision_or_recertification_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateCertificationRevisionRecertificationDateDtpDateTimeQualifier01Schema,
});
export type FluffyDateCertificationRevisionRecertificationDate = z.infer<
  typeof FluffyDateCertificationRevisionRecertificationDateSchema
>;

export const FluffyDateBeginTherapyDateSchema = z.object({
  begin_therapy_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateBeginTherapyDateDtpDateTimeQualifier01Schema,
});
export type FluffyDateBeginTherapyDate = z.infer<typeof FluffyDateBeginTherapyDateSchema>;

export const StickyContractInformationSchema = z.object({
  contract_amount_02: z.union([z.number(), z.null()]).optional(),
  contract_code_04: z.union([z.null(), z.string()]).optional(),
  contract_percentage_03: z.union([z.number(), z.null()]).optional(),
  contract_type_code_01: ContractTypeCodeSchema,
  contract_version_identifier_06: z.union([z.null(), z.string()]).optional(),
  terms_discount_percentage_05: z.union([z.number(), z.null()]).optional(),
});
export type StickyContractInformation = z.infer<typeof StickyContractInformationSchema>;

export const FluffyConditionIndicatorDurableMedicalEquipmentSchema = z.object({
  certification_condition_indicator_02: ProviderOrSupplierSignatureIndicator06_EnumSchema,
  code_category_01: ConditionIndicatorDurableMedicalEquipmentCrcCodeCategory01Schema,
  condition_indicator_03: ConditionIndicatorDurableMedicalEquipmentCrcConditionIndicator03Schema,
  condition_indicator_04: z.union([z.null(), z.string()]).optional(),
});
export type FluffyConditionIndicatorDurableMedicalEquipment = z.infer<
  typeof FluffyConditionIndicatorDurableMedicalEquipmentSchema
>;

export const StickyClinicalLaboratoryImprovementAmendmentCliaNumberSchema = z.object({
  clinical_laboratory_improvement_amendment_number_02: z.string(),
  reference_identification_qualifier_01:
    ClinicalLaboratoryImprovementAmendmentCliaNumberRefReferenceIdentificationQualifier01Schema,
});
export type StickyClinicalLaboratoryImprovementAmendmentCliaNumber = z.infer<
  typeof StickyClinicalLaboratoryImprovementAmendmentCliaNumberSchema
>;

export const StickyAmbulanceTransportInformationSchema = z.object({
  ambulance_transport_reason_code_04: AmbulanceTransportReasonCodeSchema,
  patient_weight_02: z.union([z.number(), z.null()]).optional(),
  round_trip_purpose_description_09: z.union([z.null(), z.string()]).optional(),
  stretcher_purpose_description_10: z.union([z.null(), z.string()]).optional(),
  transport_distance_06: z.number(),
  unit_or_basis_for_measurement_code_01: z
    .union([UnitOrBasisForMeasurementCode01_EnumSchema, z.null()])
    .optional(),
  unit_or_basis_for_measurement_code_05: UnitOrBasisForMeasurementCode05_EnumSchema,
});
export type StickyAmbulanceTransportInformation = z.infer<
  typeof StickyAmbulanceTransportInformationSchema
>;

export const StickyAmbulancePickUpLocationSchema = z.object({
  entity_identifier_code_01: AmbulancePickUpLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
});
export type StickyAmbulancePickUpLocation = z.infer<typeof StickyAmbulancePickUpLocationSchema>;

export const StickyAmbulancePickUpLocationCityStateZipCodeSchema = z.object({
  ambulance_pick_up_city_name_01: z.string(),
  ambulance_pick_up_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  ambulance_pick_up_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
});
export type StickyAmbulancePickUpLocationCityStateZipCode = z.infer<
  typeof StickyAmbulancePickUpLocationCityStateZipCodeSchema
>;

export const StickyAmbulancePickUpLocationAddressSchema = z.object({
  ambulance_pick_up_address_line_01: z.string(),
  ambulance_pick_up_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type StickyAmbulancePickUpLocationAddress = z.infer<
  typeof StickyAmbulancePickUpLocationAddressSchema
>;

export const StickyAmbulancePickUpLocationLoopSchema = z.object({
  ambulance_pick_up_location_address_N3: StickyAmbulancePickUpLocationAddressSchema,
  ambulance_pick_up_location_city_state_zip_code_N4:
    StickyAmbulancePickUpLocationCityStateZipCodeSchema,
  ambulance_pick_up_location_NM1: StickyAmbulancePickUpLocationSchema,
});
export type StickyAmbulancePickUpLocationLoop = z.infer<
  typeof StickyAmbulancePickUpLocationLoopSchema
>;

export const FluffyAmbulancePatientCountSchema = z.object({
  ambulance_patient_count_02: z.number(),
  quantity_qualifier_01: AmbulancePatientCountQtyQuantityQualifier01Schema,
});
export type FluffyAmbulancePatientCount = z.infer<typeof FluffyAmbulancePatientCountSchema>;

export const StickyAmbulanceDropOffLocationSchema = z.object({
  ambulance_drop_off_location_03: z.union([z.null(), z.string()]).optional(),
  entity_identifier_code_01: AmbulanceDropOffLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
});
export type StickyAmbulanceDropOffLocation = z.infer<typeof StickyAmbulanceDropOffLocationSchema>;

export const StickyAmbulanceDropOffLocationCityStateZipCodeSchema = z.object({
  ambulance_drop_off_city_name_01: z.string(),
  ambulance_drop_off_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  ambulance_drop_off_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
});
export type StickyAmbulanceDropOffLocationCityStateZipCode = z.infer<
  typeof StickyAmbulanceDropOffLocationCityStateZipCodeSchema
>;

export const StickyAmbulanceDropOffLocationAddressSchema = z.object({
  ambulance_drop_off_address_line_01: z.string(),
  ambulance_drop_off_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type StickyAmbulanceDropOffLocationAddress = z.infer<
  typeof StickyAmbulanceDropOffLocationAddressSchema
>;

export const StickyAmbulanceDropOffLocationLoopSchema = z.object({
  ambulance_drop_off_location_address_N3: StickyAmbulanceDropOffLocationAddressSchema,
  ambulance_drop_off_location_city_state_zip_code_N4:
    StickyAmbulanceDropOffLocationCityStateZipCodeSchema,
  ambulance_drop_off_location_NM1: StickyAmbulanceDropOffLocationSchema,
});
export type StickyAmbulanceDropOffLocationLoop = z.infer<
  typeof StickyAmbulanceDropOffLocationLoopSchema
>;

export const StickyAmbulanceCertificationSchema = z.object({
  certification_condition_indicator_02: ProviderOrSupplierSignatureIndicator06_EnumSchema,
  code_category_01: AmbulanceCertificationCrcCodeCategory01Schema,
  condition_code_03: AmbulanceCertificationCrcConditionCode03Schema,
  condition_code_04: z.union([z.null(), z.string()]).optional(),
  condition_code_05: z.union([z.null(), z.string()]).optional(),
  condition_code_06: z.union([z.null(), z.string()]).optional(),
  condition_code_07: z.union([z.null(), z.string()]).optional(),
});
export type StickyAmbulanceCertification = z.infer<typeof StickyAmbulanceCertificationSchema>;

export const FluffyAdjustedRepricedLineItemReferenceNumberSchema = z.object({
  adjusted_repriced_line_item_reference_number_02: z.string(),
  reference_identification_qualifier_01:
    AdjustedRepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyAdjustedRepricedLineItemReferenceNumber = z.infer<
  typeof FluffyAdjustedRepricedLineItemReferenceNumberSchema
>;

export const FluffyServiceLineNumberLoopSchema = z.object({
  adjusted_repriced_line_item_reference_number_REF: z
    .union([FluffyAdjustedRepricedLineItemReferenceNumberSchema, z.null()])
    .optional(),
  ambulance_certification_CRC: z
    .union([z.array(StickyAmbulanceCertificationSchema), z.null()])
    .optional(),
  ambulance_drop_off_location_NM1_loop: z
    .union([StickyAmbulanceDropOffLocationLoopSchema, z.null()])
    .optional(),
  ambulance_patient_count_QTY: z.union([FluffyAmbulancePatientCountSchema, z.null()]).optional(),
  ambulance_pick_up_location_NM1_loop: z
    .union([StickyAmbulancePickUpLocationLoopSchema, z.null()])
    .optional(),
  ambulance_transport_information_CR1: z
    .union([StickyAmbulanceTransportInformationSchema, z.null()])
    .optional(),
  clinical_laboratory_improvement_amendment_clia_number_REF: z
    .union([StickyClinicalLaboratoryImprovementAmendmentCliaNumberSchema, z.null()])
    .optional(),
  condition_indicator_durable_medical_equipment_CRC: z
    .union([FluffyConditionIndicatorDurableMedicalEquipmentSchema, z.null()])
    .optional(),
  contract_information_CN1: z.union([StickyContractInformationSchema, z.null()]).optional(),
  date_begin_therapy_date_DTP: z.union([FluffyDateBeginTherapyDateSchema, z.null()]).optional(),
  date_certification_revision_recertification_date_DTP: z
    .union([FluffyDateCertificationRevisionRecertificationDateSchema, z.null()])
    .optional(),
  date_initial_treatment_date_DTP: z
    .union([StickyDateInitialTreatmentDateSchema, z.null()])
    .optional(),
  date_last_certification_date_DTP: z
    .union([FluffyDateLastCertificationDateSchema, z.null()])
    .optional(),
  date_last_seen_date_DTP: z.union([StickyDateLastSeenDateSchema, z.null()]).optional(),
  date_last_x_ray_date_DTP: z.union([StickyDateLastXRayDateSchema, z.null()]).optional(),
  date_prescription_date_DTP: z.union([FluffyDatePrescriptionDateSchema, z.null()]).optional(),
  date_service_date_DTP: FluffyDateServiceDateSchema,
  date_shipped_date_DTP: z.union([FluffyDateShippedDateSchema, z.null()]).optional(),
  date_test_date_DTP: z.union([z.array(FluffyDateTestDateSchema), z.null()]).optional(),
  drug_identification_LIN_loop: z.union([FluffyDrugIdentificationLoopSchema, z.null()]).optional(),
  durable_medical_equipment_certificate_of_medical_necessity_indicator_PWK: z
    .union([FluffyDurableMedicalEquipmentCertificateOfMedicalNecessityIndicatorSchema, z.null()])
    .optional(),
  durable_medical_equipment_certification_CR3: z
    .union([FluffyDurableMedicalEquipmentCertificationSchema, z.null()])
    .optional(),
  durable_medical_equipment_service_SV5: z
    .union([FluffyDurableMedicalEquipmentServiceSchema, z.null()])
    .optional(),
  file_information_K3: z.union([z.array(StickyFileInformationSchema), z.null()]).optional(),
  form_identification_code_LQ_loop: z
    .union([z.array(FluffyFormIdentificationCodeLoopSchema), z.null()])
    .optional(),
  hospice_employee_indicator_CRC: z
    .union([FluffyHospiceEmployeeIndicatorSchema, z.null()])
    .optional(),
  immunization_batch_number_REF: z
    .union([FluffyImmunizationBatchNumberSchema, z.null()])
    .optional(),
  line_adjudication_information_SVD_loop: z
    .union([z.array(FluffyLineAdjudicationInformationLoopSchema), z.null()])
    .optional(),
  line_item_control_number_REF: z.union([FluffyLineItemControlNumberSchema, z.null()]).optional(),
  line_note_NTE: z.union([FluffyLineNoteSchema, z.null()]).optional(),
  line_pricing_repricing_information_HCP: z
    .union([FluffyLinePricingRepricingInformationSchema, z.null()])
    .optional(),
  line_supplemental_information_PWK: z
    .union([z.array(FluffyLineSupplementalInformationSchema), z.null()])
    .optional(),
  mammography_certification_number_REF: z
    .union([StickyMammographyCertificationNumberSchema, z.null()])
    .optional(),
  obstetric_anesthesia_additional_units_QTY: z
    .union([FluffyObstetricAnesthesiaAdditionalUnitsSchema, z.null()])
    .optional(),
  ordering_provider_name_NM1_loop: z
    .union([FluffyOrderingProviderNameLoopSchema, z.null()])
    .optional(),
  postage_claimed_amount_AMT: z.union([FluffyPostageClaimedAmountSchema, z.null()]).optional(),
  prior_authorization_REF: z.union([z.array(StickyPriorAuthorizationSchema), z.null()]).optional(),
  professional_service_SV1: FluffyProfessionalServiceSchema,
  purchased_service_information_PS1: z
    .union([FluffyPurchasedServiceInformationSchema, z.null()])
    .optional(),
  purchased_service_provider_name_NM1_loop: z
    .union([FluffyPurchasedServiceProviderNameLoopSchema, z.null()])
    .optional(),
  referral_number_REF: z.union([z.array(StickyReferralNumberSchema), z.null()]).optional(),
  referring_clinical_laboratory_improvement_amendment_clia_facility_identification_REF: z
    .union([
      FluffyReferringClinicalLaboratoryImprovementAmendmentCliaFacilityIdentificationSchema,
      z.null(),
    ])
    .optional(),
  referring_provider_name_NM1_loop: z
    .union([z.array(StickyReferringProviderNameLoopSchema), z.null()])
    .optional(),
  rendering_provider_name_NM1_loop: z
    .union([StickyRenderingProviderNameLoopSchema, z.null()])
    .optional(),
  repriced_line_item_reference_number_REF: z
    .union([FluffyRepricedLineItemReferenceNumberSchema, z.null()])
    .optional(),
  sales_tax_amount_AMT: z.union([FluffySalesTaxAmountSchema, z.null()]).optional(),
  service_facility_location_name_NM1_loop: z
    .union([StickyServiceFacilityLocationNameLoopSchema, z.null()])
    .optional(),
  service_line_number_LX: FluffyServiceLineNumberSchema,
  supervising_provider_name_NM1_loop: z
    .union([TentacledSupervisingProviderNameLoopSchema, z.null()])
    .optional(),
  test_result_MEA: z.union([z.array(FluffyTestResultSchema), z.null()]).optional(),
  third_party_organization_notes_NTE: z
    .union([FluffyThirdPartyOrganizationNotesSchema, z.null()])
    .optional(),
});
export type FluffyServiceLineNumberLoop = z.infer<typeof FluffyServiceLineNumberLoopSchema>;

export const TentacledServiceFacilityLocationSecondaryIdentificationSchema = z.object({
  laboratory_or_facility_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerServiceFacilityLocationSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type TentacledServiceFacilityLocationSecondaryIdentification = z.infer<
  typeof TentacledServiceFacilityLocationSecondaryIdentificationSchema
>;

export const TentacledServiceFacilityLocationNameSchema = z.object({
  entity_identifier_code_01: OtherPayerServiceFacilityLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  laboratory_or_facility_name_03: z.string(),
  laboratory_or_facility_primary_identifier_09: z.union([z.null(), z.string()]).optional(),
});
export type TentacledServiceFacilityLocationName = z.infer<
  typeof TentacledServiceFacilityLocationNameSchema
>;

export const TentacledServiceFacilityLocationCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  laboratory_or_facility_city_name_01: z.string(),
  laboratory_or_facility_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  laboratory_or_facility_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
});
export type TentacledServiceFacilityLocationCityStateZipCode = z.infer<
  typeof TentacledServiceFacilityLocationCityStateZipCodeSchema
>;

export const TentacledServiceFacilityLocationAddressSchema = z.object({
  laboratory_or_facility_address_line_01: z.string(),
  laboratory_or_facility_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type TentacledServiceFacilityLocationAddress = z.infer<
  typeof TentacledServiceFacilityLocationAddressSchema
>;

export const FluffyServiceFacilityContactInformationSchema = z.object({
  communication_number_04: z.string(),
  communication_number_06: z.union([z.null(), z.string()]).optional(),
  communication_number_qualifier_03:
    ServiceFacilityContactInformationCommunicationNumberQualifier03Schema,
  communication_number_qualifier_05: z
    .union([ServiceFacilityContactInformationCommunicationNumberQualifier05Schema, z.null()])
    .optional(),
  contact_function_code_01: ContactFunctionCodeSchema,
  name_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyServiceFacilityContactInformation = z.infer<
  typeof FluffyServiceFacilityContactInformationSchema
>;

export const TentacledServiceFacilityLocationNameLoopSchema = z.object({
  service_facility_contact_information_PER: z
    .union([FluffyServiceFacilityContactInformationSchema, z.null()])
    .optional(),
  service_facility_location_address_N3: TentacledServiceFacilityLocationAddressSchema,
  service_facility_location_city_state_zip_code_N4:
    TentacledServiceFacilityLocationCityStateZipCodeSchema,
  service_facility_location_name_NM1: TentacledServiceFacilityLocationNameSchema,
  service_facility_location_secondary_identification_REF: z
    .union([z.array(TentacledServiceFacilityLocationSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type TentacledServiceFacilityLocationNameLoop = z.infer<
  typeof TentacledServiceFacilityLocationNameLoopSchema
>;

export const FluffyServiceAuthorizationExceptionCodeSchema = z.object({
  reference_identification_qualifier_01:
    ServiceAuthorizationExceptionCodeRefReferenceIdentificationQualifier01Schema,
  service_authorization_exception_code_02: z.string(),
});
export type FluffyServiceAuthorizationExceptionCode = z.infer<
  typeof FluffyServiceAuthorizationExceptionCodeSchema
>;

export const FluffyRepricedClaimNumberSchema = z.object({
  reference_identification_qualifier_01:
    RepricedClaimNumberRefReferenceIdentificationQualifier01Schema,
  repriced_claim_reference_number_02: z.string(),
});
export type FluffyRepricedClaimNumber = z.infer<typeof FluffyRepricedClaimNumberSchema>;

export const TentacledRenderingProviderSpecialtyInformationSchema = z.object({
  provider_code_01: ErCodeSchema,
  provider_taxonomy_code_03: z.string(),
  reference_identification_qualifier_02: ReferenceIdentificationQualifier02_EnumSchema,
});
export type TentacledRenderingProviderSpecialtyInformation = z.infer<
  typeof TentacledRenderingProviderSpecialtyInformationSchema
>;

export const TentacledRenderingProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerRenderingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  rendering_provider_secondary_identifier_02: z.string(),
});
export type TentacledRenderingProviderSecondaryIdentification = z.infer<
  typeof TentacledRenderingProviderSecondaryIdentificationSchema
>;

export const TentacledRenderingProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerRenderingProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  rendering_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  rendering_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  rendering_provider_last_or_organization_name_03: z.string(),
  rendering_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  rendering_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type TentacledRenderingProviderName = z.infer<typeof TentacledRenderingProviderNameSchema>;

export const TentacledRenderingProviderNameLoopSchema = z.object({
  rendering_provider_name_NM1: TentacledRenderingProviderNameSchema,
  rendering_provider_secondary_identification_REF: z
    .union([z.array(TentacledRenderingProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
  rendering_provider_specialty_information_PRV: z
    .union([TentacledRenderingProviderSpecialtyInformationSchema, z.null()])
    .optional(),
});
export type TentacledRenderingProviderNameLoop = z.infer<
  typeof TentacledRenderingProviderNameLoopSchema
>;

export const TentacledReferringProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  referring_provider_secondary_identifier_02: z.string(),
});
export type TentacledReferringProviderSecondaryIdentification = z.infer<
  typeof TentacledReferringProviderSecondaryIdentificationSchema
>;

export const TentacledReferringProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerReferringProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherPayerReferringProviderNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  referring_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  referring_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  referring_provider_last_name_03: z.string(),
  referring_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  referring_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type TentacledReferringProviderName = z.infer<typeof TentacledReferringProviderNameSchema>;

export const TentacledReferringProviderNameLoopSchema = z.object({
  referring_provider_name_NM1: TentacledReferringProviderNameSchema,
  referring_provider_secondary_identification_REF: z
    .union([z.array(TentacledReferringProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type TentacledReferringProviderNameLoop = z.infer<
  typeof TentacledReferringProviderNameLoopSchema
>;

export const TentacledReferralNumberSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerReferralNumberRefReferenceIdentificationQualifier01Schema,
  referral_number_02: z.string(),
});
export type TentacledReferralNumber = z.infer<typeof TentacledReferralNumberSchema>;

export const TentacledPriorAuthorizationSchema = z.object({
  prior_authorization_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerPriorAuthorizationNumberRefReferenceIdentificationQualifier01Schema,
});
export type TentacledPriorAuthorization = z.infer<typeof TentacledPriorAuthorizationSchema>;

export const FluffyPayerClaimControlNumberSchema = z.object({
  payer_claim_control_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerClaimControlNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyPayerClaimControlNumber = z.infer<typeof FluffyPayerClaimControlNumberSchema>;

export const FluffyPatientConditionInformationVisionSchema = z.object({
  certification_condition_indicator_02: ProviderOrSupplierSignatureIndicator06_EnumSchema,
  code_category_01: PatientConditionInformationVisionCrcCodeCategory01Schema,
  condition_code_03: PatientConditionInformationVisionCrcConditionCode03Schema,
  condition_code_04: z.union([z.null(), z.string()]).optional(),
  condition_code_05: z.union([z.null(), z.string()]).optional(),
  condition_code_06: z.union([z.null(), z.string()]).optional(),
  condition_code_07: z.union([z.null(), z.string()]).optional(),
});
export type FluffyPatientConditionInformationVision = z.infer<
  typeof FluffyPatientConditionInformationVisionSchema
>;

export const FluffyPatientAmountPaidSchema = z.object({
  amount_qualifier_code_01: ReferenceIdentificationQualifierSchema,
  patient_amount_paid_02: z.number(),
});
export type FluffyPatientAmountPaid = z.infer<typeof FluffyPatientAmountPaidSchema>;

export const TentacledRemainingPatientLiabilitySchema = z.object({
  amount_qualifier_code_01: RemainingPatientLiabilityAmtAmountQualifierCode01Schema,
  remaining_patient_liability_02: z.number(),
});
export type TentacledRemainingPatientLiability = z.infer<
  typeof TentacledRemainingPatientLiabilitySchema
>;

export const FluffyOutpatientAdjudicationInformationSchema = z.object({
  claim_payment_remark_code_03: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_04: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_05: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_06: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_07: z.union([z.null(), z.string()]).optional(),
  end_stage_renal_disease_payment_amount_08: z.union([z.number(), z.null()]).optional(),
  hcpcs_payable_amount_02: z.union([z.number(), z.null()]).optional(),
  non_payable_professional_component_billed_amount_09: z.union([z.number(), z.null()]).optional(),
  reimbursement_rate_01: z.union([z.number(), z.null()]).optional(),
});
export type FluffyOutpatientAdjudicationInformation = z.infer<
  typeof FluffyOutpatientAdjudicationInformationSchema
>;

export const FluffyOtherSubscriberSecondaryIdentificationSchema = z.object({
  other_insured_additional_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherSubscriberSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherSubscriberSecondaryIdentification = z.infer<
  typeof FluffyOtherSubscriberSecondaryIdentificationSchema
>;

export const FluffyOtherSubscriberNameSchema = z.object({
  entity_identifier_code_01: OtherSubscriberNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: OtherSubscriberNameNm1IdentificationCodeQualifier08Schema,
  other_insured_first_name_04: z.union([z.null(), z.string()]).optional(),
  other_insured_identifier_09: z.string(),
  other_insured_last_name_03: z.string(),
  other_insured_middle_name_05: z.union([z.null(), z.string()]).optional(),
  other_insured_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type FluffyOtherSubscriberName = z.infer<typeof FluffyOtherSubscriberNameSchema>;

export const FluffyOtherSubscriberCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  other_subscriber_city_name_01: z.string(),
  other_subscriber_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  other_subscriber_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyOtherSubscriberCityStateZipCode = z.infer<
  typeof FluffyOtherSubscriberCityStateZipCodeSchema
>;

export const FluffyOtherSubscriberAddressSchema = z.object({
  other_insured_address_line_02: z.union([z.null(), z.string()]).optional(),
  other_subscriber_address_line_01: z.string(),
});
export type FluffyOtherSubscriberAddress = z.infer<typeof FluffyOtherSubscriberAddressSchema>;

export const FluffyOtherSubscriberNameLoopSchema = z.object({
  other_subscriber_address_N3: z.union([FluffyOtherSubscriberAddressSchema, z.null()]).optional(),
  other_subscriber_city_state_zip_code_N4: z
    .union([FluffyOtherSubscriberCityStateZipCodeSchema, z.null()])
    .optional(),
  other_subscriber_name_NM1: FluffyOtherSubscriberNameSchema,
  other_subscriber_secondary_identification_REF: z
    .union([FluffyOtherSubscriberSecondaryIdentificationSchema, z.null()])
    .optional(),
});
export type FluffyOtherSubscriberNameLoop = z.infer<typeof FluffyOtherSubscriberNameLoopSchema>;

export const FluffyOtherSubscriberInformationSchema = z.object({
  claim_filing_indicator_code_09: ClaimFilingIndicatorCodeSchema,
  individual_relationship_code_02: OtherSubscriberInformationSbrIndividualRelationshipCode02Schema,
  insurance_type_code_05: z.union([InsuranceTypeCodeSchema, z.null()]).optional(),
  insured_group_or_policy_number_03: z.union([z.null(), z.string()]).optional(),
  other_insured_group_name_04: z.union([z.null(), z.string()]).optional(),
  payer_responsibility_sequence_number_code_01: PayerResponsibilitySequenceNumberCodeSchema,
});
export type FluffyOtherSubscriberInformation = z.infer<
  typeof FluffyOtherSubscriberInformationSchema
>;

export const FluffyOtherPayerSupervisingProviderSecondaryIdentificationSchema = z.object({
  other_payer_supervising_provider_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerRenderingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerSupervisingProviderSecondaryIdentification = z.infer<
  typeof FluffyOtherPayerSupervisingProviderSecondaryIdentificationSchema
>;

export const FluffyOtherPayerSupervisingProviderSchema = z.object({
  entity_identifier_code_01: OtherPayerSupervisingProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherPayerReferringProviderNm1EntityTypeQualifier02Schema,
});
export type FluffyOtherPayerSupervisingProvider = z.infer<
  typeof FluffyOtherPayerSupervisingProviderSchema
>;

export const FluffyOtherPayerSupervisingProviderLoopSchema = z.object({
  other_payer_supervising_provider_NM1: FluffyOtherPayerSupervisingProviderSchema,
  other_payer_supervising_provider_secondary_identification_REF: z.array(
    FluffyOtherPayerSupervisingProviderSecondaryIdentificationSchema
  ),
});
export type FluffyOtherPayerSupervisingProviderLoop = z.infer<
  typeof FluffyOtherPayerSupervisingProviderLoopSchema
>;

export const FluffyOtherPayerServiceFacilityLocationSecondaryIdentificationSchema = z.object({
  other_payer_service_facility_location_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerServiceFacilityLocationSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerServiceFacilityLocationSecondaryIdentification = z.infer<
  typeof FluffyOtherPayerServiceFacilityLocationSecondaryIdentificationSchema
>;

export const FluffyOtherPayerServiceFacilityLocationSchema = z.object({
  entity_identifier_code_01: OtherPayerServiceFacilityLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
});
export type FluffyOtherPayerServiceFacilityLocation = z.infer<
  typeof FluffyOtherPayerServiceFacilityLocationSchema
>;

export const FluffyOtherPayerServiceFacilityLocationLoopSchema = z.object({
  other_payer_service_facility_location_NM1: FluffyOtherPayerServiceFacilityLocationSchema,
  other_payer_service_facility_location_secondary_identification_REF: z.array(
    FluffyOtherPayerServiceFacilityLocationSecondaryIdentificationSchema
  ),
});
export type FluffyOtherPayerServiceFacilityLocationLoop = z.infer<
  typeof FluffyOtherPayerServiceFacilityLocationLoopSchema
>;

export const FluffyOtherPayerRenderingProviderSecondaryIdentificationSchema = z.object({
  other_payer_rendering_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerRenderingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerRenderingProviderSecondaryIdentification = z.infer<
  typeof FluffyOtherPayerRenderingProviderSecondaryIdentificationSchema
>;

export const FluffyOtherPayerRenderingProviderSchema = z.object({
  entity_identifier_code_01: OtherPayerRenderingProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
});
export type FluffyOtherPayerRenderingProvider = z.infer<
  typeof FluffyOtherPayerRenderingProviderSchema
>;

export const FluffyOtherPayerRenderingProviderLoopSchema = z.object({
  other_payer_rendering_provider_NM1: FluffyOtherPayerRenderingProviderSchema,
  other_payer_rendering_provider_secondary_identification_REF: z.array(
    FluffyOtherPayerRenderingProviderSecondaryIdentificationSchema
  ),
});
export type FluffyOtherPayerRenderingProviderLoop = z.infer<
  typeof FluffyOtherPayerRenderingProviderLoopSchema
>;

export const FluffyOtherPayerReferringProviderSecondaryIdentificationSchema = z.object({
  other_payer_referring_provider_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerReferringProviderSecondaryIdentification = z.infer<
  typeof FluffyOtherPayerReferringProviderSecondaryIdentificationSchema
>;

export const FluffyOtherPayerReferringProviderSchema = z.object({
  entity_identifier_code_01: OtherPayerReferringProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherPayerReferringProviderNm1EntityTypeQualifier02Schema,
});
export type FluffyOtherPayerReferringProvider = z.infer<
  typeof FluffyOtherPayerReferringProviderSchema
>;

export const FluffyOtherPayerReferringProviderLoopSchema = z.object({
  other_payer_referring_provider_NM1: FluffyOtherPayerReferringProviderSchema,
  other_payer_referring_provider_secondary_identification_REF: z.array(
    FluffyOtherPayerReferringProviderSecondaryIdentificationSchema
  ),
});
export type FluffyOtherPayerReferringProviderLoop = z.infer<
  typeof FluffyOtherPayerReferringProviderLoopSchema
>;

export const FluffyOtherPayerSecondaryIdentifierSchema = z.object({
  other_payer_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerSecondaryIdentifierRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerSecondaryIdentifier = z.infer<
  typeof FluffyOtherPayerSecondaryIdentifierSchema
>;

export const FluffyOtherPayerReferralNumberSchema = z.object({
  other_payer_prior_authorization_or_referral_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerReferralNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerReferralNumber = z.infer<typeof FluffyOtherPayerReferralNumberSchema>;

export const FluffyOtherPayerPriorAuthorizationNumberSchema = z.object({
  other_payer_prior_authorization_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerPriorAuthorizationNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerPriorAuthorizationNumber = z.infer<
  typeof FluffyOtherPayerPriorAuthorizationNumberSchema
>;

export const FluffyOtherPayerNameSchema = z.object({
  entity_identifier_code_01: OtherPayerNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: PayToPlanNameNm1IdentificationCodeQualifier08Schema,
  other_payer_organization_name_03: z.string(),
  other_payer_primary_identifier_09: z.string(),
});
export type FluffyOtherPayerName = z.infer<typeof FluffyOtherPayerNameSchema>;

export const FluffyOtherPayerClaimControlNumberSchema = z.object({
  other_payers_claim_control_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerClaimControlNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerClaimControlNumber = z.infer<
  typeof FluffyOtherPayerClaimControlNumberSchema
>;

export const FluffyOtherPayerClaimAdjustmentIndicatorSchema = z.object({
  other_payer_claim_adjustment_indicator_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerClaimAdjustmentIndicatorRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerClaimAdjustmentIndicator = z.infer<
  typeof FluffyOtherPayerClaimAdjustmentIndicatorSchema
>;

export const FluffyOtherPayerCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  other_payer_city_name_01: z.string(),
  other_payer_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  other_payer_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyOtherPayerCityStateZipCode = z.infer<
  typeof FluffyOtherPayerCityStateZipCodeSchema
>;

export const FluffyOtherPayerAddressSchema = z.object({
  other_payer_address_line_01: z.string(),
  other_payer_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyOtherPayerAddress = z.infer<typeof FluffyOtherPayerAddressSchema>;

export const FluffyClaimCheckOrRemittanceDateSchema = z.object({
  adjudication_or_payment_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: ClaimCheckOrRemittanceDateDtpDateTimeQualifier01Schema,
});
export type FluffyClaimCheckOrRemittanceDate = z.infer<
  typeof FluffyClaimCheckOrRemittanceDateSchema
>;

export const FluffyOtherPayerNameLoopSchema = z.object({
  claim_check_or_remittance_date_DTP: z
    .union([FluffyClaimCheckOrRemittanceDateSchema, z.null()])
    .optional(),
  other_payer_address_N3: z.union([FluffyOtherPayerAddressSchema, z.null()]).optional(),
  other_payer_city_state_zip_code_N4: z
    .union([FluffyOtherPayerCityStateZipCodeSchema, z.null()])
    .optional(),
  other_payer_claim_adjustment_indicator_REF: z
    .union([FluffyOtherPayerClaimAdjustmentIndicatorSchema, z.null()])
    .optional(),
  other_payer_claim_control_number_REF: z
    .union([FluffyOtherPayerClaimControlNumberSchema, z.null()])
    .optional(),
  other_payer_name_NM1: FluffyOtherPayerNameSchema,
  other_payer_prior_authorization_number_REF: z
    .union([FluffyOtherPayerPriorAuthorizationNumberSchema, z.null()])
    .optional(),
  other_payer_referral_number_REF: z
    .union([FluffyOtherPayerReferralNumberSchema, z.null()])
    .optional(),
  other_payer_secondary_identifier_REF: z
    .union([z.array(FluffyOtherPayerSecondaryIdentifierSchema), z.null()])
    .optional(),
});
export type FluffyOtherPayerNameLoop = z.infer<typeof FluffyOtherPayerNameLoopSchema>;

export const FluffyOtherPayerBillingProviderSecondaryIdentificationSchema = z.object({
  other_payer_billing_provider_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerBillingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerBillingProviderSecondaryIdentification = z.infer<
  typeof FluffyOtherPayerBillingProviderSecondaryIdentificationSchema
>;

export const FluffyOtherPayerBillingProviderSchema = z.object({
  entity_identifier_code_01: BillingProviderNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
});
export type FluffyOtherPayerBillingProvider = z.infer<typeof FluffyOtherPayerBillingProviderSchema>;

export const FluffyOtherPayerBillingProviderLoopSchema = z.object({
  other_payer_billing_provider_NM1: FluffyOtherPayerBillingProviderSchema,
  other_payer_billing_provider_secondary_identification_REF: z.array(
    FluffyOtherPayerBillingProviderSecondaryIdentificationSchema
  ),
});
export type FluffyOtherPayerBillingProviderLoop = z.infer<
  typeof FluffyOtherPayerBillingProviderLoopSchema
>;

export const FluffyOtherInsuranceCoverageInformationSchema = z.object({
  benefits_assignment_certification_indicator_03: BenefitsAssignmentCertificationIndicatorSchema,
  patient_signature_source_code_04: z
    .union([PatientSignatureSourceCodeSchema, z.null()])
    .optional(),
  release_of_information_code_06: ReleaseOfInformationCodeSchema,
});
export type FluffyOtherInsuranceCoverageInformation = z.infer<
  typeof FluffyOtherInsuranceCoverageInformationSchema
>;

export const FluffyCoordinationOfBenefitsCobTotalNonCoveredAmountSchema = z.object({
  amount_qualifier_code_01:
    CoordinationOfBenefitsCobTotalNonCoveredAmountAmtAmountQualifierCode01Schema,
  non_covered_charge_amount_02: z.number(),
});
export type FluffyCoordinationOfBenefitsCobTotalNonCoveredAmount = z.infer<
  typeof FluffyCoordinationOfBenefitsCobTotalNonCoveredAmountSchema
>;

export const FluffyCoordinationOfBenefitsCobPayerPaidAmountSchema = z.object({
  amount_qualifier_code_01: CoordinationOfBenefitsCobPayerPaidAmountAmtAmountQualifierCode01Schema,
  payer_paid_amount_02: z.number(),
});
export type FluffyCoordinationOfBenefitsCobPayerPaidAmount = z.infer<
  typeof FluffyCoordinationOfBenefitsCobPayerPaidAmountSchema
>;

export const FluffyClaimLevelAdjustmentsSchema = z.object({
  adjustment_amount_03: z.number(),
  adjustment_amount_06: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_09: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_12: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_15: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_18: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_04: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_07: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_10: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_13: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_16: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_19: z.union([z.number(), z.null()]).optional(),
  adjustment_reason_code_02: z.string(),
  adjustment_reason_code_05: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_08: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_11: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_14: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_17: z.union([z.null(), z.string()]).optional(),
  claim_adjustment_group_code_01: ClaimAdjustmentGroupCodeSchema,
});
export type FluffyClaimLevelAdjustments = z.infer<typeof FluffyClaimLevelAdjustmentsSchema>;

export const FluffyOtherSubscriberInformationLoopSchema = z.object({
  claim_level_adjustments_CAS: z
    .union([z.array(FluffyClaimLevelAdjustmentsSchema), z.null()])
    .optional(),
  coordination_of_benefits_cob_payer_paid_amount_AMT: z
    .union([FluffyCoordinationOfBenefitsCobPayerPaidAmountSchema, z.null()])
    .optional(),
  coordination_of_benefits_cob_total_non_covered_amount_AMT: z
    .union([FluffyCoordinationOfBenefitsCobTotalNonCoveredAmountSchema, z.null()])
    .optional(),
  other_insurance_coverage_information_OI: FluffyOtherInsuranceCoverageInformationSchema,
  other_payer_billing_provider_NM1_loop: z
    .union([FluffyOtherPayerBillingProviderLoopSchema, z.null()])
    .optional(),
  other_payer_name_NM1_loop: FluffyOtherPayerNameLoopSchema,
  other_payer_referring_provider_NM1_loop: z
    .union([z.array(FluffyOtherPayerReferringProviderLoopSchema), z.null()])
    .optional(),
  other_payer_rendering_provider_NM1_loop: z
    .union([FluffyOtherPayerRenderingProviderLoopSchema, z.null()])
    .optional(),
  other_payer_service_facility_location_NM1_loop: z
    .union([FluffyOtherPayerServiceFacilityLocationLoopSchema, z.null()])
    .optional(),
  other_payer_supervising_provider_NM1_loop: z
    .union([FluffyOtherPayerSupervisingProviderLoopSchema, z.null()])
    .optional(),
  other_subscriber_information_SBR: FluffyOtherSubscriberInformationSchema,
  other_subscriber_name_NM1_loop: FluffyOtherSubscriberNameLoopSchema,
  outpatient_adjudication_information_MOA: z
    .union([FluffyOutpatientAdjudicationInformationSchema, z.null()])
    .optional(),
  remaining_patient_liability_AMT: z
    .union([TentacledRemainingPatientLiabilitySchema, z.null()])
    .optional(),
});
export type FluffyOtherSubscriberInformationLoop = z.infer<
  typeof FluffyOtherSubscriberInformationLoopSchema
>;

export const FluffyMedicalRecordNumberSchema = z.object({
  medical_record_number_02: z.string(),
  reference_identification_qualifier_01:
    MedicalRecordNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyMedicalRecordNumber = z.infer<typeof FluffyMedicalRecordNumberSchema>;

export const FluffyMandatoryMedicareSection4081CrossoverIndicatorSchema = z.object({
  medicare_section_4081_indicator_02: z.string(),
  reference_identification_qualifier_01: ReferenceIdentificationQualifierSchema,
});
export type FluffyMandatoryMedicareSection4081CrossoverIndicator = z.infer<
  typeof FluffyMandatoryMedicareSection4081CrossoverIndicatorSchema
>;

export const TentacledMammographyCertificationNumberSchema = z.object({
  mammography_certification_number_02: z.string(),
  reference_identification_qualifier_01:
    MammographyCertificationNumberRefReferenceIdentificationQualifier01Schema,
});
export type TentacledMammographyCertificationNumber = z.infer<
  typeof TentacledMammographyCertificationNumberSchema
>;

export const FluffyInvestigationalDeviceExemptionNumberSchema = z.object({
  investigational_device_exemption_identifier_02: z.string(),
  reference_identification_qualifier_01:
    InvestigationalDeviceExemptionNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyInvestigationalDeviceExemptionNumber = z.infer<
  typeof FluffyInvestigationalDeviceExemptionNumberSchema
>;

export const FluffyHomeboundIndicatorSchema = z.object({
  certification_condition_indicator_02: PregnancyIndicator09_EnumSchema,
  code_category_01: HomeboundIndicatorCrcCodeCategory01Schema,
  homebound_indicator_03: HomeboundIndicator03_EnumSchema,
});
export type FluffyHomeboundIndicator = z.infer<typeof FluffyHomeboundIndicatorSchema>;

export const HealthCareCodeInformation39Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation39 = z.infer<typeof HealthCareCodeInformation39Schema>;

export const HealthCareCodeInformation38Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation38 = z.infer<typeof HealthCareCodeInformation38Schema>;

export const HealthCareCodeInformation37Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation37 = z.infer<typeof HealthCareCodeInformation37Schema>;

export const HealthCareCodeInformation36Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation36 = z.infer<typeof HealthCareCodeInformation36Schema>;

export const HealthCareCodeInformation35Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation35 = z.infer<typeof HealthCareCodeInformation35Schema>;

export const HealthCareCodeInformation34Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation34 = z.infer<typeof HealthCareCodeInformation34Schema>;

export const HealthCareCodeInformation33Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation33 = z.infer<typeof HealthCareCodeInformation33Schema>;

export const HealthCareCodeInformation32Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation32 = z.infer<typeof HealthCareCodeInformation32Schema>;

export const HealthCareCodeInformation31Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation31 = z.infer<typeof HealthCareCodeInformation31Schema>;

export const HealthCareCodeInformation30Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation30 = z.infer<typeof HealthCareCodeInformation30Schema>;

export const HealthCareCodeInformation29Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation29 = z.infer<typeof HealthCareCodeInformation29Schema>;

export const HealthCareCodeInformation28Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation01_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation28 = z.infer<typeof HealthCareCodeInformation28Schema>;

export const FluffyHealthCareDiagnosisCodeSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation28Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation29Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation30Schema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([HealthCareCodeInformation31Schema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([HealthCareCodeInformation32Schema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([HealthCareCodeInformation33Schema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([HealthCareCodeInformation34Schema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([HealthCareCodeInformation35Schema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation36Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation37Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation38Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation39Schema, z.null()])
    .optional(),
});
export type FluffyHealthCareDiagnosisCode = z.infer<typeof FluffyHealthCareDiagnosisCodeSchema>;

export const TentacledFileInformationSchema = z.object({
  fixed_format_information_01: z.string(),
});
export type TentacledFileInformation = z.infer<typeof TentacledFileInformationSchema>;

export const FluffyEpsdtReferralSchema = z.object({
  certification_condition_code_applies_indicator_02:
    ProviderOrSupplierSignatureIndicator06_EnumSchema,
  code_qualifier_01: EpsdtReferralCrcCodeQualifier01Schema,
  condition_indicator_03: EpsdtReferralCrcConditionIndicator03Schema,
  condition_indicator_04: z.union([z.null(), z.string()]).optional(),
  condition_indicator_05: z.union([z.null(), z.string()]).optional(),
});
export type FluffyEpsdtReferral = z.infer<typeof FluffyEpsdtReferralSchema>;

export const FluffyDemonstrationProjectIdentifierSchema = z.object({
  demonstration_project_identifier_02: z.string(),
  reference_identification_qualifier_01:
    DemonstrationProjectIdentifierRefReferenceIdentificationQualifier01Schema,
});
export type FluffyDemonstrationProjectIdentifier = z.infer<
  typeof FluffyDemonstrationProjectIdentifierSchema
>;

export const FluffyDateRepricerReceivedDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateRepricerReceivedDateDtpDateTimeQualifier01Schema,
  repricer_received_date_03: z.string(),
});
export type FluffyDateRepricerReceivedDate = z.infer<typeof FluffyDateRepricerReceivedDateSchema>;

export const FluffyDatePropertyAndCasualtyDateOfFirstContactSchema = z.object({
  date_time_period_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DatePropertyAndCasualtyDateOfFirstContactDtpDateTimeQualifier01Schema,
});
export type FluffyDatePropertyAndCasualtyDateOfFirstContact = z.infer<
  typeof FluffyDatePropertyAndCasualtyDateOfFirstContactSchema
>;

export const FluffyDateOnsetOfCurrentIllnessOrSymptomSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateOnsetOfCurrentIllnessOrSymptomDtpDateTimeQualifier01Schema,
  onset_of_current_illness_or_injury_date_03: z.string(),
});
export type FluffyDateOnsetOfCurrentIllnessOrSymptom = z.infer<
  typeof FluffyDateOnsetOfCurrentIllnessOrSymptomSchema
>;

export const TentacledDateLastXRayDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateLastXRayDateDtpDateTimeQualifier01Schema,
  last_x_ray_date_03: z.string(),
});
export type TentacledDateLastXRayDate = z.infer<typeof TentacledDateLastXRayDateSchema>;

export const FluffyDateLastWorkedSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateLastWorkedDtpDateTimeQualifier01Schema,
  last_worked_date_03: z.string(),
});
export type FluffyDateLastWorked = z.infer<typeof FluffyDateLastWorkedSchema>;

export const TentacledDateLastSeenDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateLastSeenDateDtpDateTimeQualifier01Schema,
  last_seen_date_03: z.string(),
});
export type TentacledDateLastSeenDate = z.infer<typeof TentacledDateLastSeenDateSchema>;

export const FluffyDateLastMenstrualPeriodSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateLastMenstrualPeriodDtpDateTimeQualifier01Schema,
  last_menstrual_period_date_03: z.string(),
});
export type FluffyDateLastMenstrualPeriod = z.infer<typeof FluffyDateLastMenstrualPeriodSchema>;

export const TentacledDateInitialTreatmentDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateInitialTreatmentDateDtpDateTimeQualifier01Schema,
  initial_treatment_date_03: z.string(),
});
export type TentacledDateInitialTreatmentDate = z.infer<
  typeof TentacledDateInitialTreatmentDateSchema
>;

export const FluffyDateHearingAndVisionPrescriptionDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateHearingAndVisionPrescriptionDateDtpDateTimeQualifier01Schema,
  prescription_date_03: z.string(),
});
export type FluffyDateHearingAndVisionPrescriptionDate = z.infer<
  typeof FluffyDateHearingAndVisionPrescriptionDateSchema
>;

export const FluffyDateDischargeSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateDischargeDtpDateTimeQualifier01Schema,
  related_hospitalization_discharge_date_03: z.string(),
});
export type FluffyDateDischarge = z.infer<typeof FluffyDateDischargeSchema>;

export const FluffyDateDisabilityDatesSchema = z.object({
  date_time_period_format_qualifier_02: DateDisabilityDatesDtpDateTimePeriodFormatQualifier02Schema,
  date_time_qualifier_01: DateDisabilityDatesDtpDateTimeQualifier01Schema,
  disability_from_date_03: z.string(),
});
export type FluffyDateDisabilityDates = z.infer<typeof FluffyDateDisabilityDatesSchema>;

export const FluffyDateAuthorizedReturnToWorkSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateAuthorizedReturnToWorkDtpDateTimeQualifier01Schema,
  work_return_date_03: z.string(),
});
export type FluffyDateAuthorizedReturnToWork = z.infer<
  typeof FluffyDateAuthorizedReturnToWorkSchema
>;

export const FluffyDateAssumedAndRelinquishedCareDatesSchema = z.object({
  assumed_or_relinquished_care_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateAssumedAndRelinquishedCareDatesDtpDateTimeQualifier01Schema,
});
export type FluffyDateAssumedAndRelinquishedCareDates = z.infer<
  typeof FluffyDateAssumedAndRelinquishedCareDatesSchema
>;

export const FluffyDateAdmissionSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateAdmissionDtpDateTimeQualifier01Schema,
  related_hospitalization_admission_date_03: z.string(),
});
export type FluffyDateAdmission = z.infer<typeof FluffyDateAdmissionSchema>;

export const FluffyDateAcuteManifestationSchema = z.object({
  acute_manifestation_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateAcuteManifestationDtpDateTimeQualifier01Schema,
});
export type FluffyDateAcuteManifestation = z.infer<typeof FluffyDateAcuteManifestationSchema>;

export const FluffyDateAccidentSchema = z.object({
  accident_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateAccidentDtpDateTimeQualifier01Schema,
});
export type FluffyDateAccident = z.infer<typeof FluffyDateAccidentSchema>;

export const TentacledContractInformationSchema = z.object({
  contract_amount_02: z.union([z.number(), z.null()]).optional(),
  contract_code_04: z.union([z.null(), z.string()]).optional(),
  contract_percentage_03: z.union([z.number(), z.null()]).optional(),
  contract_type_code_01: ContractTypeCodeSchema,
  contract_version_identifier_06: z.union([z.null(), z.string()]).optional(),
  terms_discount_percentage_05: z.union([z.number(), z.null()]).optional(),
});
export type TentacledContractInformation = z.infer<typeof TentacledContractInformationSchema>;

export const HealthCareCodeInformation27Schema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation27 = z.infer<typeof HealthCareCodeInformation27Schema>;

export const HealthCareCodeInformation26Schema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation26 = z.infer<typeof HealthCareCodeInformation26Schema>;

export const HealthCareCodeInformation25Schema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation25 = z.infer<typeof HealthCareCodeInformation25Schema>;

export const HealthCareCodeInformation24Schema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation24 = z.infer<typeof HealthCareCodeInformation24Schema>;

export const HealthCareCodeInformation23Schema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation23 = z.infer<typeof HealthCareCodeInformation23Schema>;

export const HealthCareCodeInformation22Schema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation22 = z.infer<typeof HealthCareCodeInformation22Schema>;

export const HealthCareCodeInformation21Schema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation21 = z.infer<typeof HealthCareCodeInformation21Schema>;

export const HealthCareCodeInformation20Schema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation20 = z.infer<typeof HealthCareCodeInformation20Schema>;

export const HealthCareCodeInformation19Schema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation19 = z.infer<typeof HealthCareCodeInformation19Schema>;

export const HealthCareCodeInformation18Schema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation18 = z.infer<typeof HealthCareCodeInformation18Schema>;

export const HealthCareCodeInformation17Schema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation17 = z.infer<typeof HealthCareCodeInformation17Schema>;

export const HealthCareCodeInformation16Schema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation16 = z.infer<typeof HealthCareCodeInformation16Schema>;

export const FluffyConditionInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation16Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation17Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation18Schema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([HealthCareCodeInformation19Schema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([HealthCareCodeInformation20Schema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([HealthCareCodeInformation21Schema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([HealthCareCodeInformation22Schema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([HealthCareCodeInformation23Schema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation24Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation25Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation26Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation27Schema, z.null()])
    .optional(),
});
export type FluffyConditionInformation = z.infer<typeof FluffyConditionInformationSchema>;

export const TentacledClinicalLaboratoryImprovementAmendmentCliaNumberSchema = z.object({
  clinical_laboratory_improvement_amendment_number_02: z.string(),
  reference_identification_qualifier_01:
    ClinicalLaboratoryImprovementAmendmentCliaNumberRefReferenceIdentificationQualifier01Schema,
});
export type TentacledClinicalLaboratoryImprovementAmendmentCliaNumber = z.infer<
  typeof TentacledClinicalLaboratoryImprovementAmendmentCliaNumberSchema
>;

export const FluffyClaimSupplementalInformationSchema = z.object({
  attachment_control_number_06: z.union([z.null(), z.string()]).optional(),
  attachment_report_type_code_01: ClaimSupplementalInformationAttachmentReportTypeCode01Schema,
  attachment_transmission_code_02: ClaimSupplementalInformationAttachmentTransmissionCode02Schema,
  identification_code_qualifier_05: z
    .union([IdentificationCodeQualifier05_EnumSchema, z.null()])
    .optional(),
});
export type FluffyClaimSupplementalInformation = z.infer<
  typeof FluffyClaimSupplementalInformationSchema
>;

export const FluffyClaimPricingRepricingInformationSchema = z.object({
  exception_code_15: z.union([ExceptionCodeSchema, z.null()]).optional(),
  policy_compliance_code_14: z.union([PolicyComplianceCodeSchema, z.null()]).optional(),
  pricing_methodology_01: ClaimPricingRepricingInformationPricingMethodology01Schema,
  reject_reason_code_13: z.union([RejectReasonCodeSchema, z.null()]).optional(),
  repriced_allowed_amount_02: z.number(),
  repriced_approved_ambulatory_patient_group_amount_07: z.union([z.number(), z.null()]).optional(),
  repriced_approved_ambulatory_patient_group_code_06: z.union([z.null(), z.string()]).optional(),
  repriced_saving_amount_03: z.union([z.number(), z.null()]).optional(),
  repricing_organization_identifier_04: z.union([z.null(), z.string()]).optional(),
  repricing_per_diem_or_flat_rate_amount_05: z.union([z.number(), z.null()]).optional(),
});
export type FluffyClaimPricingRepricingInformation = z.infer<
  typeof FluffyClaimPricingRepricingInformationSchema
>;

export const FluffyClaimNoteSchema = z.object({
  claim_note_text_02: z.string(),
  note_reference_code_01: ClaimNoteNteNoteReferenceCode01Schema,
});
export type FluffyClaimNote = z.infer<typeof FluffyClaimNoteSchema>;

export const FluffyRelatedCausesInformationSchema = z.object({
  auto_accident_state_or_province_code_04: z.union([z.null(), z.string()]).optional(),
  country_code_05: z.union([z.null(), z.string()]).optional(),
  related_causes_code_01: RelatedCausesCodeSchema,
  related_causes_code_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyRelatedCausesInformation = z.infer<typeof FluffyRelatedCausesInformationSchema>;

export const FluffyHealthCareServiceLocationInformationSchema = z.object({
  claim_frequency_code_03: z.string(),
  facility_code_qualifier_02: FacilityCodeQualifierSchema,
  place_of_service_code_01: z.string(),
});
export type FluffyHealthCareServiceLocationInformation = z.infer<
  typeof FluffyHealthCareServiceLocationInformationSchema
>;

export const FluffyClaimInformationSchema = z.object({
  assignment_or_plan_participation_code_07: AssignmentOrPlanParticipationCodeSchema,
  benefits_assignment_certification_indicator_08: BenefitsAssignmentCertificationIndicatorSchema,
  delay_reason_code_20: z.union([DelayReasonCodeSchema, z.null()]).optional(),
  health_care_service_location_information_05: FluffyHealthCareServiceLocationInformationSchema,
  patient_control_number_01: z.string(),
  patient_signature_source_code_10: z
    .union([PatientSignatureSourceCodeSchema, z.null()])
    .optional(),
  provider_or_supplier_signature_indicator_06: ProviderOrSupplierSignatureIndicator06_EnumSchema,
  related_causes_information_11: z
    .union([FluffyRelatedCausesInformationSchema, z.null()])
    .optional(),
  release_of_information_code_09: ReleaseOfInformationCodeSchema,
  special_program_indicator_12: z.union([SpecialProgramIndicatorSchema, z.null()]).optional(),
  total_claim_charge_amount_02: z.number(),
});
export type FluffyClaimInformation = z.infer<typeof FluffyClaimInformationSchema>;

export const FluffyClaimIdentifierForTransmissionIntermediariesSchema = z.object({
  reference_identification_qualifier_01:
    ClaimIdentifierForTransmissionIntermediariesRefReferenceIdentificationQualifier01Schema,
  value_added_network_trace_number_02: z.string(),
});
export type FluffyClaimIdentifierForTransmissionIntermediaries = z.infer<
  typeof FluffyClaimIdentifierForTransmissionIntermediariesSchema
>;

export const FluffyCarePlanOversightSchema = z.object({
  care_plan_oversight_number_02: z.string(),
  reference_identification_qualifier_01:
    CarePlanOversightRefReferenceIdentificationQualifier01Schema,
});
export type FluffyCarePlanOversight = z.infer<typeof FluffyCarePlanOversightSchema>;

export const HealthCareCodeInformation15Schema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  industry_code_02: z.string(),
});
export type HealthCareCodeInformation15 = z.infer<typeof HealthCareCodeInformation15Schema>;

export const HealthCareCodeInformation14Schema = z.object({
  anesthesia_related_surgical_procedure_02: z.string(),
  code_list_qualifier_code_01: PurpleCodeListQualifierCodeSchema,
});
export type HealthCareCodeInformation14 = z.infer<typeof HealthCareCodeInformation14Schema>;

export const FluffyAnesthesiaRelatedProcedureSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation14Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation15Schema, z.null()])
    .optional(),
});
export type FluffyAnesthesiaRelatedProcedure = z.infer<
  typeof FluffyAnesthesiaRelatedProcedureSchema
>;

export const TentacledAmbulanceTransportInformationSchema = z.object({
  ambulance_transport_reason_code_04: AmbulanceTransportReasonCodeSchema,
  patient_weight_02: z.union([z.number(), z.null()]).optional(),
  round_trip_purpose_description_09: z.union([z.null(), z.string()]).optional(),
  stretcher_purpose_description_10: z.union([z.null(), z.string()]).optional(),
  transport_distance_06: z.number(),
  unit_or_basis_for_measurement_code_01: z
    .union([UnitOrBasisForMeasurementCode01_EnumSchema, z.null()])
    .optional(),
  unit_or_basis_for_measurement_code_05: UnitOrBasisForMeasurementCode05_EnumSchema,
});
export type TentacledAmbulanceTransportInformation = z.infer<
  typeof TentacledAmbulanceTransportInformationSchema
>;

export const TentacledAmbulancePickUpLocationSchema = z.object({
  entity_identifier_code_01: AmbulancePickUpLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
});
export type TentacledAmbulancePickUpLocation = z.infer<
  typeof TentacledAmbulancePickUpLocationSchema
>;

export const TentacledAmbulancePickUpLocationCityStateZipCodeSchema = z.object({
  ambulance_pick_up_city_name_01: z.string(),
  ambulance_pick_up_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  ambulance_pick_up_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
});
export type TentacledAmbulancePickUpLocationCityStateZipCode = z.infer<
  typeof TentacledAmbulancePickUpLocationCityStateZipCodeSchema
>;

export const TentacledAmbulancePickUpLocationAddressSchema = z.object({
  ambulance_pick_up_address_line_01: z.string(),
  ambulance_pick_up_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type TentacledAmbulancePickUpLocationAddress = z.infer<
  typeof TentacledAmbulancePickUpLocationAddressSchema
>;

export const TentacledAmbulancePickUpLocationLoopSchema = z.object({
  ambulance_pick_up_location_address_N3: TentacledAmbulancePickUpLocationAddressSchema,
  ambulance_pick_up_location_city_state_zip_code_N4:
    TentacledAmbulancePickUpLocationCityStateZipCodeSchema,
  ambulance_pick_up_location_NM1: TentacledAmbulancePickUpLocationSchema,
});
export type TentacledAmbulancePickUpLocationLoop = z.infer<
  typeof TentacledAmbulancePickUpLocationLoopSchema
>;

export const TentacledAmbulanceDropOffLocationSchema = z.object({
  ambulance_drop_off_location_03: z.union([z.null(), z.string()]).optional(),
  entity_identifier_code_01: AmbulanceDropOffLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
});
export type TentacledAmbulanceDropOffLocation = z.infer<
  typeof TentacledAmbulanceDropOffLocationSchema
>;

export const TentacledAmbulanceDropOffLocationCityStateZipCodeSchema = z.object({
  ambulance_drop_off_city_name_01: z.string(),
  ambulance_drop_off_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  ambulance_drop_off_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
});
export type TentacledAmbulanceDropOffLocationCityStateZipCode = z.infer<
  typeof TentacledAmbulanceDropOffLocationCityStateZipCodeSchema
>;

export const TentacledAmbulanceDropOffLocationAddressSchema = z.object({
  ambulance_drop_off_address_line_01: z.string(),
  ambulance_drop_off_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type TentacledAmbulanceDropOffLocationAddress = z.infer<
  typeof TentacledAmbulanceDropOffLocationAddressSchema
>;

export const TentacledAmbulanceDropOffLocationLoopSchema = z.object({
  ambulance_drop_off_location_address_N3: TentacledAmbulanceDropOffLocationAddressSchema,
  ambulance_drop_off_location_city_state_zip_code_N4:
    TentacledAmbulanceDropOffLocationCityStateZipCodeSchema,
  ambulance_drop_off_location_NM1: TentacledAmbulanceDropOffLocationSchema,
});
export type TentacledAmbulanceDropOffLocationLoop = z.infer<
  typeof TentacledAmbulanceDropOffLocationLoopSchema
>;

export const TentacledAmbulanceCertificationSchema = z.object({
  certification_condition_indicator_02: ProviderOrSupplierSignatureIndicator06_EnumSchema,
  code_category_01: AmbulanceCertificationCrcCodeCategory01Schema,
  condition_code_03: AmbulanceCertificationCrcConditionCode03Schema,
  condition_code_04: z.union([z.null(), z.string()]).optional(),
  condition_code_05: z.union([z.null(), z.string()]).optional(),
  condition_code_06: z.union([z.null(), z.string()]).optional(),
  condition_code_07: z.union([z.null(), z.string()]).optional(),
});
export type TentacledAmbulanceCertification = z.infer<typeof TentacledAmbulanceCertificationSchema>;

export const FluffyAdjustedRepricedClaimNumberSchema = z.object({
  adjusted_repriced_claim_reference_number_02: z.string(),
  reference_identification_qualifier_01:
    AdjustedRepricedClaimNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyAdjustedRepricedClaimNumber = z.infer<
  typeof FluffyAdjustedRepricedClaimNumberSchema
>;

export const PatientHierarchicalLevelHlLoopClaimInformationClmLoopSchema = z.object({
  adjusted_repriced_claim_number_REF: z
    .union([FluffyAdjustedRepricedClaimNumberSchema, z.null()])
    .optional(),
  ambulance_certification_CRC: z
    .union([z.array(TentacledAmbulanceCertificationSchema), z.null()])
    .optional(),
  ambulance_drop_off_location_NM1_loop: z
    .union([TentacledAmbulanceDropOffLocationLoopSchema, z.null()])
    .optional(),
  ambulance_pick_up_location_NM1_loop: z
    .union([TentacledAmbulancePickUpLocationLoopSchema, z.null()])
    .optional(),
  ambulance_transport_information_CR1: z
    .union([TentacledAmbulanceTransportInformationSchema, z.null()])
    .optional(),
  anesthesia_related_procedure_HI: z
    .union([FluffyAnesthesiaRelatedProcedureSchema, z.null()])
    .optional(),
  care_plan_oversight_REF: z.union([FluffyCarePlanOversightSchema, z.null()]).optional(),
  claim_identifier_for_transmission_intermediaries_REF: z
    .union([FluffyClaimIdentifierForTransmissionIntermediariesSchema, z.null()])
    .optional(),
  claim_information_CLM: FluffyClaimInformationSchema,
  claim_note_NTE: z.union([FluffyClaimNoteSchema, z.null()]).optional(),
  claim_pricing_repricing_information_HCP: z
    .union([FluffyClaimPricingRepricingInformationSchema, z.null()])
    .optional(),
  claim_supplemental_information_PWK: z
    .union([z.array(FluffyClaimSupplementalInformationSchema), z.null()])
    .optional(),
  clinical_laboratory_improvement_amendment_clia_number_REF: z
    .union([TentacledClinicalLaboratoryImprovementAmendmentCliaNumberSchema, z.null()])
    .optional(),
  condition_information_HI: z
    .union([z.array(FluffyConditionInformationSchema), z.null()])
    .optional(),
  contract_information_CN1: z.union([TentacledContractInformationSchema, z.null()]).optional(),
  date_accident_DTP: z.union([FluffyDateAccidentSchema, z.null()]).optional(),
  date_acute_manifestation_DTP: z.union([FluffyDateAcuteManifestationSchema, z.null()]).optional(),
  date_admission_DTP: z.union([FluffyDateAdmissionSchema, z.null()]).optional(),
  date_assumed_and_relinquished_care_dates_DTP: z
    .union([z.array(FluffyDateAssumedAndRelinquishedCareDatesSchema), z.null()])
    .optional(),
  date_authorized_return_to_work_DTP: z
    .union([FluffyDateAuthorizedReturnToWorkSchema, z.null()])
    .optional(),
  date_disability_dates_DTP: z.union([FluffyDateDisabilityDatesSchema, z.null()]).optional(),
  date_discharge_DTP: z.union([FluffyDateDischargeSchema, z.null()]).optional(),
  date_hearing_and_vision_prescription_date_DTP: z
    .union([FluffyDateHearingAndVisionPrescriptionDateSchema, z.null()])
    .optional(),
  date_initial_treatment_date_DTP: z
    .union([TentacledDateInitialTreatmentDateSchema, z.null()])
    .optional(),
  date_last_menstrual_period_DTP: z
    .union([FluffyDateLastMenstrualPeriodSchema, z.null()])
    .optional(),
  date_last_seen_date_DTP: z.union([TentacledDateLastSeenDateSchema, z.null()]).optional(),
  date_last_worked_DTP: z.union([FluffyDateLastWorkedSchema, z.null()]).optional(),
  date_last_x_ray_date_DTP: z.union([TentacledDateLastXRayDateSchema, z.null()]).optional(),
  date_onset_of_current_illness_or_symptom_DTP: z
    .union([FluffyDateOnsetOfCurrentIllnessOrSymptomSchema, z.null()])
    .optional(),
  date_property_and_casualty_date_of_first_contact_DTP: z
    .union([FluffyDatePropertyAndCasualtyDateOfFirstContactSchema, z.null()])
    .optional(),
  date_repricer_received_date_DTP: z
    .union([FluffyDateRepricerReceivedDateSchema, z.null()])
    .optional(),
  demonstration_project_identifier_REF: z
    .union([FluffyDemonstrationProjectIdentifierSchema, z.null()])
    .optional(),
  epsdt_referral_CRC: z.union([FluffyEpsdtReferralSchema, z.null()]).optional(),
  file_information_K3: z.union([z.array(TentacledFileInformationSchema), z.null()]).optional(),
  health_care_diagnosis_code_HI: FluffyHealthCareDiagnosisCodeSchema,
  homebound_indicator_CRC: z.union([FluffyHomeboundIndicatorSchema, z.null()]).optional(),
  investigational_device_exemption_number_REF: z
    .union([FluffyInvestigationalDeviceExemptionNumberSchema, z.null()])
    .optional(),
  mammography_certification_number_REF: z
    .union([TentacledMammographyCertificationNumberSchema, z.null()])
    .optional(),
  mandatory_medicare_section_4081_crossover_indicator_REF: z
    .union([FluffyMandatoryMedicareSection4081CrossoverIndicatorSchema, z.null()])
    .optional(),
  medical_record_number_REF: z.union([FluffyMedicalRecordNumberSchema, z.null()]).optional(),
  other_subscriber_information_SBR_loop: z
    .union([z.array(FluffyOtherSubscriberInformationLoopSchema), z.null()])
    .optional(),
  patient_amount_paid_AMT: z.union([FluffyPatientAmountPaidSchema, z.null()]).optional(),
  patient_condition_information_vision_CRC: z
    .union([z.array(FluffyPatientConditionInformationVisionSchema), z.null()])
    .optional(),
  payer_claim_control_number_REF: z
    .union([FluffyPayerClaimControlNumberSchema, z.null()])
    .optional(),
  prior_authorization_REF: z.union([TentacledPriorAuthorizationSchema, z.null()]).optional(),
  referral_number_REF: z.union([TentacledReferralNumberSchema, z.null()]).optional(),
  referring_provider_name_NM1_loop: z
    .union([z.array(TentacledReferringProviderNameLoopSchema), z.null()])
    .optional(),
  rendering_provider_name_NM1_loop: z
    .union([TentacledRenderingProviderNameLoopSchema, z.null()])
    .optional(),
  repriced_claim_number_REF: z.union([FluffyRepricedClaimNumberSchema, z.null()]).optional(),
  service_authorization_exception_code_REF: z
    .union([FluffyServiceAuthorizationExceptionCodeSchema, z.null()])
    .optional(),
  service_facility_location_name_NM1_loop: z
    .union([TentacledServiceFacilityLocationNameLoopSchema, z.null()])
    .optional(),
  service_line_number_LX_loop: z.array(FluffyServiceLineNumberLoopSchema),
  spinal_manipulation_service_information_CR2: z
    .union([FluffySpinalManipulationServiceInformationSchema, z.null()])
    .optional(),
  supervising_provider_name_NM1_loop: z
    .union([StickySupervisingProviderNameLoopSchema, z.null()])
    .optional(),
});
export type PatientHierarchicalLevelHlLoopClaimInformationClmLoop = z.infer<
  typeof PatientHierarchicalLevelHlLoopClaimInformationClmLoopSchema
>;

export const PatientHierarchicalLevelLoopSchema = z.object({
  claim_information_CLM_loop: z.array(PatientHierarchicalLevelHlLoopClaimInformationClmLoopSchema),
  patient_information_PAT: PatientHierarchicalLevelHlLoopPatientInformationSchema,
  patient_name_NM1_loop: PatientNameLoopSchema,
});
export type PatientHierarchicalLevelLoop = z.infer<typeof PatientHierarchicalLevelLoopSchema>;

export const FluffySupervisingProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerRenderingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  supervising_provider_secondary_identifier_02: z.string(),
});
export type FluffySupervisingProviderSecondaryIdentification = z.infer<
  typeof FluffySupervisingProviderSecondaryIdentificationSchema
>;

export const FluffySupervisingProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerSupervisingProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherPayerReferringProviderNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  supervising_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  supervising_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  supervising_provider_last_name_03: z.string(),
  supervising_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  supervising_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type FluffySupervisingProviderName = z.infer<typeof FluffySupervisingProviderNameSchema>;

export const FluffySupervisingProviderNameLoopSchema = z.object({
  supervising_provider_name_NM1: FluffySupervisingProviderNameSchema,
  supervising_provider_secondary_identification_REF: z
    .union([z.array(FluffySupervisingProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type FluffySupervisingProviderNameLoop = z.infer<
  typeof FluffySupervisingProviderNameLoopSchema
>;

export const PurpleSpinalManipulationServiceInformationSchema = z.object({
  patient_condition_code_08: PatientConditionCodeSchema,
  patient_condition_description_10: z.union([z.null(), z.string()]).optional(),
  patient_condition_description_11: z.union([z.null(), z.string()]).optional(),
});
export type PurpleSpinalManipulationServiceInformation = z.infer<
  typeof PurpleSpinalManipulationServiceInformationSchema
>;

export const PurpleThirdPartyOrganizationNotesSchema = z.object({
  line_note_text_02: z.string(),
  note_reference_code_01: ThirdPartyOrganizationNotesNteNoteReferenceCode01Schema,
});
export type PurpleThirdPartyOrganizationNotes = z.infer<
  typeof PurpleThirdPartyOrganizationNotesSchema
>;

export const PurpleTestResultSchema = z.object({
  measurement_qualifier_02: MeasurementQualifierSchema,
  measurement_reference_identification_code_01: MeasurementReferenceIdentificationCodeSchema,
  test_results_03: z.number(),
});
export type PurpleTestResult = z.infer<typeof PurpleTestResultSchema>;

export const AmbitiousReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type AmbitiousReferenceIdentifier = z.infer<typeof AmbitiousReferenceIdentifierSchema>;

export const PurpleSupervisingProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerRenderingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([AmbitiousReferenceIdentifierSchema, z.null()]).optional(),
  supervising_provider_secondary_identifier_02: z.string(),
});
export type PurpleSupervisingProviderSecondaryIdentification = z.infer<
  typeof PurpleSupervisingProviderSecondaryIdentificationSchema
>;

export const PurpleSupervisingProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerSupervisingProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherPayerReferringProviderNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  supervising_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  supervising_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  supervising_provider_last_name_03: z.string(),
  supervising_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  supervising_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type PurpleSupervisingProviderName = z.infer<typeof PurpleSupervisingProviderNameSchema>;

export const PurpleSupervisingProviderNameLoopSchema = z.object({
  supervising_provider_name_NM1: PurpleSupervisingProviderNameSchema,
  supervising_provider_secondary_identification_REF: z
    .union([z.array(PurpleSupervisingProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type PurpleSupervisingProviderNameLoop = z.infer<
  typeof PurpleSupervisingProviderNameLoopSchema
>;

export const PurpleServiceLineNumberSchema = z.object({
  assigned_number_01: z.number(),
});
export type PurpleServiceLineNumber = z.infer<typeof PurpleServiceLineNumberSchema>;

export const HilariousReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type HilariousReferenceIdentifier = z.infer<typeof HilariousReferenceIdentifierSchema>;

export const FluffyServiceFacilityLocationSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerBillingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([HilariousReferenceIdentifierSchema, z.null()]).optional(),
  service_facility_location_secondary_identifier_02: z.string(),
});
export type FluffyServiceFacilityLocationSecondaryIdentification = z.infer<
  typeof FluffyServiceFacilityLocationSecondaryIdentificationSchema
>;

export const FluffyServiceFacilityLocationNameSchema = z.object({
  entity_identifier_code_01: OtherPayerServiceFacilityLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  laboratory_or_facility_name_03: z.string(),
  laboratory_or_facility_primary_identifier_09: z.union([z.null(), z.string()]).optional(),
});
export type FluffyServiceFacilityLocationName = z.infer<
  typeof FluffyServiceFacilityLocationNameSchema
>;

export const FluffyServiceFacilityLocationCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  laboratory_or_facility_city_name_01: z.string(),
  laboratory_or_facility_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  laboratory_or_facility_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyServiceFacilityLocationCityStateZipCode = z.infer<
  typeof FluffyServiceFacilityLocationCityStateZipCodeSchema
>;

export const FluffyServiceFacilityLocationAddressSchema = z.object({
  laboratory_or_facility_address_line_01: z.string(),
  laboratory_or_facility_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyServiceFacilityLocationAddress = z.infer<
  typeof FluffyServiceFacilityLocationAddressSchema
>;

export const FluffyServiceFacilityLocationNameLoopSchema = z.object({
  service_facility_location_address_N3: FluffyServiceFacilityLocationAddressSchema,
  service_facility_location_city_state_zip_code_N4:
    FluffyServiceFacilityLocationCityStateZipCodeSchema,
  service_facility_location_name_NM1: FluffyServiceFacilityLocationNameSchema,
  service_facility_location_secondary_identification_REF: z
    .union([z.array(FluffyServiceFacilityLocationSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type FluffyServiceFacilityLocationNameLoop = z.infer<
  typeof FluffyServiceFacilityLocationNameLoopSchema
>;

export const PurpleSalesTaxAmountSchema = z.object({
  amount_qualifier_code_01: SalesTaxAmountAmtAmountQualifierCode01Schema,
  sales_tax_amount_02: z.number(),
});
export type PurpleSalesTaxAmount = z.infer<typeof PurpleSalesTaxAmountSchema>;

export const PurpleRepricedLineItemReferenceNumberSchema = z.object({
  reference_identification_qualifier_01:
    RepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01Schema,
  repriced_line_item_reference_number_02: z.string(),
});
export type PurpleRepricedLineItemReferenceNumber = z.infer<
  typeof PurpleRepricedLineItemReferenceNumberSchema
>;

export const FluffyRenderingProviderSpecialtyInformationSchema = z.object({
  provider_code_01: ErCodeSchema,
  provider_taxonomy_code_03: z.string(),
  reference_identification_qualifier_02: ReferenceIdentificationQualifier02_EnumSchema,
});
export type FluffyRenderingProviderSpecialtyInformation = z.infer<
  typeof FluffyRenderingProviderSpecialtyInformationSchema
>;

export const IndecentReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type IndecentReferenceIdentifier = z.infer<typeof IndecentReferenceIdentifierSchema>;

export const FluffyRenderingProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerRenderingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([IndecentReferenceIdentifierSchema, z.null()]).optional(),
  rendering_provider_secondary_identifier_02: z.string(),
});
export type FluffyRenderingProviderSecondaryIdentification = z.infer<
  typeof FluffyRenderingProviderSecondaryIdentificationSchema
>;

export const FluffyRenderingProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerRenderingProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  rendering_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  rendering_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  rendering_provider_last_or_organization_name_03: z.string(),
  rendering_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  rendering_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type FluffyRenderingProviderName = z.infer<typeof FluffyRenderingProviderNameSchema>;

export const FluffyRenderingProviderNameLoopSchema = z.object({
  rendering_provider_name_NM1: FluffyRenderingProviderNameSchema,
  rendering_provider_secondary_identification_REF: z
    .union([z.array(FluffyRenderingProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
  rendering_provider_specialty_information_PRV: z
    .union([FluffyRenderingProviderSpecialtyInformationSchema, z.null()])
    .optional(),
});
export type FluffyRenderingProviderNameLoop = z.infer<typeof FluffyRenderingProviderNameLoopSchema>;

export const IndigoReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type IndigoReferenceIdentifier = z.infer<typeof IndigoReferenceIdentifierSchema>;

export const FluffyReferringProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([IndigoReferenceIdentifierSchema, z.null()]).optional(),
  referring_provider_secondary_identifier_02: z.string(),
});
export type FluffyReferringProviderSecondaryIdentification = z.infer<
  typeof FluffyReferringProviderSecondaryIdentificationSchema
>;

export const FluffyReferringProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerReferringProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherPayerReferringProviderNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  referring_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  referring_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  referring_provider_last_name_03: z.string(),
  referring_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  referring_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type FluffyReferringProviderName = z.infer<typeof FluffyReferringProviderNameSchema>;

export const FluffyReferringProviderNameLoopSchema = z.object({
  referring_provider_name_NM1: FluffyReferringProviderNameSchema,
  referring_provider_secondary_identification_REF: z
    .union([z.array(FluffyReferringProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type FluffyReferringProviderNameLoop = z.infer<typeof FluffyReferringProviderNameLoopSchema>;

export const PurpleReferringClinicalLaboratoryImprovementAmendmentCliaFacilityIdentificationSchema =
  z.object({
    reference_identification_qualifier_01: AmountQualifierCodeSchema,
    referring_clia_number_02: z.string(),
  });
export type PurpleReferringClinicalLaboratoryImprovementAmendmentCliaFacilityIdentification =
  z.infer<
    typeof PurpleReferringClinicalLaboratoryImprovementAmendmentCliaFacilityIdentificationSchema
  >;

export const StickyReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type StickyReferenceIdentifier = z.infer<typeof StickyReferenceIdentifierSchema>;

export const FluffyReferralNumberSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerReferralNumberRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([StickyReferenceIdentifierSchema, z.null()]).optional(),
  referral_number_02: z.string(),
});
export type FluffyReferralNumber = z.infer<typeof FluffyReferralNumberSchema>;

export const TentacledReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type TentacledReferenceIdentifier = z.infer<typeof TentacledReferenceIdentifierSchema>;

export const PurplePurchasedServiceProviderSecondaryIdentificationSchema = z.object({
  purchased_service_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([TentacledReferenceIdentifierSchema, z.null()]).optional(),
});
export type PurplePurchasedServiceProviderSecondaryIdentification = z.infer<
  typeof PurplePurchasedServiceProviderSecondaryIdentificationSchema
>;

export const PurplePurchasedServiceProviderNameSchema = z.object({
  entity_identifier_code_01: PurchasedServiceProviderNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  purchased_service_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
});
export type PurplePurchasedServiceProviderName = z.infer<
  typeof PurplePurchasedServiceProviderNameSchema
>;

export const PurplePurchasedServiceProviderNameLoopSchema = z.object({
  purchased_service_provider_name_NM1: PurplePurchasedServiceProviderNameSchema,
  purchased_service_provider_secondary_identification_REF: z
    .union([z.array(PurplePurchasedServiceProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type PurplePurchasedServiceProviderNameLoop = z.infer<
  typeof PurplePurchasedServiceProviderNameLoopSchema
>;

export const PurplePurchasedServiceInformationSchema = z.object({
  purchased_service_charge_amount_02: z.number(),
  purchased_service_provider_identifier_01: z.string(),
});
export type PurplePurchasedServiceInformation = z.infer<
  typeof PurplePurchasedServiceInformationSchema
>;

export const TentacledCompositeMedicalProcedureIdentifierSchema = z.object({
  description_07: z.union([z.null(), z.string()]).optional(),
  procedure_code_02: z.string(),
  procedure_modifier_03: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_04: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_05: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_06: z.union([z.null(), z.string()]).optional(),
  product_or_service_id_qualifier_01: ProductOrServiceIdQualifier01_EnumSchema,
});
export type TentacledCompositeMedicalProcedureIdentifier = z.infer<
  typeof TentacledCompositeMedicalProcedureIdentifierSchema
>;

export const PurpleCompositeDiagnosisCodePointerSchema = z.object({
  diagnosis_code_pointer_01: z.number(),
  diagnosis_code_pointer_02: z.union([z.number(), z.null()]).optional(),
  diagnosis_code_pointer_03: z.union([z.number(), z.null()]).optional(),
  diagnosis_code_pointer_04: z.union([z.number(), z.null()]).optional(),
});
export type PurpleCompositeDiagnosisCodePointer = z.infer<
  typeof PurpleCompositeDiagnosisCodePointerSchema
>;

export const PurpleProfessionalServiceSchema = z.object({
  co_pay_status_code_15: z.union([CoPayStatusCodeSchema, z.null()]).optional(),
  composite_diagnosis_code_pointer_07: PurpleCompositeDiagnosisCodePointerSchema,
  composite_medical_procedure_identifier_01: TentacledCompositeMedicalProcedureIdentifierSchema,
  emergency_indicator_09: z.union([PregnancyIndicator09_EnumSchema, z.null()]).optional(),
  epsdt_indicator_11: z.union([PregnancyIndicator09_EnumSchema, z.null()]).optional(),
  family_planning_indicator_12: z.union([PregnancyIndicator09_EnumSchema, z.null()]).optional(),
  line_item_charge_amount_02: z.number(),
  place_of_service_code_05: z.union([z.null(), z.string()]).optional(),
  service_unit_count_04: z.number(),
  unit_or_basis_for_measurement_code_03: UnitOrBasisForMeasurementCode03_EnumSchema,
});
export type PurpleProfessionalService = z.infer<typeof PurpleProfessionalServiceSchema>;

export const FluffyReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type FluffyReferenceIdentifier = z.infer<typeof FluffyReferenceIdentifierSchema>;

export const FluffyPriorAuthorizationSchema = z.object({
  prior_authorization_or_referral_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerPriorAuthorizationNumberRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([FluffyReferenceIdentifierSchema, z.null()]).optional(),
});
export type FluffyPriorAuthorization = z.infer<typeof FluffyPriorAuthorizationSchema>;

export const PurplePostageClaimedAmountSchema = z.object({
  amount_qualifier_code_01: AmountQualifierCodeSchema,
  postage_claimed_amount_02: z.number(),
});
export type PurplePostageClaimedAmount = z.infer<typeof PurplePostageClaimedAmountSchema>;

export const PurpleReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type PurpleReferenceIdentifier = z.infer<typeof PurpleReferenceIdentifierSchema>;

export const PurpleOrderingProviderSecondaryIdentificationSchema = z.object({
  ordering_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([PurpleReferenceIdentifierSchema, z.null()]).optional(),
});
export type PurpleOrderingProviderSecondaryIdentification = z.infer<
  typeof PurpleOrderingProviderSecondaryIdentificationSchema
>;

export const PurpleOrderingProviderNameSchema = z.object({
  entity_identifier_code_01: OrderingProviderNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherPayerReferringProviderNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  ordering_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  ordering_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  ordering_provider_last_name_03: z.string(),
  ordering_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  ordering_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type PurpleOrderingProviderName = z.infer<typeof PurpleOrderingProviderNameSchema>;

export const PurpleOrderingProviderContactInformationSchema = z.object({
  communication_number_04: z.string(),
  communication_number_06: z.union([z.null(), z.string()]).optional(),
  communication_number_08: z.union([z.null(), z.string()]).optional(),
  communication_number_qualifier_03:
    BillingProviderContactInformationCommunicationNumberQualifier03Schema,
  communication_number_qualifier_05: z
    .union([CommunicationNumberQualifier07_EnumSchema, z.null()])
    .optional(),
  communication_number_qualifier_07: z
    .union([CommunicationNumberQualifier07_EnumSchema, z.null()])
    .optional(),
  contact_function_code_01: ContactFunctionCodeSchema,
  ordering_provider_contact_name_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleOrderingProviderContactInformation = z.infer<
  typeof PurpleOrderingProviderContactInformationSchema
>;

export const PurpleOrderingProviderCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  ordering_provider_city_name_01: z.string(),
  ordering_provider_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  ordering_provider_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleOrderingProviderCityStateZipCode = z.infer<
  typeof PurpleOrderingProviderCityStateZipCodeSchema
>;

export const PurpleOrderingProviderAddressSchema = z.object({
  ordering_provider_address_line_01: z.string(),
  ordering_provider_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleOrderingProviderAddress = z.infer<typeof PurpleOrderingProviderAddressSchema>;

export const PurpleOrderingProviderNameLoopSchema = z.object({
  ordering_provider_address_N3: z.union([PurpleOrderingProviderAddressSchema, z.null()]).optional(),
  ordering_provider_city_state_zip_code_N4: z
    .union([PurpleOrderingProviderCityStateZipCodeSchema, z.null()])
    .optional(),
  ordering_provider_contact_information_PER: z
    .union([PurpleOrderingProviderContactInformationSchema, z.null()])
    .optional(),
  ordering_provider_name_NM1: PurpleOrderingProviderNameSchema,
  ordering_provider_secondary_identification_REF: z
    .union([z.array(PurpleOrderingProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type PurpleOrderingProviderNameLoop = z.infer<typeof PurpleOrderingProviderNameLoopSchema>;

export const PurpleObstetricAnesthesiaAdditionalUnitsSchema = z.object({
  obstetric_additional_units_02: z.number(),
  quantity_qualifier_01: ObstetricAnesthesiaAdditionalUnitsQtyQuantityQualifier01Schema,
});
export type PurpleObstetricAnesthesiaAdditionalUnits = z.infer<
  typeof PurpleObstetricAnesthesiaAdditionalUnitsSchema
>;

export const FluffyMammographyCertificationNumberSchema = z.object({
  mammography_certification_number_02: z.string(),
  reference_identification_qualifier_01:
    MammographyCertificationNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyMammographyCertificationNumber = z.infer<
  typeof FluffyMammographyCertificationNumberSchema
>;

export const PurpleLineSupplementalInformationSchema = z.object({
  attachment_control_number_06: z.union([z.null(), z.string()]).optional(),
  attachment_report_type_code_01: ClaimSupplementalInformationAttachmentReportTypeCode01Schema,
  attachment_transmission_code_02: ClaimSupplementalInformationAttachmentTransmissionCode02Schema,
  identification_code_qualifier_05: z
    .union([IdentificationCodeQualifier05_EnumSchema, z.null()])
    .optional(),
});
export type PurpleLineSupplementalInformation = z.infer<
  typeof PurpleLineSupplementalInformationSchema
>;

export const PurpleLinePricingRepricingInformationSchema = z.object({
  exception_code_15: z.union([ExceptionCodeSchema, z.null()]).optional(),
  policy_compliance_code_14: z.union([PolicyComplianceCodeSchema, z.null()]).optional(),
  pricing_methodology_01: LinePricingRepricingInformationPricingMethodology01Schema,
  product_or_service_id_qualifier_09: z
    .union([ProductOrServiceIdQualifier01_EnumSchema, z.null()])
    .optional(),
  reject_reason_code_13: z.union([RejectReasonCodeSchema, z.null()]).optional(),
  repriced_allowed_amount_02: z.number(),
  repriced_approved_ambulatory_patient_group_amount_07: z.union([z.number(), z.null()]).optional(),
  repriced_approved_ambulatory_patient_group_code_06: z.union([z.null(), z.string()]).optional(),
  repriced_approved_hcpcs_code_10: z.union([z.null(), z.string()]).optional(),
  repriced_approved_service_unit_count_12: z.union([z.number(), z.null()]).optional(),
  repriced_saving_amount_03: z.union([z.number(), z.null()]).optional(),
  repricing_organization_identifier_04: z.union([z.null(), z.string()]).optional(),
  repricing_per_diem_or_flat_rate_amount_05: z.union([z.number(), z.null()]).optional(),
  unit_or_basis_for_measurement_code_11: z
    .union([UnitOrBasisForMeasurementCode03_EnumSchema, z.null()])
    .optional(),
});
export type PurpleLinePricingRepricingInformation = z.infer<
  typeof PurpleLinePricingRepricingInformationSchema
>;

export const PurpleLineNoteSchema = z.object({
  line_note_text_02: z.string(),
  note_reference_code_01: LineNoteNteNoteReferenceCode01Schema,
});
export type PurpleLineNote = z.infer<typeof PurpleLineNoteSchema>;

export const PurpleLineItemControlNumberSchema = z.object({
  line_item_control_number_02: z.string(),
  reference_identification_qualifier_01:
    LineItemControlNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleLineItemControlNumber = z.infer<typeof PurpleLineItemControlNumberSchema>;

export const FluffyRemainingPatientLiabilitySchema = z.object({
  amount_qualifier_code_01: RemainingPatientLiabilityAmtAmountQualifierCode01Schema,
  remaining_patient_liability_02: z.number(),
});
export type FluffyRemainingPatientLiability = z.infer<typeof FluffyRemainingPatientLiabilitySchema>;

export const PurpleLineCheckOrRemittanceDateSchema = z.object({
  adjudication_or_payment_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: ClaimCheckOrRemittanceDateDtpDateTimeQualifier01Schema,
});
export type PurpleLineCheckOrRemittanceDate = z.infer<typeof PurpleLineCheckOrRemittanceDateSchema>;

export const PurpleLineAdjustmentSchema = z.object({
  adjustment_amount_03: z.number(),
  adjustment_amount_06: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_09: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_12: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_15: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_18: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_04: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_07: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_10: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_13: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_16: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_19: z.union([z.number(), z.null()]).optional(),
  adjustment_reason_code_02: z.string(),
  adjustment_reason_code_05: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_08: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_11: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_14: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_17: z.union([z.null(), z.string()]).optional(),
  claim_adjustment_group_code_01: ClaimAdjustmentGroupCodeSchema,
});
export type PurpleLineAdjustment = z.infer<typeof PurpleLineAdjustmentSchema>;

export const FluffyCompositeMedicalProcedureIdentifierSchema = z.object({
  procedure_code_02: z.string(),
  procedure_code_description_07: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_03: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_04: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_05: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_06: z.union([z.null(), z.string()]).optional(),
  product_or_service_id_qualifier_01: ProductOrServiceIdQualifier01_EnumSchema,
});
export type FluffyCompositeMedicalProcedureIdentifier = z.infer<
  typeof FluffyCompositeMedicalProcedureIdentifierSchema
>;

export const PurpleLineAdjudicationInformationSchema = z.object({
  bundled_or_unbundled_line_number_06: z.union([z.number(), z.null()]).optional(),
  composite_medical_procedure_identifier_03: FluffyCompositeMedicalProcedureIdentifierSchema,
  other_payer_primary_identifier_01: z.string(),
  paid_service_unit_count_05: z.number(),
  service_line_paid_amount_02: z.number(),
});
export type PurpleLineAdjudicationInformation = z.infer<
  typeof PurpleLineAdjudicationInformationSchema
>;

export const PurpleLineAdjudicationInformationLoopSchema = z.object({
  line_adjudication_information_SVD: PurpleLineAdjudicationInformationSchema,
  line_adjustment_CAS: z.union([z.array(PurpleLineAdjustmentSchema), z.null()]).optional(),
  line_check_or_remittance_date_DTP: PurpleLineCheckOrRemittanceDateSchema,
  remaining_patient_liability_AMT: z
    .union([FluffyRemainingPatientLiabilitySchema, z.null()])
    .optional(),
});
export type PurpleLineAdjudicationInformationLoop = z.infer<
  typeof PurpleLineAdjudicationInformationLoopSchema
>;

export const PurpleImmunizationBatchNumberSchema = z.object({
  immunization_batch_number_02: z.string(),
  reference_identification_qualifier_01:
    ImmunizationBatchNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleImmunizationBatchNumber = z.infer<typeof PurpleImmunizationBatchNumberSchema>;

export const PurpleHospiceEmployeeIndicatorSchema = z.object({
  code_category_01: HospiceEmployeeIndicatorCrcCodeCategory01Schema,
  condition_indicator_03: HospiceEmployeeIndicatorCrcConditionIndicator03Schema,
  hospice_employed_provider_indicator_02: ProviderOrSupplierSignatureIndicator06_EnumSchema,
});
export type PurpleHospiceEmployeeIndicator = z.infer<typeof PurpleHospiceEmployeeIndicatorSchema>;

export const PurpleSupportingDocumentationSchema = z.object({
  question_number_letter_01: z.string(),
  question_response_02: z
    .union([BenefitsAssignmentCertificationIndicatorSchema, z.null()])
    .optional(),
  question_response_03: z.union([z.null(), z.string()]).optional(),
  question_response_04: z.union([z.null(), z.string()]).optional(),
  question_response_05: z.union([z.number(), z.null()]).optional(),
});
export type PurpleSupportingDocumentation = z.infer<typeof PurpleSupportingDocumentationSchema>;

export const PurpleFormIdentificationCodeSchema = z.object({
  code_list_qualifier_code_01: FormIdentificationCodeLqCodeListQualifierCode01Schema,
  form_identifier_02: z.string(),
});
export type PurpleFormIdentificationCode = z.infer<typeof PurpleFormIdentificationCodeSchema>;

export const PurpleFormIdentificationCodeLoopSchema = z.object({
  form_identification_code_LQ: PurpleFormIdentificationCodeSchema,
  supporting_documentation_FRM: z.array(PurpleSupportingDocumentationSchema),
});
export type PurpleFormIdentificationCodeLoop = z.infer<
  typeof PurpleFormIdentificationCodeLoopSchema
>;

export const FluffyFileInformationSchema = z.object({
  fixed_format_information_01: z.string(),
});
export type FluffyFileInformation = z.infer<typeof FluffyFileInformationSchema>;

export const PurpleCompositeMedicalProcedureIdentifierSchema = z.object({
  procedure_code_02: z.string(),
  procedure_identifier_01: ProcedureIdentifierSchema,
});
export type PurpleCompositeMedicalProcedureIdentifier = z.infer<
  typeof PurpleCompositeMedicalProcedureIdentifierSchema
>;

export const PurpleDurableMedicalEquipmentServiceSchema = z.object({
  composite_medical_procedure_identifier_01: PurpleCompositeMedicalProcedureIdentifierSchema,
  dme_purchase_price_05: z.number(),
  dme_rental_price_04: z.number(),
  length_of_medical_necessity_03: z.number(),
  rental_unit_price_indicator_06: RentalUnitPriceIndicatorSchema,
  unit_or_basis_for_measurement_code_02:
    DurableMedicalEquipmentServiceSv5UnitOrBasisForMeasurementCode02Schema,
});
export type PurpleDurableMedicalEquipmentService = z.infer<
  typeof PurpleDurableMedicalEquipmentServiceSchema
>;

export const PurpleDurableMedicalEquipmentCertificationSchema = z.object({
  certification_type_code_01: CertificationTypeCodeSchema,
  durable_medical_equipment_duration_03: z.number(),
  unit_or_basis_for_measurement_code_02:
    DurableMedicalEquipmentCertificationCr3UnitOrBasisForMeasurementCode02Schema,
});
export type PurpleDurableMedicalEquipmentCertification = z.infer<
  typeof PurpleDurableMedicalEquipmentCertificationSchema
>;

export const PurpleDurableMedicalEquipmentCertificateOfMedicalNecessityIndicatorSchema = z.object({
  attachment_report_type_code_01:
    DurableMedicalEquipmentCertificateOfMedicalNecessityIndicatorPwkAttachmentReportTypeCode01Schema,
  attachment_transmission_code_02:
    DurableMedicalEquipmentCertificateOfMedicalNecessityIndicatorPwkAttachmentTransmissionCode02Schema,
});
export type PurpleDurableMedicalEquipmentCertificateOfMedicalNecessityIndicator = z.infer<
  typeof PurpleDurableMedicalEquipmentCertificateOfMedicalNecessityIndicatorSchema
>;

export const PurplePrescriptionOrCompoundDrugAssociationNumberSchema = z.object({
  prescription_number_02: z.string(),
  reference_identification_qualifier_01:
    PrescriptionOrCompoundDrugAssociationNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurplePrescriptionOrCompoundDrugAssociationNumber = z.infer<
  typeof PurplePrescriptionOrCompoundDrugAssociationNumberSchema
>;

export const PurpleCompositeUnitOfMeasureSchema = z.object({
  code_qualifier_01: CompositeUnitOfMeasure05_CodeQualifier01Schema,
});
export type PurpleCompositeUnitOfMeasure = z.infer<typeof PurpleCompositeUnitOfMeasureSchema>;

export const PurpleDrugQuantitySchema = z.object({
  composite_unit_of_measure_05: PurpleCompositeUnitOfMeasureSchema,
  national_drug_unit_count_04: z.number(),
});
export type PurpleDrugQuantity = z.infer<typeof PurpleDrugQuantitySchema>;

export const PurpleDrugIdentificationSchema = z.object({
  national_drug_code_or_universal_product_number_03: z.string(),
  product_or_service_id_qualifier_02: ProductOrServiceIdQualifier02_EnumSchema,
});
export type PurpleDrugIdentification = z.infer<typeof PurpleDrugIdentificationSchema>;

export const PurpleDrugIdentificationLoopSchema = z.object({
  drug_identification_LIN: PurpleDrugIdentificationSchema,
  drug_quantity_CTP: PurpleDrugQuantitySchema,
  prescription_or_compound_drug_association_number_REF: z
    .union([PurplePrescriptionOrCompoundDrugAssociationNumberSchema, z.null()])
    .optional(),
});
export type PurpleDrugIdentificationLoop = z.infer<typeof PurpleDrugIdentificationLoopSchema>;

export const PurpleDateTestDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateTestDateDtpDateTimeQualifier01Schema,
  test_performed_date_03: z.string(),
});
export type PurpleDateTestDate = z.infer<typeof PurpleDateTestDateSchema>;

export const PurpleDateShippedDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateShippedDateDtpDateTimeQualifier01Schema,
  shipped_date_03: z.string(),
});
export type PurpleDateShippedDate = z.infer<typeof PurpleDateShippedDateSchema>;

export const PurpleDateServiceDateSchema = z.object({
  date_time_period_format_qualifier_02: DateDisabilityDatesDtpDateTimePeriodFormatQualifier02Schema,
  date_time_qualifier_01: DateServiceDateDtpDateTimeQualifier01Schema,
  service_date_03: z.string(),
});
export type PurpleDateServiceDate = z.infer<typeof PurpleDateServiceDateSchema>;

export const PurpleDatePrescriptionDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateHearingAndVisionPrescriptionDateDtpDateTimeQualifier01Schema,
  prescription_date_03: z.string(),
});
export type PurpleDatePrescriptionDate = z.infer<typeof PurpleDatePrescriptionDateSchema>;

export const FluffyDateLastXRayDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateLastXRayDateDtpDateTimeQualifier01Schema,
  last_x_ray_date_03: z.string(),
});
export type FluffyDateLastXRayDate = z.infer<typeof FluffyDateLastXRayDateSchema>;

export const FluffyDateLastSeenDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateLastSeenDateDtpDateTimeQualifier01Schema,
  treatment_or_therapy_date_03: z.string(),
});
export type FluffyDateLastSeenDate = z.infer<typeof FluffyDateLastSeenDateSchema>;

export const PurpleDateLastCertificationDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateLastCertificationDateDtpDateTimeQualifier01Schema,
  last_certification_date_03: z.string(),
});
export type PurpleDateLastCertificationDate = z.infer<typeof PurpleDateLastCertificationDateSchema>;

export const FluffyDateInitialTreatmentDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateInitialTreatmentDateDtpDateTimeQualifier01Schema,
  initial_treatment_date_03: z.string(),
});
export type FluffyDateInitialTreatmentDate = z.infer<typeof FluffyDateInitialTreatmentDateSchema>;

export const PurpleDateCertificationRevisionRecertificationDateSchema = z.object({
  certification_revision_or_recertification_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateCertificationRevisionRecertificationDateDtpDateTimeQualifier01Schema,
});
export type PurpleDateCertificationRevisionRecertificationDate = z.infer<
  typeof PurpleDateCertificationRevisionRecertificationDateSchema
>;

export const PurpleDateBeginTherapyDateSchema = z.object({
  begin_therapy_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateBeginTherapyDateDtpDateTimeQualifier01Schema,
});
export type PurpleDateBeginTherapyDate = z.infer<typeof PurpleDateBeginTherapyDateSchema>;

export const FluffyContractInformationSchema = z.object({
  contract_amount_02: z.union([z.number(), z.null()]).optional(),
  contract_code_04: z.union([z.null(), z.string()]).optional(),
  contract_percentage_03: z.union([z.number(), z.null()]).optional(),
  contract_type_code_01: ContractTypeCodeSchema,
  contract_version_identifier_06: z.union([z.null(), z.string()]).optional(),
  terms_discount_percentage_05: z.union([z.number(), z.null()]).optional(),
});
export type FluffyContractInformation = z.infer<typeof FluffyContractInformationSchema>;

export const PurpleConditionIndicatorDurableMedicalEquipmentSchema = z.object({
  certification_condition_indicator_02: ProviderOrSupplierSignatureIndicator06_EnumSchema,
  code_category_01: ConditionIndicatorDurableMedicalEquipmentCrcCodeCategory01Schema,
  condition_indicator_03: ConditionIndicatorDurableMedicalEquipmentCrcConditionIndicator03Schema,
  condition_indicator_04: z.union([z.null(), z.string()]).optional(),
});
export type PurpleConditionIndicatorDurableMedicalEquipment = z.infer<
  typeof PurpleConditionIndicatorDurableMedicalEquipmentSchema
>;

export const FluffyClinicalLaboratoryImprovementAmendmentCliaNumberSchema = z.object({
  clinical_laboratory_improvement_amendment_number_02: z.string(),
  reference_identification_qualifier_01:
    ClinicalLaboratoryImprovementAmendmentCliaNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyClinicalLaboratoryImprovementAmendmentCliaNumber = z.infer<
  typeof FluffyClinicalLaboratoryImprovementAmendmentCliaNumberSchema
>;

export const FluffyAmbulanceTransportInformationSchema = z.object({
  ambulance_transport_reason_code_04: AmbulanceTransportReasonCodeSchema,
  patient_weight_02: z.union([z.number(), z.null()]).optional(),
  round_trip_purpose_description_09: z.union([z.null(), z.string()]).optional(),
  stretcher_purpose_description_10: z.union([z.null(), z.string()]).optional(),
  transport_distance_06: z.number(),
  unit_or_basis_for_measurement_code_01: z
    .union([UnitOrBasisForMeasurementCode01_EnumSchema, z.null()])
    .optional(),
  unit_or_basis_for_measurement_code_05: UnitOrBasisForMeasurementCode05_EnumSchema,
});
export type FluffyAmbulanceTransportInformation = z.infer<
  typeof FluffyAmbulanceTransportInformationSchema
>;

export const FluffyAmbulancePickUpLocationSchema = z.object({
  entity_identifier_code_01: AmbulancePickUpLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
});
export type FluffyAmbulancePickUpLocation = z.infer<typeof FluffyAmbulancePickUpLocationSchema>;

export const FluffyAmbulancePickUpLocationCityStateZipCodeSchema = z.object({
  ambulance_pick_up_city_name_01: z.string(),
  ambulance_pick_up_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  ambulance_pick_up_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
});
export type FluffyAmbulancePickUpLocationCityStateZipCode = z.infer<
  typeof FluffyAmbulancePickUpLocationCityStateZipCodeSchema
>;

export const FluffyAmbulancePickUpLocationAddressSchema = z.object({
  ambulance_pick_up_address_line_01: z.string(),
  ambulance_pick_up_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyAmbulancePickUpLocationAddress = z.infer<
  typeof FluffyAmbulancePickUpLocationAddressSchema
>;

export const FluffyAmbulancePickUpLocationLoopSchema = z.object({
  ambulance_pick_up_location_address_N3: FluffyAmbulancePickUpLocationAddressSchema,
  ambulance_pick_up_location_city_state_zip_code_N4:
    FluffyAmbulancePickUpLocationCityStateZipCodeSchema,
  ambulance_pick_up_location_NM1: FluffyAmbulancePickUpLocationSchema,
});
export type FluffyAmbulancePickUpLocationLoop = z.infer<
  typeof FluffyAmbulancePickUpLocationLoopSchema
>;

export const PurpleAmbulancePatientCountSchema = z.object({
  ambulance_patient_count_02: z.number(),
  quantity_qualifier_01: AmbulancePatientCountQtyQuantityQualifier01Schema,
});
export type PurpleAmbulancePatientCount = z.infer<typeof PurpleAmbulancePatientCountSchema>;

export const FluffyAmbulanceDropOffLocationSchema = z.object({
  ambulance_drop_off_location_03: z.union([z.null(), z.string()]).optional(),
  entity_identifier_code_01: AmbulanceDropOffLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
});
export type FluffyAmbulanceDropOffLocation = z.infer<typeof FluffyAmbulanceDropOffLocationSchema>;

export const FluffyAmbulanceDropOffLocationCityStateZipCodeSchema = z.object({
  ambulance_drop_off_city_name_01: z.string(),
  ambulance_drop_off_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  ambulance_drop_off_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
});
export type FluffyAmbulanceDropOffLocationCityStateZipCode = z.infer<
  typeof FluffyAmbulanceDropOffLocationCityStateZipCodeSchema
>;

export const FluffyAmbulanceDropOffLocationAddressSchema = z.object({
  ambulance_drop_off_address_line_01: z.string(),
  ambulance_drop_off_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyAmbulanceDropOffLocationAddress = z.infer<
  typeof FluffyAmbulanceDropOffLocationAddressSchema
>;

export const FluffyAmbulanceDropOffLocationLoopSchema = z.object({
  ambulance_drop_off_location_address_N3: FluffyAmbulanceDropOffLocationAddressSchema,
  ambulance_drop_off_location_city_state_zip_code_N4:
    FluffyAmbulanceDropOffLocationCityStateZipCodeSchema,
  ambulance_drop_off_location_NM1: FluffyAmbulanceDropOffLocationSchema,
});
export type FluffyAmbulanceDropOffLocationLoop = z.infer<
  typeof FluffyAmbulanceDropOffLocationLoopSchema
>;

export const FluffyAmbulanceCertificationSchema = z.object({
  certification_condition_indicator_02: ProviderOrSupplierSignatureIndicator06_EnumSchema,
  code_category_01: AmbulanceCertificationCrcCodeCategory01Schema,
  condition_code_03: AmbulanceCertificationCrcConditionCode03Schema,
  condition_code_04: z.union([z.null(), z.string()]).optional(),
  condition_code_05: z.union([z.null(), z.string()]).optional(),
  condition_code_06: z.union([z.null(), z.string()]).optional(),
  condition_code_07: z.union([z.null(), z.string()]).optional(),
});
export type FluffyAmbulanceCertification = z.infer<typeof FluffyAmbulanceCertificationSchema>;

export const PurpleAdjustedRepricedLineItemReferenceNumberSchema = z.object({
  adjusted_repriced_line_item_reference_number_02: z.string(),
  reference_identification_qualifier_01:
    AdjustedRepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleAdjustedRepricedLineItemReferenceNumber = z.infer<
  typeof PurpleAdjustedRepricedLineItemReferenceNumberSchema
>;

export const PurpleServiceLineNumberLoopSchema = z.object({
  adjusted_repriced_line_item_reference_number_REF: z
    .union([PurpleAdjustedRepricedLineItemReferenceNumberSchema, z.null()])
    .optional(),
  ambulance_certification_CRC: z
    .union([z.array(FluffyAmbulanceCertificationSchema), z.null()])
    .optional(),
  ambulance_drop_off_location_NM1_loop: z
    .union([FluffyAmbulanceDropOffLocationLoopSchema, z.null()])
    .optional(),
  ambulance_patient_count_QTY: z.union([PurpleAmbulancePatientCountSchema, z.null()]).optional(),
  ambulance_pick_up_location_NM1_loop: z
    .union([FluffyAmbulancePickUpLocationLoopSchema, z.null()])
    .optional(),
  ambulance_transport_information_CR1: z
    .union([FluffyAmbulanceTransportInformationSchema, z.null()])
    .optional(),
  clinical_laboratory_improvement_amendment_clia_number_REF: z
    .union([FluffyClinicalLaboratoryImprovementAmendmentCliaNumberSchema, z.null()])
    .optional(),
  condition_indicator_durable_medical_equipment_CRC: z
    .union([PurpleConditionIndicatorDurableMedicalEquipmentSchema, z.null()])
    .optional(),
  contract_information_CN1: z.union([FluffyContractInformationSchema, z.null()]).optional(),
  date_begin_therapy_date_DTP: z.union([PurpleDateBeginTherapyDateSchema, z.null()]).optional(),
  date_certification_revision_recertification_date_DTP: z
    .union([PurpleDateCertificationRevisionRecertificationDateSchema, z.null()])
    .optional(),
  date_initial_treatment_date_DTP: z
    .union([FluffyDateInitialTreatmentDateSchema, z.null()])
    .optional(),
  date_last_certification_date_DTP: z
    .union([PurpleDateLastCertificationDateSchema, z.null()])
    .optional(),
  date_last_seen_date_DTP: z.union([FluffyDateLastSeenDateSchema, z.null()]).optional(),
  date_last_x_ray_date_DTP: z.union([FluffyDateLastXRayDateSchema, z.null()]).optional(),
  date_prescription_date_DTP: z.union([PurpleDatePrescriptionDateSchema, z.null()]).optional(),
  date_service_date_DTP: PurpleDateServiceDateSchema,
  date_shipped_date_DTP: z.union([PurpleDateShippedDateSchema, z.null()]).optional(),
  date_test_date_DTP: z.union([z.array(PurpleDateTestDateSchema), z.null()]).optional(),
  drug_identification_LIN_loop: z.union([PurpleDrugIdentificationLoopSchema, z.null()]).optional(),
  durable_medical_equipment_certificate_of_medical_necessity_indicator_PWK: z
    .union([PurpleDurableMedicalEquipmentCertificateOfMedicalNecessityIndicatorSchema, z.null()])
    .optional(),
  durable_medical_equipment_certification_CR3: z
    .union([PurpleDurableMedicalEquipmentCertificationSchema, z.null()])
    .optional(),
  durable_medical_equipment_service_SV5: z
    .union([PurpleDurableMedicalEquipmentServiceSchema, z.null()])
    .optional(),
  file_information_K3: z.union([z.array(FluffyFileInformationSchema), z.null()]).optional(),
  form_identification_code_LQ_loop: z
    .union([z.array(PurpleFormIdentificationCodeLoopSchema), z.null()])
    .optional(),
  hospice_employee_indicator_CRC: z
    .union([PurpleHospiceEmployeeIndicatorSchema, z.null()])
    .optional(),
  immunization_batch_number_REF: z
    .union([PurpleImmunizationBatchNumberSchema, z.null()])
    .optional(),
  line_adjudication_information_SVD_loop: z
    .union([z.array(PurpleLineAdjudicationInformationLoopSchema), z.null()])
    .optional(),
  line_item_control_number_REF: z.union([PurpleLineItemControlNumberSchema, z.null()]).optional(),
  line_note_NTE: z.union([PurpleLineNoteSchema, z.null()]).optional(),
  line_pricing_repricing_information_HCP: z
    .union([PurpleLinePricingRepricingInformationSchema, z.null()])
    .optional(),
  line_supplemental_information_PWK: z
    .union([z.array(PurpleLineSupplementalInformationSchema), z.null()])
    .optional(),
  mammography_certification_number_REF: z
    .union([FluffyMammographyCertificationNumberSchema, z.null()])
    .optional(),
  obstetric_anesthesia_additional_units_QTY: z
    .union([PurpleObstetricAnesthesiaAdditionalUnitsSchema, z.null()])
    .optional(),
  ordering_provider_name_NM1_loop: z
    .union([PurpleOrderingProviderNameLoopSchema, z.null()])
    .optional(),
  postage_claimed_amount_AMT: z.union([PurplePostageClaimedAmountSchema, z.null()]).optional(),
  prior_authorization_REF: z.union([z.array(FluffyPriorAuthorizationSchema), z.null()]).optional(),
  professional_service_SV1: PurpleProfessionalServiceSchema,
  purchased_service_information_PS1: z
    .union([PurplePurchasedServiceInformationSchema, z.null()])
    .optional(),
  purchased_service_provider_name_NM1_loop: z
    .union([PurplePurchasedServiceProviderNameLoopSchema, z.null()])
    .optional(),
  referral_number_REF: z.union([z.array(FluffyReferralNumberSchema), z.null()]).optional(),
  referring_clinical_laboratory_improvement_amendment_clia_facility_identification_REF: z
    .union([
      PurpleReferringClinicalLaboratoryImprovementAmendmentCliaFacilityIdentificationSchema,
      z.null(),
    ])
    .optional(),
  referring_provider_name_NM1_loop: z
    .union([z.array(FluffyReferringProviderNameLoopSchema), z.null()])
    .optional(),
  rendering_provider_name_NM1_loop: z
    .union([FluffyRenderingProviderNameLoopSchema, z.null()])
    .optional(),
  repriced_line_item_reference_number_REF: z
    .union([PurpleRepricedLineItemReferenceNumberSchema, z.null()])
    .optional(),
  sales_tax_amount_AMT: z.union([PurpleSalesTaxAmountSchema, z.null()]).optional(),
  service_facility_location_name_NM1_loop: z
    .union([FluffyServiceFacilityLocationNameLoopSchema, z.null()])
    .optional(),
  service_line_number_LX: PurpleServiceLineNumberSchema,
  supervising_provider_name_NM1_loop: z
    .union([PurpleSupervisingProviderNameLoopSchema, z.null()])
    .optional(),
  test_result_MEA: z.union([z.array(PurpleTestResultSchema), z.null()]).optional(),
  third_party_organization_notes_NTE: z
    .union([PurpleThirdPartyOrganizationNotesSchema, z.null()])
    .optional(),
});
export type PurpleServiceLineNumberLoop = z.infer<typeof PurpleServiceLineNumberLoopSchema>;

export const PurpleServiceFacilityLocationSecondaryIdentificationSchema = z.object({
  laboratory_or_facility_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerServiceFacilityLocationSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleServiceFacilityLocationSecondaryIdentification = z.infer<
  typeof PurpleServiceFacilityLocationSecondaryIdentificationSchema
>;

export const PurpleServiceFacilityLocationNameSchema = z.object({
  entity_identifier_code_01: OtherPayerServiceFacilityLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  laboratory_or_facility_name_03: z.string(),
  laboratory_or_facility_primary_identifier_09: z.union([z.null(), z.string()]).optional(),
});
export type PurpleServiceFacilityLocationName = z.infer<
  typeof PurpleServiceFacilityLocationNameSchema
>;

export const PurpleServiceFacilityLocationCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  laboratory_or_facility_city_name_01: z.string(),
  laboratory_or_facility_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  laboratory_or_facility_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleServiceFacilityLocationCityStateZipCode = z.infer<
  typeof PurpleServiceFacilityLocationCityStateZipCodeSchema
>;

export const PurpleServiceFacilityLocationAddressSchema = z.object({
  laboratory_or_facility_address_line_01: z.string(),
  laboratory_or_facility_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleServiceFacilityLocationAddress = z.infer<
  typeof PurpleServiceFacilityLocationAddressSchema
>;

export const PurpleServiceFacilityContactInformationSchema = z.object({
  communication_number_04: z.string(),
  communication_number_06: z.union([z.null(), z.string()]).optional(),
  communication_number_qualifier_03:
    ServiceFacilityContactInformationCommunicationNumberQualifier03Schema,
  communication_number_qualifier_05: z
    .union([ServiceFacilityContactInformationCommunicationNumberQualifier05Schema, z.null()])
    .optional(),
  contact_function_code_01: ContactFunctionCodeSchema,
  name_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleServiceFacilityContactInformation = z.infer<
  typeof PurpleServiceFacilityContactInformationSchema
>;

export const PurpleServiceFacilityLocationNameLoopSchema = z.object({
  service_facility_contact_information_PER: z
    .union([PurpleServiceFacilityContactInformationSchema, z.null()])
    .optional(),
  service_facility_location_address_N3: PurpleServiceFacilityLocationAddressSchema,
  service_facility_location_city_state_zip_code_N4:
    PurpleServiceFacilityLocationCityStateZipCodeSchema,
  service_facility_location_name_NM1: PurpleServiceFacilityLocationNameSchema,
  service_facility_location_secondary_identification_REF: z
    .union([z.array(PurpleServiceFacilityLocationSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type PurpleServiceFacilityLocationNameLoop = z.infer<
  typeof PurpleServiceFacilityLocationNameLoopSchema
>;

export const PurpleServiceAuthorizationExceptionCodeSchema = z.object({
  reference_identification_qualifier_01:
    ServiceAuthorizationExceptionCodeRefReferenceIdentificationQualifier01Schema,
  service_authorization_exception_code_02: z.string(),
});
export type PurpleServiceAuthorizationExceptionCode = z.infer<
  typeof PurpleServiceAuthorizationExceptionCodeSchema
>;

export const PurpleRepricedClaimNumberSchema = z.object({
  reference_identification_qualifier_01:
    RepricedClaimNumberRefReferenceIdentificationQualifier01Schema,
  repriced_claim_reference_number_02: z.string(),
});
export type PurpleRepricedClaimNumber = z.infer<typeof PurpleRepricedClaimNumberSchema>;

export const PurpleRenderingProviderSpecialtyInformationSchema = z.object({
  provider_code_01: ErCodeSchema,
  provider_taxonomy_code_03: z.string(),
  reference_identification_qualifier_02: ReferenceIdentificationQualifier02_EnumSchema,
});
export type PurpleRenderingProviderSpecialtyInformation = z.infer<
  typeof PurpleRenderingProviderSpecialtyInformationSchema
>;

export const PurpleRenderingProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerRenderingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  rendering_provider_secondary_identifier_02: z.string(),
});
export type PurpleRenderingProviderSecondaryIdentification = z.infer<
  typeof PurpleRenderingProviderSecondaryIdentificationSchema
>;

export const PurpleRenderingProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerRenderingProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  rendering_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  rendering_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  rendering_provider_last_or_organization_name_03: z.string(),
  rendering_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  rendering_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type PurpleRenderingProviderName = z.infer<typeof PurpleRenderingProviderNameSchema>;

export const PurpleRenderingProviderNameLoopSchema = z.object({
  rendering_provider_name_NM1: PurpleRenderingProviderNameSchema,
  rendering_provider_secondary_identification_REF: z
    .union([z.array(PurpleRenderingProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
  rendering_provider_specialty_information_PRV: z
    .union([PurpleRenderingProviderSpecialtyInformationSchema, z.null()])
    .optional(),
});
export type PurpleRenderingProviderNameLoop = z.infer<typeof PurpleRenderingProviderNameLoopSchema>;

export const PurpleReferringProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  referring_provider_secondary_identifier_02: z.string(),
});
export type PurpleReferringProviderSecondaryIdentification = z.infer<
  typeof PurpleReferringProviderSecondaryIdentificationSchema
>;

export const PurpleReferringProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerReferringProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherPayerReferringProviderNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  referring_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  referring_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  referring_provider_last_name_03: z.string(),
  referring_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  referring_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type PurpleReferringProviderName = z.infer<typeof PurpleReferringProviderNameSchema>;

export const PurpleReferringProviderNameLoopSchema = z.object({
  referring_provider_name_NM1: PurpleReferringProviderNameSchema,
  referring_provider_secondary_identification_REF: z
    .union([z.array(PurpleReferringProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type PurpleReferringProviderNameLoop = z.infer<typeof PurpleReferringProviderNameLoopSchema>;

export const PurpleReferralNumberSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerReferralNumberRefReferenceIdentificationQualifier01Schema,
  referral_number_02: z.string(),
});
export type PurpleReferralNumber = z.infer<typeof PurpleReferralNumberSchema>;

export const PurplePriorAuthorizationSchema = z.object({
  prior_authorization_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerPriorAuthorizationNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurplePriorAuthorization = z.infer<typeof PurplePriorAuthorizationSchema>;

export const PurplePayerClaimControlNumberSchema = z.object({
  payer_claim_control_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerClaimControlNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurplePayerClaimControlNumber = z.infer<typeof PurplePayerClaimControlNumberSchema>;

export const PurplePatientConditionInformationVisionSchema = z.object({
  certification_condition_indicator_02: ProviderOrSupplierSignatureIndicator06_EnumSchema,
  code_category_01: PatientConditionInformationVisionCrcCodeCategory01Schema,
  condition_code_03: PatientConditionInformationVisionCrcConditionCode03Schema,
  condition_code_04: z.union([z.null(), z.string()]).optional(),
  condition_code_05: z.union([z.null(), z.string()]).optional(),
  condition_code_06: z.union([z.null(), z.string()]).optional(),
  condition_code_07: z.union([z.null(), z.string()]).optional(),
});
export type PurplePatientConditionInformationVision = z.infer<
  typeof PurplePatientConditionInformationVisionSchema
>;

export const PurplePatientAmountPaidSchema = z.object({
  amount_qualifier_code_01: ReferenceIdentificationQualifierSchema,
  patient_amount_paid_02: z.number(),
});
export type PurplePatientAmountPaid = z.infer<typeof PurplePatientAmountPaidSchema>;

export const PurpleRemainingPatientLiabilitySchema = z.object({
  amount_qualifier_code_01: RemainingPatientLiabilityAmtAmountQualifierCode01Schema,
  remaining_patient_liability_02: z.number(),
});
export type PurpleRemainingPatientLiability = z.infer<typeof PurpleRemainingPatientLiabilitySchema>;

export const PurpleOutpatientAdjudicationInformationSchema = z.object({
  claim_payment_remark_code_03: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_04: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_05: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_06: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_07: z.union([z.null(), z.string()]).optional(),
  end_stage_renal_disease_payment_amount_08: z.union([z.number(), z.null()]).optional(),
  hcpcs_payable_amount_02: z.union([z.number(), z.null()]).optional(),
  non_payable_professional_component_billed_amount_09: z.union([z.number(), z.null()]).optional(),
  reimbursement_rate_01: z.union([z.number(), z.null()]).optional(),
});
export type PurpleOutpatientAdjudicationInformation = z.infer<
  typeof PurpleOutpatientAdjudicationInformationSchema
>;

export const PurpleOtherSubscriberSecondaryIdentificationSchema = z.object({
  other_insured_additional_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherSubscriberSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherSubscriberSecondaryIdentification = z.infer<
  typeof PurpleOtherSubscriberSecondaryIdentificationSchema
>;

export const PurpleOtherSubscriberNameSchema = z.object({
  entity_identifier_code_01: OtherSubscriberNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: OtherSubscriberNameNm1IdentificationCodeQualifier08Schema,
  other_insured_first_name_04: z.union([z.null(), z.string()]).optional(),
  other_insured_identifier_09: z.string(),
  other_insured_last_name_03: z.string(),
  other_insured_middle_name_05: z.union([z.null(), z.string()]).optional(),
  other_insured_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type PurpleOtherSubscriberName = z.infer<typeof PurpleOtherSubscriberNameSchema>;

export const PurpleOtherSubscriberCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  other_subscriber_city_name_01: z.string(),
  other_subscriber_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  other_subscriber_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleOtherSubscriberCityStateZipCode = z.infer<
  typeof PurpleOtherSubscriberCityStateZipCodeSchema
>;

export const PurpleOtherSubscriberAddressSchema = z.object({
  other_insured_address_line_02: z.union([z.null(), z.string()]).optional(),
  other_subscriber_address_line_01: z.string(),
});
export type PurpleOtherSubscriberAddress = z.infer<typeof PurpleOtherSubscriberAddressSchema>;

export const PurpleOtherSubscriberNameLoopSchema = z.object({
  other_subscriber_address_N3: z.union([PurpleOtherSubscriberAddressSchema, z.null()]).optional(),
  other_subscriber_city_state_zip_code_N4: z
    .union([PurpleOtherSubscriberCityStateZipCodeSchema, z.null()])
    .optional(),
  other_subscriber_name_NM1: PurpleOtherSubscriberNameSchema,
  other_subscriber_secondary_identification_REF: z
    .union([PurpleOtherSubscriberSecondaryIdentificationSchema, z.null()])
    .optional(),
});
export type PurpleOtherSubscriberNameLoop = z.infer<typeof PurpleOtherSubscriberNameLoopSchema>;

export const PurpleOtherSubscriberInformationSchema = z.object({
  claim_filing_indicator_code_09: ClaimFilingIndicatorCodeSchema,
  individual_relationship_code_02: OtherSubscriberInformationSbrIndividualRelationshipCode02Schema,
  insurance_type_code_05: z.union([InsuranceTypeCodeSchema, z.null()]).optional(),
  insured_group_or_policy_number_03: z.union([z.null(), z.string()]).optional(),
  other_insured_group_name_04: z.union([z.null(), z.string()]).optional(),
  payer_responsibility_sequence_number_code_01: PayerResponsibilitySequenceNumberCodeSchema,
});
export type PurpleOtherSubscriberInformation = z.infer<
  typeof PurpleOtherSubscriberInformationSchema
>;

export const PurpleOtherPayerSupervisingProviderSecondaryIdentificationSchema = z.object({
  other_payer_supervising_provider_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerRenderingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerSupervisingProviderSecondaryIdentification = z.infer<
  typeof PurpleOtherPayerSupervisingProviderSecondaryIdentificationSchema
>;

export const PurpleOtherPayerSupervisingProviderSchema = z.object({
  entity_identifier_code_01: OtherPayerSupervisingProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherPayerReferringProviderNm1EntityTypeQualifier02Schema,
});
export type PurpleOtherPayerSupervisingProvider = z.infer<
  typeof PurpleOtherPayerSupervisingProviderSchema
>;

export const PurpleOtherPayerSupervisingProviderLoopSchema = z.object({
  other_payer_supervising_provider_NM1: PurpleOtherPayerSupervisingProviderSchema,
  other_payer_supervising_provider_secondary_identification_REF: z.array(
    PurpleOtherPayerSupervisingProviderSecondaryIdentificationSchema
  ),
});
export type PurpleOtherPayerSupervisingProviderLoop = z.infer<
  typeof PurpleOtherPayerSupervisingProviderLoopSchema
>;

export const PurpleOtherPayerServiceFacilityLocationSecondaryIdentificationSchema = z.object({
  other_payer_service_facility_location_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerServiceFacilityLocationSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerServiceFacilityLocationSecondaryIdentification = z.infer<
  typeof PurpleOtherPayerServiceFacilityLocationSecondaryIdentificationSchema
>;

export const PurpleOtherPayerServiceFacilityLocationSchema = z.object({
  entity_identifier_code_01: OtherPayerServiceFacilityLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
});
export type PurpleOtherPayerServiceFacilityLocation = z.infer<
  typeof PurpleOtherPayerServiceFacilityLocationSchema
>;

export const PurpleOtherPayerServiceFacilityLocationLoopSchema = z.object({
  other_payer_service_facility_location_NM1: PurpleOtherPayerServiceFacilityLocationSchema,
  other_payer_service_facility_location_secondary_identification_REF: z.array(
    PurpleOtherPayerServiceFacilityLocationSecondaryIdentificationSchema
  ),
});
export type PurpleOtherPayerServiceFacilityLocationLoop = z.infer<
  typeof PurpleOtherPayerServiceFacilityLocationLoopSchema
>;

export const PurpleOtherPayerRenderingProviderSecondaryIdentificationSchema = z.object({
  other_payer_rendering_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerRenderingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerRenderingProviderSecondaryIdentification = z.infer<
  typeof PurpleOtherPayerRenderingProviderSecondaryIdentificationSchema
>;

export const PurpleOtherPayerRenderingProviderSchema = z.object({
  entity_identifier_code_01: OtherPayerRenderingProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
});
export type PurpleOtherPayerRenderingProvider = z.infer<
  typeof PurpleOtherPayerRenderingProviderSchema
>;

export const PurpleOtherPayerRenderingProviderLoopSchema = z.object({
  other_payer_rendering_provider_NM1: PurpleOtherPayerRenderingProviderSchema,
  other_payer_rendering_provider_secondary_identification_REF: z.array(
    PurpleOtherPayerRenderingProviderSecondaryIdentificationSchema
  ),
});
export type PurpleOtherPayerRenderingProviderLoop = z.infer<
  typeof PurpleOtherPayerRenderingProviderLoopSchema
>;

export const PurpleOtherPayerReferringProviderSecondaryIdentificationSchema = z.object({
  other_payer_referring_provider_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerReferringProviderSecondaryIdentification = z.infer<
  typeof PurpleOtherPayerReferringProviderSecondaryIdentificationSchema
>;

export const PurpleOtherPayerReferringProviderSchema = z.object({
  entity_identifier_code_01: OtherPayerReferringProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherPayerReferringProviderNm1EntityTypeQualifier02Schema,
});
export type PurpleOtherPayerReferringProvider = z.infer<
  typeof PurpleOtherPayerReferringProviderSchema
>;

export const PurpleOtherPayerReferringProviderLoopSchema = z.object({
  other_payer_referring_provider_NM1: PurpleOtherPayerReferringProviderSchema,
  other_payer_referring_provider_secondary_identification_REF: z.array(
    PurpleOtherPayerReferringProviderSecondaryIdentificationSchema
  ),
});
export type PurpleOtherPayerReferringProviderLoop = z.infer<
  typeof PurpleOtherPayerReferringProviderLoopSchema
>;

export const PurpleOtherPayerSecondaryIdentifierSchema = z.object({
  other_payer_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerSecondaryIdentifierRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerSecondaryIdentifier = z.infer<
  typeof PurpleOtherPayerSecondaryIdentifierSchema
>;

export const PurpleOtherPayerReferralNumberSchema = z.object({
  other_payer_prior_authorization_or_referral_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerReferralNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerReferralNumber = z.infer<typeof PurpleOtherPayerReferralNumberSchema>;

export const PurpleOtherPayerPriorAuthorizationNumberSchema = z.object({
  other_payer_prior_authorization_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerPriorAuthorizationNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerPriorAuthorizationNumber = z.infer<
  typeof PurpleOtherPayerPriorAuthorizationNumberSchema
>;

export const PurpleOtherPayerNameSchema = z.object({
  entity_identifier_code_01: OtherPayerNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: PayToPlanNameNm1IdentificationCodeQualifier08Schema,
  other_payer_organization_name_03: z.string(),
  other_payer_primary_identifier_09: z.string(),
});
export type PurpleOtherPayerName = z.infer<typeof PurpleOtherPayerNameSchema>;

export const PurpleOtherPayerClaimControlNumberSchema = z.object({
  other_payers_claim_control_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerClaimControlNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerClaimControlNumber = z.infer<
  typeof PurpleOtherPayerClaimControlNumberSchema
>;

export const PurpleOtherPayerClaimAdjustmentIndicatorSchema = z.object({
  other_payer_claim_adjustment_indicator_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerClaimAdjustmentIndicatorRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerClaimAdjustmentIndicator = z.infer<
  typeof PurpleOtherPayerClaimAdjustmentIndicatorSchema
>;

export const PurpleOtherPayerCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  other_payer_city_name_01: z.string(),
  other_payer_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  other_payer_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleOtherPayerCityStateZipCode = z.infer<
  typeof PurpleOtherPayerCityStateZipCodeSchema
>;

export const PurpleOtherPayerAddressSchema = z.object({
  other_payer_address_line_01: z.string(),
  other_payer_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleOtherPayerAddress = z.infer<typeof PurpleOtherPayerAddressSchema>;

export const PurpleClaimCheckOrRemittanceDateSchema = z.object({
  adjudication_or_payment_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: ClaimCheckOrRemittanceDateDtpDateTimeQualifier01Schema,
});
export type PurpleClaimCheckOrRemittanceDate = z.infer<
  typeof PurpleClaimCheckOrRemittanceDateSchema
>;

export const PurpleOtherPayerNameLoopSchema = z.object({
  claim_check_or_remittance_date_DTP: z
    .union([PurpleClaimCheckOrRemittanceDateSchema, z.null()])
    .optional(),
  other_payer_address_N3: z.union([PurpleOtherPayerAddressSchema, z.null()]).optional(),
  other_payer_city_state_zip_code_N4: z
    .union([PurpleOtherPayerCityStateZipCodeSchema, z.null()])
    .optional(),
  other_payer_claim_adjustment_indicator_REF: z
    .union([PurpleOtherPayerClaimAdjustmentIndicatorSchema, z.null()])
    .optional(),
  other_payer_claim_control_number_REF: z
    .union([PurpleOtherPayerClaimControlNumberSchema, z.null()])
    .optional(),
  other_payer_name_NM1: PurpleOtherPayerNameSchema,
  other_payer_prior_authorization_number_REF: z
    .union([PurpleOtherPayerPriorAuthorizationNumberSchema, z.null()])
    .optional(),
  other_payer_referral_number_REF: z
    .union([PurpleOtherPayerReferralNumberSchema, z.null()])
    .optional(),
  other_payer_secondary_identifier_REF: z
    .union([z.array(PurpleOtherPayerSecondaryIdentifierSchema), z.null()])
    .optional(),
});
export type PurpleOtherPayerNameLoop = z.infer<typeof PurpleOtherPayerNameLoopSchema>;

export const PurpleOtherPayerBillingProviderSecondaryIdentificationSchema = z.object({
  other_payer_billing_provider_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerBillingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerBillingProviderSecondaryIdentification = z.infer<
  typeof PurpleOtherPayerBillingProviderSecondaryIdentificationSchema
>;

export const PurpleOtherPayerBillingProviderSchema = z.object({
  entity_identifier_code_01: BillingProviderNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
});
export type PurpleOtherPayerBillingProvider = z.infer<typeof PurpleOtherPayerBillingProviderSchema>;

export const PurpleOtherPayerBillingProviderLoopSchema = z.object({
  other_payer_billing_provider_NM1: PurpleOtherPayerBillingProviderSchema,
  other_payer_billing_provider_secondary_identification_REF: z.array(
    PurpleOtherPayerBillingProviderSecondaryIdentificationSchema
  ),
});
export type PurpleOtherPayerBillingProviderLoop = z.infer<
  typeof PurpleOtherPayerBillingProviderLoopSchema
>;

export const PurpleOtherInsuranceCoverageInformationSchema = z.object({
  benefits_assignment_certification_indicator_03: BenefitsAssignmentCertificationIndicatorSchema,
  patient_signature_source_code_04: z
    .union([PatientSignatureSourceCodeSchema, z.null()])
    .optional(),
  release_of_information_code_06: ReleaseOfInformationCodeSchema,
});
export type PurpleOtherInsuranceCoverageInformation = z.infer<
  typeof PurpleOtherInsuranceCoverageInformationSchema
>;

export const PurpleCoordinationOfBenefitsCobTotalNonCoveredAmountSchema = z.object({
  amount_qualifier_code_01:
    CoordinationOfBenefitsCobTotalNonCoveredAmountAmtAmountQualifierCode01Schema,
  non_covered_charge_amount_02: z.number(),
});
export type PurpleCoordinationOfBenefitsCobTotalNonCoveredAmount = z.infer<
  typeof PurpleCoordinationOfBenefitsCobTotalNonCoveredAmountSchema
>;

export const PurpleCoordinationOfBenefitsCobPayerPaidAmountSchema = z.object({
  amount_qualifier_code_01: CoordinationOfBenefitsCobPayerPaidAmountAmtAmountQualifierCode01Schema,
  payer_paid_amount_02: z.number(),
});
export type PurpleCoordinationOfBenefitsCobPayerPaidAmount = z.infer<
  typeof PurpleCoordinationOfBenefitsCobPayerPaidAmountSchema
>;

export const PurpleClaimLevelAdjustmentsSchema = z.object({
  adjustment_amount_03: z.number(),
  adjustment_amount_06: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_09: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_12: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_15: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_18: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_04: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_07: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_10: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_13: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_16: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_19: z.union([z.number(), z.null()]).optional(),
  adjustment_reason_code_02: z.string(),
  adjustment_reason_code_05: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_08: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_11: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_14: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_17: z.union([z.null(), z.string()]).optional(),
  claim_adjustment_group_code_01: ClaimAdjustmentGroupCodeSchema,
});
export type PurpleClaimLevelAdjustments = z.infer<typeof PurpleClaimLevelAdjustmentsSchema>;

export const PurpleOtherSubscriberInformationLoopSchema = z.object({
  claim_level_adjustments_CAS: z
    .union([z.array(PurpleClaimLevelAdjustmentsSchema), z.null()])
    .optional(),
  coordination_of_benefits_cob_payer_paid_amount_AMT: z
    .union([PurpleCoordinationOfBenefitsCobPayerPaidAmountSchema, z.null()])
    .optional(),
  coordination_of_benefits_cob_total_non_covered_amount_AMT: z
    .union([PurpleCoordinationOfBenefitsCobTotalNonCoveredAmountSchema, z.null()])
    .optional(),
  other_insurance_coverage_information_OI: PurpleOtherInsuranceCoverageInformationSchema,
  other_payer_billing_provider_NM1_loop: z
    .union([PurpleOtherPayerBillingProviderLoopSchema, z.null()])
    .optional(),
  other_payer_name_NM1_loop: PurpleOtherPayerNameLoopSchema,
  other_payer_referring_provider_NM1_loop: z
    .union([z.array(PurpleOtherPayerReferringProviderLoopSchema), z.null()])
    .optional(),
  other_payer_rendering_provider_NM1_loop: z
    .union([PurpleOtherPayerRenderingProviderLoopSchema, z.null()])
    .optional(),
  other_payer_service_facility_location_NM1_loop: z
    .union([PurpleOtherPayerServiceFacilityLocationLoopSchema, z.null()])
    .optional(),
  other_payer_supervising_provider_NM1_loop: z
    .union([PurpleOtherPayerSupervisingProviderLoopSchema, z.null()])
    .optional(),
  other_subscriber_information_SBR: PurpleOtherSubscriberInformationSchema,
  other_subscriber_name_NM1_loop: PurpleOtherSubscriberNameLoopSchema,
  outpatient_adjudication_information_MOA: z
    .union([PurpleOutpatientAdjudicationInformationSchema, z.null()])
    .optional(),
  remaining_patient_liability_AMT: z
    .union([PurpleRemainingPatientLiabilitySchema, z.null()])
    .optional(),
});
export type PurpleOtherSubscriberInformationLoop = z.infer<
  typeof PurpleOtherSubscriberInformationLoopSchema
>;

export const PurpleMedicalRecordNumberSchema = z.object({
  medical_record_number_02: z.string(),
  reference_identification_qualifier_01:
    MedicalRecordNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleMedicalRecordNumber = z.infer<typeof PurpleMedicalRecordNumberSchema>;

export const PurpleMandatoryMedicareSection4081CrossoverIndicatorSchema = z.object({
  medicare_section_4081_indicator_02: z.string(),
  reference_identification_qualifier_01: ReferenceIdentificationQualifierSchema,
});
export type PurpleMandatoryMedicareSection4081CrossoverIndicator = z.infer<
  typeof PurpleMandatoryMedicareSection4081CrossoverIndicatorSchema
>;

export const PurpleMammographyCertificationNumberSchema = z.object({
  mammography_certification_number_02: z.string(),
  reference_identification_qualifier_01:
    MammographyCertificationNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleMammographyCertificationNumber = z.infer<
  typeof PurpleMammographyCertificationNumberSchema
>;

export const PurpleInvestigationalDeviceExemptionNumberSchema = z.object({
  investigational_device_exemption_identifier_02: z.string(),
  reference_identification_qualifier_01:
    InvestigationalDeviceExemptionNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleInvestigationalDeviceExemptionNumber = z.infer<
  typeof PurpleInvestigationalDeviceExemptionNumberSchema
>;

export const PurpleHomeboundIndicatorSchema = z.object({
  certification_condition_indicator_02: PregnancyIndicator09_EnumSchema,
  code_category_01: HomeboundIndicatorCrcCodeCategory01Schema,
  homebound_indicator_03: HomeboundIndicator03_EnumSchema,
});
export type PurpleHomeboundIndicator = z.infer<typeof PurpleHomeboundIndicatorSchema>;

export const HealthCareCodeInformation13Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation13 = z.infer<typeof HealthCareCodeInformation13Schema>;

export const HealthCareCodeInformation12Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation12 = z.infer<typeof HealthCareCodeInformation12Schema>;

export const HealthCareCodeInformation11Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation11 = z.infer<typeof HealthCareCodeInformation11Schema>;

export const HealthCareCodeInformation10Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation10 = z.infer<typeof HealthCareCodeInformation10Schema>;

export const HealthCareCodeInformation9Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation9 = z.infer<typeof HealthCareCodeInformation9Schema>;

export const HealthCareCodeInformation8Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation8 = z.infer<typeof HealthCareCodeInformation8Schema>;

export const HealthCareCodeInformation7Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation7 = z.infer<typeof HealthCareCodeInformation7Schema>;

export const HealthCareCodeInformation6Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation6 = z.infer<typeof HealthCareCodeInformation6Schema>;

export const HealthCareCodeInformation5Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation5 = z.infer<typeof HealthCareCodeInformation5Schema>;

export const HealthCareCodeInformation4Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation4 = z.infer<typeof HealthCareCodeInformation4Schema>;

export const HealthCareCodeInformation3Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation02_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation3 = z.infer<typeof HealthCareCodeInformation3Schema>;

export const HealthCareCodeInformation2Schema = z.object({
  diagnosis_code_02: z.string(),
  diagnosis_type_code_01: HealthCareCodeInformation01_DiagnosisTypeCode01Schema,
});
export type HealthCareCodeInformation2 = z.infer<typeof HealthCareCodeInformation2Schema>;

export const PurpleHealthCareDiagnosisCodeSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation2Schema,
  health_care_code_information_02: z.union([HealthCareCodeInformation3Schema, z.null()]).optional(),
  health_care_code_information_03: z.union([HealthCareCodeInformation4Schema, z.null()]).optional(),
  health_care_code_information_04: z.union([HealthCareCodeInformation5Schema, z.null()]).optional(),
  health_care_code_information_05: z.union([HealthCareCodeInformation6Schema, z.null()]).optional(),
  health_care_code_information_06: z.union([HealthCareCodeInformation7Schema, z.null()]).optional(),
  health_care_code_information_07: z.union([HealthCareCodeInformation8Schema, z.null()]).optional(),
  health_care_code_information_08: z.union([HealthCareCodeInformation9Schema, z.null()]).optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation10Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation11Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation12Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation13Schema, z.null()])
    .optional(),
});
export type PurpleHealthCareDiagnosisCode = z.infer<typeof PurpleHealthCareDiagnosisCodeSchema>;

export const PurpleFileInformationSchema = z.object({
  fixed_format_information_01: z.string(),
});
export type PurpleFileInformation = z.infer<typeof PurpleFileInformationSchema>;

export const PurpleEpsdtReferralSchema = z.object({
  certification_condition_code_applies_indicator_02:
    ProviderOrSupplierSignatureIndicator06_EnumSchema,
  code_qualifier_01: EpsdtReferralCrcCodeQualifier01Schema,
  condition_indicator_03: EpsdtReferralCrcConditionIndicator03Schema,
  condition_indicator_04: z.union([z.null(), z.string()]).optional(),
  condition_indicator_05: z.union([z.null(), z.string()]).optional(),
});
export type PurpleEpsdtReferral = z.infer<typeof PurpleEpsdtReferralSchema>;

export const PurpleDemonstrationProjectIdentifierSchema = z.object({
  demonstration_project_identifier_02: z.string(),
  reference_identification_qualifier_01:
    DemonstrationProjectIdentifierRefReferenceIdentificationQualifier01Schema,
});
export type PurpleDemonstrationProjectIdentifier = z.infer<
  typeof PurpleDemonstrationProjectIdentifierSchema
>;

export const PurpleDateRepricerReceivedDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateRepricerReceivedDateDtpDateTimeQualifier01Schema,
  repricer_received_date_03: z.string(),
});
export type PurpleDateRepricerReceivedDate = z.infer<typeof PurpleDateRepricerReceivedDateSchema>;

export const PurpleDatePropertyAndCasualtyDateOfFirstContactSchema = z.object({
  date_time_period_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DatePropertyAndCasualtyDateOfFirstContactDtpDateTimeQualifier01Schema,
});
export type PurpleDatePropertyAndCasualtyDateOfFirstContact = z.infer<
  typeof PurpleDatePropertyAndCasualtyDateOfFirstContactSchema
>;

export const PurpleDateOnsetOfCurrentIllnessOrSymptomSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateOnsetOfCurrentIllnessOrSymptomDtpDateTimeQualifier01Schema,
  onset_of_current_illness_or_injury_date_03: z.string(),
});
export type PurpleDateOnsetOfCurrentIllnessOrSymptom = z.infer<
  typeof PurpleDateOnsetOfCurrentIllnessOrSymptomSchema
>;

export const PurpleDateLastXRayDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateLastXRayDateDtpDateTimeQualifier01Schema,
  last_x_ray_date_03: z.string(),
});
export type PurpleDateLastXRayDate = z.infer<typeof PurpleDateLastXRayDateSchema>;

export const PurpleDateLastWorkedSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateLastWorkedDtpDateTimeQualifier01Schema,
  last_worked_date_03: z.string(),
});
export type PurpleDateLastWorked = z.infer<typeof PurpleDateLastWorkedSchema>;

export const PurpleDateLastSeenDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateLastSeenDateDtpDateTimeQualifier01Schema,
  last_seen_date_03: z.string(),
});
export type PurpleDateLastSeenDate = z.infer<typeof PurpleDateLastSeenDateSchema>;

export const PurpleDateLastMenstrualPeriodSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateLastMenstrualPeriodDtpDateTimeQualifier01Schema,
  last_menstrual_period_date_03: z.string(),
});
export type PurpleDateLastMenstrualPeriod = z.infer<typeof PurpleDateLastMenstrualPeriodSchema>;

export const PurpleDateInitialTreatmentDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateInitialTreatmentDateDtpDateTimeQualifier01Schema,
  initial_treatment_date_03: z.string(),
});
export type PurpleDateInitialTreatmentDate = z.infer<typeof PurpleDateInitialTreatmentDateSchema>;

export const PurpleDateHearingAndVisionPrescriptionDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateHearingAndVisionPrescriptionDateDtpDateTimeQualifier01Schema,
  prescription_date_03: z.string(),
});
export type PurpleDateHearingAndVisionPrescriptionDate = z.infer<
  typeof PurpleDateHearingAndVisionPrescriptionDateSchema
>;

export const PurpleDateDischargeSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateDischargeDtpDateTimeQualifier01Schema,
  related_hospitalization_discharge_date_03: z.string(),
});
export type PurpleDateDischarge = z.infer<typeof PurpleDateDischargeSchema>;

export const PurpleDateDisabilityDatesSchema = z.object({
  date_time_period_format_qualifier_02: DateDisabilityDatesDtpDateTimePeriodFormatQualifier02Schema,
  date_time_qualifier_01: DateDisabilityDatesDtpDateTimeQualifier01Schema,
  disability_from_date_03: z.string(),
});
export type PurpleDateDisabilityDates = z.infer<typeof PurpleDateDisabilityDatesSchema>;

export const PurpleDateAuthorizedReturnToWorkSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateAuthorizedReturnToWorkDtpDateTimeQualifier01Schema,
  work_return_date_03: z.string(),
});
export type PurpleDateAuthorizedReturnToWork = z.infer<
  typeof PurpleDateAuthorizedReturnToWorkSchema
>;

export const PurpleDateAssumedAndRelinquishedCareDatesSchema = z.object({
  assumed_or_relinquished_care_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateAssumedAndRelinquishedCareDatesDtpDateTimeQualifier01Schema,
});
export type PurpleDateAssumedAndRelinquishedCareDates = z.infer<
  typeof PurpleDateAssumedAndRelinquishedCareDatesSchema
>;

export const PurpleDateAdmissionSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateAdmissionDtpDateTimeQualifier01Schema,
  related_hospitalization_admission_date_03: z.string(),
});
export type PurpleDateAdmission = z.infer<typeof PurpleDateAdmissionSchema>;

export const PurpleDateAcuteManifestationSchema = z.object({
  acute_manifestation_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateAcuteManifestationDtpDateTimeQualifier01Schema,
});
export type PurpleDateAcuteManifestation = z.infer<typeof PurpleDateAcuteManifestationSchema>;

export const PurpleDateAccidentSchema = z.object({
  accident_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier05_EnumSchema,
  date_time_qualifier_01: DateAccidentDtpDateTimeQualifier01Schema,
});
export type PurpleDateAccident = z.infer<typeof PurpleDateAccidentSchema>;

export const PurpleContractInformationSchema = z.object({
  contract_amount_02: z.union([z.number(), z.null()]).optional(),
  contract_code_04: z.union([z.null(), z.string()]).optional(),
  contract_percentage_03: z.union([z.number(), z.null()]).optional(),
  contract_type_code_01: ContractTypeCodeSchema,
  contract_version_identifier_06: z.union([z.null(), z.string()]).optional(),
  terms_discount_percentage_05: z.union([z.number(), z.null()]).optional(),
});
export type PurpleContractInformation = z.infer<typeof PurpleContractInformationSchema>;

export const HealthCareCodeInformation1Schema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation1 = z.infer<typeof HealthCareCodeInformation1Schema>;

export const BraggadociousHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type BraggadociousHealthCareCodeInformation = z.infer<
  typeof BraggadociousHealthCareCodeInformationSchema
>;

export const MischievousHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type MischievousHealthCareCodeInformation = z.infer<
  typeof MischievousHealthCareCodeInformationSchema
>;

export const FriskyHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type FriskyHealthCareCodeInformation = z.infer<typeof FriskyHealthCareCodeInformationSchema>;

export const MagentaHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type MagentaHealthCareCodeInformation = z.infer<
  typeof MagentaHealthCareCodeInformationSchema
>;

export const CunningHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type CunningHealthCareCodeInformation = z.infer<
  typeof CunningHealthCareCodeInformationSchema
>;

export const AmbitiousHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type AmbitiousHealthCareCodeInformation = z.infer<
  typeof AmbitiousHealthCareCodeInformationSchema
>;

export const HilariousHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type HilariousHealthCareCodeInformation = z.infer<
  typeof HilariousHealthCareCodeInformationSchema
>;

export const IndecentHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type IndecentHealthCareCodeInformation = z.infer<
  typeof IndecentHealthCareCodeInformationSchema
>;

export const IndigoHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type IndigoHealthCareCodeInformation = z.infer<typeof IndigoHealthCareCodeInformationSchema>;

export const StickyHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type StickyHealthCareCodeInformation = z.infer<typeof StickyHealthCareCodeInformationSchema>;

export const TentacledHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: HealthCareCodeInformation03_CodeListQualifierCode01Schema,
  condition_code_02: z.string(),
});
export type TentacledHealthCareCodeInformation = z.infer<
  typeof TentacledHealthCareCodeInformationSchema
>;

export const PurpleConditionInformationSchema = z.object({
  health_care_code_information_01: TentacledHealthCareCodeInformationSchema,
  health_care_code_information_02: z
    .union([StickyHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([IndigoHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([IndecentHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([HilariousHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([AmbitiousHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([CunningHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([MagentaHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([FriskyHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([MischievousHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([BraggadociousHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_12: z.union([HealthCareCodeInformation1Schema, z.null()]).optional(),
});
export type PurpleConditionInformation = z.infer<typeof PurpleConditionInformationSchema>;

export const PurpleClinicalLaboratoryImprovementAmendmentCliaNumberSchema = z.object({
  clinical_laboratory_improvement_amendment_number_02: z.string(),
  reference_identification_qualifier_01:
    ClinicalLaboratoryImprovementAmendmentCliaNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleClinicalLaboratoryImprovementAmendmentCliaNumber = z.infer<
  typeof PurpleClinicalLaboratoryImprovementAmendmentCliaNumberSchema
>;

export const PurpleClaimSupplementalInformationSchema = z.object({
  attachment_control_number_06: z.union([z.null(), z.string()]).optional(),
  attachment_report_type_code_01: ClaimSupplementalInformationAttachmentReportTypeCode01Schema,
  attachment_transmission_code_02: ClaimSupplementalInformationAttachmentTransmissionCode02Schema,
  identification_code_qualifier_05: z
    .union([IdentificationCodeQualifier05_EnumSchema, z.null()])
    .optional(),
});
export type PurpleClaimSupplementalInformation = z.infer<
  typeof PurpleClaimSupplementalInformationSchema
>;

export const PurpleClaimPricingRepricingInformationSchema = z.object({
  exception_code_15: z.union([ExceptionCodeSchema, z.null()]).optional(),
  policy_compliance_code_14: z.union([PolicyComplianceCodeSchema, z.null()]).optional(),
  pricing_methodology_01: ClaimPricingRepricingInformationPricingMethodology01Schema,
  reject_reason_code_13: z.union([RejectReasonCodeSchema, z.null()]).optional(),
  repriced_allowed_amount_02: z.number(),
  repriced_approved_ambulatory_patient_group_amount_07: z.union([z.number(), z.null()]).optional(),
  repriced_approved_ambulatory_patient_group_code_06: z.union([z.null(), z.string()]).optional(),
  repriced_saving_amount_03: z.union([z.number(), z.null()]).optional(),
  repricing_organization_identifier_04: z.union([z.null(), z.string()]).optional(),
  repricing_per_diem_or_flat_rate_amount_05: z.union([z.number(), z.null()]).optional(),
});
export type PurpleClaimPricingRepricingInformation = z.infer<
  typeof PurpleClaimPricingRepricingInformationSchema
>;

export const PurpleClaimNoteSchema = z.object({
  claim_note_text_02: z.string(),
  note_reference_code_01: ClaimNoteNteNoteReferenceCode01Schema,
});
export type PurpleClaimNote = z.infer<typeof PurpleClaimNoteSchema>;

export const PurpleRelatedCausesInformationSchema = z.object({
  auto_accident_state_or_province_code_04: z.union([z.null(), z.string()]).optional(),
  country_code_05: z.union([z.null(), z.string()]).optional(),
  related_causes_code_01: RelatedCausesCodeSchema,
  related_causes_code_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleRelatedCausesInformation = z.infer<typeof PurpleRelatedCausesInformationSchema>;

export const PurpleHealthCareServiceLocationInformationSchema = z.object({
  claim_frequency_code_03: z.string(),
  facility_code_qualifier_02: FacilityCodeQualifierSchema,
  place_of_service_code_01: z.string(),
});
export type PurpleHealthCareServiceLocationInformation = z.infer<
  typeof PurpleHealthCareServiceLocationInformationSchema
>;

export const PurpleClaimInformationSchema = z.object({
  assignment_or_plan_participation_code_07: AssignmentOrPlanParticipationCodeSchema,
  benefits_assignment_certification_indicator_08: BenefitsAssignmentCertificationIndicatorSchema,
  delay_reason_code_20: z.union([DelayReasonCodeSchema, z.null()]).optional(),
  health_care_service_location_information_05: PurpleHealthCareServiceLocationInformationSchema,
  patient_control_number_01: z.string(),
  patient_signature_source_code_10: z
    .union([PatientSignatureSourceCodeSchema, z.null()])
    .optional(),
  provider_or_supplier_signature_indicator_06: ProviderOrSupplierSignatureIndicator06_EnumSchema,
  related_causes_information_11: z
    .union([PurpleRelatedCausesInformationSchema, z.null()])
    .optional(),
  release_of_information_code_09: ReleaseOfInformationCodeSchema,
  special_program_indicator_12: z.union([SpecialProgramIndicatorSchema, z.null()]).optional(),
  total_claim_charge_amount_02: z.number(),
});
export type PurpleClaimInformation = z.infer<typeof PurpleClaimInformationSchema>;

export const PurpleClaimIdentifierForTransmissionIntermediariesSchema = z.object({
  reference_identification_qualifier_01:
    ClaimIdentifierForTransmissionIntermediariesRefReferenceIdentificationQualifier01Schema,
  value_added_network_trace_number_02: z.string(),
});
export type PurpleClaimIdentifierForTransmissionIntermediaries = z.infer<
  typeof PurpleClaimIdentifierForTransmissionIntermediariesSchema
>;

export const PurpleCarePlanOversightSchema = z.object({
  care_plan_oversight_number_02: z.string(),
  reference_identification_qualifier_01:
    CarePlanOversightRefReferenceIdentificationQualifier01Schema,
});
export type PurpleCarePlanOversight = z.infer<typeof PurpleCarePlanOversightSchema>;

export const FluffyHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  industry_code_02: z.string(),
});
export type FluffyHealthCareCodeInformation = z.infer<typeof FluffyHealthCareCodeInformationSchema>;

export const PurpleHealthCareCodeInformationSchema = z.object({
  anesthesia_related_surgical_procedure_02: z.string(),
  code_list_qualifier_code_01: PurpleCodeListQualifierCodeSchema,
});
export type PurpleHealthCareCodeInformation = z.infer<typeof PurpleHealthCareCodeInformationSchema>;

export const PurpleAnesthesiaRelatedProcedureSchema = z.object({
  health_care_code_information_01: PurpleHealthCareCodeInformationSchema,
  health_care_code_information_02: z
    .union([FluffyHealthCareCodeInformationSchema, z.null()])
    .optional(),
});
export type PurpleAnesthesiaRelatedProcedure = z.infer<
  typeof PurpleAnesthesiaRelatedProcedureSchema
>;

export const PurpleAmbulanceTransportInformationSchema = z.object({
  ambulance_transport_reason_code_04: AmbulanceTransportReasonCodeSchema,
  patient_weight_02: z.union([z.number(), z.null()]).optional(),
  round_trip_purpose_description_09: z.union([z.null(), z.string()]).optional(),
  stretcher_purpose_description_10: z.union([z.null(), z.string()]).optional(),
  transport_distance_06: z.number(),
  unit_or_basis_for_measurement_code_01: z
    .union([UnitOrBasisForMeasurementCode01_EnumSchema, z.null()])
    .optional(),
  unit_or_basis_for_measurement_code_05: UnitOrBasisForMeasurementCode05_EnumSchema,
});
export type PurpleAmbulanceTransportInformation = z.infer<
  typeof PurpleAmbulanceTransportInformationSchema
>;

export const PurpleAmbulancePickUpLocationSchema = z.object({
  entity_identifier_code_01: AmbulancePickUpLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
});
export type PurpleAmbulancePickUpLocation = z.infer<typeof PurpleAmbulancePickUpLocationSchema>;

export const PurpleAmbulancePickUpLocationCityStateZipCodeSchema = z.object({
  ambulance_pick_up_city_name_01: z.string(),
  ambulance_pick_up_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  ambulance_pick_up_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
});
export type PurpleAmbulancePickUpLocationCityStateZipCode = z.infer<
  typeof PurpleAmbulancePickUpLocationCityStateZipCodeSchema
>;

export const PurpleAmbulancePickUpLocationAddressSchema = z.object({
  ambulance_pick_up_address_line_01: z.string(),
  ambulance_pick_up_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleAmbulancePickUpLocationAddress = z.infer<
  typeof PurpleAmbulancePickUpLocationAddressSchema
>;

export const PurpleAmbulancePickUpLocationLoopSchema = z.object({
  ambulance_pick_up_location_address_N3: PurpleAmbulancePickUpLocationAddressSchema,
  ambulance_pick_up_location_city_state_zip_code_N4:
    PurpleAmbulancePickUpLocationCityStateZipCodeSchema,
  ambulance_pick_up_location_NM1: PurpleAmbulancePickUpLocationSchema,
});
export type PurpleAmbulancePickUpLocationLoop = z.infer<
  typeof PurpleAmbulancePickUpLocationLoopSchema
>;

export const PurpleAmbulanceDropOffLocationSchema = z.object({
  ambulance_drop_off_location_03: z.union([z.null(), z.string()]).optional(),
  entity_identifier_code_01: AmbulanceDropOffLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
});
export type PurpleAmbulanceDropOffLocation = z.infer<typeof PurpleAmbulanceDropOffLocationSchema>;

export const PurpleAmbulanceDropOffLocationCityStateZipCodeSchema = z.object({
  ambulance_drop_off_city_name_01: z.string(),
  ambulance_drop_off_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  ambulance_drop_off_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
});
export type PurpleAmbulanceDropOffLocationCityStateZipCode = z.infer<
  typeof PurpleAmbulanceDropOffLocationCityStateZipCodeSchema
>;

export const PurpleAmbulanceDropOffLocationAddressSchema = z.object({
  ambulance_drop_off_address_line_01: z.string(),
  ambulance_drop_off_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleAmbulanceDropOffLocationAddress = z.infer<
  typeof PurpleAmbulanceDropOffLocationAddressSchema
>;

export const PurpleAmbulanceDropOffLocationLoopSchema = z.object({
  ambulance_drop_off_location_address_N3: PurpleAmbulanceDropOffLocationAddressSchema,
  ambulance_drop_off_location_city_state_zip_code_N4:
    PurpleAmbulanceDropOffLocationCityStateZipCodeSchema,
  ambulance_drop_off_location_NM1: PurpleAmbulanceDropOffLocationSchema,
});
export type PurpleAmbulanceDropOffLocationLoop = z.infer<
  typeof PurpleAmbulanceDropOffLocationLoopSchema
>;

export const PurpleAmbulanceCertificationSchema = z.object({
  certification_condition_indicator_02: ProviderOrSupplierSignatureIndicator06_EnumSchema,
  code_category_01: AmbulanceCertificationCrcCodeCategory01Schema,
  condition_code_03: AmbulanceCertificationCrcConditionCode03Schema,
  condition_code_04: z.union([z.null(), z.string()]).optional(),
  condition_code_05: z.union([z.null(), z.string()]).optional(),
  condition_code_06: z.union([z.null(), z.string()]).optional(),
  condition_code_07: z.union([z.null(), z.string()]).optional(),
});
export type PurpleAmbulanceCertification = z.infer<typeof PurpleAmbulanceCertificationSchema>;

export const PurpleAdjustedRepricedClaimNumberSchema = z.object({
  adjusted_repriced_claim_reference_number_02: z.string(),
  reference_identification_qualifier_01:
    AdjustedRepricedClaimNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleAdjustedRepricedClaimNumber = z.infer<
  typeof PurpleAdjustedRepricedClaimNumberSchema
>;

export const SubscriberHierarchicalLevelHlLoopClaimInformationClmLoopSchema = z.object({
  adjusted_repriced_claim_number_REF: z
    .union([PurpleAdjustedRepricedClaimNumberSchema, z.null()])
    .optional(),
  ambulance_certification_CRC: z
    .union([z.array(PurpleAmbulanceCertificationSchema), z.null()])
    .optional(),
  ambulance_drop_off_location_NM1_loop: z
    .union([PurpleAmbulanceDropOffLocationLoopSchema, z.null()])
    .optional(),
  ambulance_pick_up_location_NM1_loop: z
    .union([PurpleAmbulancePickUpLocationLoopSchema, z.null()])
    .optional(),
  ambulance_transport_information_CR1: z
    .union([PurpleAmbulanceTransportInformationSchema, z.null()])
    .optional(),
  anesthesia_related_procedure_HI: z
    .union([PurpleAnesthesiaRelatedProcedureSchema, z.null()])
    .optional(),
  care_plan_oversight_REF: z.union([PurpleCarePlanOversightSchema, z.null()]).optional(),
  claim_identifier_for_transmission_intermediaries_REF: z
    .union([PurpleClaimIdentifierForTransmissionIntermediariesSchema, z.null()])
    .optional(),
  claim_information_CLM: PurpleClaimInformationSchema,
  claim_note_NTE: z.union([PurpleClaimNoteSchema, z.null()]).optional(),
  claim_pricing_repricing_information_HCP: z
    .union([PurpleClaimPricingRepricingInformationSchema, z.null()])
    .optional(),
  claim_supplemental_information_PWK: z
    .union([z.array(PurpleClaimSupplementalInformationSchema), z.null()])
    .optional(),
  clinical_laboratory_improvement_amendment_clia_number_REF: z
    .union([PurpleClinicalLaboratoryImprovementAmendmentCliaNumberSchema, z.null()])
    .optional(),
  condition_information_HI: z
    .union([z.array(PurpleConditionInformationSchema), z.null()])
    .optional(),
  contract_information_CN1: z.union([PurpleContractInformationSchema, z.null()]).optional(),
  date_accident_DTP: z.union([PurpleDateAccidentSchema, z.null()]).optional(),
  date_acute_manifestation_DTP: z.union([PurpleDateAcuteManifestationSchema, z.null()]).optional(),
  date_admission_DTP: z.union([PurpleDateAdmissionSchema, z.null()]).optional(),
  date_assumed_and_relinquished_care_dates_DTP: z
    .union([z.array(PurpleDateAssumedAndRelinquishedCareDatesSchema), z.null()])
    .optional(),
  date_authorized_return_to_work_DTP: z
    .union([PurpleDateAuthorizedReturnToWorkSchema, z.null()])
    .optional(),
  date_disability_dates_DTP: z.union([PurpleDateDisabilityDatesSchema, z.null()]).optional(),
  date_discharge_DTP: z.union([PurpleDateDischargeSchema, z.null()]).optional(),
  date_hearing_and_vision_prescription_date_DTP: z
    .union([PurpleDateHearingAndVisionPrescriptionDateSchema, z.null()])
    .optional(),
  date_initial_treatment_date_DTP: z
    .union([PurpleDateInitialTreatmentDateSchema, z.null()])
    .optional(),
  date_last_menstrual_period_DTP: z
    .union([PurpleDateLastMenstrualPeriodSchema, z.null()])
    .optional(),
  date_last_seen_date_DTP: z.union([PurpleDateLastSeenDateSchema, z.null()]).optional(),
  date_last_worked_DTP: z.union([PurpleDateLastWorkedSchema, z.null()]).optional(),
  date_last_x_ray_date_DTP: z.union([PurpleDateLastXRayDateSchema, z.null()]).optional(),
  date_onset_of_current_illness_or_symptom_DTP: z
    .union([PurpleDateOnsetOfCurrentIllnessOrSymptomSchema, z.null()])
    .optional(),
  date_property_and_casualty_date_of_first_contact_DTP: z
    .union([PurpleDatePropertyAndCasualtyDateOfFirstContactSchema, z.null()])
    .optional(),
  date_repricer_received_date_DTP: z
    .union([PurpleDateRepricerReceivedDateSchema, z.null()])
    .optional(),
  demonstration_project_identifier_REF: z
    .union([PurpleDemonstrationProjectIdentifierSchema, z.null()])
    .optional(),
  epsdt_referral_CRC: z.union([PurpleEpsdtReferralSchema, z.null()]).optional(),
  file_information_K3: z.union([z.array(PurpleFileInformationSchema), z.null()]).optional(),
  health_care_diagnosis_code_HI: PurpleHealthCareDiagnosisCodeSchema,
  homebound_indicator_CRC: z.union([PurpleHomeboundIndicatorSchema, z.null()]).optional(),
  investigational_device_exemption_number_REF: z
    .union([PurpleInvestigationalDeviceExemptionNumberSchema, z.null()])
    .optional(),
  mammography_certification_number_REF: z
    .union([PurpleMammographyCertificationNumberSchema, z.null()])
    .optional(),
  mandatory_medicare_section_4081_crossover_indicator_REF: z
    .union([PurpleMandatoryMedicareSection4081CrossoverIndicatorSchema, z.null()])
    .optional(),
  medical_record_number_REF: z.union([PurpleMedicalRecordNumberSchema, z.null()]).optional(),
  other_subscriber_information_SBR_loop: z
    .union([z.array(PurpleOtherSubscriberInformationLoopSchema), z.null()])
    .optional(),
  patient_amount_paid_AMT: z.union([PurplePatientAmountPaidSchema, z.null()]).optional(),
  patient_condition_information_vision_CRC: z
    .union([z.array(PurplePatientConditionInformationVisionSchema), z.null()])
    .optional(),
  payer_claim_control_number_REF: z
    .union([PurplePayerClaimControlNumberSchema, z.null()])
    .optional(),
  prior_authorization_REF: z.union([PurplePriorAuthorizationSchema, z.null()]).optional(),
  referral_number_REF: z.union([PurpleReferralNumberSchema, z.null()]).optional(),
  referring_provider_name_NM1_loop: z
    .union([z.array(PurpleReferringProviderNameLoopSchema), z.null()])
    .optional(),
  rendering_provider_name_NM1_loop: z
    .union([PurpleRenderingProviderNameLoopSchema, z.null()])
    .optional(),
  repriced_claim_number_REF: z.union([PurpleRepricedClaimNumberSchema, z.null()]).optional(),
  service_authorization_exception_code_REF: z
    .union([PurpleServiceAuthorizationExceptionCodeSchema, z.null()])
    .optional(),
  service_facility_location_name_NM1_loop: z
    .union([PurpleServiceFacilityLocationNameLoopSchema, z.null()])
    .optional(),
  service_line_number_LX_loop: z.array(PurpleServiceLineNumberLoopSchema),
  spinal_manipulation_service_information_CR2: z
    .union([PurpleSpinalManipulationServiceInformationSchema, z.null()])
    .optional(),
  supervising_provider_name_NM1_loop: z
    .union([FluffySupervisingProviderNameLoopSchema, z.null()])
    .optional(),
});
export type SubscriberHierarchicalLevelHlLoopClaimInformationClmLoop = z.infer<
  typeof SubscriberHierarchicalLevelHlLoopClaimInformationClmLoopSchema
>;

export const SubscriberHierarchicalLevelLoopSchema = z.object({
  claim_information_CLM_loop: z
    .union([z.array(SubscriberHierarchicalLevelHlLoopClaimInformationClmLoopSchema), z.null()])
    .optional(),
  patient_hierarchical_level_HL_loop: z
    .union([z.array(PatientHierarchicalLevelLoopSchema), z.null()])
    .optional(),
  patient_information_PAT: z
    .union([PatientInformationPatientInformationSchema, z.null()])
    .optional(),
  payer_name_NM1_loop: PayerNameLoopSchema,
  subscriber_information_SBR: SubscriberInformationSchema,
  subscriber_name_NM1_loop: SubscriberNameLoopSchema,
});
export type SubscriberHierarchicalLevelLoop = z.infer<typeof SubscriberHierarchicalLevelLoopSchema>;

export const PayToPlanTaxIdentificationNumberSchema = z.object({
  pay_to_plan_tax_identification_number_02: z.string(),
  reference_identification_qualifier_01:
    PayToPlanTaxIdentificationNumberRefReferenceIdentificationQualifier01Schema,
});
export type PayToPlanTaxIdentificationNumber = z.infer<
  typeof PayToPlanTaxIdentificationNumberSchema
>;

export const PayToPlanSecondaryIdentificationSchema = z.object({
  pay_to_plan_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    PayToPlanSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PayToPlanSecondaryIdentification = z.infer<
  typeof PayToPlanSecondaryIdentificationSchema
>;

export const PayToPlanNameSchema = z.object({
  entity_identifier_code_01: ErCodeSchema,
  entity_type_qualifier_02: PayToPlanNameNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: PayToPlanNameNm1IdentificationCodeQualifier08Schema,
  pay_to_plan_organizational_name_03: z.string(),
  pay_to_plan_primary_identifier_09: z.string(),
});
export type PayToPlanName = z.infer<typeof PayToPlanNameSchema>;

export const PayToPlanCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  pay_to_plan_city_name_01: z.string(),
  pay_to_plan_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  pay_to_plan_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
});
export type PayToPlanCityStateZipCode = z.infer<typeof PayToPlanCityStateZipCodeSchema>;

export const PayToPlanAddressSchema = z.object({
  pay_to_plan_address_line_01: z.string(),
  pay_to_plan_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type PayToPlanAddress = z.infer<typeof PayToPlanAddressSchema>;

export const PayToPlanNameLoopSchema = z.object({
  pay_to_plan_address_N3: PayToPlanAddressSchema,
  pay_to_plan_city_state_zip_code_N4: PayToPlanCityStateZipCodeSchema,
  pay_to_plan_name_NM1: PayToPlanNameSchema,
  pay_to_plan_secondary_identification_REF: z
    .union([PayToPlanSecondaryIdentificationSchema, z.null()])
    .optional(),
  pay_to_plan_tax_identification_number_REF: PayToPlanTaxIdentificationNumberSchema,
});
export type PayToPlanNameLoop = z.infer<typeof PayToPlanNameLoopSchema>;

export const PayToAddressNameSchema = z.object({
  entity_identifier_code_01: PayToAddressNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
});
export type PayToAddressName = z.infer<typeof PayToAddressNameSchema>;

export const PayToAddressCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  pay_to_address_city_name_01: z.string(),
  pay_to_address_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  pay_to_address_state_code_02: z.union([z.null(), z.string()]).optional(),
});
export type PayToAddressCityStateZipCode = z.infer<typeof PayToAddressCityStateZipCodeSchema>;

export const PayToAddressAddressSchema = z.object({
  pay_to_address_line_01: z.string(),
  pay_to_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type PayToAddressAddress = z.infer<typeof PayToAddressAddressSchema>;

export const PayToAddressNameLoopSchema = z.object({
  pay_to_address_address_N3: PayToAddressAddressSchema,
  pay_to_address_city_state_zip_code_N4: PayToAddressCityStateZipCodeSchema,
  pay_to_address_name_NM1: PayToAddressNameSchema,
});
export type PayToAddressNameLoop = z.infer<typeof PayToAddressNameLoopSchema>;

export const ForeignCurrencyInformationSchema = z.object({
  currency_code_02: z.string(),
  entity_identifier_code_01: BillingProviderNameEntityIdentifierCode01Schema,
});
export type ForeignCurrencyInformation = z.infer<typeof ForeignCurrencyInformationSchema>;

export const BillingProviderSpecialtyInformationSchema = z.object({
  provider_code_01: ProviderCodeSchema,
  provider_taxonomy_code_03: z.string(),
  reference_identification_qualifier_02: ReferenceIdentificationQualifier02_EnumSchema,
});
export type BillingProviderSpecialtyInformation = z.infer<
  typeof BillingProviderSpecialtyInformationSchema
>;

export const BillingProviderUpinLicenseInformationSchema = z.object({
  billing_provider_license_and_or_upin_information_02: z.string(),
  reference_identification_qualifier_01:
    BillingProviderUpinLicenseInformationRefReferenceIdentificationQualifier01Schema,
});
export type BillingProviderUpinLicenseInformation = z.infer<
  typeof BillingProviderUpinLicenseInformationSchema
>;

export const BillingProviderTaxIdentificationSchema = z.object({
  billing_provider_tax_identification_number_02: z.string(),
  reference_identification_qualifier_01:
    BillingProviderTaxIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type BillingProviderTaxIdentification = z.infer<
  typeof BillingProviderTaxIdentificationSchema
>;

export const BillingProviderNameSchema = z.object({
  billing_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  billing_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  billing_provider_last_or_organizational_name_03: z.string(),
  billing_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  billing_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
  entity_identifier_code_01: BillingProviderNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
});
export type BillingProviderName = z.infer<typeof BillingProviderNameSchema>;

export const BillingProviderContactInformationSchema = z.object({
  billing_provider_contact_name_02: z.union([z.null(), z.string()]).optional(),
  communication_number_04: z.string(),
  communication_number_06: z.union([z.null(), z.string()]).optional(),
  communication_number_08: z.union([z.null(), z.string()]).optional(),
  communication_number_qualifier_03:
    BillingProviderContactInformationCommunicationNumberQualifier03Schema,
  communication_number_qualifier_05: z
    .union([CommunicationNumberQualifier07_EnumSchema, z.null()])
    .optional(),
  communication_number_qualifier_07: z
    .union([CommunicationNumberQualifier07_EnumSchema, z.null()])
    .optional(),
  contact_function_code_01: ContactFunctionCodeSchema,
});
export type BillingProviderContactInformation = z.infer<
  typeof BillingProviderContactInformationSchema
>;

export const BillingProviderCityStateZipCodeSchema = z.object({
  billing_provider_city_name_01: z.string(),
  billing_provider_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  billing_provider_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
});
export type BillingProviderCityStateZipCode = z.infer<typeof BillingProviderCityStateZipCodeSchema>;

export const BillingProviderAddressSchema = z.object({
  billing_provider_address_line_01: z.string(),
  billing_provider_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type BillingProviderAddress = z.infer<typeof BillingProviderAddressSchema>;

export const BillingProviderNameLoopSchema = z.object({
  billing_provider_address_N3: BillingProviderAddressSchema,
  billing_provider_city_state_zip_code_N4: BillingProviderCityStateZipCodeSchema,
  billing_provider_contact_information_PER: z
    .union([z.array(BillingProviderContactInformationSchema), z.null()])
    .optional(),
  billing_provider_name_NM1: BillingProviderNameSchema,
  billing_provider_tax_identification_REF: BillingProviderTaxIdentificationSchema,
  billing_provider_upin_license_information_REF: z
    .union([z.array(BillingProviderUpinLicenseInformationSchema), z.null()])
    .optional(),
});
export type BillingProviderNameLoop = z.infer<typeof BillingProviderNameLoopSchema>;

export const BillingProviderHierarchicalLevelLoopSchema: z.ZodType<BillingProviderHierarchicalLevelLoop> =
  z.object({
    billing_provider_name_NM1_loop: BillingProviderNameLoopSchema,
    billing_provider_specialty_information_PRV: z
      .union([BillingProviderSpecialtyInformationSchema, z.null()])
      .optional(),
    foreign_currency_information_CUR: z
      .union([ForeignCurrencyInformationSchema, z.null()])
      .optional(),
    pay_to_address_name_NM1_loop: z.union([PayToAddressNameLoopSchema, z.null()]).optional(),
    pay_to_plan_name_NM1_loop: z.union([PayToPlanNameLoopSchema, z.null()]).optional(),
    subscriber_hierarchical_level_HL_loop: z.array(SubscriberHierarchicalLevelLoopSchema),
  });

export type BillingProviderHierarchicalLevelLoop = {
  billing_provider_name_NM1_loop: BillingProviderNameLoop;
  billing_provider_specialty_information_PRV?: BillingProviderSpecialtyInformation | null;
  foreign_currency_information_CUR?: ForeignCurrencyInformation | null;
  pay_to_address_name_NM1_loop?: PayToAddressNameLoop | null;
  pay_to_plan_name_NM1_loop?: PayToPlanNameLoop | null;
  subscriber_hierarchical_level_HL_loop: SubscriberHierarchicalLevelLoop[];
};

export const DetailSchema = z.object({
  billing_provider_hierarchical_level_HL_loop: z.array(BillingProviderHierarchicalLevelLoopSchema),
  transaction_set_trailer_SE: z.union([TransactionSetTrailerSchema, z.null()]).optional(),
});
export type Detail = z.infer<typeof DetailSchema>;

export const X12837PSchema = z.object({
  detail: DetailSchema,
  heading: HeadingSchema,
});
export type X12837P = z.infer<typeof X12837PSchema>;
