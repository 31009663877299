import { ErrorScreen, LoadScreen } from "@procision-software/ui";
import { useAppContext } from "~/components/AppContextProvider";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { useRouter } from "~/hooks/useRouter";
import { Suspense, useMemo, type ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import Header from "../branding/Header";
import AscNavBar from "../navigation/navbar/asc";
import PlatformNavBar from "../navigation/navbar/platform";
import PracticeNavBar from "../navigation/navbar/practice";
import { SupportButton } from "~/procision-core/components/SupportButton";

export const AppLayout = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const { perspective } = useAppContext();

  const NavBar = useMemo(() => {
    if (perspective === "platform") return PlatformNavBar;
    if (perspective === "practice") return PracticeNavBar;
    if (perspective === "asc") return AscNavBar;
    return undefined;
  }, [perspective]);

  return (
    <div className="min-w-screen relative flex h-full min-h-screen w-full flex-col">
      <Header nav={NavBar}>
        <SupportButton />
      </Header>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            key={`err-${router.asPath}`} // Reset the error boundary when the route changes
            onReset={reset}
            fallback={
              <ErrorScreen
                error={"An error occurred while rendering this page. Please try again."}
              />
            }
          >
            <Suspense fallback={<LoadScreen />}>{children}</Suspense>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </div>
  );
};
