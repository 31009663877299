import type { PrismaClient } from "@procision-software/database";
import type { EmrTemplate, EmrTemplatePage } from "./types";
import { getEncounter } from "../encounter";

// Define a unique symbol
// declare const EmrTemplateIdTag: unique symbol;

// Create a tagged type
// export type EmrTemplateId = string & { readonly tag: typeof EmrTemplateIdTag };
export type EmrTemplateId = string;

// Function to tag a string
export function toEmrTemplateId(id: string): EmrTemplateId {
  return id;
}

export function emrTemplateIdToString(id: EmrTemplateId): string {
  return id.toString();
}

export const emrTemplateFindFirst = async (
  prisma: PrismaClient,
  params: Partial<{
    filters: Partial<{ id: string }>;
  }>
): Promise<EmrTemplate> => {
  const template = await prisma.emrTemplate.findFirstOrThrow({
    where: params.filters,
    include: {
      facility: {
        select: {
          id: true,
        },
      },
      pages: {
        include: {
          sections: {
            orderBy: {
              sequenceNumber: "asc",
            },
          },
        },
        where: {
          status: "Published",
        },
      },
    },
  });
  return {
    ...template,
    pages: template.pages.map((page) => ({
      ...page,
      template: {
        id: template.id,
      },
      sections: page.sections.map((section) => ({
        ...section,
        emrTemplatePage: {
          id: page.id,
        },
      })),
    })),
  };
};

export const emrTemplateFindById = async (
  prisma: PrismaClient,
  id: string
): Promise<EmrTemplate> => {
  return await emrTemplateFindFirst(prisma, { filters: { id } });
};

export async function getWorkflowsForEncounter(
  prisma: PrismaClient,
  encounterId: string
): Promise<EmrTemplatePage[]> {
  // load the right version for the particular encounter (not neccessarily the current or even published)
  const encounter = await getEncounter(prisma, encounterId); // get encounter will actually mutate the encounter if the template pages haven't been set yet
  const pageIds = encounter.workflowPages.map((wf) => wf.emrTemplatePageId);
  const pages = await prisma.emrTemplatePage.findMany({
    where: {
      id: {
        in: pageIds,
      },
    },
    include: {
      sections: {
        orderBy: [{ sequenceNumber: "asc" }],
      },
    },
  });
  return pages.map((page) => ({
    ...page,
    sections: page.sections.map((section) => ({
      ...section,
      emrTemplatePage: {
        id: page.id,
      },
    })),
    template: {
      id: encounter.emrTemplateId,
    },
  }));
}
