import { z } from 'zod';
import { PatStatusSchema } from '../inputTypeSchemas/PatStatusSchema'

/////////////////////////////////////////
// ENCOUNTER WORKFLOW SCHEMA
/////////////////////////////////////////

export const EncounterWorkflowSchema = z.object({
  patStatus: PatStatusSchema,
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  chargeNurseId: z.string().nullish(),
  emrTemplatePageId: z.string(),
  encounterId: z.string(),
})

export type EncounterWorkflow = z.infer<typeof EncounterWorkflowSchema>

export default EncounterWorkflowSchema;
