import { useSignIn } from "@clerk/nextjs";
import type { OAuthStrategy } from "@clerk/types";
import { Button, InputField } from "@procision-software/ui";
import Image from "next/image";
import Link from "next/link";
import { useForm, type SubmitHandler } from "react-hook-form";
import googleLogo from "~/assets/images/google.svg?resource";
import microsoftLogo from "~/assets/images/microsoft.svg?resource";
import logo from "~/assets/images/procision_logo_title.svg?resource";
import schedule from "~/assets/images/schedule.svg?resource";
import HeadlessLayout from "~/components/layouts/headless";
import { useRouter } from "~/hooks/useRouter";
import { wrapWithLayout } from "~/utils/wrap-with-layout";

type AuthenticationInput = {
  email: string;
  password: string;
};

const SignInPage = () => {
  const router = useRouter();
  const { redirectUrl } = router.query as { redirectUrl?: string };
  const { signIn, setActive } = useSignIn();
  const methods = useForm<AuthenticationInput>();
  const {
    handleSubmit,
    setError,
    setFocus,
    formState: { isSubmitting },
  } = methods;

  const afterSignInRedirectUrl = redirectUrl ? `/?redirectUrl=${redirectUrl}` : "/";

  const onSubmit: SubmitHandler<AuthenticationInput> = async (
    { email: identifier, password },
    event
  ) => {
    event?.preventDefault();

    try {
      const response = await signIn?.create({
        strategy: "password",
        identifier,
        password,
      });

      if (response?.status === "complete") {
        await setActive?.({
          session: response.createdSessionId,
        });
        await router.replace(afterSignInRedirectUrl);
      }
    } catch (_error) {
      setError("email", { message: "Invalid email address" });
      setError("password", { message: "Invalid password" });
      setFocus("email");
    }
  };

  const signInWith = (strategy: OAuthStrategy) => {
    return signIn?.authenticateWithRedirect({
      strategy,
      redirectUrl: "/sso-callback",
      redirectUrlComplete: afterSignInRedirectUrl,
    });
  };

  return (
    <div className="flex grow items-center justify-center">
      <div className="flex flex-col gap-10">
        <Image src={logo} width={217} height={65} alt="Procision Logo" className="ml-2" />
        <div className="flex flex-row items-center gap-20">
          <div className="">
            <Image src={schedule} width={723} height={592} alt="Procision Logo" />
          </div>
          <div className="flex w-96 flex-col gap-8">
            <div>
              <h1 className="text-2xl text-gray-900">Sign In</h1>
              <span className="text-sm text-gray-900">Enter to you account at Procision</span>
            </div>
            <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
              <InputField
                {...methods}
                name="email"
                label="Email Address"
                className="w-full"
                type="email"
              />
              <InputField
                {...methods}
                name="password"
                label="Password"
                className="w-full"
                type="password"
              />
              <div className="pb-4">
                <span className="text-xs text-gray-900 hover:text-blue-800">
                  Forgot password? <Link href="/forgot-password">Click here</Link>.
                </span>
              </div>
              <div>
                <Button
                  type="submit"
                  variant="primary"
                  outline={false}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  className="w-full"
                >
                  Continue
                </Button>
              </div>
            </form>
            <div className="flex flex-row items-center justify-center gap-6 text-gray-300">
              <hr className="h-px grow border-0 bg-gray-300" />
              <div>or</div>
              <hr className="h-px grow border-0 bg-gray-300" />
            </div>
            <div className="flex flex-col gap-4">
              <Button
                variant="primary"
                outline={true}
                type="button"
                onClick={async () => await signInWith("oauth_google")}
              >
                <Image src={googleLogo} width={16} height={16} alt="Google" /> Sign in with Google
              </Button>
              <Button
                variant="primary"
                outline={true}
                type="button"
                onClick={async () => await signInWith("oauth_microsoft")}
              >
                <Image src={microsoftLogo} width={16} height={16} alt="Microsoft" /> Sign in with
                Microsoft
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default wrapWithLayout(HeadlessLayout, SignInPage);
