import { EmptyZodString, type Address } from "@procision-software/database-zod";
import { z } from "zod";

export const AddressFormSchema = z.object({
  address1: z.string().trim().min(1, { message: "Required" }),
  address2: z.string().trim().nullish(),
  city: z.string().trim().min(1, { message: "Required" }),
  state: z.string().trim().length(2, { message: "Required" }),
  zip: z.string().trim().min(5, { message: "Required (>5 digits)" }),
} satisfies Partial<Record<keyof Address, z.ZodTypeAny>>);

export const OptionalAddressFormSchema = z.object({
  address1: z.string().trim().min(1, { message: "Required" }).or(EmptyZodString.optional()),
  address2: z.string().trim().nullish(),
  city: z.string().trim().min(1, { message: "Required" }).or(EmptyZodString.optional()),
  state: z.string().trim().length(2, { message: "Required" }).or(EmptyZodString.optional()),
  zip: z.string().trim().min(5, { message: "Required (>5 digits)" }).or(EmptyZodString.optional()),
} satisfies Partial<Record<keyof Address, z.ZodTypeAny>>);

export type AddressFormData = z.infer<typeof AddressFormSchema>;
