/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { z } from "zod";

export const EmptyZodString = z.string().trim().length(0);

/**
 * Helper function to determine if the zod schema is a ZodEffects.
 * @param schema - The Zod schema to check.
 */
export function isZodEffects<T extends z.ZodTypeAny>(
  schema: z.ZodTypeAny
): schema is z.ZodEffects<T> {
  return (
    schema &&
    (schema._def.typeName === "ZodEffects" ||
      isZodEffects(schema._def.innerType) ||
      isZodEffects(schema._def.schema))
  );
}

/**
 * Returns the keys of a Zod schema as an array of strings. This is useful for checking to see if a key
 * exists when working with generic Zod schemas. Deeply nested keys will be flattened by combining the
 * parent key with the child key separated by a period.
 * @param schema - The Zod schema to get the keys from.
 * @returns string[]
 * @example
 * ```tsx
 * const schema = z.object({
 *   firstName: z.string(),
 *   lastName: z.string(),
 *   address: z.object({
 *     city: z.string(),
 *     state: z.string(),
 *     zip: z.string(),
 *   }),
 * });
 * const keys = zodSchemaKeys(schema);
 * // keys = ["firstName", "lastName", "address.city", "address.state", "address.zip"]
 * ```
 */
export function zodSchemaKeys(schema: z.ZodTypeAny, prefix = ""): string[] {
  const paths = new Set<string>();

  // Unwrap the schema if it's wrapped with optional, nullable, default, effects, preprocess, or transformer
  let unwrappedSchema = schema;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (
      unwrappedSchema instanceof z.ZodOptional ||
      unwrappedSchema instanceof z.ZodNullable ||
      unwrappedSchema instanceof z.ZodDefault
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      unwrappedSchema = unwrappedSchema._def.innerType;
    } else if (
      unwrappedSchema instanceof z.ZodEffects ||
      unwrappedSchema instanceof z.ZodTransformer
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      unwrappedSchema = unwrappedSchema._def.schema;
    } else {
      break;
    }
  }

  if (unwrappedSchema instanceof z.ZodObject) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const shape = unwrappedSchema.shape;
    for (const key in shape) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const subSchema = shape[key];
      const newPrefix = prefix ? `${prefix}.${key}` : key;
      zodSchemaKeys(subSchema, newPrefix).forEach((path) => paths.add(path));
    }
  } else if (unwrappedSchema instanceof z.ZodUnion) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const options = unwrappedSchema._def.options;
    for (const option of options) {
      zodSchemaKeys(option, prefix).forEach((path) => paths.add(path));
    }
  } else {
    // Base case: primitive type
    paths.add(prefix);
  }

  return Array.from(paths);
}
