import { Footer } from "@procision-software/ui";

type LayoutProps = {
  children?: React.ReactNode;
  subnav?: React.ReactNode;
};

/**
 * @deprecated Use AppLayout instead
 */
export default function HeadlessLayout({ children }: LayoutProps) {
  return (
    <div className="flex min-h-screen flex-col">
      <main className="flex h-full min-h-full w-full grow">{children}</main>
      <Footer />
    </div>
  );
}
