import { Prisma, type PrismaClient } from "@procision-software/database";
import type { PaginationInput } from "../../util/pagination";
import { memoize } from "lodash-es";
import type { DocumentTemplateFormData, DocumentTemplateRecord } from "./types";
import { generateDocumentPreview } from "../tip-tap-editor";
import { codeableConceptIdForPath } from "../codeable-concept";
import type { JobRoleType } from "@procision-software/database-zod";

const RELATED_MODELS: Prisma.DocumentTemplateInclude = {
  type: true,
  organization: true,
};

type DocumentTemplateFindFilter = Partial<{
  organizationId: string;
  search: string;
  typeId: string;
  surgeryProfileId: string;
  jobRoleType: JobRoleType;
}>;

const documentTemplateWhereClause = memoize(
  async function documentTemplateWhereClause(
    prisma: PrismaClient,
    filters: DocumentTemplateFindFilter
  ): Promise<Prisma.DocumentTemplateWhereInput> {
    const result: Prisma.DocumentTemplateWhereInput = { deletedAt: null };
    if (filters.organizationId) {
      result.organization = {
        id: filters.organizationId,
      };
    }
    if (filters.typeId) {
      if (filters.typeId.includes(".")) {
        result.typeId = await codeableConceptIdForPath(prisma, filters.typeId.split("."));
      } else {
        result.typeId = filters.typeId;
      }
    }
    if (filters.surgeryProfileId) {
      result.surgeryProfiles = {
        some: {
          id: filters.surgeryProfileId,
        },
      };
    }
    if (filters.search) {
      const terms = filters.search.split(" ");
      result.AND = terms.map((term) => ({
        OR: [
          { title: { contains: term, mode: "insensitive" } },
          { preview: { contains: term, mode: "insensitive" } },
        ],
      }));
    }
    if (filters.typeId) {
      // TODO: Accept a KCC Path // TODO for Brian
      result.typeId = filters.typeId;
    }
    if (filters.jobRoleType) {
      result.jobRoleTypes = { hasSome: [filters.jobRoleType] };
    }
    return result;
  },
  (_prisma: PrismaClient, filters: DocumentTemplateFindFilter) => JSON.stringify(filters)
);

export async function documentTemplateCount(
  prisma: PrismaClient,
  filters: DocumentTemplateFindFilter
): Promise<number> {
  const where = await documentTemplateWhereClause(prisma, filters);
  return await prisma.documentTemplate.count({ where });
}

export async function documentTemplateFindMany(
  prisma: PrismaClient,
  filters: DocumentTemplateFindFilter,
  pagination: PaginationInput
): Promise<DocumentTemplateRecord[]> {
  const where = await documentTemplateWhereClause(prisma, filters);
  return await prisma.documentTemplate.findMany({
    where,
    skip: (pagination.page - 1) * pagination.perPage,
    take: pagination.perPage,
    include: RELATED_MODELS,
  });
}

export function documentTemplateFindById(
  prisma: PrismaClient,
  id: string
): Promise<DocumentTemplateRecord> {
  return prisma.documentTemplate.findFirstOrThrow({
    where: { id, deletedAt: null },
    include: RELATED_MODELS,
  });
}

export function documentTemplateCreate(
  prisma: PrismaClient,
  { organizationId, typeId, body, ...data }: DocumentTemplateFormData
): Promise<DocumentTemplateRecord> {
  return prisma.documentTemplate.create({
    data: {
      organization: { connect: { id: organizationId } },
      type: { connect: { id: typeId } },
      body: body ?? Prisma.JsonNull,
      preview: generateDocumentPreview(body),
      ...data,
    },
    include: RELATED_MODELS,
  });
}

export async function documentTemplateUpdate(
  prisma: PrismaClient,
  id: string,
  { typeId, organizationId, body, ...data }: Partial<DocumentTemplateFormData>
): Promise<DocumentTemplateRecord> {
  // access check on requested org
  const organization = organizationId
    ? await prisma.organization.findFirstOrThrow({ where: { id: organizationId } })
    : null;

  return prisma.documentTemplate.update({
    where: { id, deletedAt: null },
    data: {
      type: typeId ? { connect: { id: typeId } } : undefined,
      ...(organization && { organization: { connect: { id: organizationId } } }),
      ...(body && {
        body,
        preview: generateDocumentPreview(body),
      }),
      ...data,
    },
    include: RELATED_MODELS,
  });
}

export async function documentTemplateDelete(prisma: PrismaClient, id: string): Promise<boolean> {
  await prisma.documentTemplate.findFirstOrThrow({
    where: { id, deletedAt: null },
  });
  await prisma.documentTemplate.update({
    where: { id },
    data: { deletedAt: new Date() },
  });

  return true;
}

export async function documentTemplateUpsert(
  prisma: PrismaClient,
  data: DocumentTemplateFormData,
  discriminator: Prisma.DocumentTemplateWhereInput
) {
  // check if there are multiple matches for this discriminator and error if so
  if (
    (await prisma.documentTemplate.count({
      where: discriminator,
    })) >= 2
  ) {
    throw new Error("Non-unique discriminator");
  }
  const existing = await prisma.documentTemplate.findFirst({
    where: discriminator,
  });
  if (existing) {
    const { organizationId: _, ...formData } = data;
    return documentTemplateUpdate(prisma, existing.id, formData);
  } else {
    return documentTemplateCreate(prisma, data);
  }
}

export async function attachDocumentTemplate(
  prisma: PrismaClient,
  data: { documentTemplateId: string; surgeryProfileId: string }
) {
  await prisma.documentTemplate.findFirstOrThrow({
    where: { id: data.documentTemplateId },
  });
  await prisma.surgeryProfile.findFirstOrThrow({
    where: { id: data.surgeryProfileId },
  });
  await prisma.documentTemplate.update({
    where: { id: data.documentTemplateId },
    data: {
      surgeryProfiles: {
        connect: { id: data.surgeryProfileId },
      },
    },
  });
  return true;
}

export async function detachDocumentTemplate(
  prisma: PrismaClient,
  data: { documentTemplateId: string; surgeryProfileId: string }
) {
  await prisma.documentTemplate.findFirstOrThrow({
    where: { id: data.documentTemplateId },
  });
  await prisma.surgeryProfile.findFirstOrThrow({
    where: { id: data.surgeryProfileId },
  });
  await prisma.documentTemplate.update({
    where: { id: data.documentTemplateId },
    data: {
      surgeryProfiles: {
        disconnect: { id: data.surgeryProfileId },
      },
    },
  });
  return true;
}
