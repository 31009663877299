import {
  PatientCommunicationWhitelistSchema,
  type PaginationState,
} from "@procision-software/database-zod";
import type { PatientCommunicationChannel } from "../patient-communication/types";
import type { z } from "zod";

export const PatientCommunicationWhitelistFormDataSchema = PatientCommunicationWhitelistSchema.pick(
  {
    channel: true,
    to: true,
  }
);

export type PatientCommunicationWhitelistFormData = z.infer<
  typeof PatientCommunicationWhitelistFormDataSchema
>;

export type PatientCommunicationWhitelistCreateInput = {
  channel: PatientCommunicationChannel;
  to: string;
};

export type PatientCommunicationWhitelistFilters = Partial<{ search: string }>;

export type PatientCommunicationWhitelistUpdateInput = {
  id: string;
} & Partial<{
  channel: PatientCommunicationChannel;
  to: string;
}>;

export type PatientCommunicationWhitelistRecord = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  channel: PatientCommunicationChannel;
  to: string;
};

export type PatientCommunicationWhitelistRecords = {
  rows: PatientCommunicationWhitelistRecord[];
  pagination: PaginationState;
};
