"use client";

import { noop } from "lodash-es";
import { useRouter as useNextRouter } from "next/router"; // Pages Router
import {
  useRouter as useAppRouter, // App Router
  useParams,
  usePathname,
  useSearchParams,
} from "next/navigation";
import type { ParsedUrlQueryInput } from "querystring";
import { useMemo } from "react";
import type { Url } from "url";

type RouterUrlObject =
  | {
      pathname?: string;
      query?: Record<string, string | string[] | undefined | null | number> | ParsedUrlQueryInput;
    }
  | string;
// TransitionOptions (the third parameter) can't be exported from next/router here
// so we'll dupe the Next 14.2 version of it here
type RouterTransitionOptions = {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
};
// signature of push and replace
type PortableRouterMethod = (
  url: RouterUrlObject,
  as?: Url | undefined,
  options?: RouterTransitionOptions
) => Promise<boolean>;

const fallbackPush = (url: RouterUrlObject) => {
  if (typeof url === "string") {
    window.location.href = url;
  } else {
    throw new Error("Cannot push a complex URL without a router.");
  }
};

const fallbackBack = () => window.history.back();

// Compatibility hook for using either next/router or next/navigation
export function useRouter() {
  const pagesRouter = (() => {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useNextRouter();
    } catch (_) {
      return undefined;
    }
  })();
  const appRouter = (() => {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useAppRouter();
    } catch (_) {
      return undefined;
    }
  })();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  /**
   * The generic type here is a workaround for a TypeScript error:
   * > error TS4058: Return type of exported function has or is using name 'Params' from
   * > external module "next/dist/client/components/navigation" but cannot be named.
   *
   * This is an issue within the export for the `useParams` hook in the `next` package, as it does
   * not export the interface `Params`. The generic `T` on the `useParams` hook extends `Params` and
   * assigns `Params` as the default. TypeScript doesn't like this assignment to an interface that
   * is not exported. Simply assigning the generic to a similar interface resolves the lint.
   *
   * Notice: removing this generic will cause the TypeScript error to reappear, but only in CI. I
   * was not able to get this lint to fail locally unless I executed `tsc -d useRouter.ts`. For
   * some reason, `tsc` behavior differs between environments.
   */
  const params = useParams<Record<string, string | string[]>>();
  const query = useMemo(() => Object.fromEntries(searchParams ?? []), [searchParams]);
  return {
    isReady: true,
    basePath: "/",
    push: (appRouter?.push.bind(appRouter) ??
      pagesRouter?.push.bind(pagesRouter) ??
      fallbackPush) as PortableRouterMethod,
    replace: (appRouter?.replace.bind(appRouter) ??
      pagesRouter?.replace.bind(pagesRouter) ??
      fallbackPush) as PortableRouterMethod,
    pathname: pagesRouter?.pathname ?? pathname ?? "",
    query: {
      ...params, // these are routing params like [encounterId]
      ...pagesRouter?.query, // these are both routing params AND query params
      ...query, // these are only query params when using app router
    },
    params: params,
    asPath:
      pagesRouter?.asPath ??
      pathname + (searchParams ? "?" + new URLSearchParams(searchParams).toString() : ""),
    back: appRouter?.back.bind(appRouter) ?? pagesRouter?.back ?? fallbackBack,
    route: pagesRouter?.route ?? pathname,
    reload: pagesRouter?.reload ?? noop,
  };
}
