import { z } from 'zod';
import { CommunicationChannelSchema } from '../inputTypeSchemas/CommunicationChannelSchema'

/////////////////////////////////////////
// PATIENT COMMUNICATION WHITELIST SCHEMA
/////////////////////////////////////////

export const PatientCommunicationWhitelistSchema = z.object({
  channel: CommunicationChannelSchema,
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  to: z.string(),
})

export type PatientCommunicationWhitelist = z.infer<typeof PatientCommunicationWhitelistSchema>

export default PatientCommunicationWhitelistSchema;
