"use client";

import { type KnownUserRole } from "@procision-software/auth";
import {
  type Facility,
  type Organization,
  type Practice,
  type Staff,
  type User,
} from "@procision-software/database-zod";
import { DateTime } from "luxon";
import { createContext, useCallback, useContext, useMemo, useState } from "react";

export type Perspective = "asc" | "practice" | "platform" | "patient";

// incoming
type Context = {
  user: User | null;
  facility: Partial<Facility> &
    Pick<
      Facility,
      | "id"
      | "address1"
      | "address2"
      | "city"
      | "state"
      | "zip"
      | "phone"
      | "name"
      | "timezone"
      | "organizationId"
      | "highBmiFlagLevel" // defaulted in this file
      | "defaultRoomTurnOverTimeInMinutes" // defaulted in this file
      | "defaultArriveMinutesBeforeAppointment"
    > & {
      organization: Partial<Pick<Organization, "id" | "type" | "name" | "displayName">>;
    }; // the facility a public user can get doesn't have all scalar values
  practice: Practice | null | undefined;
  perspective: Perspective;
  staff: Staff | null | undefined;
  roles: KnownUserRole[];
  refresh: () => void;
};

export type AppContextProviderContextType = Context;

// outgoing
export type AppContext = Context & {
  formatDate: (luxonFormatString: string, date: Date) => string;
  facilityDateTime: (date: Date) => DateTime;
  isProcisionUser: () => boolean;
  advancedMode: boolean;
  setAdvancedMode: (mode: boolean) => void;
};

const AppContext = createContext<AppContext | null>(null);

export function AppContextProvider({
  context,
  children,
}: {
  context: Context;
  children: React.ReactNode;
}) {
  const [advancedMode, setAdvancedMode] = useState(false);

  const facilityDateTime = useCallback(
    (date: Date): DateTime => {
      return DateTime.fromJSDate(date, { zone: context.facility.timezone });
    },
    [context.facility.timezone]
  );

  const formatDate = useCallback(
    (luxonFormatString: string, date: Date): string => {
      return facilityDateTime(date).toFormat(luxonFormatString);
    },
    [facilityDateTime]
  );

  const isProcisionUser = useCallback((): boolean => {
    return (
      (context.user?.email?.endsWith("@procision.com") ||
        context.user?.email?.endsWith("@getprocision.com")) ??
      false
    );
  }, [context.user]);

  const value = useMemo(
    () => ({
      ...context,
      facility: {
        ...context.facility,
        defaultRoomTurnOverTimeInMinutes: context.facility.defaultRoomTurnOverTimeInMinutes ?? 0,
        defaultArriveMinutesBeforeAppointment:
          context.facility.defaultArriveMinutesBeforeAppointment ?? 0,
        highBmiFlagLevel: context.facility.highBmiFlagLevel ?? 0,
      },
      formatDate,
      facilityDateTime,
      isProcisionUser,
      advancedMode,
      setAdvancedMode,
    }),
    [context, formatDate, facilityDateTime, isProcisionUser, advancedMode]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useAppContext() {
  const context = useContext(AppContext);
  if (context === null) {
    throw new Error("useAppContext must be used within a AppContextProvider");
  }
  return context;
}

export function useAscTimezone() {
  const context = useContext(AppContext);
  if (context === null) {
    throw new Error("useAppContext must be used within a AppContextProvider");
  }
  return context.facility.timezone;
}

export function organizationIdForAppContext(c: AppContext) {
  switch (c.perspective) {
    case "asc":
      return c.facility.organizationId;
    case "platform":
      return null;
    case "practice":
      if (!c.practice?.organizationId) {
        throw new Error("No organizationId found for practice");
      } else {
        return c.practice.organizationId;
      }
  }
  throw new Error("No organizationId found for unexpected perspective");
}
