import { z } from 'zod';
import { JsonValueSchema } from '../inputTypeSchemas/JsonValueSchema'
import { MessageTypeSchema } from '../inputTypeSchemas/MessageTypeSchema'

/////////////////////////////////////////
// COMMUNICATION TEMPLATE SCHEMA
/////////////////////////////////////////

export const CommunicationTemplateSchema = z.object({
  messageType: MessageTypeSchema,
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  organizationId: z.string(),
  name: z.string(),
  smsContentJson: JsonValueSchema.nullable(),
  smsContentText: z.string().nullish(),
})

export type CommunicationTemplate = z.infer<typeof CommunicationTemplateSchema>

export default CommunicationTemplateSchema;
