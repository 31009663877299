import { z } from 'zod';
import { JsonValueSchema } from '../inputTypeSchemas/JsonValueSchema'
import { CommunicationChannelSchema } from '../inputTypeSchemas/CommunicationChannelSchema'
import { PatientCommunicationErrorSchema } from '../inputTypeSchemas/PatientCommunicationErrorSchema'

/////////////////////////////////////////
// PATIENT COMMUNICATION SCHEMA
/////////////////////////////////////////

export const PatientCommunicationSchema = z.object({
  channel: CommunicationChannelSchema,
  error: PatientCommunicationErrorSchema,
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  organizationId: z.string(),
  patientId: z.string().nullish(),
  encounterId: z.string().nullish(),
  userId: z.string().nullish(),
  to: z.string(),
  message: JsonValueSchema,
  preview: z.string(),
  context: JsonValueSchema,
  sentAt: z.coerce.date().nullish(),
  templateId: z.string().nullish(),
})

export type PatientCommunication = z.infer<typeof PatientCommunicationSchema>

export default PatientCommunicationSchema;
