import { MessageType } from "@prisma/client";
import { DateTime } from "luxon";

type OffsetSpec = {
  day?: number;
  week?: number;
};

type Rule = {
  scheduleInEnglish: string;
  audienceInEnglish: string;
  // If `before` is defined, operation_date = now + those business days
  // If `after` is defined, operation_date = now - those business days
  before?: OffsetSpec;
  after?: OffsetSpec;
};

// Adjusted rules per updated understanding and corrected PAT_Reminder
export const RULES: Record<MessageType, Rule> = {
  [MessageType.Appointment_Reminder]: {
    scheduleInEnglish: "schedule: 1 day before operation @ 3pm [local time]",
    audienceInEnglish: "Encounters occurring anytime tomorrow",
    before: {
      day: 1,
    },
  },
  [MessageType.PAT_Reminder]: {
    scheduleInEnglish: "3 days before operation @ 5pm [local time]",
    audienceInEnglish:
      "Encounter with no public PATs linked & no PATs completed & operation is 3 days from today",
    before: {
      day: 3,
    },
  },
  [MessageType.Post_Appointment_Survey]: {
    scheduleInEnglish: "1 day after operation at 9am [local time]",
    audienceInEnglish: "Encounters from 1 day ago & Case status not equal to canceled",
    after: {
      day: 1,
    },
  },
  [MessageType.Prompt_to_complete_PAT]: {
    scheduleInEnglish: "1 week before operation @ 5pm [local time]",
    audienceInEnglish:
      "Encounter with no public PATs linked & no PATs completed & operation is 1 week from today",
    before: {
      week: 1,
    },
  },
};

function isFridayEvening(d: DateTime): boolean {
  return d.weekday === 5 && d.hour >= 17;
}

// Helper to check weekends
function isWeekend(d: DateTime): boolean {
  return d.weekday === 6 || d.weekday === 7 || isFridayEvening(d);
}

function addBusinessDays(base: DateTime, days: number): DateTime {
  let d = base.startOf("day");
  let added = 0;
  while (added < days) {
    d = d.plus({ days: 1 });
    if (!isWeekend(d)) added++;
  }
  return d;
}

function subtractBusinessDays(base: DateTime, days: number): DateTime {
  let d = base.startOf("day");
  let subtracted = 0;
  while (subtracted < days) {
    d = d.minus({ days: 1 });
    if (!isWeekend(d)) subtracted++;
  }
  return d;
}

// If operation_date lands on a weekend, adjust to nearest business day as needed.
// For simplicity, let's always push forward to the next Monday if it's weekend.
function ensureBusinessDay(d: DateTime): DateTime {
  let dt = d;
  while (isWeekend(dt)) {
    dt = dt.plus({ days: 1 });
  }
  return dt;
}

export function convertCalenderDateRulesToBusinessDayRules(
  now: Date,
  message: MessageType,
  zone: string
): { isAfterRule: boolean; currentDt: Date; dateOfServiceBegin: Date; dateOfServiceEnd: Date } {
  const rule = RULES[message];
  const currentDt = DateTime.fromJSDate(now, { zone });
  const nowDt = currentDt.startOf("day");

  let offsetDays = 0;
  let goForward = true;
  if (rule.before) {
    if (rule.before.day) offsetDays = rule.before.day;
    if (rule.before.week) offsetDays = rule.before.week * 5;
    goForward = true; // before means now + offsetDays
  } else if (rule.after) {
    if (rule.after.day) offsetDays = rule.after.day;
    if (rule.after.week) offsetDays = rule.after.week * 5;
    goForward = false; // after means now - offsetDays
  }

  const tentativeDt = goForward
    ? addBusinessDays(nowDt, offsetDays)
    : subtractBusinessDays(nowDt, offsetDays);
  const operationDt = ensureBusinessDay(tentativeDt);

  // Return single day
  const dateOfServiceBegin = operationDt.startOf("day").toJSDate();
  let dateOfServiceEndDt = operationDt.endOf("day");
  while (isWeekend(dateOfServiceEndDt)) {
    dateOfServiceEndDt = ensureBusinessDay(dateOfServiceEndDt);
  }
  const dateOfServiceEnd = dateOfServiceEndDt.toJSDate();

  return {
    /**
     * This is useful for the condition builder to determine if we need to a periodEnd condition.
     */
    isAfterRule: !goForward,
    /**
     * This is useful for the condition builder to set a periodEnd condition.
     */
    currentDt: currentDt.toJSDate(),
    dateOfServiceBegin,
    dateOfServiceEnd,
  };
}
