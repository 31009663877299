import { type AppAbility } from "@procision-software/auth";
import { type Facility, type FacilityHours, type Prisma } from "@procision-software/database";
import { DaysOfWeek, type Organization } from "@procision-software/database-zod";
import { DateTime } from "luxon";
import type { PrismaClient } from "~/utils/flexible-prisma-client";

// declare const FacilityIdTag: unique symbol;

// type FacilityId = string & { readonly tag: typeof FacilityIdTag };
type FacilityId = string;

export function facilityIdToString(id: FacilityId) {
  return id.toString();
}

export function toFacilityId(id: string) {
  return id;
}

/**
 * Retrieves a single Facility record by ID. Optionally, creates default facility hours if not present.
 *
 * @param {PrismaClient} prisma - Prisma client instance.
 * @param {AppAbility} ability - The ability instance for access control, defined via CASL.
 * @param {string} id - The ID of the facility to retrieve.
 * @param {Prisma.FacilityInclude} [include] - Eager-load relations.
 *
 * @returns {Promise<Prisma.Facility | null>} The retrieved Facility record, or null if not found.
 *
 * @throws Will throw an error if Facility record is not accessible or not found.
 *
 * @example
 * ```typescript
 * const facilityRecord = await facility(prisma, ability, 'some-id', { hours: true });
 * ```
 *
 * @see {@link https://www.prisma.io/docs/concepts/components/prisma-client/working-with-prismaclient/crud Prisma CRUD Operations}
 * @see {@link https://casl.js.org/v5/en/guide/subject-type-detection Access Control with CASL}
 */

const facilityFactory =
  <T extends Prisma.FacilityInclude>(include: T) =>
  (prisma: PrismaClient, ability: AppAbility, id: string) =>
    prisma.facility.findFirstOrThrow({
      where: {
        id,
      },
      include,
    });

export const getFacilityWithBillingOrganization = facilityFactory({
  organization: {
    include: {
      billingOrganizations: {
        include: {
          staff: true,
        },
        take: 1,
      },
    },
  },
});

export async function getFacility(
  prisma: PrismaClient,
  id: FacilityId
): Promise<Facility & { hours: FacilityHours[] }> {
  const where = {
    id: facilityIdToString(id),
  };
  const facilityRecord = await prisma.facility.findFirstOrThrow({
    where,
    include: {
      hours: true,
    },
  });

  if (facilityRecord.hours.length === 0) {
    await prisma.facilityHours.createMany({
      data: Object.values(DaysOfWeek).map((day) => ({
        day,
        open: false,
        facilityId: facilityRecord.id,
      })),
    });
    return await getFacility(prisma, id);
  } else {
    return facilityRecord;
  }
}

/**
 *
 * @param prisma jailed prisma instance
 * @param ability user's ability from context
 * @param id facilityid
 * @param include optional prisma includes
 * @returns facility with includes
 * @deprecated use getFacility instead
 */
export async function facility(
  prisma: PrismaClient,
  ability: AppAbility,
  id: string,
  include: Prisma.FacilityInclude = {}
) {
  const facilityRecord = await prisma.facility.findFirstOrThrow({
    where: {
      id,
    },
    include,
  });

  if (include.hours && facilityRecord?.hours?.length === 0) {
    await prisma.facilityHours.createMany({
      data: Object.values(DaysOfWeek).map((day) => ({
        day,
        open: false,
        facilityId: facilityRecord.id,
      })),
    });
    return facility(prisma, ability, id, include);
  } else {
    return facilityRecord;
  }
}
export function facilities(
  prisma: PrismaClient,
  ability: AppAbility | Organization,
  filters: { ids?: string[] } = {}
) {
  if ("displayName" in ability) {
    // if ability is an organization
    return prisma.facility.findMany({
      where: {
        organizationId: ability.id,
      },
    });
  } else {
    return prisma.facility.findMany({
      where: {
        id: {
          in: filters.ids,
        },
      },
    });
  }
}

export function createFacility(
  prisma: PrismaClient,
  ability: AppAbility | false,
  data: Prisma.FacilityCreateInput
) {
  if (ability && !ability.can("create", "Facility")) {
    throw new Error("Unauthorized");
  }
  return prisma.facility.create({
    data: {
      ...data,
      hours: {
        create: Object.values(DaysOfWeek).map((day) => ({
          day,
          open: false,
          openTime: null,
          closeTime: null,
        })),
      },
    },
  });
}

export async function facilityDateTime(
  prisma: PrismaClient,
  ability: AppAbility,
  facilityId: string | null | undefined,
  time: Date | string
) {
  if (!facilityId) {
    throw new Error("Facility ID is required");
  }
  const record = await facility(prisma, ability, facilityId);
  if (typeof time === "string") {
    return DateTime.fromISO(time, { zone: record.timezone });
  } else {
    return DateTime.fromJSDate(time, { zone: record.timezone });
  }
}

export function utcDateTime(time: Date | string) {
  if (typeof time === "string") {
    return DateTime.fromISO(time, { zone: "UTC" });
  } else {
    return DateTime.fromJSDate(time, { zone: "UTC" });
  }
}

export async function facilityDateTimeFactory(
  prisma: PrismaClient,
  ability: AppAbility,
  facilityId: string | null | undefined
) {
  if (!facilityId) {
    throw new Error("Facility ID is required");
  }
  const facilityRecord = await facility(prisma, ability, facilityId);
  return (time: Date | string) => {
    if (typeof time === "string") {
      return DateTime.fromISO(time, { zone: facilityRecord.timezone });
    } else {
      return DateTime.fromJSDate(time, { zone: facilityRecord.timezone });
    }
  };
}
