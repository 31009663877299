import { EmptyZodString, SexSchema, type Patient } from "@procision-software/database-zod";
import { z } from "zod";

export const SsnSchema = z
  .string()
  .trim()
  .transform((val, ctx) => {
    if (!val?.length) return val;
    const parsed = val.replace(/\D/g, "");
    if (parsed.length !== 9) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Required (9 digits)",
      });
    }
    return parsed;
  })
  .or(EmptyZodString.nullish());

export const PATIENT_COMPLETION = {
  BASE: 0,
  SCHEDULING: 25,
  REGISTRATION: 50,
} as const;

// Base requirements for a patient to exist
const PatientBaseSchema = z.object({
  preferredName: z.string().trim().nullish(),
  firstName: z.string().trim().min(1, { message: "Required" }),
  lastName: z.string().trim().min(1, { message: "Required" }),
  dateOfBirth: z.date({ required_error: "Required", invalid_type_error: "Required" }),
  middleName: z.string().trim().nullish(),
  email: z.string().trim().email().or(EmptyZodString.nullish()),
  primaryPhone: z
    .string()
    .trim()
    .min(10, { message: "Required (10 digits)" })
    .or(EmptyZodString.nullish()),
  secondaryPhone: z
    .string()
    .trim()
    .min(10, { message: "Required (10 digits)" })
    .or(EmptyZodString.nullish()),
  sex: SexSchema.nullable().default(null),
  ssn: SsnSchema,
  suffixName: z.string().trim().nullish(),
} satisfies Partial<Record<keyof Patient, z.ZodTypeAny>>);

// Additional requirements beyond base to schedule a case for a patient
const PatientSchedulingSchema = PatientBaseSchema.extend({
  primaryPhone: z
    .string({ invalid_type_error: "Required" })
    .trim()
    .min(10, { message: "Required (10 digits)" }),
});

// Additional requirements beyond scheduling to complete patient registration
const PatientRegistrationSchema = PatientSchedulingSchema.extend({
  sex: SexSchema,
});
export type PatientRegistrationSchemaType = typeof PatientRegistrationSchema;

export type PatientFormSchemaType =
  | typeof PatientBaseSchema
  | typeof PatientSchedulingSchema
  | typeof PatientRegistrationSchema;

export type PatientFormData = z.infer<PatientFormSchemaType>;

/**
 * Returns the appropriate schema to validate a patient based on the level of completion. The
 * higher the completion value, the more fields are required. Multiple completion values can be
 * provided. The highest completion value will be used.
 *
 * @param completionValues One or more completion values to determine the schema to use.
 *
 * @example
 * ```tsx
 * const patient = { completion: 50 };
 * const schema = patientFormSchemaForCompletion(patient.completion, 25);
 * // The schema returned will be for the completion value of 50 to the higher patient completion value.
 * ```
 */
export function patientFormSchemaForCompletion(completion: 0): typeof PatientBaseSchema;
export function patientFormSchemaForCompletion(completion: 25): typeof PatientSchedulingSchema;
export function patientFormSchemaForCompletion(completion: 50): typeof PatientRegistrationSchema;
export function patientFormSchemaForCompletion(
  completion: number,
  minCompletion?: number
): PatientFormSchemaType;
export function patientFormSchemaForCompletion(
  completion: number,
  minCompletion?: number
): PatientFormSchemaType {
  const value = Math.max(completion, minCompletion ?? 0);
  if (value >= PATIENT_COMPLETION.REGISTRATION) return PatientRegistrationSchema;
  if (value >= PATIENT_COMPLETION.SCHEDULING) return PatientSchedulingSchema;
  return PatientBaseSchema;
}

export function isPatientHomeAddressRequired(patient: Pick<Patient, "completion">) {
  return patient.completion >= PATIENT_COMPLETION.REGISTRATION;
}
