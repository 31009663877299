import { DOS_FORMAT_STRING, toCaseId, type CaseId } from "@procision-software/mason";
import { CommandMenu, Icon, useAutoFocus, useDrawer } from "@procision-software/ui";
import {
  BriefcaseMedicalIcon,
  FileSymlinkIcon,
  PersonStandingIcon,
  ReceiptIcon,
} from "lucide-react";
import { useEffect, useMemo, useState, type FC } from "react";
import { useDebounce } from "use-debounce";
import type { BillingCaseId } from "~/billing/models/case";
import type { BillingClaimId } from "~/billing/models/claim";
import { useAppContext } from "~/components/AppContextProvider";
import { useRouter } from "~/hooks/useRouter";
import { trpc, type RouterOutputs } from "~/utils/trpc";
import { useIntents } from "./useIntents";

type Context = "appointment" | "overview" | "payer" | "staff" | "transaction";

type IntentShelfProps = {
  search: string;
  caseId?: CaseId;
  billingCaseId?: BillingCaseId;
  billingClaimId?: BillingClaimId;
  context: Context;
};
type SearchResult = RouterOutputs["core"]["search"]["query"][number];
const viewModelForMatch = (match: SearchResult) => {
  switch (match.context) {
    case "appointment":
      return {
        key: `appointment:${match.caseId}`,
        category: "Case",
        icon: PersonStandingIcon,
      };
    case "staff":
      return {
        key: `staff:${match.staffId}`,
        category: "Staff",
        icon: BriefcaseMedicalIcon,
      };
    case "transaction":
      return {
        key: `transaction:${match.transactionId}`,
        category: "Payment",
        icon: ReceiptIcon,
      };
  }
};
const keyForMatch = (match: SearchResult) => viewModelForMatch(match).key;
const iconForResult = (match: SearchResult) => viewModelForMatch(match).icon;
const categorizationForMatch = (match: SearchResult) => viewModelForMatch(match).category;
export const IntentShelf: FC<IntentShelfProps> = (props) => {
  const { formatDate } = useAppContext();
  const drawer = useDrawer();
  const [context, setContext] = useState(props.context ?? "overview");
  const [search, setSearch] = useState(props.search);
  const [caseId, setCaseId] = useState(props.caseId);
  const [billingCaseId, setBillingCaseId] = useState(props.billingCaseId);
  const [billingClaimId, setBillingClaimId] = useState(props.billingClaimId);
  const [staffId, setStaffId] = useState<string | undefined>();
  const focusRef = useAutoFocus(true);
  const intentSubjects = useMemo(
    () => ({
      claimId: billingClaimId,
      billingCaseId,
      caseId,
      staffId,
    }),
    [billingClaimId, billingCaseId, caseId, staffId]
  );
  const exclusions = useMemo((): ("payer" | "staff" | "appointment")[] => {
    if (context === "overview") return ["appointment", "payer", "staff"];
    if (context === "appointment") return ["staff" as const, "payer" as const];
    if (context === "staff") return ["appointment" as const, "payer" as const];
    return [];
  }, [context]);
  const [destinations] = useIntents(intentSubjects, exclusions);
  const router = useRouter();
  const freeTextSearchEnabled = search.length >= 2;
  const [debouncedSearchTerm] = useDebounce(search, 500);
  const possibleMatchesTrpc = trpc.core.search.query.useQuery(
    { terms: debouncedSearchTerm },
    { enabled: freeTextSearchEnabled }
  );
  const staffTrpc = trpc.staff.get.useQuery({ id: staffId ?? "" }, { enabled: !!staffId });
  const caseTrpc = trpc.case.context.useQuery({ id: caseId ?? "" }, { enabled: !!caseId });
  const handleSearchResult = (match: SearchResult) => () => {
    setContext(match.context);
    switch (match.context) {
      case "appointment":
        setCaseId(toCaseId(match.caseId));
        setBillingCaseId(undefined);
        setBillingClaimId(undefined);
        setStaffId(undefined);
        setSearch("");
        break;
      case "staff":
        setStaffId(match.staffId);
        setCaseId(undefined);
        setBillingCaseId(undefined);
        setBillingClaimId(undefined);
        setSearch("");
        break;
      case "transaction":
        void router.push(`/billing/transactions/${match.transactionId}`);
        break;
    }
  };
  useEffect(() => {
    switch (context) {
      case "overview":
        return drawer.setTitlebar({
          title: "Search",
        });
      case "appointment":
        return drawer.setTitlebar({
          title: caseTrpc.data?.patient
            ? `${caseTrpc.data.patient.mrn} - ${formatDate(DOS_FORMAT_STRING, caseTrpc.data.surgeryDate)}`
            : "Appointment",
        });
      case "staff":
        return drawer.setTitlebar({
          title: staffTrpc.data
            ? `${staffTrpc.data.firstName} ${staffTrpc.data.lastName}`
            : "Staff",
        });
      case "transaction":
        return drawer.setTitlebar({
          title: "Transaction",
        });
    }
  }, [context, drawer, staffTrpc.data, caseTrpc.data, formatDate]);

  const terms = useMemo(
    () =>
      search
        .split(" ")
        .map((s) => s.trim())
        .filter((s) => s.length > 0)
        .map((s) => s.toLowerCase()),
    [search]
  );
  const destinationResults = useMemo(
    () => destinations.filter((d) => terms.every((term) => d.label.toLowerCase().includes(term))),
    [destinations, terms]
  );

  return (
    <CommandMenu shouldFilter={false} className="h-full">
      <CommandMenu.Input ref={focusRef} value={search} onValueChange={setSearch} />
      <CommandMenu.List className="max-h-full">
        {destinationResults.map((intent) => (
          <CommandMenu.Item
            disabled={!intent.href}
            key={intent.key}
            onSelect={() => intent.href && router.push(intent.href)}
          >
            <Icon icon={FileSymlinkIcon} className="mr-2" />
            {intent.label}
          </CommandMenu.Item>
        ))}
        {freeTextSearchEnabled &&
          possibleMatchesTrpc.data?.map((match) => (
            <CommandMenu.Item key={keyForMatch(match)} onSelect={handleSearchResult(match)}>
              <Icon icon={iconForResult(match)} className="mr-2" />
              {match.label} ({categorizationForMatch(match)})
            </CommandMenu.Item>
          ))}
        <CommandMenu.Empty>
          {context === "overview" && (
            <p>
              {possibleMatchesTrpc.isLoading && freeTextSearchEnabled
                ? "Searching"
                : search === ""
                  ? ""
                  : "No results found"}
              {search === "" && "search for appointments, surgeons, etc"}
            </p>
          )}
          {context !== "overview" && <p>No results found</p>}
        </CommandMenu.Empty>
      </CommandMenu.List>
    </CommandMenu>
  );
};
