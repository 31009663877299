import { diff as differ } from "deep-object-diff";
import type { AuditEntry, VersionedData } from "./schema/Audit";
import { utcDateTime } from "./facility";

const differenceInSeconds = (a: Date, b: Date) => {
  const dtA = utcDateTime(a),
    dtB = utcDateTime(b);
  return dtA.diff(dtB, "seconds").seconds;
};

function deleteVersionProperties(obj: Record<string, unknown>) {
  const newObj: typeof obj = {};
  for (const i in obj) {
    if (!i.startsWith("version")) {
      if (typeof obj[i] === "object" && !(obj[i] instanceof Date)) {
        newObj[i] = deleteVersionProperties(obj[i] as Record<string, unknown>);
      } else if (!["createdAt", "updatedAt"].includes(i)) {
        newObj[i] = obj[i];
      }
    }
  }
  return newObj;
}

function fuzzDates(obj: Record<string, unknown>, cleanObj: Record<string, unknown>) {
  const newObj: typeof obj = {};
  for (const i in obj) {
    if (cleanObj && typeof obj[i] === "object" && !(obj[i] instanceof Date)) {
      newObj[i] = fuzzDates(
        obj[i] as Record<string, unknown>,
        cleanObj[i] as Record<string, unknown>
      );
    } else if (obj[i] instanceof Date) {
      if (cleanObj && cleanObj[i] instanceof Date) {
        if (Math.abs(differenceInSeconds(obj[i], cleanObj[i])) < 2) newObj[i] = cleanObj[i];
        else newObj[i] = obj[i];
      } else {
        newObj[i] = obj[i];
      }
    } else {
      newObj[i] = obj[i];
    }
  }
  return newObj;
}

export function diffBetween(a: Record<string, unknown>, b: Record<string, unknown>) {
  const cleanA = deleteVersionProperties(a);
  const cleanB = fuzzDates(deleteVersionProperties(b), cleanA);
  return differ(cleanB, cleanA);
}

export const getEarlierRev = (versionId: number, records: { versionId: number }[]) => {
  const earlierRev = records.find((p) => p.versionId < versionId);
  if (earlierRev) return earlierRev;
  else return null;
};

export function diffEntry(
  record: VersionedData,
  entityName: string,
  entityPath: string | null,
  diff: unknown
) {
  return {
    versionId: record.versionId,
    versionTimestamp: record.versionTimestamp,
    versionUserId: record.versionUserId,
    versionOperation: record.versionOperation,
    diff,
    entityName,
    entityPath,
    now: record,
  } as AuditEntry;
}
