import type { InputJsonValue, JsonValue } from "@prisma/client/runtime/library";
import { type Content } from "@procision-software/comic-sans/tiptap";
import type {
  BarePrismaClient,
  CommunicationTemplate,
  MessageType,
} from "@procision-software/database";
import type { PaginationInput, PaginationState } from "@procision-software/database-zod";
import { parsePhoneNumberWithError } from "libphonenumber-js";
import { getEncounterSmartFieldDataPrisma } from "../../encounter";
import { getPatientSmartFieldDataPrisma } from "../../patient/repository";
import {
  generateDocumentPreview,
  smartFieldReplaceTipTapNode,
  type TipTapDocument,
} from "../../tip-tap-editor";
import type {
  PatientCommunicationChannel,
  PatientCommunicationCreateInput,
  PatientCommunicationRecord,
  PatientCommunicationUpdateInput,
} from "../types";

export async function patientCommunicationCreate(
  prisma: BarePrismaClient,
  data: PatientCommunicationCreateInput
): Promise<PatientCommunicationRecord> {
  if (!data.organizationId) throw new Error("organizationID is required");
  await prisma.organization.findFirstOrThrow({ where: { id: data.organizationId } });
  if (data.encounterId)
    await prisma.encounter.findFirstOrThrow({ where: { id: data.encounterId } });
  if (data.patientId) await prisma.patient.findFirstOrThrow({ where: { id: data.patientId } });
  if (data.userId) await prisma.user.findFirstOrThrow({ where: { id: data.userId } });
  if (!(!!data.encounterId || !!data.patientId || !!data.userId))
    throw new Error("At least one of encounterId or patientId must be provided");

  const to = data.channel === "SMS" ? parsePhoneNumberWithError(data.to, "US").number : data.to;

  const { id } = await prisma.patientCommunication.create({
    data: {
      channel: data.channel,
      message: data.message,
      preview: generateDocumentPreview(data.message as JsonValue),
      encounter: data.encounterId ? { connect: { id: data.encounterId } } : undefined,
      patient: data.patientId ? { connect: { id: data.patientId } } : undefined,
      template: data.templateId ? { connect: { id: data.templateId } } : undefined,
      user: data.userId ? { connect: { id: data.userId } } : undefined,
      context: {},
      organization: { connect: { id: data.organizationId } },
      to,
    },
  });

  return await prisma.patientCommunication.findFirstOrThrow({
    where: { id },
    include: { template: true },
  });
}

export async function patientCommunicationUpdate(
  prisma: BarePrismaClient,
  data: PatientCommunicationUpdateInput
): Promise<PatientCommunicationRecord> {
  const r = await patientCommunicationFindById(prisma, data.id);

  if (r.sentAt) throw new Error("Cannot update a communication that has been sent");

  const { id } = await prisma.patientCommunication.update({
    where: { id: data.id },
    data: {
      ...data,
      ...(data.message && {
        preview: generateDocumentPreview(data.message as JsonValue),
      }),
      ...(data.to &&
        (data.channel ?? r.channel) === "SMS" && {
          to: parsePhoneNumberWithError(data.to, "US").number,
        }),
    },
  });

  return await prisma.patientCommunication.findFirstOrThrow({
    where: { id },
    include: { template: true },
  });
}

export async function patientCommunicationDelete(
  prisma: BarePrismaClient,
  id: string
): Promise<boolean> {
  return (await prisma.patientCommunication.delete({ where: { id } })) !== null;
}

export async function patientCommunicationFindById(
  prisma: BarePrismaClient,
  id: string
): Promise<PatientCommunicationRecord> {
  return prisma.patientCommunication.findFirstOrThrow({
    where: { id },
    include: {
      template: true,
    },
  });
}

export async function patientCommunicationFindBySid(
  prisma: BarePrismaClient,
  sid: string
): Promise<PatientCommunicationRecord> {
  return prisma.patientCommunication.findFirstOrThrow({
    where: {
      context: {
        path: ["sid"],
        equals: sid,
      },
    },
    include: {
      template: true,
    },
  });
}

export async function patientCommunicationFindMany(
  prisma: BarePrismaClient,
  filters: {
    patientId?: string;
    encounterId?: string;
    userId?: string;
    messageTypes?: MessageType[];
  },
  pagination: PaginationInput
): Promise<{
  rows: PatientCommunicationRecord[];
  pagination: PaginationState;
}> {
  const { messageTypes, ...restFilters } = filters;

  const where = {
    ...restFilters,
    ...(messageTypes ? { template: { messageType: { in: messageTypes } } } : {}),
  };

  const all = await prisma.patientCommunication.count({
    where,
  });

  const rows = await prisma.patientCommunication.findMany({
    where,
    include: {
      template: true,
    },
    orderBy: [{ sentAt: "desc" }, { createdAt: "desc" }],
    skip: (pagination.page - 1) * pagination.perPage,
    take: pagination.perPage,
  });

  return {
    rows,
    pagination: {
      ...pagination,
      all,
    },
  };
}

export async function createMessageFromTemplate(
  prisma: BarePrismaClient,
  templateId: string,
  patientId: string,
  encounterId: string | null,
  channel: PatientCommunicationChannel,
  to: string,
  userId?: string | null
): Promise<PatientCommunicationRecord> {
  const template = await prisma.communicationTemplate.findFirstOrThrow({
    where: { id: templateId },
  });

  const personalizedMessage = await fillInTemplate(prisma, template, channel, {
    patientId,
    encounterId,
  });

  if (!personalizedMessage) throw new Error("Failed to fill in template");

  return await patientCommunicationCreate(prisma, {
    encounterId,
    organizationId: template.organizationId,
    patientId,
    templateId,
    channel,
    message: personalizedMessage as InputJsonValue,
    to,
    userId,
  });
}

function getTemplateContent(
  template: Pick<CommunicationTemplate, "smsContentJson">,
  channel: PatientCommunicationChannel
): Content {
  switch (channel) {
    case "SMS":
      return template.smsContentJson as Content;
    default:
      throw new Error("Unsupported channel");
  }
}

export async function fillInTemplate(
  prisma: BarePrismaClient,
  template: Pick<CommunicationTemplate, "smsContentJson">,
  channel: PatientCommunicationChannel,
  context: { patientId: string; encounterId: string | null }
): Promise<TipTapDocument> {
  const smartFieldData = {
    ...(await getPatientSmartFieldDataPrisma(prisma, { patientId: context.patientId })),
    ...(context.encounterId
      ? await getEncounterSmartFieldDataPrisma(prisma, { encounterId: context.encounterId })
      : {}),
  };

  const templateContent = getTemplateContent(template, channel);
  if (!templateContent) throw new Error("Desired template content is missing");
  const replacedContent = smartFieldReplaceTipTapNode(
    templateContent as TipTapDocument,
    smartFieldData
  ) as TipTapDocument;

  return replacedContent;
}
