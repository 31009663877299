import { PatientSubmissionStatus, type Encounter } from "@procision-software/database";

export function encounterHasMatchedPat(
  encounter: Pick<Encounter, "matchedSubmissionId" | "patientSubmissionStatus">
) {
  return !!encounter.matchedSubmissionId;
  // && (encounter.patientSubmissionStatus === PatientSubmissionStatus.Matched ||
  //   encounter.patientSubmissionStatus === PatientSubmissionStatus.Merged ||
  //   encounter.patientSubmissionStatus === PatientSubmissionStatus.Reviewed)
}

export function encounterHasMergedPat(
  encounter: Pick<Encounter, "matchedSubmissionId" | "patientSubmissionStatus">
) {
  return (
    encounter.matchedSubmissionId &&
    (encounter.patientSubmissionStatus === PatientSubmissionStatus.Merged ||
      encounter.patientSubmissionStatus === PatientSubmissionStatus.Reviewed)
  );
}

export function encounterHasReviewedPat(
  encounter: Pick<Encounter, "matchedSubmissionId" | "patientSubmissionStatus">
) {
  return encounter.patientSubmissionStatus === PatientSubmissionStatus.Reviewed;
}
