import {
  PermissionDeniedError,
  billingOrganizationFor,
  type AppAbility,
} from "@procision-software/auth";
import type {
  BillingAdjustment,
  BillingCase,
  BillingCharge,
  BillingClaim,
  BillingPayer,
  BillingTransaction,
  BillingTransactionAllocation,
  Case,
  CptCode,
  Patient,
  Prisma,
  PrismaClient,
  Procedure,
} from "@procision-software/database";
import {
  BillingClaimStatus,
  BillingPayerType,
  BillingTransactionAllocationStatus,
  BillingTransactionStatus,
  X12ClaimAdjustmentCodeGroup,
} from "@procision-software/database";
import { CurrencyType } from "@procision-software/database-zod";
import type { z } from "zod";
import { sumByField } from "~/utils/math";
import type { billingTransactionListInputSchema } from "../server/trpc/router/transactions";
import { needToApplyPrePayment, payersFromCase } from "./case";
import { getChargeMasterForId } from "./chargemaster";
import { NotUpdatableError } from "./charges";
import {
  augmentClaimWithBillingAmounts,
  billingClaimIdToString,
  isSelfPay,
  toBillingClaimId,
  type BillingClaimId,
} from "./claim";
import { applyPrePayment } from "./claim/applyprepayment";
import { payerName, type BillingPayerId, type NamedPayer } from "./payers";

// Define a unique symbol
// declare const BillingTransactionIdTag: unique symbol;

// Create a tagged type
// export type BillingTransactionId = string & { readonly tag: typeof BillingTransactionIdTag };
export type BillingTransactionId = string;

// Function to tag a string
export function toBillingTransactionId(id: string): BillingTransactionId {
  // return id as BillingTransactionId;
  return id;
}

export function billingTransactionIdToString(id: BillingTransactionId): string {
  return id.toString();
}

// utility function to sum a set of allocations
export const sumAllocations = (allocations: BillingTransactionAllocation[]) =>
  sumByField(allocations, "amount");

// predicates to determine what the nature of the allocation is
export const isAdjustment = (
  a: Pick<BillingTransactionAllocation, "billingAdjustmentId" | "adjustmentGroup">
): boolean => !!(a.billingAdjustmentId && a.adjustmentGroup !== "PR");
export const isPayment = (
  a: Pick<BillingTransactionAllocation, "billingAdjustmentId" | "adjustmentGroup">
): boolean =>
  (a.adjustmentGroup === X12ClaimAdjustmentCodeGroup.CO || a.adjustmentGroup === null) &&
  a.billingAdjustmentId === null;
export const isPatientResp = (
  a: Pick<BillingTransactionAllocation, "billingAdjustmentId" | "adjustmentGroup">
): boolean => !!(a.adjustmentGroup === "PR" && a.billingAdjustmentId);

// private utility functions to extract particular portions of the claims allocations
export const adjustments = (c: { allocations: BillingTransactionAllocation[] }) =>
  c.allocations.filter(isAdjustment);
export const payments = (c: { allocations: BillingTransactionAllocation[] }) =>
  c.allocations.filter(isPayment);
export const patientResponsibility = (c: { allocations: BillingTransactionAllocation[] }) =>
  c.allocations.filter(isPatientResp);
export const priorPayersAllocations = (
  allocations: (BillingTransactionAllocation & {
    billingTransaction: BillingTransaction;
    billingClaim: BillingClaim & { billingPayer: BillingPayer };
  })[],
  currentPayerSequenceNumber: number
) =>
  allocations.filter(
    (a) =>
      a.billingTransaction.status === BillingTransactionStatus.Complete &&
      !isPatientResp(a) &&
      a.billingClaim.billingPayer.sequenceNumber < currentPayerSequenceNumber
  );

export async function getTransactionWithClaims(
  prisma: PrismaClient,
  ability: AppAbility,
  paymentId: string,
  relatedCases: "record"
): Promise<BillingTransaction & { allocated: number; remaining: number } & { claims: string[] }>;

export async function getTransactionWithClaims(
  prisma: PrismaClient,
  ability: AppAbility,
  paymentId: string,
  relatedCases: "deeprecord"
): Promise<
  BillingTransaction & { allocated: number; remaining: number } & {
    claims: (BillingClaim & {
      payer: NamedPayer;
      billedAmount: number;
      priorPayersAmount: number;
      expectedAmount: number;
      previousPaymentAmount: number;
      paymentAmount: number;
      adjustmentAmount: number;
      patientResponsibility: number;
      outstandingAmount: number;
      patient: Patient;
      dateOfService: Date;
    })[];
  }
>;

export async function getTransactionWithClaims(
  prisma: PrismaClient,
  ability: AppAbility,
  paymentId: string,
  relatedCases: "count"
): Promise<BillingTransaction & { allocated: number; remaining: number } & { claims: number }>;

export async function getTransactionWithClaims(
  prisma: PrismaClient,
  ability: AppAbility,
  paymentId: string,
  relatedCases: "none"
): Promise<BillingTransaction & { allocated: number; remaining: number } & { claims: undefined }>;

/**
 * Retrieves a billing transaction with associated claims and payment allocations.
 * Overloaded function with different response types depending on the `relatedCases` argument.
 *
 * @param {PrismaClient} prisma - Prisma client for database access.
 * @param {AppAbility} ability - Ability object from CASL for authorization.
 * @param {string} paymentId - The ID of the billing payment.
 * @param {"record"|"deeprecord"|"count"|"none"} relatedCases - Type of related cases to include.
 *
 * @returns {Promise<BillingTransaction & { allocated: number; remaining: number } & { claims: string[] | number | undefined }>} - relatedCases=="record" will return an array of `BillingClaimId`s. relatedCases==="deepRecord" returns an array of the related `BillingClaim`s. relatedCases==="count" returns a count of the related claims. relatedCases==="none" omits a claims return entirely.
 *
 * @throws {PrismaClientKnownRequestError} - When billing transaction is not found.
 *
 */
export async function getTransactionWithClaims(
  prisma: PrismaClient,
  ability: AppAbility,
  paymentId: string,
  relatedCases: "count" | "record" | "deeprecord" | "none"
) {
  const getIncludes = (relatedCases: "count" | "record" | "deeprecord" | "none") => {
    if (relatedCases === "deeprecord") {
      return {
        include: {
          billingPayer: true,
          billingCase: {
            include: {
              billingCharges: true,
              case: {
                include: {
                  patient: true,
                },
              },
            },
          },
        },
      };
    }
    return true;
  };
  const transaction = await prisma.billingTransaction.findFirstOrThrow({
    where: {
      id: paymentId,
    },
    include: { claims: getIncludes(relatedCases) },
  });

  const allocated = await paymentAllocation(prisma, ability, toBillingTransactionId(paymentId));
  const remaining = transaction.amount - allocated;

  if (relatedCases === "deeprecord") {
    return {
      ...transaction,
      allocated,
      remaining,
      claims: await Promise.all(
        (
          transaction.claims as (BillingClaim & {
            billingPayer: BillingPayer;
            billingCase: BillingCase & {
              billingCharges: BillingCharge[];
              case: Case & {
                patient: Patient;
              };
            };
          })[]
        ).map(async (claim) => {
          const claimCharges = await getAllocationsForTransaction(
            prisma,
            ability,
            toBillingTransactionId(transaction.id),
            toBillingClaimId(claim.id)
          );

          return {
            ...claim,
            dateOfService: claim.billingCase.case.surgeryDate,
            patient: claim.billingCase.case.patient,
            billedAmount: sumByField(claimCharges, "billedAmount"),
            priorPayersAmount: sumByField(claimCharges, "priorPayersAmount"),
            expectedAmount: sumByField(claimCharges, "expectedAmount"),
            previousPaymentAmount: sumByField(claimCharges, "previousPaymentAmount"),
            paymentAmount: sumByField(claimCharges, "payment"),
            adjustmentAmount: claimCharges.reduce(
              (sum, c) => sum + sumAllocations(c.adjustments.filter((a) => !isPatientResp(a))),
              0
            ),
            patientResponsibility: claimCharges.reduce(
              (sum, c) => sum + sumAllocations(c.adjustments.filter((a) => isPatientResp(a))),
              0
            ),
            outstandingAmount: sumByField(claimCharges, "outstandingAmount"),
            payer: {
              ...claim.billingPayer,
              name: await payerName(prisma, ability, claim.billingPayerId),
            },
          };
        })
      ),
    } as BillingTransaction & { allocated: number; remaining: number } & {
      claims: (BillingClaim & {
        payer: NamedPayer;
        billedAmount: number;
        expectedAmount: number;
        priorPayersAmount: number;
        previousPaymentAmount: number;
        paymentAmount: number;
        adjustmentAmount: number;
        patientResponsibility: number;
        outstandingAmount: number;
        patient: Patient;
        dateOfService: Date;
      })[];
    };
  } else {
    return {
      ...transaction,
      allocated,
      remaining,
      claims:
        relatedCases === "count"
          ? transaction.claims.length
          : relatedCases === "record"
            ? transaction.claims.map((kase) => kase.id)
            : undefined,
    };
  }
}

type BillingTransactionListInputSchema = z.infer<typeof billingTransactionListInputSchema>;

export async function listTransactions(
  prisma: PrismaClient,
  ability: AppAbility,
  filter: BillingTransactionListInputSchema["filter"],
  pagination: {
    page: number;
    perPage: number;
  }
) {
  const clauses: Prisma.BillingTransactionWhereInput[] = [];
  clauses.push({
    status: {
      in: filter.status ?? ["New", "In_Progress"],
    },
  });
  if (filter.search) {
    clauses.push({
      referenceNumber: {
        contains: filter.search,
      },
    });
  }
  if (filter.date) {
    clauses.push({
      transactionDate: {
        gte: filter.date.start,
        lte: filter.date.end,
      },
    });
  }
  const paymentsQuery = prisma.billingTransaction.findMany({
    where: {
      AND: clauses,
    },
    select: {
      id: true,
    },
    orderBy: {
      transactionDate: "desc",
    },
    skip: (pagination.page - 1) * pagination.perPage,
    take: pagination.perPage,
  });
  const count = await prisma.billingTransaction.count({
    where: {
      AND: clauses,
    },
  });
  const paymentRecords = await paymentsQuery;
  return {
    rows: await Promise.all(
      paymentRecords.map(async ({ id }) => getTransactionWithClaims(prisma, ability, id, "count"))
    ),
    pagination: {
      page: pagination.page,
      perPage: pagination.perPage,
      all: count,
    },
  };
}

export async function paymentAllocation(
  prisma: PrismaClient,
  ability: AppAbility,
  billingTransactionId: BillingTransactionId
) {
  const allocations = await prisma.billingTransactionAllocation.findMany({
    where: {
      billingTransactionId: billingTransactionIdToString(billingTransactionId),
      billingAdjustmentId: null,
    },
  });
  return sumAllocations(allocations);
}

export async function chargeMasterFor(
  prisma: PrismaClient,
  ability: AppAbility,
  procedure: Procedure & { cptCode: CptCode }
) {
  if (!ability.can("read", "BillingChargeMaster")) {
    throw new Error("You do not have permission to read charge masters");
  }
  const chargeMaster = await prisma.billingChargeMaster.findFirst({
    where: {
      cptCode: procedure.cptCode.code,
    },
  });
  if (!chargeMaster) {
    throw new Error(`No charge master found for ${procedure.cptCode.code}`);
  }
  return chargeMaster;
}

export function frequencyCodeFor(
  caseTypeCode: string,
  facilityTypeCode: string,
  billingCode: string
) {
  return `0${caseTypeCode}${facilityTypeCode}${billingCode}`;
}

export async function createBillingPayerRecordsFromCase(
  prisma: PrismaClient,
  ability: AppAbility,
  kase: { id: string },
  billingCase: { id: string }
) {
  if (!ability.can("read", "BillingPayer")) {
    throw new Error("You do not have permission to read payers");
  }
  const paymentCase = await prisma.case.findFirstOrThrow({
    where: {
      id: kase.id,
    },
    include: {
      paymentInsurance: true,
      paymentSelfPay: true,
      paymentWorkersComp: true,
      paymentLetterProtection: true,
    },
  });
  const creates = await payersFromCase(paymentCase, prisma);
  return (
    await Promise.all(
      creates.map(async (data) => {
        if (data.paymentType === BillingPayerType.Self_Pay && !data.paymentSelfPayId) {
          const sp = await prisma.paymentSelfPay.create({
            data: {
              case: {
                connect: {
                  id: kase.id,
                },
              },
            },
          });
          data.paymentSelfPayId = sp.id;
        }
        const result =
          ability.can("create", "BillingPayer") &&
          (await prisma.billingPayer.create({
            data: { billingCaseId: billingCase.id, ...data },
          }));
        if (!result) {
          throw new PermissionDeniedError();
        }
        return result;
      })
    )
  ).filter((p) => !!p);
}

export async function createPayment(
  prisma: PrismaClient,
  ability: AppAbility,
  data: Prisma.BillingTransactionCreateInput
) {
  const payment =
    ability.can("crate", "BillingTransaction") &&
    (await prisma.billingTransaction.create({
      data,
    }));
  if (!payment) throw new PermissionDeniedError();
  return payment;
}

export async function updatePayment(
  prisma: PrismaClient,
  ability: AppAbility,
  id: string,
  data: Prisma.BillingTransactionUpdateInput
) {
  const { count } = await prisma.billingTransaction.updateMany({
    data,
    where: {
      id,
    },
  });
  if (count === 0) {
    throw new PermissionDeniedError();
  }
  const payment = await prisma.billingTransaction.findFirstOrThrow({
    where: {
      id: id,
    },
  });
  return payment;
}

export async function deletePayment(
  prisma: PrismaClient,
  ability: AppAbility,
  id: BillingTransactionId
) {
  const payment = await prisma.billingTransaction.findFirstOrThrow({
    where: {
      id: billingTransactionIdToString(id),
    },
  });
  const { count } = await prisma.billingTransaction.deleteMany({
    where: {
      id: payment.id,
    },
  });
  if (count !== 1) throw new PermissionDeniedError();
  return true;
}

export class AlreadyLinkedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "AlreadyLinkedError";
  }
}

export async function getPaymentWithAllocations(
  prisma: PrismaClient,
  ability: AppAbility,
  id: BillingTransactionId
) {
  return await prisma.billingTransaction.findFirstOrThrow({
    where: {
      id: billingTransactionIdToString(id),
    },
    include: {
      allocations: {
        include: {
          billingAdjustment: true,
          billingCharge: true,
        },
      },
      claims: true,
    },
  });
}

export class PaymentAlreadyPostedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "PaymentAlreadyPostedError";
  }
}
export class PaymentNotFullyAllocatedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "PaymentNotFullyAllocatedError";
  }
}

export async function postPayment(
  prisma: PrismaClient,
  ability: AppAbility,
  payment: Awaited<ReturnType<typeof getPaymentWithAllocations>>
) {
  if (payment.status === "Complete") {
    throw new PaymentAlreadyPostedError("Payment is already posted");
  }
  if (!ability.can("post", "BillingTransaction")) {
    throw new PermissionDeniedError();
  }

  // error if numbers don't balance
  const paymentCashTotal = payment.amount;
  const cashAllocationsTotal = sumAllocations(payments(payment));
  if (paymentCashTotal !== cashAllocationsTotal) {
    throw new PaymentNotFullyAllocatedError("Payment is not fully allocated");
  }

  // load the associated billing case for each claim. We need the current payer id to clone the claim later
  const claims = await Promise.all(
    payment.claims.map(async (claim) => ({
      ...claim,
      billingCase: await prisma.billingCase.findFirstOrThrow({
        include: {
          billingClaims: true,
          case: true,
          billingPayers: true,
        },
        where: {
          id: claim.billingCaseId,
        },
      }),
      billingPayer: await prisma.billingPayer.findFirstOrThrow({
        where: { id: claim.billingPayerId },
      }),
    }))
  );
  type Claim = (typeof claims)[0];

  const [claimsToClone, claimsThatAlreadyExist] = claims.reduce(
    ([clone, existing]: [Claim[], Claim[]], claim) => {
      const selectedNextPayerId = claim.billingCase.nextPayerId;
      if (!selectedNextPayerId) return [clone, existing];
      // check if claims array has a case has a claim with the selectedNextPayerId
      const hasExistingClaim = claims.some(
        (c) =>
          c.billingCaseId === claim.billingCaseId &&
          c.status !== BillingClaimStatus.Voided &&
          claim.billingCase.billingClaims.find((bc) => bc.billingPayerId === selectedNextPayerId)
      );
      if (hasExistingClaim) {
        const existingClaim = claim.billingCase.billingClaims.find(
          (c) => c.billingPayerId === selectedNextPayerId && c.status !== BillingClaimStatus.Voided
        )!;
        return [
          clone,
          [
            ...existing,
            {
              ...claim,
              ...existingClaim,
            },
          ],
        ];
      } else {
        return [[...clone, claim], existing];
      }
    },
    [[], []]
  );

  // Update statuses
  await prisma.$transaction([
    ...payment.allocations.map((allocation) =>
      prisma.billingTransactionAllocation.updateMany({
        where: {
          id: allocation.id,
        },
        data: {
          status: "Posted",
        },
      })
    ),
    prisma.billingTransaction.updateMany({
      where: {
        id: payment.id,
      },
      data: {
        status: "Complete",
      },
    }),
    ...claimsToClone.map((claim) =>
      prisma.billingClaim.create({
        data: {
          frequencyCode: claim.frequencyCode,
          status: BillingClaimStatus.Ready_to_Submit,
          referenceNumber: claim.referenceNumber,
          billingCase: {
            connect: {
              id: claim.billingCaseId,
            },
          },
          billingPayer: {
            connect: {
              id: claim.billingCase.nextPayerId!,
            },
          },
        },
      })
    ),
    ...claimsToClone.map((claim) =>
      // clear the current payer id
      prisma.billingCase.updateMany({
        data: {
          nextPayerId: null,
        },
        where: {
          id: claim.billingCaseId,
        },
      })
    ),
    ...claimsThatAlreadyExist
      .filter((claim) => claim.status === "New")
      .map((claim) =>
        prisma.billingClaim.updateMany({
          where: {
            id: claim.id,
          },
          data: {
            status: BillingClaimStatus.Ready_to_Submit,
          },
        })
      ),
  ]);

  // Fetch the newly cloned claims for later processing
  const clonedClaims = await prisma.billingClaim.findMany({
    where: {
      OR: claimsToClone.map((claim) => ({
        referenceNumber: claim.referenceNumber,
        billingCaseId: claim.billingCaseId,
        billingPayerId: claim.billingCase.nextPayerId!,
        status: BillingClaimStatus.Ready_to_Submit,
      })),
    },
    include: {
      billingPayer: true,
      billingCase: {
        include: {
          case: true,
        },
      },
    },
  });

  // Apply prepayments to any newly created self pay claims
  // 2024-04-08 BWB I don't think this is relevant anymore
  await Promise.all(
    clonedClaims.map(async (claim) => {
      if (
        claim.billingPayer.paymentType === "Self_Pay" &&
        (await needToApplyPrePayment(claim.billingCase.case))
      ) {
        await applyPrePayment(prisma, ability, toBillingClaimId(claim.id));
      }
    })
  );

  const billingClaimsThatMayBeDone = claims.filter(
    (claim) => claim.billingCase.nextPayerId === null
  );

  // update claim status if it's partially paid so it'll re-appear on the invoice list
  const claimStatusUpdates = claims.map(async (claim) => {
    const augmentedClaim = await augmentClaimWithBillingAmounts(prisma, claim);
    const outstanding = augmentedClaim.outstandingAmount;
    if (
      outstanding > 0 &&
      (await isSelfPay(prisma, ability, claim)) &&
      claim.status === BillingClaimStatus.Billed
    ) {
      return await prisma.billingClaim.updateMany({
        where: {
          id: claim.id,
        },
        data: {
          status: BillingClaimStatus.Ready_to_Submit,
        },
      });
    } else if (outstanding <= 0) {
      // if the claim is fully paid, or overpaid, we may still have collected a prepayment that still needs applied.
      const thisClaim = billingClaimsThatMayBeDone.find((c) => c.id === claim.id);
      if (thisClaim && (await needToApplyPrePayment(thisClaim.billingCase.case))) {
        const selfPayPayer = thisClaim?.billingCase.billingPayers.find(
          (payer) => payer.paymentType === BillingPayerType.Self_Pay
        );
        if (selfPayPayer) {
          const selfPayClaim = await prisma.billingClaim.create({
            data: {
              frequencyCode: claim.frequencyCode,
              status: BillingClaimStatus.Ready_to_Submit,
              referenceNumber: claim.referenceNumber,
              billingCase: { connect: { id: claim.billingCaseId } },
              billingPayer: { connect: { id: selfPayPayer.id } },
            },
          });
          await applyPrePayment(prisma, ability, toBillingClaimId(selfPayClaim.id));
        }
      }
    }
    // if the claim is fully paid, mark it as done
    // this is only relevant to self pays because self pay doesn't have a "save" button on the transaction allocation form
    // but it won't hurt to run against other claims, it's just a no-op
    if (outstanding === 0 && claim.status === BillingClaimStatus.Billed) {
      return await prisma.billingClaim.updateMany({
        where: {
          id: claim.id,
        },
        data: {
          status: BillingClaimStatus.Done,
        },
      });
    }
  });

  await Promise.all(claimStatusUpdates);

  return await getPaymentWithAllocations(prisma, ability, toBillingTransactionId(payment.id));
}

export async function deleteClaimAllocation(
  prisma: PrismaClient,
  ability: AppAbility,
  data: {
    billingTransactionId: string;
    billingChargeId: string;
    billingClaimId: BillingClaimId;
    billingAdjustmentId: string;
  }
) {
  const transaction = await prisma.billingTransaction.findFirstOrThrow({
    where: {
      id: data.billingTransactionId,
    },
  });
  if (transaction.status === "Complete")
    throw new NotUpdatableError("Transactions is already posted");

  const { count } = await prisma.billingTransactionAllocation.deleteMany({
    where: {
      billingTransactionId: data.billingTransactionId,
      billingChargeId: data.billingChargeId,
      billingClaimId: billingClaimIdToString(data.billingClaimId),
      billingAdjustmentId: data.billingAdjustmentId,
    },
  });
  if (count === 0) throw new PermissionDeniedError();
  return true;
}

export async function getAllocationsForTransaction(
  prisma: PrismaClient,
  ability: AppAbility,
  transactionId: BillingTransactionId,
  claimId: BillingClaimId
) {
  const claim = await prisma.billingClaim.findFirstOrThrow({
    where: { id: billingClaimIdToString(claimId) },
    include: {
      billingPayer: true,
      billingCase: {
        include: {
          billingCharges: {
            include: {
              billingChargeMaster: true,
            },
            orderBy: [{ sequenceNumber: "asc" }],
          },
        },
      },
    },
  });

  const allocations = await prisma.billingTransactionAllocation.findMany({
    where: {
      billingClaim: { billingCaseId: claim.billingCaseId },
    },
    include: {
      billingTransaction: true,
      billingClaim: {
        include: {
          billingPayer: true,
        },
      },
      billingAdjustment: true,
    },
  });

  return Promise.all(
    claim.billingCase.billingCharges
      .filter((charge) => charge.payerTypes.includes(claim.billingPayer.paymentType))
      .map(async (charge) => {
        const chargeMaster = await getChargeMasterForId(prisma, charge.billingChargeMasterId);

        // We need to manually calculate the claim billing amounts instead of using the
        // common claimBillingAmountsByCharge function because we need to separate out this
        // transaction's payments and adjustments from other transactions by the same payer,
        // and we need the individual adjustment records instead of just the sum.

        const billedAmount = charge.billedAmount;

        const chargeAllocations = allocations.filter(
          (a) =>
            a.billingChargeId === charge.id &&
            (a.billingTransaction.status === BillingTransactionStatus.Complete ||
              a.billingTransactionId === transactionId)
        );

        // Payments and adjustmnets made by prior payers in the sequence
        const priorPayersAmount = sumAllocations(
          priorPayersAllocations(chargeAllocations, claim.billingPayer.sequenceNumber)
        );

        // Adjustments made on this transaction
        const chargeAdjustments = chargeAllocations.filter(
          (a) => a.billingTransactionId === transactionId && !isPayment(a)
        ) as (BillingTransactionAllocation & {
          billingAdjustment: BillingAdjustment; // isPayment filters out any allocations with a null billingAdjustment
        })[];

        // Payments made on this transaction
        const paymentAmount = sumAllocations(
          payments({
            allocations: chargeAllocations.filter((a) => a.billingTransactionId === transactionId),
          })
        );

        // Payments made by this payer on previous transaction(s)
        const previousPaymentAmount = sumAllocations(
          payments({
            allocations: chargeAllocations.filter(
              (a) =>
                a.billingClaim.billingPayerId === claim.billingPayerId &&
                a.billingTransactionId !== transactionId
            ),
          })
        );

        // The total amount the current payer is responsible for
        const expectedAmount = billedAmount - priorPayersAmount;

        // The amount still owed by the current payer
        const outstandingAmount =
          billedAmount -
          priorPayersAmount -
          sumAllocations(chargeAdjustments.filter((a) => !isPatientResp(a))) -
          previousPaymentAmount -
          paymentAmount;

        return {
          ...charge,
          billingChargeMaster: chargeMaster,
          currency: CurrencyType.USD,
          billedAmount,
          priorPayersAmount,
          expectedAmount,
          adjustments: chargeAdjustments,
          previousPaymentAmount,
          payment: paymentAmount,
          outstandingAmount,
        };
      })
  );
}

/**
 * Detaches a billing claim from a payment
 * @see {@link https://www.prisma.io/docs/concepts/components/prisma-client/crud}
 *
 * @param prisma - PrismaClient instance
 * @param ability - CASL Ability instance
 * @param billingClaimId - ID of the billing claim
 * @param billingTransactionId - ID of the billing transaction (payment)
 * @returns A boolean indicating the success of the operation
 */
export async function detachBillingClaimFromPayment(
  prisma: PrismaClient,
  ability: AppAbility,
  billingClaimId: BillingClaimId,
  billingTransactionId: BillingTransactionId
): Promise<boolean> {
  // Validate permissions
  if (!ability.can("update", "BillingClaim")) {
    throw new PermissionDeniedError();
  }

  // Find billing allocations
  const billingAllocations = await prisma.billingTransactionAllocation.findMany({
    where: {
      billingTransactionId: billingTransactionIdToString(billingTransactionId),
      billingClaimId: billingClaimIdToString(billingClaimId),
    },
  });

  // Check for posted allocations
  if (billingAllocations.some((a) => a.status === "Posted")) {
    throw new Error("Claim contains charges which are already posted");
  }

  // Delete allocations
  await prisma.billingTransactionAllocation.deleteMany({
    where: {
      billingTransactionId: billingTransactionIdToString(billingTransactionId),
      billingClaimId: billingClaimIdToString(billingClaimId),
    },
  });

  // Update claim
  const updatedClaim =
    ability.can("update", "BillingClaim") &&
    (await prisma.billingClaim.update({
      where: {
        id: billingClaimIdToString(billingClaimId),
      },
      data: {
        transactions: {
          disconnect: {
            id: billingTransactionIdToString(billingTransactionId),
          },
        },
        status: "Billed",
        billingCase: {
          update: {
            nextPayerId: null,
          },
        },
      },
    }));

  return !!updatedClaim;
}

export class OverpaymentError extends Error {
  billingClaimId: BillingClaimId;
  constructor(message: string, claimId: BillingClaimId) {
    super(message);
    this.name = "OverpaymentError";
    this.billingClaimId = claimId;
  }
}
export async function refundOverpaymentToPatient(
  prisma: PrismaClient,
  ability: AppAbility,
  overpayerId: BillingPayerId
): ReturnType<typeof postPayment> {
  // this is all in flux right now and we're trying to get charges away from claims
  // so operate off the billingPayer which is only doable with a single claim but whatever.
  const overpaidClaim = await prisma.billingClaim.findFirstOrThrow({
    where: {
      billingPayerId: overpayerId,
    },
    include: {
      billingPayer: true,
      billingCase: {
        include: {
          case: {
            include: {
              facility: true,
            },
          },
        },
      },
    },
  });
  const sourceClaimId = toBillingClaimId(overpaidClaim.id);

  const augmentedClaim = await augmentClaimWithBillingAmounts(prisma, overpaidClaim);

  const difference = augmentedClaim.outstandingAmount;

  // sanity check the operation
  if (difference >= 0)
    throw new OverpaymentError(
      `Claim ${overpaidClaim.referenceNumber} is not overpaid`,
      sourceClaimId
    );
  const firstAllocation = augmentedClaim.allocations[0];
  if (!firstAllocation)
    throw new OverpaymentError(
      `Claim ${overpaidClaim.referenceNumber} has no allocations`,
      sourceClaimId
    );

  // BWB - I'm going to hell for putting a billingOrganizationId on BillingTransaction
  const { id: billingOrganizationId } = await billingOrganizationFor(prisma, {
    id: overpaidClaim.billingCase.case.facility.organizationId,
  });

  // make a transaction. Link it to the claim we found. Name the payment after the facility. Use the
  // organization from the facility. Tie it to the first charge, regardless of how many charges there
  // are on the claim.
  // TODO: It'd be better to spread the refund across all the charges. But that's a future improvement.
  const created = await prisma.billingTransaction.create({
    data: {
      amount: difference,
      payerType: BillingPayerType.Self_Pay,
      payerName: overpaidClaim.billingCase.case.facility.name,
      referenceNumber: `Refund of overpayment`,
      status: "In_Progress",
      organization: {
        connect: {
          id: billingOrganizationId,
        },
      },
      claims: {
        connect: {
          id: overpaidClaim.id,
        },
      },
      allocations: {
        create: {
          amount: difference,
          billingClaimId: sourceClaimId,
          billingChargeId: firstAllocation.billingChargeId,
          billingAdjustmentId: null,
          adjustmentGroup: null,
          status: BillingTransactionAllocationStatus.Draft,
        },
      },
    },
  });

  // Now we've drafted a payment. Post it.
  // we have to re-load the transaction in the form that postPayment expects
  const refund = await getPaymentWithAllocations(
    prisma,
    ability,
    toBillingTransactionId(created.id)
  );
  const posted = await postPayment(prisma, ability, refund);
  return posted;
}
