import { useEffect, useMemo, useState } from "react";
import { trpc } from "~/utils/trpc";
import { toCaseId, type CaseId, toEncounterId, type EncounterId } from "@procision-software/mason";
import { toBillingCaseId, type BillingCaseId } from "~/billing/models/case";
import { toBillingClaimId, type BillingClaimId } from "~/billing/models/claim";

export const useCaseRelatedData = ({
  caseId: initialCaseId = null,
  billingCaseId: initialBillingCaseId = null,
  claimId: initialClaimId = null,
  patientId: initialPatientId = null,
  encounterId: initialEncounterId = null,
  hasBilling = false,
}: {
  caseId?: CaseId | null;
  billingCaseId?: BillingCaseId | null;
  claimId?: BillingClaimId | null;
  patientId?: string | null;
  encounterId?: EncounterId | null;
  hasBilling?: boolean;
}) => {
  const [caseId, setCaseId] = useState<string | null>(initialCaseId);
  const [patientId, setPatientId] = useState<string | null>(initialPatientId);
  const [billingCaseId, setBillingCaseId] = useState<string | null>(initialBillingCaseId);
  const [encounterId, setEncounterId] = useState<string | null>(initialEncounterId);
  const claimId = initialClaimId;

  // Synchronize state with input parameters
  useEffect(() => {
    setCaseId(initialCaseId ?? null);
  }, [initialCaseId]);

  useEffect(() => {
    setBillingCaseId(initialBillingCaseId ?? null);
  }, [initialBillingCaseId]);

  useEffect(() => {
    setPatientId(initialPatientId ?? null);
  }, [initialPatientId]);

  useEffect(() => {
    setEncounterId(initialEncounterId ?? null);
  }, [initialEncounterId]);

  // Fetch patientId and encounterId from caseId
  const { data: caseData } = trpc.case.get.useQuery({ id: caseId ?? "" }, { enabled: !!caseId });
  useEffect(() => {
    if (caseData) {
      setPatientId(caseData.patientId ?? patientId);
      setEncounterId(caseData.encounterIds?.[0] ?? encounterId);
    }
  }, [caseData, patientId, encounterId]);

  // Fetch billingCaseId from caseId
  const { data: billingCaseData } = trpc.billing.case.billingCaseIdForCase.useQuery(
    { id: caseId ?? "" },
    { enabled: !billingCaseId && !!caseId && hasBilling }
  );
  useEffect(() => {
    if (billingCaseData) {
      setBillingCaseId(billingCaseData.billingCaseId ?? billingCaseId);
    }
  }, [billingCaseData, billingCaseId]);

  // Fetch caseId and patientId from billingCaseId
  const { data: billingCaseSummaryData } = trpc.billing.case.summary.useQuery(
    { id: billingCaseId ?? "" },
    { enabled: !!billingCaseId && !caseId && hasBilling }
  );
  useEffect(() => {
    if (billingCaseSummaryData) {
      setCaseId(billingCaseSummaryData.caseId ?? caseId);
      setPatientId(billingCaseSummaryData.patientId ?? patientId);
      setEncounterId(billingCaseSummaryData.encounterId ?? encounterId);
    }
  }, [billingCaseSummaryData, caseId, patientId, encounterId]);

  // Fetch billingCaseId from claimId
  const { data: claimData } = trpc.billing.claims.get.useQuery(
    { id: claimId ?? "" },
    { enabled: !!claimId && !billingCaseId }
  );
  useEffect(() => {
    if (claimData) {
      setBillingCaseId(claimData.billingCaseId ?? billingCaseId);
      setCaseId(claimData.billingCase?.caseId ?? caseId);
    }
  }, [claimData, billingCaseId, caseId]);

  return useMemo(
    () => ({
      caseId: caseId ? toCaseId(caseId) : null,
      billingCaseId: billingCaseId ? toBillingCaseId(billingCaseId) : null,
      claimId: claimId ? toBillingClaimId(claimId) : null,
      patientId,
      encounterId: encounterId ? toEncounterId(encounterId) : null,
    }),
    [caseId, billingCaseId, claimId, patientId, encounterId]
  );
};
