import cuid2 from "@paralleldrive/cuid2";
import {
  EncounterStatus,
  type BarePrismaClient,
  type EmrTemplatePage,
  type EmrTemplateWorkflowType,
  type Encounter,
  type EncounterWorkflow,
} from "@procision-software/database";
import type { Case, CaseStatus, Staff } from "@prisma/client";
import { JobRoleType } from "@procision-software/database-zod";
import { dictionary as NOTEKEYS, noteGet } from "../patient-note";
import { surgeryDetailsAsText } from "./surgery-details";
import { DateTime } from "luxon";
import { AMERICAN_12H_TIME, AMERICAN_DATE_4Y } from "../../util/dates";
import { staffNameLastFirstTitle } from "../staff";
import { caseIdToString, type CaseId } from "../appointment";
import type { EmrTemplateId } from "../emr-template";
import { codeableConceptIdForPath } from "../codeable-concept";

export enum EncounterCreationStatus {
  Exists,
  Created,
  TooSoon,
  ErrorCreating,
}

// declare const EncounterId: unique symbol;
// export type EncounterId = string & { readonly tag: typeof EncounterId };
export type EncounterId = string;
export function encounterIdToString(id: EncounterId) {
  return id.toString();
}
export function toEncounterId(id: string) {
  return id;
}

export const appointmentStatusToEncounterStatusCrosswalk: Partial<
  Record<CaseStatus, EncounterStatus>
> = {
  Abandoned: EncounterStatus.Canceled,
  Accepted: EncounterStatus.Planned,
  Arrived: EncounterStatus.Arrived,
  Canceled: EncounterStatus.Canceled,
  Checked_In: EncounterStatus.CheckedIn,
  Denied: EncounterStatus.Canceled,
  Completed: EncounterStatus.Finished,
  Discharged: EncounterStatus.Finished,
  In_OR: EncounterStatus.InProgress,
  In_PreOp: EncounterStatus.InProgress,
  Late: EncounterStatus.Planned,
  Ready_for_OR: EncounterStatus.Arrived,
  // Requested: null,
  Partially_Completed: EncounterStatus.Finished,
  Recovery: EncounterStatus.InProgress,
  // Draft: null
};
export const EncounterSmartFields = [
  { field: "facilityName", label: "Facility name" },
  { field: "facilityAddress", label: "Facility address" },
  { field: "dateOfService", label: "Date of service" },
  { field: "timeOfService", label: "Time of service" },
  { field: "timeToArrive", label: "Time to arrive" },
  { field: "surgeonName", label: "Surgeon name" },
  { field: "anesthesiaProvider", label: "Anesthesia provider" },
  { field: "scheduledSurgery", label: "Scheduled surgery" },
  { field: "scheduledAnesthesia", label: "Scheduled anesthesia" },
  { field: "performedSurgery", label: "Performed surgery" },
  { field: "administeredAnesthesia", label: "Administered anesthesia" },
  { field: "preOpDiagnosis", label: "Pre-Op diagnosis" },
  { field: "postOpDiagnosis", label: "Post-Op diagnosis" },
  { field: "estimatedBloodLoss", label: "Estimated blood loss" },
  { field: "patientPatLink", label: "Patient PAT Link" },
] as const;

export const getEncounterSmartFieldDataPrisma = async (
  prisma: BarePrismaClient,
  input: { encounterId: string }
) => {
  const [encounter, caseRoles, surgeryDetails, ebl, aa, ps, podx] = await Promise.all([
    prisma.encounter.findFirstOrThrow({
      where: { id: input.encounterId },
      include: { appointment: { include: { facility: { include: { organization: true } } } } },
    }),
    caseRolesForEncounter(prisma, input.encounterId),
    surgeryDetailsAsText(prisma, input.encounterId),
    noteGet(prisma, input.encounterId, NOTEKEYS.estimatedbloodloss, false),
    noteGet(prisma, input.encounterId, NOTEKEYS.administeredAnesthesia, false),
    noteGet(prisma, input.encounterId, NOTEKEYS.performedsurgery, false),
    noteGet(prisma, input.encounterId, NOTEKEYS.postopdx, false),
  ]);
  const facility = encounter.appointment?.facility;

  const surgeon = caseRoles.find((cr) => cr.jobRole.type === JobRoleType.SURGEON)?.staff;

  const anesthesiaProviders = caseRoles
    .filter((cr) => cr.jobRole.type === JobRoleType.ANESTHESIOLOGIST)
    .map((cr) => cr.staff);

  const facilityAddress =
    `${facility?.address1 ?? ""} ${facility?.address2 ?? ""} ${facility?.city ?? ""}, ${facility?.state ?? ""} ${facility?.zip ?? ""}`
      .replace("  ", " ")
      .trim();

  return encounterSmartFieldData(
    {
      facility: facility
        ? {
            name: facility.name,
            address: facilityAddress,
            timezone: facility.timezone,
            organizationSlug: facility.organization.name,
          }
        : undefined,
      periodStart: encounter.periodStart,
      surgeon: surgeon ?? undefined,
      anesthesiaProviders,
      notes: {
        preformedSurgery: ps?.notes,
        administeredAnesthesia: aa?.notes,
        preOpDiagnosis: surgeryDetails?.preOpDiagnosis,
        postOpDiagnosis: podx?.notes,
        estimatedBloodLoss: ebl?.notes,
      },
      appointment: encounter.appointment,
      emrTemplateId: encounter.emrTemplateId,
    },
    {
      baseUrl: process.env.FC_URL ?? "", // TODO: fix this so when mason is not running under the scheduling app context it still works
    }
  );
};

export function encounterSmartFieldData(
  encounter: {
    facility?: {
      name: string;
      address: string;
      timezone: string;
      organizationSlug: string;
    };
    periodStart: Date | null;
    surgeon: Staff | undefined;
    anesthesiaProviders: Staff[];
    notes: {
      preformedSurgery: string | undefined;
      administeredAnesthesia: string | undefined;
      preOpDiagnosis: string | undefined;
      postOpDiagnosis: string | undefined;
      estimatedBloodLoss: string | undefined;
    };
    appointment: Pick<Case, "name" | "anesthesiaType" | "arriveMinutesBeforeAppointment"> | null;
    emrTemplateId: string;
  },
  context: {
    baseUrl: string;
  }
) {
  // const baseUrlWithSlash = context.baseUrl.endsWith("/") ? context.baseUrl : `${context.baseUrl}/`;
  const emrTemplateParam = encounter.emrTemplateId ? `/${encounter.emrTemplateId}` : "";
  // const patientPatLink = !encounter.facility
  //   ? undefined
  //   : `${baseUrlWithSlash}pat/${encounter.facility.organizationSlug}${emrTemplateParam}`;

  const patientPatLink =
    !encounter.facility || !context.baseUrl.length
      ? undefined
      : new URL(
          `pat/${encounter.facility.organizationSlug}${emrTemplateParam}`,
          context.baseUrl
        ).toString();

  return {
    facilityName: encounter.facility?.name,
    facilityAddress:
      (encounter.facility?.address.length ?? 0) > 0 ? encounter.facility!.address : undefined,
    dateOfService:
      encounter.periodStart && encounter.facility
        ? DateTime.fromJSDate(encounter.periodStart, {
            zone: encounter.facility.timezone,
          }).toFormat(AMERICAN_DATE_4Y)
        : undefined,
    timeOfService:
      encounter.periodStart && encounter.facility
        ? DateTime.fromJSDate(encounter.periodStart, {
            zone: encounter.facility.timezone,
          }).toFormat(AMERICAN_12H_TIME)
        : undefined,
    timeToArrive:
      encounter.periodStart &&
      encounter.facility &&
      encounter.appointment?.arriveMinutesBeforeAppointment
        ? DateTime.fromJSDate(encounter.periodStart, {
            zone: encounter.facility.timezone,
          })
            .minus({ minutes: encounter.appointment.arriveMinutesBeforeAppointment })
            .toFormat(AMERICAN_12H_TIME)
        : undefined,
    surgeonName: encounter.surgeon ? staffNameLastFirstTitle(encounter.surgeon) : undefined,
    anesthesiaProvider:
      encounter.anesthesiaProviders.length > 0
        ? encounter.anesthesiaProviders.map((anes) => staffNameLastFirstTitle(anes)).join(", ")
        : undefined,
    scheduledSurgery: encounter.appointment?.name,
    scheduledAnesthesia: encounter.appointment?.anesthesiaType ?? undefined,
    performedSurgery: encounter.notes.preformedSurgery ?? undefined,
    administeredAnesthesia: encounter.notes.administeredAnesthesia ?? undefined,
    preOpDiagnosis: encounter.notes.preOpDiagnosis ?? undefined,
    postOpDiagnosis: encounter.notes.postOpDiagnosis ?? undefined,
    estimatedBloodLoss: encounter.notes.estimatedBloodLoss ?? undefined,
    patientPatLink,
  } satisfies Partial<Record<(typeof EncounterSmartFields)[number]["field"], string | undefined>>;
}

/**
 * Applies a template to an encounter. Any pages already associated will be skipped.
 * @param prisma
 * @param encounter
 * @returns true if changes were made, false if no changes were made
 */
export async function makeEmrWorkflowPagesForTemplate(
  prisma: Pick<BarePrismaClient, "emrTemplate" | "encounterWorkflow">,
  {
    emrTemplateId,
    ...encounter
  }: Pick<Encounter, "id" | "emrTemplateId"> & {
    workflowPages: (EncounterWorkflow & { emrTemplatePage: EmrTemplatePage })[];
  }
) {
  // a map of types to the existing page ids. If a page doesn't yet exist, it'll be undefined after this
  const existingPages = encounter.workflowPages.reduce(
    (acc, page) => {
      acc[page.emrTemplatePage.workflowType] = page.emrTemplatePageId;
      return acc;
    },
    {} as Record<EmrTemplateWorkflowType, string | null>
  );

  // load newest published version of template
  const emrTemplate = await prisma.emrTemplate.findFirstOrThrow({
    where: {
      id: emrTemplateId,
    },
    include: {
      pages: {
        where: {
          status: "Published",
        },
        orderBy: {
          version: "desc",
        },
      },
    },
  });

  // find pages that are defined on the template but missing on the workflow assignment
  const missingPages = emrTemplate.pages.filter((p) => !existingPages[p.workflowType]);

  // if there are no missing pages, we're done
  if (missingPages.length === 0) return false;

  // link the leftovers
  await prisma.encounterWorkflow.createMany({
    data: missingPages.map((page) => {
      return {
        encounterId: encounter.id,
        emrTemplatePageId: page.id,
      };
    }),
  });

  return true;
}

export async function getEncounter(prisma: BarePrismaClient, id: string) {
  const loader = async () => {
    const encounter = await prisma.encounter.findFirstOrThrow({
      where: {
        id,
      },
      include: {
        lengthCode: true,
        location: true,
        appointment: true,
        patient: true,
        priorityCode: true,
        serviceProvider: true,
        emrTemplate: true,
        workflowPages: {
          include: {
            emrTemplatePage: true,
          },
        },
      },
    });
    const emrTemplateId = encounter.emrTemplateId;
    return {
      ...encounter,
      workflowPages: encounter.workflowPages
        .filter((wp) => wp.emrTemplatePage.emrTemplateId === emrTemplateId)
        .map((wp) => ({
          ...wp,
          readOnly: false, // FIXME: This needs to be computed based on future case closing work.
        })),
    };
  };
  const encounter = await loader();
  try {
    // TODO: once "closing the chart" happens this should return what actually exists and never mutate
    // keywords closed closing close the chart
    const changes = await makeEmrWorkflowPagesForTemplate(prisma, encounter);
    if (changes) {
      return loader();
    } else {
      return encounter;
    }
  } catch (_e) {
    // TODO: verify it's a AbilityError
    return encounter;
  }
}

export async function encounterForAppointment(
  prisma: Pick<BarePrismaClient, "encounter">,
  appointmentId: string
): Promise<{ id: string }> {
  if (
    1 !==
    (await prisma.encounter.count({
      where: {
        appointmentId,
        phantom: false,
      },
    }))
  ) {
    throw new Error("Expected exactly one encounter for appointment");
  }
  return await prisma.encounter.findFirstOrThrow({
    where: {
      appointmentId,
      phantom: false,
    },
    select: {
      id: true,
    },
  });
}

export function encounterUpdate(client: BarePrismaClient, id: string, data: Partial<Encounter>) {
  return client.encounter.update({
    where: { id },
    data,
  });
}

export async function ensureEncounterExistsForAppointment(
  prisma: BarePrismaClient,
  appointmentId: CaseId,
  emrTemplateId: EmrTemplateId
): Promise<EncounterCreationStatus> {
  try {
    const appointment = await prisma.case.findFirstOrThrow({
      where: {
        id: appointmentId,
      },
      include: {
        facility: true,
      },
    });
    const status = appointmentStatusToEncounterStatusCrosswalk[appointment.status];
    if (!status) return EncounterCreationStatus.TooSoon;
    const existingEncounter = await prisma.encounter.count({
      where: {
        appointmentId,
      },
    });

    if (existingEncounter) {
      return EncounterCreationStatus.Exists;
    }

    if (!appointment.patientId) throw new Error("Appointment is missing patientId");

    const periodEnd = DateTime.fromJSDate(appointment.surgeryDate, {
      zone: appointment.facility.timezone,
    })
      .plus({ minutes: appointment.expectedCaseLength ?? 0 })
      .toJSDate();

    await prisma.encounter.create({
      data: {
        patient: {
          connect: {
            id: appointment.patientId,
          },
        },
        status,
        emrTemplate: {
          connect: {
            id: emrTemplateId,
          },
        },
        identifier: appointment.financialReference.toString(),
        priorityCode: {
          connect: {
            id: await codeableConceptIdForPath(prisma, "CLIN.ENCNTR.PRI.ROUT"),
          },
        },
        appointment: {
          connect: {
            id: caseIdToString(appointmentId),
          },
        },
        serviceProvider: {
          connect: {
            id: appointment.facility.organizationId,
          },
        },
        length: appointment.expectedCaseLength,
        lengthCode: {
          connect: {
            id: await codeableConceptIdForPath(prisma, "UOM.TIME.MIN"),
          },
        },
        periodStart: appointment.surgeryDate,
        periodEnd,
      },
    });
    return EncounterCreationStatus.Created;
  } catch (e) {
    console.error(JSON.stringify(e));
    return EncounterCreationStatus.ErrorCreating;
  }
}
export async function caseRolesForEncounter(prisma: BarePrismaClient, encounterId: string) {
  const encounter = await prisma.encounter.findFirstOrThrow({
    where: { id: encounterId },
    include: {
      appointment: {
        include: {
          caseRoles: {
            include: {
              staff: true,
              jobRole: true,
            },
          },
          surgeon: true,
        },
      },
    },
  });
  if (!encounter.appointment) return [];
  const caseRoles = encounter.appointment.caseRoles;
  const jitJobRoleId = cuid2.createId();
  caseRoles.unshift({
    // in scheduling, surgeon was special. In EMR, we want to treat them as a case role. Fake it here.
    id: cuid2.createId(),
    caseId: encounter.appointment.id,
    staffId: encounter.appointment.surgeonId,
    jobRoleId: jitJobRoleId,
    staff: encounter.appointment.surgeon,
    jobRole: {
      type: "SURGEON",
      id: jitJobRoleId,
      name: "Surgeon",
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    createdAt: new Date(),
    updatedAt: new Date(),
    roleId: encounter.appointment.surgeonId, // our old roleId idea seems useless
  });
  return caseRoles;
}
