import { useUser } from "@clerk/nextjs";
import { zodResolver } from "@hookform/resolvers/zod";
import { type Message, MessageSchema, type User } from "@procision-software/database-zod";
import { Button, cn, Fieldset, Form, InputField } from "@procision-software/ui";
import { useAppContext } from "~/components/AppContextProvider";
import { useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { AMERICAN_12H_TIME, AMERICAN_DATE_4Y } from "~/utils/dates";
import { useMessageThreadContext } from "./MessageThreadContext";

const messageFormSchema = MessageSchema.pick({
  body: true,
});

type MessageFormData = z.infer<typeof messageFormSchema>;

type MessageProps = {
  message: Message & { user: User };
  variant: "left" | "right";
};

function Message({
  message: {
    body,
    createdAt,
    user: { name },
  },
  variant,
}: MessageProps) {
  const { formatDate } = useAppContext();
  return (
    <div className={cn("mx-2 flex", variant === "left" ? "justify-start" : "justify-end")}>
      <div
        className={cn(
          "flex w-full flex-col gap-1 sm:w-3/4",
          variant === "left" ? "items-start" : "items-end"
        )}
      >
        <div className="text-xs text-secondary">
          {formatDate(`${AMERICAN_DATE_4Y} ${AMERICAN_12H_TIME}`, createdAt)}
        </div>
        <div
          className={cn(
            "rounded-lg border p-2 text-sm shadow-md",
            variant === "left" && "rounded-bl-none border-primary bg-primary text-primary",
            variant === "right" && "rounded-br-none border-brand/20 bg-brand-alt/30 text-primary"
          )}
        >
          {body}
        </div>
        {variant === "left" && <div className="text-sm text-secondary">{name}</div>}
      </div>
    </div>
  );
}

export default function Messages() {
  const { messages, isLoading, createMessage, isCreatingMessage } = useMessageThreadContext();
  const { user } = useUser();

  const form = useForm<MessageFormData>({
    resolver: zodResolver(messageFormSchema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    watch,
  } = form;

  const body = watch("body");

  const onSubmit = useCallback(
    async ({ body }: MessageFormData) => {
      const cleanBody = body.trim();
      if (cleanBody.length > 0) {
        await createMessage(cleanBody);
        reset();
      }
    },
    [createMessage, reset]
  );

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, messagesEndRef]);

  return (
    <>
      <div className="flex flex-1 flex-col gap-2 overflow-y-scroll">
        {messages.map((message) => (
          <Message
            key={message.id}
            message={message}
            variant={user?.id === message.userId ? "right" : "left"}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} className="flex-none border-t border-primary pt-4">
        <Fieldset>
          <InputField
            {...form}
            name="body"
            placeholder="Enter your message..."
            disabled={isLoading || isSubmitting || isCreatingMessage}
            className="w-full"
            fieldClassName="flex-1"
          />
          <Button
            type="submit"
            variant="primary"
            outline={false}
            disabled={!body || isSubmitting || isCreatingMessage}
            loading={isCreatingMessage}
            className="w-32"
          >
            Send
          </Button>
        </Fieldset>
      </Form>
    </>
  );
}
