import { CaseStatus } from "@procision-software/database";
import { DateTime } from "luxon";
import { useRouter } from "~/hooks/useRouter";
import CreateCaseButton from "~/components/practice/case/CreateCaseButton";
import { trpc } from "~/utils/trpc";
import NavBar from "./NavBar";
import { useFeatureFlags } from "~/hooks/feature-flags";
import { useMemo } from "react";

type NavItem = {
  name: string;
  href: string;
  count?: number;
  children?: React.ReactNode;
};

export default function AscNavBar() {
  const { billing, scheduling, reports, emrFeatures, materialsDemo } = useFeatureFlags();
  const router = useRouter();

  const { data: pendingCount } = trpc.case.count.useQuery(
    {
      filters: { status: [CaseStatus.Requested] },
    },
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      select: (data) => data._count.id,
    }
  );

  const { data: statusTrackerOverviewCount } = trpc.statusTracker.overviewCount.useQuery(
    {
      filter: {
        date: {
          start: DateTime.utc().startOf("day").toJSDate(),
          end: DateTime.utc().plus({ week: 1 }).endOf("day").toJSDate(),
        },
        status: ["incomplete"],
      },
    },
    {
      staleTime: 1000 * 60 * 60, // 1 hour
    }
  );

  const { data: cancelledCount } = trpc.scheduling.reports.case.cancellations.useQuery(
    {
      page: 0,
      perPage: 0,
      filters: {
        dos: {
          start: DateTime.now().minus({ week: 1 }).endOf("day").toJSDate(),
          end: DateTime.now().plus({ week: 1 }).endOf("day").toJSDate(),
        },
      },
    },
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      select: (data) => data.pagination.all,
    }
  );

  const navItems: NavItem[] = useMemo(
    () =>
      [
        ...(((scheduling && [
          { name: "Pending", href: "/facility/cases/pending", count: pendingCount },
          { name: "Canceled", href: "/facility/cases/canceled", count: cancelledCount },
          {
            name: "Cases",
            href: "/facility/cases",
            children: (
              <CreateCaseButton
                variant="primary"
                outline={false}
                asLink
                size="sm"
                onCreated={(id) => router.push(`/facility/cases/${id}`)}
              />
            ),
          },
          { name: "Calendar", href: "/facility/calendar" },
          {
            name: "Status Tracker",
            href: "/facility/status-tracker",
            count: statusTrackerOverviewCount,
          },
          { name: "Patients", href: "/patients" },
        ]) ||
          []) satisfies NavItem[]),
        ...(((materialsDemo && [{ name: "Materials", href: "/mat-demo" }]) ||
          []) satisfies NavItem[]),
        ...(((billing && [{ name: "Billing", href: "/billing/cases" }]) || []) satisfies NavItem[]),
        ...(((reports && [{ name: "Reports", href: "/reports" }]) || []) satisfies NavItem[]),
        ...(((emrFeatures && [{ name: "Charts", href: "/emr/encounters" }]) ||
          []) satisfies NavItem[]),
        { name: "Admin", href: "/facility/admin/center-info" },
      ] satisfies NavItem[],
    [
      router,
      billing,
      scheduling,
      reports,
      emrFeatures,
      materialsDemo,
      pendingCount,
      statusTrackerOverviewCount,
      cancelledCount,
    ]
  );

  return <NavBar navItems={navItems} />;
}
