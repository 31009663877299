import { prisma } from "@procision-software/database";
import { CaseStatus, type Case, type Patient } from "@procision-software/database-zod";
import {
  PATIENT_COMPLETION,
  patientFormSchemaForCompletion,
} from "@procision-software/mason/types";
import type { Perspective } from "~/components/AppContextProvider";
import { z, type RefinementCtx } from "zod";
import {
  caseIsClearOfOthersBlockTime,
  caseIsInAvailableBlockTime,
  caseIsNotOnAHoliday,
  caseIsRelativelyInTheFuture,
  facilityAllowSchedulingInOpenTime,
  facilityIsOpen,
  isDayOfStatus,
} from "~/models/case";
import { SurgeryDateSchema } from "~/schemas/SurgeryDateSchema";
import { client } from "~/utils/trpc";

export const PAGE_FOR_FIELD: Partial<
  Record<
    | keyof Case
    | "procedures"
    | "paymentInsurance"
    | "paymentSelfPay"
    | "paymentWorkersComp"
    | "payment"
    | "patient",
    string
  >
> = {
  patientFirstName: "demographics",
  patientLastName: "demographics",
  patientDateOfBirth: "demographics",
  heightInInches: "demographics",
  weightInPounds: "demographics",
  procedures: "casedetails",
  surgeonId: "casedetails",
  roomId: "casedetails",
  surgeryDate: "casedetails",
  paymentInsurance: "payment",
  paymentSelfPay: "payment",
  paymentWorkersComp: "payment",
  patientSex: "demographics",
  patientAddress1: "demographics",
  patientCity: "demographics",
  patientState: "demographics",
  patientZip: "demographics",
  patientEmail: "demographics",
  patientMobilePhone: "demographics",
  payment: "payment",
  patientId: "demographics",
  patient: "demographics",
  name: "casedetails",
  expectedCaseLength: "casedetails",
  roomTurnOverTime: "casedetails",
  arriveMinutesBeforeAppointment: "casedetails",
  anesthesiaType: "casedetails",
};

// when casestatus == draft, these are the required fields
const CaseCreationRequirements = z
  .object({
    id: z.string().optional(),
    facilityId: z.string(),
    practiceId: z.string(),
    name: z.string(),
    patientFirstName: z.string(),
    patientLastName: z.string(),
    surgeonId: z.string(),
    surgeryDate: z.date({
      required_error: "Surgery date is required",
      invalid_type_error: "Surgery date is required",
    }),
  })
  .passthrough();

// This block of code is preserved for posterity lest we need to require one of many payment methods to be supplied
// nasty OR hack to produce a single error message across 3 fields
// const PaymentsSchema = z
//   .object({
//     paymentSelfPay: z.array(PaymentSelfPaySchema).nonempty({
//       message:
//         "At least one payment method amongst workers comp, insurance, and self pay is required",
//     }),
//     paymentInsurance: z.array(PaymentInsuranceSchema),
//     paymentWorkersComp: z.array(PaymentWorkersCompSchema),
//   })
//   .or(
//     z.object({
//       paymentWorkersComp: z.array(PaymentWorkersCompSchema).nonempty({
//         message:
//           "At least one payment method amongst workers comp, insurance, and self pay is required",
//       }),
//       paymentSelfPay: z.array(PaymentSelfPaySchema),
//       paymentInsurance: z.array(PaymentInsuranceSchema),
//     })
//   )
//   .or(
//     z.object({
//       paymentInsurance: z.array(PaymentInsuranceSchema).nonempty({
//         message:
//           "At least one payment method amongst workers comp, insurance, and self pay is required",
//       }),
//       paymentSelfPay: z.array(PaymentSelfPaySchema),
//       paymentWorkersComp: z.array(PaymentWorkersCompSchema),
//     })
//   );

const draft = (_perspective: Perspective) => {
  return CaseCreationRequirements.extend({
    id: z.string(),
    status: z.nativeEnum(CaseStatus).default(CaseStatus.Draft),
  });
};

const requested = (perspective: Perspective) => {
  return draft(perspective).extend({
    surgeonId: z.string().min(1, "Surgeon is required"),
    facilityId: z.string().min(1, "Facility is required"),
    practiceId: z.string().min(1, "Practice is required"),
    roomId: z
      .string({
        invalid_type_error: "Room is required",
      })
      .min(1, "Room is required"),
    specialInstructions: z.string(),
    name: z.string().min(1, "Surgery description required"),
    patientFirstName: z.string().min(1, "Patient first name is required"),
    patientLastName: z.string().min(1, "Patient last name is required"),
    surgeryDate: SurgeryDateSchema,
    expectedCaseLength: z
      .number({
        invalid_type_error: "Expected case length is required",
      })
      .min(1, "Expected case length must be greater than 0"),
    roomTurnOverTime: z
      .number({
        invalid_type_error: "Room turn over time is required",
      })
      .min(0, "Room turn over time cannot be negative"),
    patientDateOfBirth: z.date({
      required_error: "Patient date of birth is required",
      invalid_type_error: "Patient date of birth is required",
    }),
    patientMobilePhone: z
      .string({ invalid_type_error: "Patient mobile phone is required" })
      .min(1, "Patient mobile phone is required"),
    arriveMinutesBeforeAppointment: z
      .number({
        invalid_type_error: "Arrival time prior to surgery is required",
      })
      .min(0, "Arrival time prior to surgery cannot be negative"),
  }); // .and(PaymentsSchema) -- issue 810 https://github.com/Procision-Software/webapp/issues/810
};

const accepted = (perspective: Perspective) => {
  if (perspective === "asc") {
    return requested(perspective).extend({
      patientId: z.string().min(1, "Patient is required"),
      patientMobilePhone: z.string().nullish(), // mobile phone no longer required
    });
  } else {
    return requested(perspective);
  }
};

const dayOf = (perspective: Perspective) => {
  return accepted(perspective).extend({
    anesthesiaType: z.string().min(1, "Anesthesia type is required"),
  });
};

function validatorForStatus(desiredStatus: CaseStatus, perspective: Perspective) {
  if (desiredStatus === CaseStatus.Draft) {
    return draft(perspective);
  } else if (desiredStatus === CaseStatus.Requested) {
    return requested(perspective);
  } else if (desiredStatus === CaseStatus.Accepted) {
    return accepted(perspective);
  } else if (desiredStatus === CaseStatus.Denied) {
    return draft(perspective);
  } else if (desiredStatus === CaseStatus.Abandoned) {
    return draft(perspective);
  } else if (desiredStatus === CaseStatus.Canceled) {
    return draft(perspective);
  } else if (isDayOfStatus(desiredStatus)) {
    return dayOf(perspective);
  } else {
    return accepted(perspective);
  }
}

export function getValidator(
  desiredStatus: CaseStatus,
  perspective: Perspective,
  page?: "casedetails" | "demographics",
  patientSchema?: z.ZodTypeAny
) {
  const v = patientSchema
    ? // This is ugly, but I cannot find a better way to do this that doesn't break the return type of
      // getValidator. Trying to conditionally set the patient results in a return type of "any".
      // ex: ...(perspective === "practice" ? { patient: patientSchema } : {})
      // Also trying to set the patientFirstName or patientLastName breaks the return type and results in
      // a type of "any" as well.
      perspective === "practice"
      ? validatorForStatus(desiredStatus, perspective).extend({
          // The practice cannot edit a patient, so we need to remove the requirements for patient fields
          patientFirstName: z.string(),
          patientLastName: z.string(),
          patientDateOfBirth: z.date().nullable(),
          patientMobilePhone: z.string().nullable(),
        })
      : validatorForStatus(desiredStatus, perspective).extend({
          // Make require case fields optional, require patient instead
          patientFirstName: z.string(),
          patientLastName: z.string(),
          patientDateOfBirth: z.date().nullable(),
          patientMobilePhone: z.string().nullable(),
          patient: patientSchema,
        })
    : validatorForStatus(desiredStatus, perspective);

  // if this was inlined into the requestedSchema, it'd be a zodeffect instead of a zodobject and we couldn't further extend it
  // once the case is in the past or accepted, it could rightfully become otherwise disallowed
  const surgeryDateValidation = async (
    kase: z.infer<ReturnType<typeof requested>>,
    ctx: RefinementCtx
  ) => {
    const trpc = client;
    if (
      perspective === "practice" &&
      ([CaseStatus.Requested, CaseStatus.Accepted] as CaseStatus[]).includes(desiredStatus)
    ) {
      // a number of restrictions on the practice that are not imposed on facility
      // most of these have 2 code paths based on tRPC (f/e compatability) or Prisma (server, faster)
      // it's long. I'm sorry. I don't know a better way.
      // security warning: the global prisma is not scoped to a user and won't be appropriately logged.
      //                   these calls are all lookups and return booleans and as of time of commenting
      //                   wouldn't be logged anyway.
      const globalPrisma__warn = typeof window === "undefined" ? prisma : undefined;
      await Promise.all(
        [
          async () => /* caseIsRelativelyInTheFuture */ {
            if (!caseIsRelativelyInTheFuture(kase)) {
              ctx.addIssue({
                message: "The case cannot be scheduled in the past",
                code: "custom",
                path: ["surgeryDate"],
              });
            }
            return Promise.resolve();
          },
          async () => /* caseIsNotOnAHoliday */ {
            if (globalPrisma__warn) {
              if (
                !(await caseIsNotOnAHoliday(globalPrisma__warn, {
                  ...kase,
                  roomId: kase.roomId,
                }))
              ) {
                ctx.addIssue({
                  message: `The facility is closed for a holiday`,
                  code: "custom",
                  path: ["surgeryDate"],
                });
              }
            } else {
              if (
                !(await trpc.case.surgeryDate.caseIsNotOnAHoliday.mutate({
                  surgeryDate: kase.surgeryDate,
                  roomId: kase.roomId,
                  expectedCaseLength: kase.expectedCaseLength,
                }))
              ) {
                ctx.addIssue({
                  message: `The facility is closed for a holiday`,
                  code: "custom",
                  path: ["surgeryDate"],
                });
              }
            }
          },
          async () => {
            if (globalPrisma__warn) {
              if (
                !(await caseIsInAvailableBlockTime(globalPrisma__warn, {
                  ...kase,
                  roomId: kase.roomId,
                  expectedCaseLength: kase.expectedCaseLength,
                })) &&
                !(await facilityIsOpen(globalPrisma__warn, {
                  ...kase,
                  roomId: kase.roomId,
                  expectedCaseLength: kase.expectedCaseLength,
                }))
              ) {
                ctx.addIssue({
                  message: "The facility is closed during the appointment time",
                  code: "custom",
                  path: ["surgeryDate"],
                });
              }
            } else {
              if (
                !(await trpc.case.surgeryDate.caseIsInOwnBlockTime.mutate({
                  surgeryDate: kase.surgeryDate,
                  roomId: kase.roomId,
                  expectedCaseLength: kase.expectedCaseLength,
                })) &&
                !(await trpc.case.surgeryDate.facilityIsOpen.mutate({
                  surgeryDate: kase.surgeryDate,
                  roomId: kase.roomId,
                  expectedCaseLength: kase.expectedCaseLength,
                }))
              ) {
                ctx.addIssue({
                  message: "The facility is closed during the appointment time",
                  code: "custom",
                  path: ["surgeryDate"],
                });
              }
            }
          },
          async () => {
            if (globalPrisma__warn) {
              if (
                !(await caseIsClearOfOthersBlockTime(globalPrisma__warn, {
                  ...kase,
                  roomId: kase.roomId,
                  expectedCaseLength: kase.expectedCaseLength,
                }))
              ) {
                ctx.addIssue({
                  message: "The facility is unavailable during the appointment time",
                  code: "custom",
                  path: ["surgeryDate"],
                });
              }
            } else {
              if (
                !(await trpc.case.surgeryDate.caseIsClearOfOthersBlockTime.mutate({
                  surgeryDate: kase.surgeryDate,
                  roomId: kase.roomId,
                  practiceId: kase.practiceId,
                  expectedCaseLength: kase.expectedCaseLength,
                }))
              ) {
                ctx.addIssue({
                  message: "The facility is unavailable during the appointment time",
                  code: "custom",
                  path: ["surgeryDate"],
                });
              }
            }
          },
          async () => {
            if (globalPrisma__warn) {
              if (
                !(await caseIsInAvailableBlockTime(globalPrisma__warn, {
                  ...kase,
                  roomId: kase.roomId,
                  expectedCaseLength: kase.expectedCaseLength,
                })) &&
                !(await facilityAllowSchedulingInOpenTime(globalPrisma__warn, {
                  ...kase,
                  roomId: kase.roomId,
                }))
              ) {
                ctx.addIssue({
                  message: "The facility is unavailable during the appointment time",
                  code: "custom",
                  path: ["surgeryDate"],
                });
              }
            } else {
              if (
                !(await trpc.case.surgeryDate.caseIsInOwnBlockTime.mutate({
                  surgeryDate: kase.surgeryDate,
                  roomId: kase.roomId,
                  expectedCaseLength: kase.expectedCaseLength,
                })) &&
                !(await trpc.case.surgeryDate.facilityAllowSchedulingInOpenTime.mutate({
                  roomId: kase.roomId,
                }))
              ) {
                ctx.addIssue({
                  message: "The facility is unavailable during the appointment time",
                  code: "custom",
                  path: ["surgeryDate"],
                });
              }
            }
          },
        ].map((fn) => fn.call(null))
      );
    }
  };

  if (page) {
    const allValidatedFields = Object.keys(v.shape);
    const fieldsToValidate = allValidatedFields.filter(
      (f) => PAGE_FOR_FIELD[f as keyof typeof PAGE_FOR_FIELD] === page
    );
    const keep = fieldsToValidate.reduce((acc, f) => ({ ...acc, [f]: true }), {
      id: true,
      practiceId: true,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
    const limited = (v.pick as unknown as (o: any) => any)(keep);
    if (page === "casedetails") {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      return limited.superRefine(surgeryDateValidation) as typeof v;
    } else {
      return limited as typeof v;
    }
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return v.superRefine(surgeryDateValidation);
  }
}

export function validatorForCase(
  kase: Case,
  perspective: Perspective,
  patient?: Pick<Patient, "completion"> | null
) {
  const patientSchema =
    !!kase.patientId || !!patient
      ? patientFormSchemaForCompletion(patient?.completion ?? 0, PATIENT_COMPLETION.SCHEDULING)
      : undefined;

  if (kase.status === CaseStatus.Draft || kase.status === CaseStatus.Requested) {
    return getValidator(kase.status, perspective, undefined, patientSchema);
  } else {
    return getValidator(kase.status, perspective, undefined, patientSchema);
  }
}

export function validatorForAdvancingCase(
  kase: Case,
  perspective: Perspective,
  page?: "casedetails" | "demographics",
  patient?: Pick<Patient, "completion"> | null
) {
  const patientSchema =
    !!kase.patientId || !!patient
      ? patientFormSchemaForCompletion(patient?.completion ?? 0, PATIENT_COMPLETION.SCHEDULING)
      : undefined;

  if (kase.status === CaseStatus.Draft) {
    return getValidator(CaseStatus.Requested, perspective, page, patientSchema);
  } else if (kase.status === CaseStatus.Requested) {
    return getValidator(CaseStatus.Accepted, perspective, page, patientSchema);
  } else {
    return getValidator(CaseStatus.Accepted, perspective, page, patientSchema);
  }
}
