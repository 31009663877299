import { type FC, type PropsWithChildren, type ReactNode } from "react";
import { cn } from "../../utils";
import { Label, type LabelProps } from "./Label";

export type FieldProps = {
  required?: boolean;
  disabled?: boolean;
  error?: boolean | string;
  errorProps?: { className?: string };
  label?: ReactNode;
  labelProps?: LabelProps;
  labelPreserveCase?: boolean;
  helperText?: string;
  helperTextProps?: { className?: string };
  className?: string;
};

/**
 * Wraps the given children within a form field that contains an optional label, helper text,
 * and error message. If no label content is provied, the label is omitted. In order to render
 * empty space to align with other fields that do have labels, use an empty string as the label.
 */
export const Field: FC<PropsWithChildren<FieldProps>> = ({
  required,
  disabled,
  error,
  errorProps,
  label,
  labelProps,
  labelPreserveCase,
  helperText,
  helperTextProps,
  className,
  children,
}) => {
  return (
    <div className={cn("field relative flex flex-col gap-1", className)}>
      {label !== undefined && (
        <Label
          {...labelProps}
          preserveCase={labelPreserveCase}
          required={required}
          className={cn("block", labelProps?.className)}
        >
          {label}
        </Label>
      )}
      <div className="relative flex gap-1">{children}</div>
      {error && !disabled ? (
        <div className={cn("text-sm font-normal text-error empty:hidden", errorProps?.className)}>
          {typeof error === "boolean" ? "Error" : error}
        </div>
      ) : (
        helperText && (
          <div
            className={cn(
              "text-sm font-normal text-secondary",
              disabled && "opacity-50",
              helperTextProps?.className
            )}
          >
            {helperText}
          </div>
        )
      )}
    </div>
  );
};
