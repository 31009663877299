import type { BarePrismaClient } from "@procision-software/database";
import type { PaginationInput } from "@procision-software/database-zod";
import { parsePhoneNumberWithError } from "libphonenumber-js";
import type {
  PatientCommunicationWhitelistCreateInput,
  PatientCommunicationWhitelistFilters,
  PatientCommunicationWhitelistRecord,
  PatientCommunicationWhitelistRecords,
  PatientCommunicationWhitelistUpdateInput,
} from "./types";

export async function patientCommunicationWhitelistCreate(
  prisma: BarePrismaClient,
  { channel, to: unparsedTo }: PatientCommunicationWhitelistCreateInput
): Promise<PatientCommunicationWhitelistRecord> {
  const to = channel === "SMS" ? parsePhoneNumberWithError(unparsedTo, "US").number : unparsedTo;

  return await prisma.patientCommunicationWhitelist.create({
    data: {
      channel,
      to,
    },
  });
}

export async function patientCommunicationWhitelistFindById(
  prisma: BarePrismaClient,
  id: string
): Promise<PatientCommunicationWhitelistRecord> {
  return prisma.patientCommunicationWhitelist.findFirstOrThrow({
    where: { id },
  });
}

export async function patientCommunicationWhitelistFindMany(
  prisma: BarePrismaClient,
  filters: PatientCommunicationWhitelistFilters,
  pagination: PaginationInput
): Promise<PatientCommunicationWhitelistRecords> {
  const all = await prisma.patientCommunicationWhitelist.count({
    where: {
      ...(filters.search && {
        to: {
          contains: filters.search,
        },
      }),
    },
  });

  const rows = await prisma.patientCommunicationWhitelist.findMany({
    where: {
      ...(filters.search && {
        to: {
          contains: filters.search,
        },
      }),
    },
    skip: (pagination.page - 1) * pagination.perPage,
    take: pagination.perPage,
  });

  return {
    rows,
    pagination: {
      ...pagination,
      all,
    },
  };
}

export async function patientCommunicationWhitelistUpdate(
  prisma: BarePrismaClient,
  { id, ...data }: PatientCommunicationWhitelistUpdateInput
): Promise<PatientCommunicationWhitelistRecord> {
  const { channel } = await patientCommunicationWhitelistFindById(prisma, id);

  return await prisma.patientCommunicationWhitelist.update({
    where: { id },
    data: {
      ...data,
      ...(data.to &&
        (data.channel ?? channel) === "SMS" && {
          to: parsePhoneNumberWithError(data.to, "US").number,
        }),
    },
  });
}

export async function patientCommunicationWhitelistDelete(
  prisma: BarePrismaClient,
  id: string
): Promise<boolean> {
  return (await prisma.patientCommunicationWhitelist.delete({ where: { id } })) !== null;
}

export async function patientCommunicationWhitelistValidate(
  prisma: BarePrismaClient,
  { channel, to: unparsedTo }: PatientCommunicationWhitelistCreateInput
): Promise<boolean> {
  /**
   * In production, all checks to the whitelist should be allowed. The goal of the whitelist
   * is to prevent usage of Twilio & SendGrid in development environments unless explicitly
   * allowed by the whitelist.
   */
  if (process.env.INFRA_ENV === "prod") {
    return true;
  }

  const to = channel === "SMS" ? parsePhoneNumberWithError(unparsedTo, "US").number : unparsedTo;

  const value = await prisma.patientCommunicationWhitelist.findFirst({
    select: {
      id: true,
    },
    where: {
      channel,
      to,
    },
  });

  return value !== null;
}
