"use client";

import { createContext, useContext, useState, type ReactNode } from "react";
import { Button, Dialog, type ButtonVariantProps } from "../components";

export type ConfirmButtonProps<Key extends string> = {
  label: string;
  key: Key;
} & ButtonVariantProps;

// Context for the confirm
type ConfirmContextProps = {
  confirm: <Key extends string>(
    message: ReactNode,
    title?: string,
    buttons?: (ConfirmButtonProps<Key> | null | undefined)[]
  ) => Promise<Key | null>;
};

const ConfirmContext = createContext<ConfirmContextProps | undefined>(undefined);

// Provider component for confirm
export const ConfirmProvider = <Key extends string>({ children }: { children: ReactNode }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState<ReactNode>("");
  const [title, setTitle] = useState("");
  const [buttons, setButtons] = useState<ConfirmButtonProps<Key>[]>([]);
  const [resolveConfirm, setResolveConfirm] = useState<(value: string | null) => void>(
    () => () => void 0
  );

  const confirm = (
    message: ReactNode,
    title: string = "",
    buttons: (ConfirmButtonProps<Key> | null | undefined)[] = [
      { label: "Cancel", key: "cancel" as Key, outline: true },
      { label: "OK", key: "ok" as Key, variant: "primary" },
    ]
  ): Promise<Key | null> => {
    setMessage(message);
    setTitle(title);
    setButtons(buttons.filter((button) => !!button));
    setIsVisible(true);
    return new Promise<Key | null>((resolve) => {
      setResolveConfirm(() => resolve);
    });
  };

  const handleClose = (value: string | null) => {
    setIsVisible(false);
    setTimeout(() => resolveConfirm(value), 1);
  };

  return (
    // @ts-expect-error 2322 ConfirmButtonProps and ConfirmContextProps each take a generic type extends string but TS doesn't know they're the same
    <ConfirmContext.Provider value={{ confirm }}>
      {children}
      <Dialog
        show={isVisible}
        title={title}
        onClose={() => handleClose(null)}
        panelProps={{
          className: "sm:max-w-[40rem]",
        }}
      >
        <div className="flex flex-col gap-4">
          <p>{message}</p>
          <div className="grid w-full grid-flow-col-dense items-center justify-stretch gap-4 pt-6">
            {buttons.map(({ key, label, ...buttonProps }, index) => (
              <Button key={index} {...buttonProps} onClick={() => handleClose(key)}>
                {label}
              </Button>
            ))}
          </div>
        </div>
      </Dialog>
    </ConfirmContext.Provider>
  );
};

// Hook to use the confirm
export const useConfirm = () => {
  const context = useContext(ConfirmContext);
  if (context === undefined) {
    throw new Error("useConfirm must be used within a ConfirmProvider");
  }
  return context.confirm;
};

const confirmToAlert =
  ({ confirm }: ConfirmContextProps) =>
  (message: string, title?: string) =>
    confirm(message, title, [{ label: "OK", key: "OK" }]);
const confirmToDeleteConfirm =
  ({ confirm }: ConfirmContextProps) =>
  (name: string, title?: string) =>
    confirm(`Are you sure you want to delete "${name}"?`, title, [
      { label: "Cancel", key: "cancel", outline: true },
      { label: "Delete", key: "delete", variant: "destructive" },
    ]);

export const useAlert = () => {
  const context = useContext(ConfirmContext);
  if (context === undefined) {
    throw new Error("useAlert must be used within a ConfirmProvider");
  }
  return confirmToAlert(context);
};

export const useEnhancedDialogs = () => {
  const context = useContext(ConfirmContext);
  if (context === undefined) {
    throw new Error("useEnhancedDialogs must be used within a ConfirmProvider");
  }

  return {
    confirm: context.confirm,
    alert: confirmToAlert(context),
    deleteConfirm: confirmToDeleteConfirm(context),
  };
};
