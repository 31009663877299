import type { NextPage } from "next";
import type { ComponentType, ReactElement, ReactNode } from "react";
import React from "react";
import type { NextPageWithLayout } from "~/types/next";
import type { LayoutProps } from "../components/layouts/marketing";

/**
 * This function is used to set the layout for a page.
 * Once tRPC supports Next 13 appdir, we should move to nested layouts and abandon this.
 * @param {React.ComponentType} Layout  The layout component to use.
 * @param {NextPage} page The page component to use.
 * @param {ReactElement} [subnav] The subnav component to use.
 *
 * @deprecated Use the `withLayout` function instead that includes role access
 */
export function wrapWithLayout<P, IP>(
  Layout: ComponentType<LayoutProps>,
  _page: NextPage<P, IP>,
  options?: {
    subNav?: ReactNode;
  }
) {
  const page = _page as NextPageWithLayout<P, IP>;
  page.getLayout = (page: ReactElement) => {
    return <Layout subnav={options?.subNav}>{page}</Layout>;
  };
  return page;
}
