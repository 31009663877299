import { type Prisma, type PrismaClient } from "@procision-software/database";
import modifiersJson from "~/../prisma/seed/blob/modifier_codes.json";

// Define a unique symbol
// declare const BillingModifierIdTag: unique symbol;

// Create a tagged type
// export type BillingModifierId = string & { readonly tag: typeof BillingModifierIdTag };
export type BillingModifierId = string;

// Function to tag a string
export function toBillingModifierId(id: string): BillingModifierId {
  // return id as BillingModifierId;
  return id;
}

export function billingModifierIdToString(id: BillingModifierId): string {
  return id.toString();
}

export function seedModifiersCount() {
  return modifiersJson.length;
}

export async function seedModifiers(prisma: PrismaClient) {
  // If we don't have BillingModifier:manage, we'll fail quickly
  for (const modifier of modifiersJson) {
    const modifierCode = modifier.code.toString();
    // Check for existing record
    const existingModifier = await prisma.billingModifier.findFirst({
      where: {
        modifierCode,
      },
    });

    const modifierData: Prisma.BillingModifierCreateInput = {
      modifierCode: modifierCode,
      description: modifier.description,
      name: modifier.name,
    };

    if (existingModifier) {
      // Update existing record
      await prisma.billingModifier.update({
        where: { id: existingModifier.id },
        data: modifierData,
      });
    } else {
      // Create new record
      await prisma.billingModifier.create({
        data: modifierData,
      });
    }
  }
  return { length: modifiersJson.length };
}
