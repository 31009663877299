import { z } from 'zod';
import { JsonValueSchema } from '../inputTypeSchemas/JsonValueSchema'

/////////////////////////////////////////
// CLINICAL EVENT DIMENSION SCHEMA
/////////////////////////////////////////

export const ClinicalEventDimensionSchema = z.object({
  id: z.string(),
  clinicalEventId: z.string(),
  dimensionId: z.string(),
  valueJson: JsonValueSchema,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type ClinicalEventDimension = z.infer<typeof ClinicalEventDimensionSchema>

export default ClinicalEventDimensionSchema;
